<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras concretizadas via AutoBid"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getAutoBidCompleted"
    labelCard="Comprado por"
    buyerScope="AutoBidCompleted"
    noVehicleLabel="Você não possui AutoBids ativos…"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getAutoBidCompleted } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "AutoBidCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras concretizadas - AutoBid",
      section: "Encerrados",
    };
  },
  methods: {
    getAutoBidCompleted,
  },
};
</script>

<style scoped></style>
