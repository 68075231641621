import getPlatform from "@/helpers/getPlatform";
import PATHS from "./Paths";
import PLATFORMS from "./Platforms";
import PORTAL_BASE from "./PortalBase";
import CarSaleIcon from '@/assets/icons/car-sale.svg';
import CarSaleOrangeIcon from '@/assets/icons/car-sale-orange.svg';

const isMobile = () => {
  const platform = getPlatform();
  return platform === PLATFORMS.MOBILE;
}

export const HEADER_SECTIONS = [
  {
    id: 'anunciantes',
    icon: isMobile() ? CarSaleOrangeIcon : CarSaleIcon,
    label: 'ANUNCIANTES',
    path: PATHS.Dealers,
    link: '/anunciantes'
  },
  {
    id: 'veiculos',
    icon: 'fa-car',
    label: 'VEÍCULOS',
    path: PATHS.Vehicles,
    link: '/veiculos'
  },
  {
    id: 'favoritos',
    icon: 'fa-heart',
    label: 'FAVORITOS',
    link: `/favoritos`,
  },
  {
    id: 'avaliacoes',
    icon: 'fa-gavel',
    label: 'MINHAS AVALIAÇÕES',
    link: `${PORTAL_BASE}/minhas-avaliacoes`,
  },
  {
    id: 'pagamentos',
    icon: 'fa-barcode',
    label: 'PAGAMENTOS',
    link: `${PORTAL_BASE}/pagamento`,
  },
  {
    id: 'reviews',
    icon: 'fa-star',
    label: 'REVIEWS',
    link: `${PORTAL_BASE}/reviews`,
  },
]

export const HEADER_SUB_MENU_SECTIONS = [
  {
    id: 'meus-dados',
    icon: 'fa-user',
    label: 'Meus dados',
    link: `${PORTAL_BASE}/meus-dados`,
  },
  {
    id: 'reviews-pendentes',
    icon: 'fa-star',
    label: 'Reviews pendentes',
    link: `${PORTAL_BASE}/reviews`,
  },
  {
    id: 'minhas-compras',
    icon: 'fa-gavel',
    label: 'Minhas compras',
    link: `${PORTAL_BASE}/minhas-avaliacoes/arremates`,
  },
  {
    id: 'sair',
    icon: 'fa-power-off',
    label: 'Sair',
    link: `${PORTAL_BASE}/logout`,
  },
]