import { useToast, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

// demo: https://vue-toastification.maronato.dev/

const optionsToast = {
  position: "top-right",
  transition: "Vue-Toastification__fade",
  toastDefaults: {
      [TYPE.ERROR]: {
          timeout: 10000,
      },
      [TYPE.SUCCESS]: {
          timeout: 3000,
      }    
  }
};

export const toast = useToast(optionsToast);
export const toastOptions = optionsToast;

export default toast;
