<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas propostas pendentes de aprovação do anunciante"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getProposalPeding"
    labelCard="Proposta enviada"
    buyerScope="ProposalPending"
    priceDescriptionPage="Você comprou por"
    noVehicleLabel="Você ainda não possui propostas pendentes"
  />
</template>

<script>
import { BUYER_SECTION_PENDING } from "@/views/buyer/constants/BuyerSections";

import { getProposalPeding } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import NavigateMixings from "@/mixins/NavigateMixings";

import BuyerOneColumnPage from "../BuyerOneColumnPage";

export default {
  name: "ProposalPendingPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_PENDING,
      title: "Propostas pendentes de aprovação",
      section: "Pendentes",
 
    };
  },
  methods: {
    getProposalPeding,
  }
};
</script>

<style lang="scss" scoped></style>
