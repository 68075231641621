<template>
  <div id="quick-view-modal-container" v-if="modalIsOpen">
    <v-container class="quick-view-modal">
      <button class="close-container" @click="closeModal" id="fazer_avaliacao_fechar_modal_botao">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M12.9703 1.65685L1.65662 12.9706M1.65662 1.65685L12.9703 12.9706"
            stroke="#B7B7B7"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="quick-view-header">
        <h2>Visualização Rápida</h2>
      </div>
      <v-row class="content" v-if="loading">
        Carregando ...
      </v-row>
      <v-row class="content" v-else-if="vehiclePackages?.length === 0">
        <v-col cols="12" sm="12" md="4" xl="4" lg="4" :style="isMobile() ? 'height: 200px' : ''">
          <v-img
            :src="
              vehicle.foto === null
                ? require('@/assets/images/VehicleItemImage.png')
                : vehicle.foto
            "
            width="100%"
            :height="isMobile() ? '160px' : '280px'"
            cover
            class="image quick-view-img"
            aspect-ratio="1/1"
          >
            <div class="overlay"></div>
            <span class="favorite" :id="'fav-' + vehicle.ava_id">
              <Heart
                @click="handleSetFavorite(vehicle.ava_id)"
                color="#ee2424"
                :size="22"
                fill="#ee2424"
                v-if="isFavorite"
                id="fazer_avaliacao_favoritar_botao"
              />
              <Heart
                @click="handleSetFavorite(vehicle.ava_id)"
                color="white"
                :size="22"
                v-else
              />
            </span>
            <span class="location">{{ vehicle.cidade }}/{{ vehicle.uf }}</span>
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
            <template v-slot:error>
              <v-img
                :src="require('@/assets/images/VehicleItemImage.png')"
              ></v-img>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" sm="12" md="8" xl="7" lg="8" class="details-container">
          <v-container style="padding: 0" fluid>
            <v-row justify="center" align="center">
              <v-col cols="8">
                <h2>
                  {{ vehicle.v_marca }} <strong>{{ vehicle.v_modelo }}</strong>
                </h2>
              </v-col>
              <v-col cols="4">
                <p class="price">
                  R$
                  {{
                    toFormatBRL(parseFloat(vehicle.tem_oferta ?? vehicle.valor))
                  }}
                </p>
              </v-col>
            </v-row>
          </v-container>

          <p>{{ vehicle.v_versao }}</p>
          <div class="details">
            <div class="detail">
              <img
                :src="require('@/assets/icons/calendar-icon.svg')"
                height="25"
                width="25"
              />
              <div>
                <small>Ano</small>
                <span>{{ vehicle.v_anof }}/{{ vehicle.v_anom }}</span>
              </div>
            </div>
            <div class="detail">
              <img
                :src="require('@/assets/icons/fuel-icon.svg')"
                height="25"
                width="25"
              />
              <div>
                <small>Combustível</small>
                <span>{{ capitalize(vehicle.v_combustivel) }}</span>
              </div>
            </div>
            <div class="detail">
              <img
                :src="require('@/assets/icons/transmission-icon.svg')"
                height="25"
                width="25"
                style="margin-left: 3px"
              />
              <div>
                <small>Câmbio</small>
                <span>{{ capitalize(vehicle.v_cambio) }}</span>
              </div>
            </div>
          </div>
          <v-divider
            class="mt-4 mb-3"
            :thickness="2"
            color="#808080"
          ></v-divider>
          <div>
            <div class="left-container" v-if="showBuyNowButton">
              <h2>COMPRE JÁ</h2>
              <span
                >R${{
                  toFormatBRL(parseFloat(vehicle.vlr_compra_arremate))
                }}
                </span
              >
  
          </div>
          <div class="right-container">
            <h2>AVALIE AGORA</h2>
            <span
              >R$ {{
                toFormatBRL(parseFloat(vehicle.tem_oferta ?? vehicle.valor))
              }}
              </span
            >
            <div class="input-value-container">
              <v-text-field
                :error-messages="errorMessage"
                prefix="R$ "
                autofocus
                variant="underlined"
                v-model="ofertaFormatted"
              ></v-text-field>
            </div>            
          </div>
          </div>
          <div class="container-buttons">
            <button
              class="confirm-button"
              type="button"
              @click="modalBuyNow = true"
              v-if="showBuyNowButton"
              :disabled="loading"
              id="fazer_avaliacao_compre_ja_botao"
            >
              <v-icon class="icon" icon="mdi-plus-circle" v-if="!loading"></v-icon>
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="loading"
              ></v-progress-circular>
              COMPRE JÁ
            </button>
            <button
              class="confirm-button"
              @click="handleOferta"
              :disabled="errorMessage !== '' || !validForm || loading"
              id="fazer_avaliacao_botao"
            >
              <v-icon class="icon" icon="mdi-check" v-if="!loading"></v-icon>
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="loading"
              ></v-progress-circular>
              AVALIE AGORA
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row class="content" v-else-if="vehiclePackages?.length > 0">
        <v-carousel
            height="auto"
            show-arrows="show-arrows"
            hide-delimiter-background
            hide-delimiters
            :continuous="false"
        >
          <template v-slot:prev="{ props }">
            <span
                class="arrow previous"
                @click="props.onClick"
                :style="isMobile() ? 'top: 100px' : ''"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </span>
          </template>
          <template v-slot:next="{ props }">
            <span
                class="arrow next"
                @click="props.onClick"
                :style="isMobile() ? 'top: 100px' : ''"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </span>
          </template>

          <v-carousel-item
              v-for="(vehicle, i) in vehiclePackages"
              :key="i"
          >
            <v-row>
              <v-col cols="12" sm="12" md="4" xl="4" lg="4" :style="isMobile() ? 'height: 200px' : ''">
          <v-img
            :src="
              vehicle.foto === null
                ? require('@/assets/images/VehicleItemImage.png')
                : vehicle.foto
            "
            width="100%"
            :height="isMobile() ? '160px' : '280px'"
            cover
            class="image quick-view-img"
            aspect-ratio="1/1"
          >
            <div class="overlay"></div>
            <span class="favorite" :id="'fav-' + vehicle.ava_id">
              <Heart
                @click="handleSetFavorite(vehicle.ava_id)"
                color="#ee2424"
                :size="22"
                fill="#ee2424"
                v-if="isFavorite"
                id="fazer_avaliacao_favoritar_botao"
              />
              <Heart
                @click="handleSetFavorite(vehicle.ava_id)"
                color="white"
                :size="22"
                v-else
              />
            </span>
            <span class="location">{{ vehicle.cidade }}/{{ vehicle.uf }}</span>
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
            <template v-slot:error>
              <v-img
                :src="require('@/assets/images/VehicleItemImage.png')"
              ></v-img>
            </template>
          </v-img>
        </v-col>
              <v-col cols="12" sm="12" md="8" xl="7" lg="8" class="details-container">
                <v-container style="padding: 0" fluid>
                  <v-row justify="center" align="center">
                    <v-col cols="8">
                      <h2>
                        {{ vehicle.v_marca }} <strong>{{ vehicle.v_modelo }}</strong>
                      </h2>
                    </v-col>
                    <v-col cols="4">
                      <p class="price">
                        R$
                        {{
                          toFormatBRL(parseFloat(vehicle.tem_oferta ?? vehicle.valor))
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>

                <p :style="isMobile ? 'margin-top: 80px !important;' : ''">{{ vehicle.v_versao }}</p>
                <div class="details">
                  <div class="detail">
                    <img
                      :src="require('@/assets/icons/calendar-icon.svg')"
                      height="25"
                      width="25"
                    />
                    <div>
                      <small>Ano</small>
                      <span>{{ vehicle.v_anof }}/{{ vehicle.v_anom }}</span>
                    </div>
                  </div>
                  <div class="detail">
                    <img
                      :src="require('@/assets/icons/fuel-icon.svg')"
                      height="25"
                      width="25"
                    />
                    <div>
                      <small>Combustível</small>
                      <span>{{ capitalize(vehicle.v_combustivel) }}</span>
                    </div>
                  </div>
                  <div class="detail">
                    <img
                      :src="require('@/assets/icons/transmission-icon.svg')"
                      height="25"
                      width="25"
                      style="margin-left: 3px"
                    />
                    <div>
                      <small>Câmbio</small>
                      <span>{{ capitalize(vehicle.v_cambio) }}</span>
                    </div>
                  </div>
                </div>
                <v-divider
                  class="mt-4 mb-3"
                  :thickness="2"
                  color="#808080"
                ></v-divider>
                <div>
                  <div class="left-container" v-if="showBuyNowButton">
                    <h2>COMPRE JÁ</h2>
                    <span
                      >R${{
                        toFormatBRL(parseFloat(vehicle.vlr_compra_arremate))
                      }}
                      </span
                    >
                  </div>
                  <div class="right-container">
                    <h2>AVALIE AGORA</h2>
                    <span>
                      R$ {{
                        toFormatBRL(parseFloat(vehiclePackages[0]?.tem_oferta ?? vehiclePackages[0]?.valor))
                      }}
                    </span>
                  </div>

                </div>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <v-col cols="12" sm="12" md="4" xl="4" lg="4" :style="isMobile() ? 'height: 200px' : ''"></v-col>

        <v-col cols="12" sm="12" md="8" xl="7" lg="8" :style="isMobile() ? 'margin-top: auto; margin-bottom: 70px;' : ''">

          <div class="container-buttons">


            <button
                class="confirm-button"
                type="button"
                @click="modalBuyNow = true"
                v-if="showBuyNowButton"
                :disabled="loading"
                id="fazer_avaliacao_compre_ja_botao"
            >
              <v-icon class="icon" icon="mdi-plus-circle" v-if="!loading"></v-icon>
              <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
              ></v-progress-circular>
              COMPRE JÁ
            </button>

            <div class="confirm-button-container">

              <div class="input-value-container">
                <v-text-field
                    :error-messages="errorMessage"
                    prefix="R$ "
                    autofocus
                    variant="underlined"
                    v-model="ofertaFormatted"
                ></v-text-field>
              </div>

              <button
                  class="confirm-button"
                  @click="handleOferta"
                  :disabled="errorMessage !== '' || !validForm || loading"
                  id="fazer_avaliacao_botao"
                  style="width: 100%"
              >
                <v-icon class="icon" icon="mdi-check" v-if="!loading"></v-icon>
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="loading"
                ></v-progress-circular>
                AVALIE AGORA
              </button>

            </div>
          </div>
        </v-col>
      </v-row>
      <button class="ok-button" @click="closeModal" id="fazer_avaliacao_ok_botao">OK</button>
    </v-container>
    <buyNowModal
        :loading="loading"
        :opened="modalBuyNow"
        @close="modalBuyNow = false"
        @confirm="handleBuyNow"
        v-if="!confirmBuyNow && modalBuyNow"
    />
  </div>
</template>

<script>
import { capitalize, toFormatBRL, toFormatUSD } from "@/helpers/strings";
import { mapState, mapMutations, mapActions } from "vuex";
import { Heart } from "lucide-vue-next";

import VehicleCardMixin from "../mixins/VehicleCardMixin";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import toast from "@/plugins/toast";

export default {
  name: "QuickViewModal",
  mixins: [VehicleCardMixin, PlatformsMixins],
  data() {
    return {
      validForm: false,
      errorMessage: "",
      oferta: "",
      showMessageAction: false,
      messageAction: "",
    };
  },
  components: {
    Heart,
  },
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.makeEvaluationViewModal.modalIsOpen,
      vehicle: (state) => state.makeEvaluationViewModal.vehicle,
      vehiclePackages: (state) => state.makeEvaluationViewModal.vehiclePackages,
      response: (state) => state.makeEvaluationViewModal.response,
      loading: (state) => state.makeEvaluationViewModal.loading,
    }),
    ofertaFormatted: {
      get: function () {
        return this.formatAsCurrency(this.oferta);
      },
      set: function (newValue) {
        const newValueFormatted = this.formatAsCurrency(newValue);
        const value = parseFloat(
          newValueFormatted.replace(/\./g, "").replace(",", ".")
        );
        const minValue =
          parseFloat(this.vehicle?.tem_oferta ?? this.vehicle?.valor ?? this.vehiclePackages[0]?.tem_oferta ?? this.vehiclePackages[0]?.valor) +
          parseFloat(this.vehicle?.vlr_intervalo_lance ?? this.vehiclePackages[0]?.tem_oferta);
        if (minValue > value) {
          this.errorMessage = "Oferta mínima de R$ " + toFormatBRL(minValue);
        } else {
          this.errorMessage = "";
          this.validForm = true;
        }
        this.oferta = newValueFormatted;
      },
    },
    showBuyNowButton() {
      return ((this.vehicle?.vlr_compra_arremate > 0) && (this.vehicle?.exibir_compre_ja)) ||
          ((this.vehiclePackages[0]?.vlr_compra_arremate > 0) && (this.vehiclePackages[0]?.exibir_compre_ja))
    },
    containerButtonsJustifyContent() {
      return this.showBuyNowButton ? 'space-between' : 'flex-end';
    },
    confirmButtonFontWidth() {
      if (this.isMobile()) {
        return '100%';
      }
      return '50%';
    }
  },
  methods: {
    ...mapMutations("makeEvaluationViewModal", ["toggleModal", "setVehicle"]),
    ...mapActions("makeEvaluationViewModal", ["makeEvaluation", "buyNow"]),

    formatAsCurrency: (newValue) => {
      let value = Number(newValue.replace(/[^0-9]/g, ""));

      if (value === null || isNaN(parseFloat(value))) {
        return 0;
      }

      let tmp = value
        .toFixed(0)
        .slice(0, 14)
        .replace(/([0-9]{2})$/g, ",$1");

      if (tmp.length === 3) {
        tmp = "0" + tmp;
      } else if (tmp.length > 6 && tmp.length <= 9) {
        tmp = tmp.replace(/(\d{3}),(\d{2}$)/, ".$1,$2");
      } else if (tmp.length > 9 && tmp.length <= 12) {
        tmp = tmp.replace(/(\d{3})(\d{3}),(\d{2}$)/, ".$1.$2,$3");
      } else if (tmp.length > 12) {
        tmp = tmp.replace(/(\d{3})(\d{3})(\d{3}),(\d{2}$)/, ".$1.$2.$3,$4");
      }
      return tmp;
    },
    closeModal() {
      if (this.modalIsOpen) {
        this.setVehicle(null);
        this.toggleModal();
        this.oferta = "";
        this.errorMessage = "";
        this.messageAction = "";
        this.showMessageAction = false;
      }
    },
    capitalize,
    toFormatBRL,
    toFormatUSD,
    async handleOferta() {
      await this.makeEvaluation({
        id_avaliacao: this.vehicle?.ava_id ?? this.vehiclePackages[0]?.ava_id,
        valor: toFormatUSD(this.oferta),
      });
      if (this.response.statusCode !== 200) {
        this.validForm = false;
        if (this.response.statusCode === 400) {
          Object.keys(this.response.messages).forEach((key) => {
            this.errorMessage +=
              (Array.isArray(this.response.messages[key])
                ? this.response.messages[key][0]
                : this.response.messages[key]) + "\n";
          });
        } else {
          this.errorMessage = Object.values(this.response.messages)[0];
        }
        this.messageAction = this.errorMessage;
        this.showMessageAction = true;
      } else {
        toast.success("Operação realizada com sucesso!");
        this.closeModal();
        this.$emitter.emit("refreshEvent", "MakeEvaluationViewModal");
      }
    },
    async handleBuyNow() {
      await this.buyNow({
        id_avaliacao: this.vehicle?.ava_id ?? this.vehiclePackages[0]?.ava_id,
        valor: toFormatUSD(this.vehicle.vlr_compra_arremate),
      });
      if (this.response.statusCode !== 200) {
        this.validForm = false;
        if (this.response.statusCode === 400) {
          Object.keys(this.response.messages).forEach((key) => {
            this.errorMessage +=
              (Array.isArray(this.response.messages[key])
                ? this.response.messages[key][0]
                : this.response.messages[key]) + "\n";
          });
        } else {
          this.errorMessage = Object.values(this.response.messages)[0];
        }
        this.messageAction = this.errorMessage;
        this.showMessageAction = true;
      }
      else {
        this.modalBuyNow = false;
        this.closeModal();
        toast.success("Operação realizada com sucesso!");
        this.$emitter.emit("refreshEvent", "MakeEvaluationViewModal");
        if (this.isMobile()) {
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  font-size: 10pt;
}
#quick-view-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  display: flex;
  overflow-y: v-bind(quickViewModalContainerOverflowY);
  background-color: rgb(0 0 0 / 58%);
  z-index: 9999;
  
  .quick-view-modal {
    position: relative;
    background: #fff;
    box-shadow: -26px 0px 20px 0px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    min-height: 60vh;
    max-height: 100%;
    width: 55vw;
    margin: auto;
    .close-container {
      position: absolute;
      right: 20px;
      top: 10px;
    }
    .confirm-button-container {
      width: v-bind(confirmButtonFontWidth);
    }
    .container-buttons {
      display: flex;
      align-items: center;
      justify-content: v-bind(containerButtonsJustifyContent);
      width: 100%;
      .confirm-button {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        margin: 10px 0;
        width: v-bind(confirmButtonFontWidth);
        min-width: 170px;
        margin: 5px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 12px;
        background-color: #fdad07;
        color: white;
        font-size: 14px;
        &:disabled {
          cursor: unset;
          opacity: 0.5;
        }
        .icon {
          font-size: 24px;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
    .quick-view-header {
      padding: 10px;
      padding-top: 0px;
      margin: 15px;
      text-align: left;
      border-bottom: 1px solid #e4e4e4;
      h2 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 124%; /* 19.84px */
      }
    }

    .content {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 97.5%; /* 19.5px */
      padding: 10px;
      height: 100%;
      margin-bottom: 20px;
      .quick-view-img {
        margin-top: 5px;
        width: 100%;
        max-height: 300px;
        border-radius: 5px;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: block;
          height: 100%;
          border-radius: 5px;
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            #000000 0.01%,
            rgba(0, 0, 0, 0) 33.11%
          );
          z-index: 0;
        }

        .location {
          font-size: 12px;
          color: #fff;
          position: absolute;
          top: 4px;
          left: 4px;
          font-family: "Lexend Deca";
        }

        .favorite {
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
        }
      }

      .details-container {
        margin-top: 20px;
        h2 {
          font-size: 25px;
        }
        p {
          margin: 20px 0px;
          font-weight: 400;
          font-size: 17px;
        }

        .price {
          font-size: 20px;
          font-weight: 450;
        }
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 90%;
          .detail {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 6px 0;
            font-family: "Lexend Deca";
            font-style: normal;
            img {
              filter: grayscale(100%);
            }
            div {
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: space-between;
              text-align: left;
              small {
                font-size: 12px;
                font-weight: 400;
              }
              span {
                font-weight: 700;
                font-size: 17px;
                margin-left: 4px;
                text-align: left;
                flex: 1;
              }
            }
          }
        }
        .right-container {
          width: 50%;
          float: right;
          color: #808080;
          h2 {
            font-weight: 400;
            font-size: 17px;
            margin-bottom: 10px;
          }
          span {
            font-weight: 400;
            font-size: 16px;
          }
          .input-value-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            margin-top: 10px;
            small {
              color: #e4e4e4;
            }
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            input {
              width: auto;
              -moz-appearance: textfield;
              appearance: textfield;
              border: none !important;
              color: #808080;
            }
            span {
              width: auto;
              font-size: 18px;
              color: #808080;
            }
          }          
        }

        .left-container {
          width: 50%;
          float: left;
          color: #808080;
          h2 {
            font-weight: 400;
            font-size: 17px;
            margin-bottom: 12px;
          }

          @media only screen and (max-width: 920px) {
            h2 {
              margin-bottom: 0;
            }
          }

          span {
            font-weight: 600;
            font-size: 23px;
          }
          .input-value-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            margin-top: 10px;
            small {
              color: #e4e4e4;
            }
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            input {
              width: auto;
              -moz-appearance: textfield;
              appearance: textfield;
              border: none !important;
              color: #808080;
            }
            span {
              width: auto;
              font-size: 18px;
              color: #808080;
            }
          }          
        }
      }
    }
  }
  .ok-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px;
    font-weight: 700;
    background-color: #e4e4e4;
    color: #808080;
  }
}

@media (max-width: 1024px) {

  .quick-view-header h2 {
    font-size: 18px !important;
  }
  .quick-view-modal {
    height: 100vh !important;
    width: 100vw !important;
  }

  .details-container {
    margin-top: -80px !important;
  }
  .details-container p {
    font-size: 14px !important;
    margin-bottom: 0 !important;
    margin-top: 6px !important;
  }

  .details-container h2 {
    font-size: 18px !important;
  }
  .container-buttons {
    flex-direction: column;
  }
  .detail {
    margin-right: 12px !important;
  }

  .content {
    padding-top: 0 !important;
  }

  .detail small {
    font-size: 12px !important;
    margin-left: 4px;

  }
  .detail span {
    font-size: 14px !important;
  }

  .quick-view-header h2 {
    margin-bottom: 0 !important;
  }

  .right-container {
    margin-bottom: 0 !important;
  }

  .right-container h2 {
    margin-bottom: 0 !important;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.arrow {
  position: absolute;
  border: 1px solid #ff7a00;
  background-color: #ffffff79;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #ff7a00;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.previous {
  left: 0;
}

.next {
  right: 0;
}
</style>
