import { getLetterOfCreditEligibleVehiclesPending } from "@/services/api/BuyerServiceApi";

import { getBalance } from "@/services/api/CreditLetterServiceApi";

const DEFAULT_STATE = {
  balance: null,
  balanceDate: null,
  loadingBalance: false,
  vehicles: null,
  loadingVehicles: true,
  showBalance: true,
};

const walletModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setBalance(state, balance) {
      state.balance = balance;
    },
    setBalanceDate(state, balanceDate) {
      state.balanceDate = balanceDate;
    },
    setLoadingBalance(state, loadingBalance) {
      state.loadingBalance = loadingBalance;
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    setLoadingVehicles(state, loadingVehicles) {
      state.loadingVehicles = loadingVehicles;
    },
    setShowBalance(state, showBalance) {
      state.showBalance = showBalance;
    },
  },
  actions: {
    async getBalance({ commit, state }) {
      if (state.loadingBalance) return;

      commit("setLoadingBalance", true);
      const { saldo, data } = await getBalance();
      commit("setBalance", saldo);
      commit("setBalanceDate", data);
      commit("setLoadingBalance", false);
    },
    async getVehicles({ commit }) {
      commit("setLoadingVehicles", true);
      const vehicles = await getLetterOfCreditEligibleVehiclesPending();
      commit("setVehicles", vehicles);
      commit("setLoadingVehicles", false);
    },
  },
};

export default walletModule;
