<template>
  <v-container class="wallet-buy-step-container" v-if="!loading" id="wallet-buy-step-container">
    <progress-bar :steps="3" :currentStep="step === 2 ? 3 : step" id="progress-bar-wallet-buy-step-container" />
    <v-row>
      <h5 v-if="isWalletBuyPartialStep">
        <span v-if="step === 1 && discount_type === 'partial'" id="progress-bar-wallet-buy-setp-1-partial">
          Nesta operação, o valor de
          <b
            >{{
              discount.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
            será descontado</b
          >
          de seu saldo disponível, e um boleto com a diferença será gerado para
          você efetuar o pagamento.
        </span>
        <span v-if="step === 1 && discount_type === 'total'" id="progress-bar-wallet-buy-setp-1-total">
          Nesta operação, o valor de
          <b
            >{{
              discount.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
            será descontado</b
          >
          de seu saldo disponível para pagamento da taxa do veículo.
        </span>
        <p v-if="step === 2">
          <i class="fa fa-3x fa-check" style="color: #0DC561" aria-hidden="true"></i>
        </p>
        <span v-if="step === 2 && discount_type === 'partial'" id="progress-bar-wallet-buy-setp-2-partial">
          <b>Pronto!</b> Você pagou <b>parte do valor</b> da taxa AAV deste
          veículo com saldo disponível. Seu saldo foi atualizado e um
          <b>boleto com o valor residual</b> foi gerado!
        </span>
        <span v-if="step === 2 && discount_type === 'total'" id="progress-bar-wallet-buy-setp-1-total">
          <b>Parabéns!</b> Você efetuou o pagamento da taxa AAV deste veículo com
          saldo disponível e seu saldo foi atualizado.
        </span>
      </h5>
    </v-row>
    <v-row v-if="discount_type === 'partial' && step === 2" id="progress-bar-wallet-buy-setp-2-partial">
      <v-col justify="center" align="center">
        <span class="wallet-buy-partial-info">Não conseguiu visualizar o boleto? <a :href="linkForBoleto" id="progress-bar-wallet-buy-setp-2-nao-conseguiu-visualizar-boleto">Clique aqui</a></span>
      </v-col>
    </v-row>
    <v-row :class="step !== 2 ? 'wallet-buy-step-footer mt-10' : ''">
      <v-col justify="center" align="center" v-if="step === 1">
        <span>Deseja continuar?</span>
        <v-container class="mt-4">
          <v-row>
            <v-col>
              <button class="cancel" @click="cancel" id="progress-bar-wallet-buy-btn-cancel">Cancelar</button>
            </v-col>
            <v-col>
              <button class="submit" @click="confirm" id="progress-bar-wallet-buy-btn-confirm">
                {{ discount_type == "total" ? "Pagar" : "Descontar" }} e
                prosseguir
              </button>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col justify="center" align="center" v-if="step === 2">
        <button class="btn-finish" @click="cancel" id="progress-bar-wallet-buy-btn-pagar-outra-taxa">Pagar outra taxa</button>
      </v-col>
    </v-row>

    <v-row class="mt-6" v-if="discount_type === 'partial'">
      <v-col justify="center" align="center">
        <p class="info-discount-partial">
          <b>Lembrete:</b> O valor residual dessa operação deverá ser pago via
          boleto.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { URL } from "@/constants/Buyer";
import WalletModalMixin from "@/views/buyer/mixins/WalletModalMixin";
import { mapState } from "vuex";
import ProgressBar from "@/views/buyer/components/Progress.vue";
import toast from "@/plugins/toast";

export const WALLET_BUY_PARTIALS_STEP = "WALLET_BUY_PARTIALS_STEP";
export const WALLET_BUY_TOTAL_STEP = "WALLET_BUY_TOTAL_STEP";

export default {
  name: "WalletBuyStep",
  components: {ProgressBar},
  mixins: [WalletModalMixin],
  props: {
    type: String,
  },
  data: () => ({
    step: 1,
  }),
  computed: {
    ...mapState({
      vehicle: (state) => state.walletModal.vehicle,
      response: (state) => state.walletModal.response,
      loading: (state) => state.walletModal.loading,
      discount: (state) => state.walletModal.discount,
      discount_type: (state) => state.walletModal.discount_type,
    }),
    isWalletBuyPartialStep() {
      return this.type === WALLET_BUY_PARTIALS_STEP;
    },
    linkForBoleto() {
      return URL.BUYER_ACTION_BUTTON_BOLETO + this.vehicle.ava_id;
    },
  },
  methods: {
    cancel() {
      this.step = 1;
      this.navigateToHomeStep();
      this.$emitter.emit("refreshCreditLetterList");
    },
    async confirm() {
      await this.applyBilletDiscount({
        id_avaliacao: this.vehicle.ava_id,
      }).then((res) => {
        if (res.statusCode == 200) {
          this.step = 2;
          this.$emitter.emit("refreshEvent", "RefreshBalance");
        } else {
          let messages = res.messages.map((m) => "\n" + m);
          toast(messages);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-buy-step-container {
  h5 {
    color: #373737;
    text-align: center;
    font-family: "Lexend Deca";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.5%; /* 26.7px */
  }

  .wallet-buy-step-footer {
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 4px 8px;

    span {
      color: #373737;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 113.5%; /* 22.7px */
    }

    button {
      border-radius: 6px;
      width: 100%;
      padding: 16px;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 113.5%; /* 22.7px */
      height: 100%;
    }

    button:hover {
      opacity: 0.8;
    }

    .cancel {
      background: #f1f1f1;
      color: #2a2a2a;
    }

    .submit {
      background: #0dc561;
      color: #fff;
      flex: 1;
    }
  }
  @media screen and (min-width: 1024px) {
    .wallet-buy-step-footer {
      h5{
        font-size: 20px;
      }
      span, button {
        font-size: 20px;
      }
    }
  }

  .btn-finish {
    font-family: "Lexend Deca";
    border: 1px solid #ffaf2b;
    padding: 15px 20px;
    border-radius: 5px;
    font-weight: 500;
    margin-top: 20px;
  }

  .info-discount-partial {
    font-family: "Lexend Deca";
    font-size: 14px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: center;
    color: #A0A0A0;
  }

  .wallet-buy-partial-info {
font-family: "Lexend Deca";
font-size: 12px;
font-weight: 700;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color: #A0A0A0;

  }
}
</style>
