<template>
  <div id="dashboard">
    <div>
      <div class="dashboard-header">
        <h1>Dashboard - Área do comprador</h1>
      </div>
      <div class="line_up" v-if="loading">
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="line_up" v-else>
        <div class="line_up_inner active">
          <div class="card-number">
            <div>{{ payload?.anuncios?.ativos?.total || 0 }}</div>
            <div class="lh-22">Anúncios<br />Ativos</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToAutoBidInProgressPage()" id="btn-anuncios-ativos-via-autobid">
              <div>{{ payload?.anuncios?.ativos?.via_autobid || 0 }}</div>
              <div class="lh-12">Via<br />AutoBid</div>
            </div>
            <div @click="natigateToProposalInProgressPage()" id="btn-anuncios-ativos-via-proposta">
              <div>{{ payload?.anuncios?.ativos?.via_proposta || 0 }}</div>
              <div class="lh-12">Via<br />Proposta</div>
            </div>
            <div @click="natigateToBidInProgressPage()" id="btn-anuncios-ativos-avaliacoes">
              <div>{{ payload?.anuncios?.ativos?.lances_manuais || 0 }}</div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToQuotesInProgressPage()" id="btn-anuncios-ativos-via-cotacao">
              <div>{{ payload?.anuncios?.ativos?.via_cotacoes || 0 }}</div>
              <div class="lh-12">Via<br />Cotação</div>
            </div>
          </div>
        </div>
        <div class="line_up_inner overcome">
          <div class="card-number">
            <div>{{ payload?.anuncios?.superados?.total || 0 }}</div>
            <div class="lh-22">Anúncios<br />Superados</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToAutoBidInProgressPage()" id="btn-anuncios-superados-via-cotacao">
              <div>{{ payload?.anuncios?.superados?.via_autobid || 0 }}</div>
              <div class="lh-12">Via<br />AutoBid</div>
            </div>
            <div @click="natigateToProposalInProgressPage()" id="btn-anuncios-superados-via-proposta">
              <div>{{ payload?.anuncios?.superados?.via_proposta || 0 }}</div>
              <div class="lh-12">Via<br />Proposta</div>
            </div>
            <div @click="natigateToBidInProgressPage()" id="btn-anuncios-superados-avaliacoes">
              <div>{{ payload?.anuncios?.superados?.lances_manuais || 0 }}</div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToQuotesInProgressPage()" id="btn-anuncios-superados-via-cotacao">
              <div>{{ payload?.anuncios?.superados?.via_cotacoes || 0 }}</div>
              <div class="lh-12">Via<br />Cotação</div>
            </div>
          </div>
        </div>
        <div class="line_up_inner pending">
          <div class="card-number">
            <div>{{ payload?.anuncios?.pagamento_pendente?.total || 0 }}</div>
            <div class="lh-22">Anúncios com<br />pagamento pendente</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToPaymentPendingPage()" id="btn-anuncios-pagamento-pendente-via-boleto">
              <div>
                {{ payload?.anuncios?.pagamento_pendente?.via_boleto || 0 }}
              </div>
              <div class="lh-12">Via<br />Boleto</div>
            </div>
            <div @click="natigateToProposalPendingPage()" id="btn-anuncios-pagamento-pendente-propostas-pendentes">
              <div>
                {{
                  payload?.anuncios?.pagamento_pendente?.propostas_pendentes ||
                  0
                }}
              </div>
              <div class="lh-12">Propostas<br />Pendentes</div>
            </div>
            <div @click="natigateToReleasePendingPage()" id="btn-anuncios-pagamento-pendente-via-autopay">
              <div>
                {{ payload?.anuncios?.pagamento_pendente?.via_autopay || 0 }}
              </div>
              <div class="lh-12">Via<br />AutoPay</div>
            </div>
            <div>
              <div id="btn-anuncios-pagamento-pendente-via-autobid">
                {{ payload?.anuncios?.pagamento_pendente?.via_autobid || 0 }}
              </div>
              <div class="lh-12">Via<br />AutoBid</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-header historic">
        <div>
          <h1>Seu Histórico</h1>
        </div>
        <div>
          <hr />
        </div>
      </div>
      <div class="line_up" v-if="loading">
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader
            class="skeleton"
            type="image, image"
          ></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="skeleton"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="line_up" v-else>
        <div class="line_up_inner active-out">
          <div class="card-number">
            <div>{{ payload?.historico?.compras?.total || 0 }}</div>
            <div>Compras</div>
            <div>nos últimos 30 dias</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToAutoBidCompletedPurchasesClosedPage()" id="btn-compras-via-autobid">
              <div>{{ payload?.historico?.compras?.via_autobid || 0 }}</div>
              <div class="lh-12">Via<br />AutoBid</div>
            </div>
            <div @click="natigateToProposalCompletedPurchasesClosedPage()" id="btn-compras-via-proposta">
              <div>{{ payload?.historico?.compras?.via_proposta || 0 }}</div>
              <div class="lh-12">Via<br />Proposta</div>
            </div>
            <div @click="natigateToAnnouncementCompletedPurchasesClosedPage()" id="btn-compras-avaliacoes">
              <div>{{ payload?.historico?.compras?.lances_manuais || 0 }}</div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToBuyNowCompletedPurchasesClosedPage(30)" id="btn-compras-via-compre-ja">
              <div>{{ payload?.historico?.compras?.via_compre_ja || 0 }}</div>
              <div class="lh-12">Via<br />Compre Já</div>
            </div>
          </div>
        </div>
        <div class="line_up_inner overcome-out">
          <div class="card-number">
            <div>{{ payload?.historico?.compras_canceladas?.total || 0 }}</div>
            <div>Compras Canceladas</div>
            <div>nos últimos 30 dias</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToCanceledUnfulfilledPurchasesClosedPage()" id="btn-compras-canceladas-via-autobid">
              <div>
                {{ payload?.historico?.compras_canceladas?.via_autobid || 0 }}
              </div>
              <div class="lh-12">Via<br />AutoBid</div>
            </div>
            <div @click="natigateToCanceledUnfulfilledPurchasesClosedPage()" id="btn-compras-canceladas-via-proposta">
              <div>
                {{ payload?.historico?.compras_canceladas?.via_proposta || 0 }}
              </div>
              <div class="lh-12">Via<br />Proposta</div>
            </div>
            <div @click="natigateToCanceledUnfulfilledPurchasesClosedPage()" id="btn-compras-canceladas-avaliacoes">
              <div>
                {{
                  payload?.historico?.compras_canceladas?.lances_manuais || 0
                }}
              </div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToCanceledUnfulfilledPurchasesClosedPage()" id="btn-compras-canceladas-via-compre-ja">
              <div>
                {{ payload?.historico?.compras_canceladas?.via_compre_ja || 0 }}
              </div>
              <div class="lh-12">Via<br />Compre Já</div>
            </div>
          </div>
        </div>
        <div class="wallet-card-container" >
          <WalletCard :onClick="toggleModal" type="dashboard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { BUYER } from "@/constants/Buyer";
import NavigateMixings from "@/mixins/NavigateMixings";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
import WalletCard from "@/views/buyer/components/WalletCard.vue";

export default {
  name: "DashboardDesktop",
  components: {
    VSkeletonLoader,
    WalletCard,
  },
  props: {
    loading: Boolean,
    payload: Object,
  },
  mixins: [NavigateMixings],
  mounted() {
    this.setTitlePage(BUYER.TITLE_DASHBOARD);
  },
  methods: {
    ...mapMutations("buyer", ["setTitlePage"]),
    ...mapMutations("walletModal", ["toggleModal"]),
  },
  computed: {
    ...mapState({
      participantFlags: (state) => state.participantFlags.flags
    })
  }
};
</script>

<style scoped>
#dashboard {
  padding-top: 0px !important;
}

.card-number div:nth-child(1) {
  line-height: 85px;
  padding-top: 22px;
  font-size: 65px;
}

.lh-12 {
  line-height: 12px;
}

.lh-22 {
  line-height: 22px;
}

#dashboard {
  flex: 78;
  justify-content: center;
  padding-right: 25px;
  overflow: hidden;
}

.dashboard-header {
  margin: 0 0 30px 0;
  position: relative;
}

.dashboard-header h1 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-header.historic {
  display: flex;
  flex-direction: row;
}

.dashboard-header.historic > div:first-of-type {
  flex-basis: 15%;
}

.dashboard-header.historic > div:nth-of-type(2) {
  flex-basis: 85%;
  padding: 15px 0;
}

.dashboard-header.historic > div:nth-of-type(2) hr {
  border: none;
  border-bottom: 1px dotted #cecece;
}

.line_up {
  display: flex;
  flex-direction: row;
  font-family: "Lexend Deca";
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.line_up > div {
  display: flex;
  flex-basis: 33%;
}

.line_up_inner {
  display: flex;
  padding-top: 10px;
}

.line_up_inner > div {
  flex-basis: 50%;
}

.line_up_inner > div:not(.skeleton):first-of-type {
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  width: 206px;
  font-weight: 700;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  max-height: 198px;
  cursor: pointer;
}

.line_up_inner > div:is(.skeleton):first-of-type {
  background-color: transparent;
  width: 206px;
  height: 198px;
  overflow: hidden;
}

.line_up_inner.active > div:first-of-type {
  background-color: #0dc561;
  cursor: auto;
}

.line_up_inner.active-out > div:first-of-type {
  border: 2px solid #0dc561;
  background-color: #fff;
  color: #0dc561;
  cursor: auto;
}

.line_up_inner.overcome > div:first-of-type {
  background-color: #f22300;
  cursor: auto;
}

.line_up_inner.overcome-out > div:first-of-type {
  border: 2px solid #f22300;
  background-color: #fff;
  color: #f22300;
  cursor: auto;
}

.line_up_inner.pending > div:first-of-type {
  background-color: #ff7a00;
  cursor: auto;
}

.line_up_inner > div:first-of-type > div:first-of-type {
  font-size: 75px;
}

.line_up_inner > div:first-of-type > div:nth-of-type(2) {
  font-size: 18px;
}

.line_up_inner > div:first-of-type > div:nth-of-type(3) {
  font-weight: 400;
  font-size: 14px;
}

.line_up_inner_4 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.line_up_inner_4 > div:not(.skeleton) {
  width: 45%;
  max-width: 93px;
  height: 90px;
  text-align: center;
  border-radius: 12px;
  margin: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.line_up_inner_4 > div:is(.skeleton) {
  width: 42%;
  height: 90px;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
  margin: 4px;
}

.line_up_inner_4 > div > div:first-of-type {
  font-size: 32px;
  font-weight: 700;
}

.line_up_inner_4 > div > div:nth-of-type(2) {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.line_up_inner.active .line_up_inner_4 > div > div:first-of-type,
.line_up_inner.active-out .line_up_inner_4 > div > div:first-of-type {
  color: #0dc561;
}

.line_up_inner.overcome .line_up_inner_4 > div > div:first-of-type,
.line_up_inner.overcome-out .line_up_inner_4 > div > div:first-of-type {
  color: #f22300;
}

.line_up_inner.pending .line_up_inner_4 > div > div:first-of-type {
  color: #ff7a00;
  cursor: auto;
}

@media (max-width: 1729px) {
  .line_up > div {
    zoom: 0.9;
  }
  .dashboard-header h1 {
    font-size: 20px !important;
  }
}

@media (max-width: 1567px) {
  .line_up > div {
    zoom: 0.7 !important;
  }
}

@media (max-width: 1440px) {
  .line_up > div {
    zoom: 0.7;
  }
  .dashboard-header h1 {
    font-size: 20px !important;
  }
}

@media (max-width: 1294px) {
  .line_up > div {
    zoom: 0.6 !important;
  }
}

@media (max-width: 1239px) {
  .line_up_inner > div {
    flex-basis: auto;
  }
  .line_up_inner > div:first-of-type > div:nth-of-type(2) {
    font-size: 16px !important;
  }
}

@media (max-width: 1159px) {
  .line_up > div {
    flex-basis: 50%;
  }
  .line_up_inner > div {
    flex-basis: 100%;
  }
  .line_up_inner_4 > div {
    max-width: none !important;
    width: 40% !important;
  }
  .dashboard-header.historic > div:first-of-type {
    flex-basis: 20% !important;
  }
  .dashboard-header.historic > div:nth-of-type(2) {
    flex-basis: 80% !important;
  }
}

@media (max-width: 1024px) {
  .line_up > div {
    flex-basis: 100% !important;
  }
}

@media (min-width: 1700px) and (max-width: 1600px) {
  .line_up_inner_4 > div {
    width: 40%;
  }
}

@media (max-width: 1208px) {
  .line_up_inner_4 > div {
    width: 42%;
  }
}

.wallet-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wallet-card-container h5 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -40%;
  left: 0;
  z-index: 2;
  background-color: #fafafa;
  padding-right: 20px;
}

</style>
