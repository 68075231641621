<template>
  <div  >
    <div class="filter-item-header" v-bind="props" :id="`sidebar_section_${label}`.toLowerCase().replace(/ /g, '_')">
    <div class="prepend-icon">
      <img :src="icon" />
    </div>
    <div class="filter-item-header-label">{{ label }}</div>
    <div class="append-icon">
      <img
        :src="require('@/assets/icons/up-icon.svg')"
        :style="{ width: isSmall ? '15px' : '20px' }"
        :class="{
          'css-that-rotates-this-icon': !isOpen,
          'css-that-rotates-this-icon-init': isOpen,
        }"
      />
    </div>
  </div>
  </div>
 
</template>

<script>
export default {
  name: "FilterItemHeader",
  props: {
    icon: String,
    label: String,
    isOpen: Boolean,
    isSmall: Boolean,
  },
  computed: {
    backgroundColor: function () {
      if (this.isSmall) {
        return "#FFF";
      }
      return "#FAFAFA";
    },
    borderBottom: function () {
      if (this.isSmall) {
        return "1px solid #E0E0E0";
      }
      return "";
    },
    fontSize: function () {
      if (this.isSmall) {
        return "20px";
      }
      return "22px";
    },
  },
};
</script>

<style scoped>
.filter-item-header {
  display: flex;
  flex-direction: row;
  background-color: v-bind(backgroundColor);
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: v-bind(borderBottom);
}
.filter-item-header .prepend-icon {
  color: #f90;
  flex: 8;
  text-align: right;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 8px;
}
.filter-item-header .prepend-icon > * {
  vertical-align: bottom;
}
.filter-item-header .filter-item-header-label {
  flex: 73;
  font-size: v-bind(fontSize);
  font-weight: 700;
  padding-left: 10px;
  color: #373737;
  cursor: pointer;
}
.filter-item-header .append-icon {
  flex: 8;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
}

.css-that-rotates-this-icon {
  transform: rotate(180deg);
  transition: 0.2s;
}

.css-that-rotates-this-icon-init {
  transform: rotate(0deg);
  transition: 0.2s;
}
</style>
