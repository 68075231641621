export const BUYER_SECTION_IN_PROGRESS = {
  icon: require("@/assets/icons/buyer-time-icon.svg"),
  title: "Em andamento",
  description: "Seus lances e propostas em andamento",
};

export const BUYER_SECTION_PENDING = {
  icon: require("@/assets/icons/pending-icon.svg"),
  title: "Pendentes",
  description: "Compras com pagamentos pendentes",
};

export const BUYER_SECTION_CLOSED = {
  icon: require("@/assets/icons/buyer-closed-icon.svg"),
  title: "Encerrados",
  description: "Suas compras finalizadas ou canceladas",
};

export const BUYER_SECTION_SERVICE = {
  icon: require("@/assets/icons/buyer-after-sale-icon.svg"),
  title: "Atendimento",
  description: "Menu de atendimento",
};

const BUYER_SECTIONS = [
  BUYER_SECTION_IN_PROGRESS,
  BUYER_SECTION_PENDING,
  BUYER_SECTION_CLOSED,
  BUYER_SECTION_SERVICE,
];

export default BUYER_SECTIONS;
