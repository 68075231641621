<template>
  <div v-if="isOpen" class="modal-overlay active">
    <div class="modal-container">
      <div class="modal2 active">
        <div class="btn-close2" @click="closeModal">
          <button @click="closeModal">X</button>
        </div>
        <div class="modal-content">
          <div class="content1">
            <div class="content2">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
        <div class="div-button">
          <button @click="closeModal" class="close-button">FECHAR</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.btn-close2 {
  width: 100%;
  text-align: right;
  padding: 15px;
  font-family: "Font Awesome 6 Free";
}

.btn-close2 button {
  cursor: pointer;
}

.content1 {
  height: 640px;
  overflow-y: scroll;
}
.content2 {
  padding: 0 30px 80px 30px;
}

@media only screen and (max-width: 920px) {
  .content2 {
    padding: 0 30px 500px 30px !important;
    overflow: hidden;
  }
}

.div-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #f0f0f0;
  padding: 7px;
  font-weight: 500;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal2 {
  overflow: hidden !important;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-width: 60%;
  max-height: 80%;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-overlay.active,
.modal2.active {
  opacity: 1;
}

.close-button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

@media only screen and (max-width: 920px) {
  .modal2 {
    max-width: 90%;
    max-height: 90%;
  }

  .content1 {
    height: 850px;
  }
}
</style>
