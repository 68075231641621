<template>
  <BuyerTwoColumnPage
    :title="title"
    subTitle="Acompanhe suas avaliações sob aprovação!"
    :icon="icon"
    :section="section"
    :description="description"
    buyerScope="OnDemandAssesmentInProgress"
    :getContent="getOnDemandAssessmentInProgress"
    labelCard="Sua avaliação"
    :sectionLeft="{
      title: 'Avaliações sob aprovação ativas',
      subTitle:
        'Saiba quando agir. Ativas? Você está liderando. Fique de olho para continuar no topo.',
      labelCard: 'Sua avaliação',
    }"
    :sectionRight="{
      title: 'Avaliações sob aprovação superadas',
      subTitle:
        'Superadas? Outra avaliação superou a sua, mas ainda há chances! Faça uma nova avaliação e volte à frente.',
      labelCard: 'Última avaliação',
    }"
    priceDescriptionPage="Sua última avaliação em andamento"
    noVehicleLabel="Você ainda não avaliou nenhum veículo…"
    noVehicleRightLabel="Você não possui avaliações superadas"
  />
  <QuickViewModal />
</template>

<script>
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import BuyerTwoColumnPage from "../BuyerTwoColumnPage";
import { getOnDemandAssessmentInProgress } from "@/services/api/BuyerServiceApi";
import QuickViewModal from "../../components/MakeEvaluationViewModal.vue";

export default {
  name: "OnDemandAssessmentInProgressPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerTwoColumnPage, QuickViewModal },
  data: function () {
    return {
      ...BUYER_SECTION_IN_PROGRESS,
      title: "Avaliações sob aprovação",
      section: "Em andamento",
      pageDescription:
        "Avaliação ativa significa que o anunciante está decidindo se aprova ou não sua avaliação. Avaliação rejeitada, significa que o anunciante não aprovou sua avaliação!",
    };
  },
  methods: {
    getOnDemandAssessmentInProgress,
  },
};
</script>

<style scoped>
.lots-completed-purchases-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.lots-completed-purchases-page-top {
  width: 100%;
}

.lots-completed-purchases-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
