import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";

export default {
  data: () => ({
    topAppHeight: 0,
    windowHeight: window.innerHeight
  }),
  computed: {
    contentHeight() {
      var element = null;
      if (document.getElementById("header")) {
        element = document.getElementById("header").offsetHeight;
      } else if (document.getElementsByTagName("header")[0]) {
        element = document.getElementsByTagName("header")[0].offsetHeight;
      }
      const offset =
        parseFloat( element ?? '150px') + 
        (this.topAppHeight || 0);

      const height = this.windowHeight - offset;

      return `${height}px`;
    },
    contentHeightWithButtonFooter() {
      if (!this.isMobile()) {
        return this.contentHeight;
      }
      var header = null;
      if (document.getElementById("header")) {
        header = document.getElementById("header").offsetHeight;
      } else if (document.getElementsByTagName("header")[0]) {
        header = document.getElementsByTagName("header")[0].offsetHeight;
      }

      const offset =
        parseFloat(header) +
        this.topAppHeight +
        parseFloat(document.getElementById("filter-drawer-container")?.offsetHeight) ?? 0;

      const height = this.windowHeight - offset;
      return `${height}px`;
    },
  },
  methods: {
    isMobile() {
      return getPlatform() === PLATFORMS.MOBILE;
    },
    checkTopAppHeight() {
      this.topAppHeight = parseFloat(document.getElementById("top-app")?.offsetHeight)
    },
    handleResize() {
      this.checkTopAppHeight();
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    this.checkTopAppHeight();
    this.$emitter.on('setParam', () => {
      this.checkTopAppHeight();
    });
    window.addEventListener('resize', this.handleResize);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
