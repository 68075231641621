<template>
  <div class="select-container" ref="selectContainer">
    <div class="select-box" @click="toggleDropdown">
      <span>{{ selectedOption ? selectedOption.name : label }}</span>
      <span class="arrow">&#9662;</span>
    </div>
    <ul v-if="showDropdown" class="dropdown">
      <li
          v-for="option in options"
          :key="option.id"
          @mousedown="disableItemSelected != null && disableItemSelected?.includes(option.id) ? () => {} : selectOption(option)"
          :class="disableItemSelected != null && disableItemSelected?.includes(option.id) ? 'dropdown-item-disabled' : 'dropdown-item'"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    resetAfterSelection: {
      type: Boolean,
      default: false,
    },
    disableItemSelected: {
      type: Array,
      required: false,
    },
    label: String
  },
  data() {
    return {
      selectedOption: null,
      showDropdown: false,
    };
  },
  watch: {
    resetAfterSelection(newVal) {
      if (newVal) {
        this.resetSelection();
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showDropdown = false;
      this.$emit('selectSearchSelectOption', { option })
    },
    resetSelection() {
      this.selectedOption = null;
      this.showDropdown = false;
      this.$emit('selectSearchSelectOption', { option: null })
    },
    handleClickOutside(event) {
      if (this.$refs.selectContainer && !this.$refs.selectContainer.contains(event.target)) {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
};
</script>
<style scoped>
.select-container {
  width: 100%;
  max-width: 300px;
  position: relative;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
.arrow {
  font-size: 16px;
  padding-right: 5px;
}
.dropdown {
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
  color: #000000;
}
.dropdown-item {
  font-size: 11px;
  padding: 8px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
.dropdown-item-disabled {
  font-size: 11px;
  padding: 8px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
}
</style>