const DEFAULT_STATE = {
  currentMainMenuSelected: "",
  isNotFoundPage: false,
  isHideHeader: false,
  view: "grid",
};

const globalModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setView(state, view) {
      state.view = view;
    },
    setCurrentMainMenu(state, menu) {
      state.currentMainMenuSelected = menu;
    },
    setNotFoundPage(state, args) {
      const { status } = args;
      state.isNotFoundPage = status;
    },
    setIsHideHeader(state, isHideHeader) {
      state.isHideHeader = isHideHeader;
    }
  },
};

export default globalModule;
