import { ONE_SECOND_IN_MS } from "@/constants/Time";
import {
  generateBrandModelVersionQueryParam,
  parseBrandModelVersionQueryParam,
  parseQueryParams,
  removeNullOrEmptyValues,
} from "@/helpers/strings";
import { emitter } from "@/plugins/emitter";
import search from "@/services/api/VehicleServiceApi";
import testStorage from "@/services/localStorage";
import {
  getAllBrandings,
  getAllFuels,
  getAllOptions,
  getAllTransmissions,
} from "@/services/api/ItemMenuSeeAll/SeeAllService";
import router from "@/plugins/router";
import PATHS from "@/constants/Paths";

const DEFAULT_STATE = {
  loading: true,
  vehicleLoading: true,
  cacheLoaded: false,
  request: null,
  mobile: false,
  result: [],
  filters: [],
  aliasFilter: {
    quotation: "modalidade",
    vehicleCondition: "condicao",
    typeVehicle: "tipo",
    reportStatus: "laudo",
    optionals: "opcionais",
    fuel: "combustiveis",
    transmission: "transmissoes",
  },
  params: {},
  fipe: 0,
  priceMin: undefined,
  priceMax: undefined,
  yearMin: undefined,
  yearMax: undefined,
  kmMin: undefined,
  kmMax: undefined,
  page: 1,
  perPage: 0,
  total: 0,
  totalResults: 0,
  totalOfResult: 0,
  orderBy: "",
  optionalsData: {
    optionals: [],
    fuel: [],
    transmission: [],
    brands: [],
  },
};

const fieldMap = {
  adType: "tipoAnunciante",
  brands: "marca",
  cities: "cidades",
  fipe: "fipe",
  fuel: "combustivel",
  kmMax: "kmMaximo",
  kmMin: "kmMinimo",
  location: "localidade",
  optionals: "opcionais",
  order: "ordenacao",
  priceMax: "valorMaximo",
  priceMin: "valorMinimo",
  quotation: "modalidadeCompra",
  reportStatus: "laudo",
  transmission: "transmissao",
  typeVehicle: "tipo",
  vehicleCondition: "condicao",
  category: "categoria",
  yearMax: "anoMaximo",
  yearMin: "anoMinimo",
  models: "modelo",
  versions: "versao",
};

const filterFormModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setResult(state, result) {
      state.result = result;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setVehicleLoading(state, loading) {
      state.vehicleLoading = loading;
    },
    setCategories(state, categories) {
      state.categories = categories;
      this.commit("filterForm/refreshSearch");
    },
    setFipeValue(state, fipe) {
      state.fipe = fipe;
      state.params["fipe"] = fipe;
      state.page = 1;
    },
    setFipe(state, fipe) {
      state.fipe = fipe;
      state.params["fipe"] = fipe;
      state.page = 1;
      this.commit("filterForm/refreshSearch");
    },
    setPriceMin(state, priceMin) {
      state.priceMin = priceMin;
      state.params.priceMin = priceMin;
      this.commit("filterForm/refreshSearch");
    },
    setPriceMax(state, priceMax) {
      state.priceMax = priceMax;
      state.params.priceMax = priceMax;
      this.commit("filterForm/refreshSearch");
    },
    setYearMin(state, yearMin) {
      state.yearMin = yearMin;
      state.params.yearMin = yearMin;
      this.commit("filterForm/refreshSearch");
    },
    setYearMax(state, yearMax) {
      state.yearMax = yearMax;
      state.params.yearMax = yearMax;
      this.commit("filterForm/refreshSearch");
    },
    setKmMin(state, kmMin) {
      state.kmMin = kmMin;
      state.params.kmMin = kmMin;
      this.commit("filterForm/refreshSearch");
    },
    setKmMax(state, kmMax) {
      state.kmMax = kmMax;
      state.params.kmMax = kmMax;
      this.commit("filterForm/refreshSearch");
    },
    setMobile(state) {
      state.mobile = document.body.clientWidth < 1024;
    },
    setOrder(state, orderBy) {
      state.orderBy = orderBy;
      state.page = 1;
      this.commit("filterForm/refreshSearch");
    },
    setPage(state, page) {
      state.page = page;
    },
    setPerPage(state, perPage) {
      state.perPage = perPage;
    },
    setParam(state, param) {
      this.commit("filterForm/setVehicleLoading", true);
      let key = Object.keys(param)[0];
      let value = Object.is(Object.values(param)[0], {})
        ? Object.values(param)
        : Object.values(param)[0];
      state.params[key] = value;

      this.commit("filterForm/setPage", 1);
      this.commit("filterForm/refreshSearch");
    },
    refreshSearch(state, isSync = false) {
      this.commit("filterForm/setVehicleLoading", true);
      emitter.emit("setParam");
      state.loading = true;
      if (state.request !== null) {
        clearTimeout(state.request);
        state.request = null;
      }

      this.commit("filterForm/objectToQueryParams");

      const cities = state.params["cities"]
        ? state.params["cities"].map((city) => {
            const breakPoint = city.indexOf("-");
            return city.slice(0, breakPoint - 1);
          })
        : null;
      state.request = setTimeout(
        () =>
          this.dispatch("filterForm/search", {
            ...state.params,
            cities,
            fipe: state.fipe,
            priceMin: state.priceMin?.toString(),
            priceMax: state.priceMax?.toString(),
            yearMin: state.yearMin?.toString(),
            yearMax: state.yearMax?.toString(),
            kmMin: state.kmMin?.toString(),
            kmMax: state.kmMax?.toString(),
            p: state.page?.toString() ?? 0,
            order: state.orderBy?.toString(),
          }),
        isSync ? 0 : ONE_SECOND_IN_MS * 0.8
      );
    },
    objectToQueryParams(state) {
      const cities = state.params["cities"]
        ? state.params["cities"].map((city) => {
            const breakPoint = city.indexOf("-");
            return city.slice(0, breakPoint - 1);
          })
        : null;
      const obj = {
        ...state.params,
        cities,
        fipe: state.fipe,
        priceMin: state.priceMin?.toString(),
        priceMax: state.priceMax?.toString(),
        yearMin: state.yearMin?.toString(),
        yearMax: state.yearMax?.toString(),
        kmMin: state.kmMin?.toString(),
        kmMax: state.kmMax?.toString(),
        order: state.orderBy?.toString(),
      };

      const params = generateBrandModelVersionQueryParam(obj, fieldMap);
      const hasOwnProperty = Object.prototype.hasOwnProperty;

      for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const mappedKey = fieldMap[key] || key;
          if (
            ![fieldMap.brands, fieldMap.models, fieldMap.versions].includes(
              mappedKey
            ) &&
            value != null &&
            value != "" &&
            value != undefined
          ) {
            if (Array.isArray(value)) {
              if (value.length > 0) {
                if (
                  typeof value[0] === "object" &&
                  value[0] !== null &&
                  "id" in value[0]
                ) {
                  const formattedValues = value
                    .map((item) => `${item.id}`)
                    .join(",");
                  params.push(`${mappedKey}=[${formattedValues}]`);
                } else {
                  const formattedValues = value.join(",");
                  params.push(`${mappedKey}=[${formattedValues}]`);
                }
              }
            } else {
              params.push(`${mappedKey}=${encodeURIComponent(value)}`);
            }
          }
        }
      }

      const queryString = params.join("&");
      const url = new URL(window.location.href);
      url.search = queryString;
      window.history.replaceState({}, "", url);

      if ((router.currentRoute.value.name.toString() === "dashboardshowcase")
        || (url.pathname === "/favoritos"))  {
        router.push({
          path: PATHS.Vehicles + url.search,
          query: parseQueryParams(url.search),
        });
      }
    },
    removeParam(state, param) {
      this.commit("filterForm/setVehicleLoading", true);
      switch (param) {
        case "fipe":
          this.commit("filterForm/setFipe", 0);
          break;

        default:
          state.params[param] = [];
          break;
      }
      this.commit("filterForm/refreshSearch");
    },
    clear(state) {
      this.commit("filterForm/setVehicleLoading", true);
      state.fipe = 0;
      state.request = null;
      state.result = [];
      state.priceMax = undefined;
      state.priceMin = undefined;
      state.yearMin = undefined;
      state.yearMax = undefined;
      state.kmMin = undefined;
      state.kmMax = undefined;
      state.query = "";
      state.page = 1;
      state.perPage = 0;
      state.total = 0;
      state.totalOfResult = 0;
      state.totalResults = 0;
      state.orderBy = "";

      Object.keys(state.params).forEach((param) => {
        this.commit("filterForm/removeParam", param);
      });

      this.commit("filterForm/refreshSearch");
    },
    setParamsObject(state, params) {
      delete params["p"];
      Object.keys(params).forEach((param) => {
        if (params[param] && params[param].length <= 0) {
          return;
        }
        state.params[param] = params[param];
      });
    },
    setCacheLoaded(state) {
      state.cacheLoaded = true;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setTotalOfResult(state, totalOfResult) {
      state.totalOfResult = totalOfResult;
    },
    setTotalResults(state, totalResults) {
      state.totalResults = totalResults;
    },
    updateReportStatus(_, reportStatus) {
      const anotherReportStatusWasSelected = reportStatus.filter(
        (item) => item.id !== "no"
      );
      const lastReportStatus = reportStatus[reportStatus.length - 1];
      const noReportIsSelectedNow = lastReportStatus?.id === "no";

      if (noReportIsSelectedNow) {
        this.commit("filterForm/setParam", {
          reportStatus: [lastReportStatus],
        });
        return;
      }
      if (anotherReportStatusWasSelected) {
        this.commit("filterForm/setParam", {
          reportStatus: anotherReportStatusWasSelected,
        });
        return;
      }
      setTimeout(() => {
        this.commit("filterForm/setParam", { reportStatus });
        this.commit("filterForm/refreshSearch");
      }, ONE_SECOND_IN_MS * 3);
    },
  },
  actions: {
    loadFromCache({ commit, state }) {
      if (state.cacheLoaded) return;
      if (!testStorage()) return;
      const data = localStorage.getItem("paramsFilter");
      let params = typeof data == "string" ? JSON.parse(data) : false;
      if (!params) {
        localStorage.removeItem("paramsFilter");
        return;
      }
      if (state.cacheLoaded == false) commit("setCacheLoaded", true);
      commit("setParamsObject", params);
    },
    saveCache({ state }, params) {
      if (!state.cacheLoaded) return;
      if (!testStorage()) return;
      const parsed = JSON.stringify(params);
      localStorage.setItem("paramsFilter", parsed);
    },
    async search({ commit, state, dispatch }, params) {
      commit("setVehicleLoading", true);

      if (params === undefined) {
        if (
          state.optionalsData.optionals.length == 0 ||
          state.optionalsData.fuel.length == 0 ||
          state.optionalsData.transmission.length == 0 ||
          state.optionalsData.brands.length == 0
        ) {
          const [options, fuels, transmissions, brands] = await Promise.all([
            getAllOptions(),
            getAllFuels(),
            getAllTransmissions(),
            getAllBrandings(),
          ]);

          state.optionalsData.optionals = options;
          state.optionalsData.fuel = fuels;
          state.optionalsData.transmission = transmissions;
          state.optionalsData.brands = brands;
        }
        const url = new URL(window.location.href);
        const queryString = url.search;
        const urlParams = new URLSearchParams(queryString);
        const reverseFieldMap = Object.fromEntries(
          Object.entries(fieldMap).map(([key, value]) => [value, key])
        );
        const obj = {};

        for (const [i, value] of urlParams.entries()) {
          const key = i.replace(/\d/g, "");
          const originalKey = reverseFieldMap[key] || key;

          if (!["brands", "models", "versions"].includes(originalKey)) {
            if (value.startsWith("[") && value.endsWith("]")) {
              const trimmedValue = value.slice(1, -1);
              const values = trimmedValue.split(",");

              if (["optionals", "fuel", "transmission"].includes(originalKey)) {
                const newOptions = [];
                values.forEach((v) => {
                  const optionExists = state.optionalsData[originalKey].find(
                    (o) => o.id == v
                  );
                  if (optionExists) {
                    newOptions.push({ id: v, title: optionExists.name });
                  } else {
                    newOptions.push(v);
                  }
                });
                obj[originalKey] = newOptions;
              } else {
                obj[originalKey] = values;
              }
            } else {
              obj[originalKey] = value;
              if (originalKey.toString() === "fipe") {
                state.fipe = value;
              }
            }
          }
        }
        params = obj;

        const { brands, models, versions } = parseBrandModelVersionQueryParam(
          queryString,
          fieldMap
        );
        params = { ...params, brands, models, versions };

        params = removeNullOrEmptyValues(params);

        commit("setParamsObject", params);

        if (params.order) {
          state.orderBy = params.order;
        }
      }

      this.dispatch("filterForm/saveCache", params);

      const paramsFormatted = { ...params };
      const keys = Object.keys(paramsFormatted);
      keys.forEach((key) => {
        paramsFormatted[key] =
          typeof paramsFormatted[key] === "object" && paramsFormatted[key]
            ? paramsFormatted[key].map((item) =>
                typeof item === "object" ? item?.value ?? item?.id : item
              )
            : paramsFormatted[key];
      });
      search(paramsFormatted)
        .then((response) => {
          commit(
            "setResult",
            response.page > 1
              ? [...state.result, ...response.result]
              : response.result ?? []
          );
          commit("setFilters", response.filters);
          commit("setPage", response.page);
          commit("setPerPage", response.perPage);
          commit("setTotal", response.totalPages);
          commit("setTotalOfResult", response.total);
          commit("setTotalResults", response.totalResults);

          if (state.page === 1) {
            document
              .getElementById("filter-result-app")
              .scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .finally(() => {
          const filterResultAppContainer =
            document.getElementById("filter-result-app");
          const isScrollInFilterResultAppContainer =
            filterResultAppContainer.scrollHeight >
            filterResultAppContainer.clientHeight;

          if (
            !isScrollInFilterResultAppContainer &&
            state.page + 1 <= state.total
          ) {
            dispatch("pagination", state.page);
          }
          if (state.request) {
            clearTimeout(state.request);
            state.request = null;
          }
          commit("setVehicleLoading", false);
        });
    },

    async handleFipe({ state, commit, dispatch }, newFipe) {
      commit("setParagem", newFipe);
      commit("setLoading", true);

      setTimeout(() => {
        const shouldRefreshSearch = state.fipe === newFipe;
        if (shouldRefreshSearch) {
          dispatch("search");
        }
      }, ONE_SECOND_IN_MS * 2);
    },

    async pagination({ commit }, page) {
      commit("setPage", page);
      commit("refreshSearch", true);
    },
  },
};

export default filterFormModule;
