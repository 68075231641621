<template>
  <div id="ordination" :class="{ mobile: mobile }">
    <div id="ordination_wrapper">
      <div>
        <span class="total">
          <img
            v-if="isMobile"
            :src="require('@/assets/icons/car-ordenation-icon.svg')"
          />
          <strong>{{ total || 0 }}</strong>
          <span v-if="!isMobile">&nbsp;</span>
          <span :class="{ 'd-none': mobile }">Resultados</span>
        </span>
      </div>
      &nbsp;
      <div style="color: #c7c7c7">|</div>
      <div>
        <div class="select">
          <select
            id="select-order-by"
            :value="orderBy"
            @change="(event) => setOrder(event.target.value)"
          >
            <option value="" disabled>Ordenar por</option>
            <option
              :id="'opt-'+item[0]"
              :value="item[0]"
              v-for="item in Object.entries(this.ordinationOpts)"
              :key="item"
            >
              {{ item[1] }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import { mapMutations, mapState } from "vuex";
export default {
  name: "AA-Ordination",
  props: ["mobile"],
  data() {
    return {
      ordinationOpts: {
        lprc: "Menor valor",
        hprc: "Maior valor",
        ldt: "Menor tempo",
        hdt: "Maior tempo",
        mrc: "Marca, Modelo",
        vis: "Visualizações",
        km: "Menor KM",
        pac: "Pacotes",
        nwr: "Recentes",
        dsc: "Valor tabela",
        taa: "Tabela AA",
      },
    };
  },
  computed: {
    ...mapState({
      orderBy: (state) => state.filterForm.orderBy,
      perPage: (state) => state.filterForm.perPage,
      total: (state) => state.filterForm.totalOfResult,
    }),
    isMobile() {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
  },
  methods: {
    ...mapMutations("filterForm", ["setOrder"]),
  },
};
</script>
<style lang="scss" scoped>
.hide-xs {
  display: none;
}

.content-top {
  &.mobile {
    .order {
      right: 0;
      .select {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }
}
#ordination {
  border: 1px solid #c7c7c7;
  padding: 6px 15px;
  border-radius: 6px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

span.total {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.total img {
  margin-right: 4px;
}

#ordination_wrapper {
  display: flex;
  color: #393939;
}

#ordination_wrapper > div {
  padding: 0 2px;
}

#ordination_wrapper select {
  color: #393939;
}

#ordination_wrapper select:focus-visible {
  outline: none;
}

#select-icon {
  transform: rotate(90deg) !important;
}

#select-icon::before {
  font-size: 32px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 9em;
  max-width: 9em;
  padding: 0.25em;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: transparent;
  position: relative;
  font-weight: 600;
  color: #393939;

  select,
  &::after {
    grid-area: select;
  }

  &::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 1em;
    background-color: #777;
    -webkit-clip-path: polygon(
      0 56%,
      49% 85%,
      100% 54%,
      100% 68%,
      50% 100%,
      0 70%
    );
    clip-path: polygon(0 56%, 49% 85%, 100% 54%, 100% 68%, 50% 100%, 0 70%);
    position: absolute;
    bottom: 5px;
    right: 0;
  }
}
</style>
