import axios from "axios";
import API_BASE from "@/constants/ApiBase";

export default function search(params = []) {
    const slug = params.slug;
    delete params.slug;

    params.perPage = 12;
    params.page = params.p ?? 1;

    if (params.orderBy) {
        params.order = params.orderBy;
    }

    return axios
        .get(`${API_BASE}/showcase/` + slug + `/vehicles`, {
            params,
            withCredentials: true,
        })
        .then((response) => response.data.payload)
        .catch((error) => `search ${error}`);
}


