import API_BASE from "./ApiBase";
import PORTAL_BASE from "./PortalBase";

export const BUYER = {
  LINK_REQUEST_CENCELABLE: "https://autoavaliar.movidesk.com/form/7211",
  TITLE_SECTION_DEFAULT: "Página inicial",
  TITLE_DASHBOARD: "Área do comprador",
  DEFAULT_PERIOD_VALUE: "60"
};

export const URL = {
  BUYER_ACTION_BUTTON_BOLETO: process.env.BUYER_ACTION_BUTTON_BOLETO ?? "https://adm.autoavaliar.com.br/boletos/boleto_itau.php?id_avaliacao=",
  BUYER_ACTION_BUTTON_CANCELAMENTO: process.env.BUYER_ACTION_BUTTON_CANCELAMENTO ?? "https://autoavaliar.movidesk.com/form/",
  BUYER_ACTION_BUTTON_NOTA_FISCAL: process.env.BUYER_ACTION_BUTTON_NOTA_FISCAL ?? `${API_BASE}/purchases/invoice/`,
  BUYER_ACTION_BUTTON_REVIEWS: `${PORTAL_BASE}/reviews`,
};