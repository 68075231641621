<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Compras canceladas"
    :icon="icon"
    :section="section"
    :description="description"
    :vehicles="vehicles"
    labelCard="Comprado por"
    priceBackgroundColor="#F22300"
    parent="CanceledUnfulfilled"
    :getContent="getCanceledUnfufilled"
    noVehicleLabel="Você ainda não possui compras canceladas…"
    buyerScope="UnfulilledPurchases"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getCanceledUnfufilled } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "CanceledUnfulfilledPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras canceladas",
      section: "Encerrados",
    };
  },
  methods: {
    getCanceledUnfufilled,
  },
};
</script>

<style scoped></style>
