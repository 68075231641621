import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export default function getAllAdvertiserType() {
  return axios
    .get(`${API_BASE}/advertiser-type`, {
      headers: {
        token: API_TOKEN
      }
    })
    .catch((error) => `getAllSuggestions ${error}`);
}

export function getFilterData() {
  return axios
    .get(`${API_BASE}/filter`, { headers: {
      token: API_TOKEN
    }})
    .catch((error) => `getFilterData ${error}`);
}

export function getDefaultValueFilterInputs() {
  return axios
    .get(`${API_BASE}/purchasers/getDefaultValueFilterInputs`, {
      headers: {
        token: API_TOKEN
      }
    }).catch((error) => `Error in getDefaultValueFilterInputs: ${error.message}`);
}