<template>
  <div class="select-container">
    <div class="select-box">
      <input
          v-model="searchTerm"
          @input="filterOptions"
          type="text"
          class="search-input"
          @focus="filterBySelectedOption"
          @blur="handleBlur"
          :placeholder="label"
      />
      <span class="arrow">&#9662;</span>
    </div>
    <ul v-if="showDropdown" class="dropdown">
      <li
          v-for="(option) in filteredOptions"
          :key="option.id"
          @mousedown="disableItemSelected != null && disableItemSelected?.includes(option.id) ? () => {} : selectOption(option)"
          :class="disableItemSelected != null && disableItemSelected?.includes(option.id) ? 'dropdown-item-disabled' : 'dropdown-item'"
      >
        {{ option.name }}
      </li>
    </ul>
    <small class="text-red" v-if="errorMessage">
      {{ errorMessage }}
    </small>
    <p v-if="!filteredOptions.length && searchTerm && !itemSelectedExists()">
      Nenhum resultado encontrado para "{{ searchTerm }}"
    </p>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    resetAfterSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableItemSelected: {
      type: Array,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    keyName: {
      type: String,
      required: false,
    },
    label: String
  },
  data() {
    return {
      searchTerm: "",
      filteredOptions: [],
      selectedOption: null,
      showDropdown: false,
      DEFAULT_SIZE_SELECT: 10,
    };
  },
  methods: {
    filterOptions() {
      if (this.searchTerm) {
        this.filteredOptions = this.options.filter(option =>
            option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        this.filteredOptions = this.options;
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.searchTerm = option.name.toUpperCase();
      this.filteredOptions = [];
      this.showDropdown = false;
      this.$emit('selectSearchSelectOption', { option, key: this.keyName ?? null })
    },
    resetSelectedItem() {
      this.searchTerm = "";
      this.selectedOption = null;
      this.$emit('selectSearchSelectOption', { option: null, key: this.keyName ?? null })
    },
    handleBlur() {
      const matchingOption = this.options.find(
          option => option.name.toLowerCase() === this.searchTerm.toLowerCase()
      );
      if (!matchingOption) {
        this.resetSelectedItem();
      }
      this.showDropdown = false;
    },
    filterBySelectedOption() {
      this.showDropdown = true;
      if (this.selectedOption) {
        this.filteredOptions = this.options.filter(option =>
            option.name.toLowerCase().includes(this.selectedOption?.id.toLowerCase())
        );
      } else {
        this.filteredOptions = this.options;
      }
    },
    itemSelectedExists() {
      return this.options.filter(option =>
          option.id.toLowerCase().includes(this.selectedOption?.id.toLowerCase())
      ).length > 0;
    },
  },
  watch: {
    resetAfterSelection(newVal) {
      if (newVal) {
        this.resetSelectedItem();
      }
    }
  },
  mounted() {
    this.filteredOptions = this.options;
  },
};
</script>
<style scoped>
.select-container {
  width: 100%;
  max-width: 300px;
  position: relative;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.search-input {
  width: 90%;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: inherit;
}
.search-input::placeholder {
  color: #000000;
  text-transform: uppercase;
}
.arrow {
  font-size: 16px;
  padding-right: 5px;
  color: #000;
}
.dropdown {
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.dropdown-item {
  font-size: 11px;
  padding: 8px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-item-disabled {
  padding: 8px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>