<template>
  <v-container class="vehicle-card" fluid>
    <v-row fluid>
      
      <v-col cols="9" xl="8" class="vehicle-card-center pl-0 pb-0">

        <div class="header-pacote pb-5">
          <div class="mr-2 icon-title"><img :src="require('@/assets/icons/lots-icon.svg')"  /></div>
          <div class="title_card">
            <div>
              <span class="title_upperCase">Pacote {{ lot.ava_id }}</span> - Contém 
              <span class="title_upperCase">{{ lot.qtdVeic }} veículos</span><br/>
            </div>
          </div>
        </div>

        <div class="bottom mt-4 mb-2">

          <v-col cols="5" md="4" xl="4" lg="4" class="right mr-2 vehicle-card-left">

              <div class="box-proposal text-center">
                <span class="title-box-proposal">{{ labelPrice }}</span>
                <span
                  class="vehicle-card-price px-8"
                  :style="`background: ${
                    priceBackgroundColor ? priceBackgroundColor : '#0dc561'
                  };`"
                >
                  <span class="label">{{ labelAbouvePrice }}</span>
                  <span class="price">
                    <span class="symbol">R$</span>
                    {{ getPriceBase(price) }}
                    <span class="end">,{{ getPriceDecimal(price) }}</span>
                  </span>
                </span>
              </div>

          </v-col>

          <v-col cols="7" md="8" class="right ">
            <div class="seller">
              <img :src="lot.dealer_img" height="31" width="66" />
              <h3>{{ lot.em_nome }}</h3>
              <div class="rating">
                <v-rating
                  v-bind:model-value="lot.em_nota_reviews"
                  size="x-small"
                  density="compact"
                  color="#FF7A00"
                  half-increments
                  disabled
                  readonly
                ></v-rating>
              </div>
            </div>
            <CardTimer v-if="isInProgress" :dt_ts_final="lot.dt_ts_final" />
          </v-col>
          
        </div>
        
      </v-col>

      <v-col
        cols="3"
        xl="4"
        justify-content="center"
        align-items="center"
        class="vehicle-card-right pr-0"
      >
        <!-- <div class="divider"></div> -->
        <span class="actions-label">Botões de ação</span>
        <VehicleActionButtons
          :metadata="actions"
          :vehicle="lot"
          class="mt-2 pl-4 actions-buttons"
        />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import VehicleCardMixin from "../../mixins/VehicleCardMixin";
import CardTimer from "../CardTimer"
import VehicleActionButtons from "@/views/buyer/components/VehicleCard/components/VehicleActionButtons.vue";

export default {
  name: "LotCardDesktop",
  mixins: [VehicleCardMixin],
  components: { CardTimer, VehicleActionButtons },
  props: {
    lot: Object,
    label: String,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    onClick: Function,
    buyerScope: String,
    isActive: Boolean,
    labelPrice: String,
    labelAbouvePrice: String,
    price: String,
  },
  computed: {
    actions() {
      return this.actionsByBuyerScopeForPackage(this.buyerScope, this.onClick);
    },
  },
};
</script>

<style lang="scss" scoped>

  .container-bottom-card{
      align-items: flex-end !important;
  }

  .box-proposal{
    text-align: center;
    width: 100%;
  }
  .title-box-proposal{
    color: #373737;
    font-size: 16px;
    font-family: "Lexend Deca";
    font-weight: 700;
    word-wrap: break-word
  }

  .date_creation{
    color: #B4B4B4;
    font-size: 14px;
    font-family: "Lexend Deca";
    font-weight: 400;
    word-wrap: break-word
  }
  .buttons-right{
    text-align: center;
  }
  .button-show-all {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 7px;
    border: 1px solid #FF9900;
    background: #FF9900;
    color: #fff;
    font-family: "Lexend Deca";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 108.5%; /* 15.19px */
    letter-spacing: -0.28px;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
  }

  .button-alternate-values{
    align-items: center;
    display: flex;
    border-radius: 7px;
    border: 1px solid #0DC561;
    background: #fff;
    color: #0DC561;
    font-family: "Lexend Deca";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 108.5%; /* 15.19px */
    letter-spacing: -0.28px;
    padding: 12px;
    text-decoration: none;
    opacity: 0.20;
    justify-content: flex-end;
  }

  .icon-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .icon-title img{
    height: 46px;
  }
  .title_card{
    line-height: 21px;
    color: #373737;
    font-size: 16px;
    font-family: "Lexend Deca";
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title_upperCase{
    font-weight: 700;
  }

  .header-pacote {
      display: flex;

      h3 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fipe-content {
        position: relative;

        .fipe-container {
          position: relative;
        }
        .percent {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 7px;
          letter-spacing: -0.11em;
          margin-top: 12px;
          margin-left: 11px;

          color: #ffffff;
        }
        .fipe {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: -0.11em;
          margin-left: 7.8px;

          color: #ffffff;
        }
      }
    }
.vehicle-card-image img.car_img {
  width: 100% !important;
}

.vehicle-card {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;
  padding: 8px 19px !important;

  .vehicle-card-left {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    .vehicle-card-image {
      position: relative;

      img {
        width: 168px;
        height: 130.465px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 6px;
      }

      .vehicle-card-image-overlay {
        width: 100%;
        height: 130.465px;
        position: absolute;
        border-radius: 6px;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 49.66%);
        top: 0;
      }

      .favorite {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 1;
        width: 24px;
        height: 22px;
      }

      .location {
        position: absolute;
        left: 4px;
        top: 8px;
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 104%; /* 9.36px */
      }
    }

    .vehicle-card-price {
      padding: 8px 28px;
      flex-shrink: 0;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .label {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 80%;
        overflow: hidden;
        white-space: nowrap;
        /* 10.62px */
      }

      .price {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;

        .symbol {
          font-size: 12px;
          position: absolute;
          top: 2px;
          left: -18px;
        }

        .end {
          font-size: 12px;
          position: absolute;
          top: 2px;
          right: -18px;
        }
      }
    }
  }

  .vehicle-card-center {
    .header {
      display: flex;

      h3 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fipe-content {
        position: relative;

        .fipe-container {
          position: relative;
        }

        .percent {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 7px;
          letter-spacing: -0.11em;
          margin-top: 12px;
          margin-left: 11px;

          color: #ffffff;
        }

        .fipe {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: -0.11em;
          margin-left: 7.8px;

          color: #ffffff;
        }
      }
    }

    h5 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }

    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .status-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -8.5px;

        img {
          width: 21px;
          height: 21px;
          opacity: 0.7;
          mix-blend-mode: luminosity;
        }

        span {
          color: #696969;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      .left {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;

        .report {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 108.5%;
          /* 15.19px */
          letter-spacing: -0.28px;
          padding: 12px;
          text-decoration: none;
        }

        .date {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 16px;

          img {
            width: 21px;
            height: 21px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%;
            /* 17.36px */
          }
        }
      }

      .right {
        display: flex;
        padding: 0;

        .seller {
          padding: 4px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          width: 113.7px;
        }

        .seller h3 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
        }

        .timer {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 18px;

          span {
            color: #373737;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 108.5%;
            /* 17.36px */
          }
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 175px;
    background: #ededed;
  }

  .vehicle-card-right {
    position: relative;

    .actions-label {
      font-family: "Lexend Deca";
      font-weight: 400;
      color: #b3b3b3;
      background-color: #ffffff;
      position: absolute;
      font-size: 12px;
      text-align: center;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 112px;
    }

    .actions-buttons {
      border-left: 1px solid;
      border-color: #ededed;
    }

    .action-buttons-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 0 5px;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 3840px) {
  .vehicle-card-left{
    max-width: 37% !important;
    flex: 0 0 39.333333% !important;
  }
}

@media screen and (max-width: 1550px) {

  .actions-buttons {

  }

}

</style>
