<template>
  <div
    class="dashboard-top-item"
    v-on:click="goBack()"
  >
    <div class="dashboard-top-item-left">
      <img :src="icon" width="25" height="25" />
    </div>
    <div class="dashboard-top-item-mid">
      <h3>{{ title }}</h3>
      <h6>{{ description }}</h6>
    </div>
    <div class="dashboard-top-item-right">
      <img
        :src="
          shouldReturnToTheBuyerPage
            ? require('@/assets/icons/up-icon-alt.svg')
            : require('@/assets/icons/right-icon-alt.svg')
        "
      />
    </div>
  </div>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  name: "BuyerSectionItem",
  mixins: [NavigateMixings],
  
  methods: {
    goBack() {
      if(this.shouldReturnToTheBuyerPage) {
        this.$router.go(-1);
      } else {
        this.action();
      }
    }
  },
  props: {
    icon: String,
    title: String,
    description: String,
    action: Function,
    goback: Function,
    shouldReturnToTheBuyerPage: Boolean,
  },
};
</script>

<style scoped>
.dashboard-top-item {
  display: flex;
  background-color: #fff;
  padding: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.dashboard-top-item-left {
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.dashboard-top-item-mid {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dashboard-top-item-mid h3 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard-top-item-mid h6 {
  color: #909090;
  font-family: "Lexend Deca";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
