<template>
  <div class="lot-detail-mobile-page" v-if="isMobile()">
    <div v-if="!loading">
      <div class="top">
        <BuyerSectionItem
          :icon="icon"
          :title="section"
          :description="description"
          :shouldReturnToTheBuyerPage="true"
        />
      </div>

      <div class="content">
        <div
          class="top"
          :style="`background: ${isActive ? '#0dc561' : '#F22300'};`"
          v-bind="props"
          v-if="titleContainer !== null"
        >
          <div class="left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle cx="8" cy="8" r="8" fill="white" />
            </svg>
            <span class="ml-2"
              >Lote {{ isActive ? "ativo" : "superado" }}
              <span class="small">({{ vehicles.length }} veículos)</span>
            </span>
          </div>
          <span class="code">Lote {{ lot.ava_id }}</span>
        </div>

        <div class="list">
          <div class="item" v-for="(vehicle, key) in vehicles" :key="key">
            <VehicleCard
              :vehicle="vehicle"
              :label="labelCard"
              :priceBackgroundColor="priceBackgroundColor"
              :onClick="() => {}"
              :buyerScope="isInProgress ? '' : 'LotDetailPageClosed'"
              :showLabelOnly="isInProgress"
              :isInProgress="isInProgress"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
    </div>
  </div>
  <v-container class="lot-detail-page mb-12" fluid v-else>
    <div class="top">
      <h1>{{ titlePage }}</h1>
    </div>

    <div
      :class="`container ${
        titlePage !== null && titleContainer !== null ? 'mt-10' : ''
      }`"
    >
      <div class="top" v-if="titleContainer !== null">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 26 26"
          fill="none"
          v-if="isActive && titlePage !== null"
        >
          <circle cx="13" cy="13" r="13" fill="#0DC561" />
        </svg>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="!isActive && titlePage !== null"
        >
          <circle cx="13" cy="13" r="13" fill="#F22300" />
        </svg>

        <h2
          class="ml-2"
          :style="`font-size: ${isInProgress ? '20px' : '26px'}`"
        >
          {{ titleContainer }}
        </h2>
      </div>

      <div v-if="!loading">
        <div class="content mt-6">
          <div class="item" v-for="(vehicle, key) in vehicles" :key="key">
            <VehicleCard
              :vehicle="vehicle"
              :label="(scope==='UnfulilledPurchases') ? 'Sua ultima avaliação' : labelCard"
              :priceBackgroundColor="priceBackgroundColor"
              :buyerScope="`${scope}Package`"
              :showLabelOnly="isInProgress"
              :isInProgress="isInProgress"
            />
          </div>
        </div>

        <div
          class="bottom mt-4 mb-6"
          v-if="titleContainer !== null && titlePage !== null"
        >
          <span>{{ isActive ? "Sua avaliação" : "Superada" }}:</span>
          <div
            class="total ml-4 mr-4"
            :style="`background: ${isActive ? '#0dc561' : '#F22300'}`"
          >
            <span class="label">{{
              isActive ? "Ativa" : "Última avaliação"
            }}</span>
            <span class="price">
              {{
                parseFloat(total).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              }}</span
            >
          </div>
          <button class="buy-button" v-on:click="buyVehicle" :id="`area_comprador_fazer_avaliacao_pacote`">
            Fazer avaliação
          </button>
        </div>
      </div>
      <div class="loading mt-6" v-else>
        <div class="item">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card"
            width="50%"
            class="ml-1"
          ></v-skeleton-loader>
        </div>
        <div class="item mt-2">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
        </div>
        <div class="loading-footer mt-2">
          <v-skeleton-loader
            type="heading"
            width="30%"
            class="text-center"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <MakeEvaluationViewModal />

  </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import { getVehiclesByIdInPackage } from "@/services/api/BuyerServiceApi";

import {
  BUYER_SECTION_CLOSED,
  BUYER_SECTION_IN_PROGRESS,
} from "@/views/buyer/constants/BuyerSections";

import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import VehicleCard from "@/views/buyer/components/VehicleCard";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import MakeEvaluationViewModal from "../components/MakeEvaluationViewModal.vue";


export default {
  name: "PackageDetailPage",
  mixins: [PlatformsMixins],
  components: { VehicleCard, VSkeletonLoader, BuyerSectionItem, MakeEvaluationViewModal },
  data: function () {
    return {
      loading: true,
      vehicles: [],
      ...BUYER_SECTION_CLOSED,
      title: "Pacotes",
    };
  },
  computed: {
    ...mapState({
      titlePage: (state) => state.buyerLotDetail.titlePage,
      titleContainer: (state) => state.buyerLotDetail.titleContainer,
      total: (state) => state.buyerLotDetail.total,
      lot: (state) => state.buyerLotDetail.lotDetail,
      isActive: (state) => state.buyerLotDetail.isActive,
      scope: (state) => state.buyerLotDetail.scope,
      modalIsOpen: (state) => state.makeEvaluationViewModal.modalIsOpen,
    }),
    priceBackgroundColor: function () {
      if (this.scope === "UnfulilledPurchases") {
        return "#F22300";
      }

      return this.isActive ? "#0DC561" : "#F22300";
    },
    labelCard: function () {
      if (
        this.scope === "AnnouncementCompleted" ||
        this.scope === "PaymentPending" ||
        this.scope === "UnfulilledPurchases" ||
        this.scope === "ReleasePending"
      ) {
        return "Pacote comprado por";
      }

      return this.isActive ? "Avaliação Ativa" : "Avaliação Superada";
    },
    isInProgress: function () {
      return this.titleContainer !== null && this.titlePage !== null;
    },
    section: function () {
      if (this.isInProgress) {
        return "Em andamento";
      }

      return "Encerrados";
    },
    description: function () {
      if (this.isInProgress) {
        return BUYER_SECTION_IN_PROGRESS.description;
      }

      return BUYER_SECTION_CLOSED.description;
    },
  },
  methods: {
    ...mapMutations("makeEvaluationViewModal", [
      "setVehicle",
      "toggleModal",
    ]),
    ...mapActions("makeEvaluationViewModal",
    [
      "getVehiclesPackage"
    ]),
    async openModal(vehicle) {
      if (!this.modalIsOpen) {
        this.toggleModal();
        if (vehicle.qtdVeic > 1) {
          await this.getVehiclesPackage({
            id_avaliacao: vehicle.ava_id,
          })
        }
        else {
          this.setVehicle(vehicle)
        }
      }
    },
    buyVehicle: function () {
      const vehicle = this.vehicles[0];
      this.openModal(vehicle);
    },
  },
  mounted: function () {
    getVehiclesByIdInPackage(this.lot.ava_id)
      .then((vehicles) => (this.vehicles = vehicles))
      .finally(() => (this.loading = false));
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1535px) and (min-width: 1024px) {
  .lot-detail-page .content {
    width: 100% !important;
    display: block !important;
  }
  .container {
    display: block !important;
  }
  .item {
    width: 100% !important;
    display: block !important;
  }
}

.lot-detail-mobile-page {
  .green {
    background: #0dc561;
  }

  .content {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 12px;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .code {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

.lot-detail-page {
  flex: 78;
  justify-content: center;
  padding: 0;
  .top {
    h1 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .container {
    border-radius: 12px;
    background: #fff;
    padding: 21px;
    min-height: 78vh;

    .top {
      display: flex;
      align-items: center;

      h2 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 31px;
      }
    }

    .bottom {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      span {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .total {
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 167px;
        height: 42px;
        flex-shrink: 0;

        .label {
          color: #fff;
          text-align: center;
          font-feature-settings: "sups" on;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .price {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      button {
        width: 148px;
        height: 42px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #fff;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .buy-button {
        border-radius: 6px;
        border: 1px solid #ff7a00;
        background: #ff7a00;
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 16px;
      }
    }

    .loading {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        flex: 1;
        flex-direction: row;
      }

      .loading-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }
    }
  }
}
</style>
