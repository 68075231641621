<template>
  <div class="vehicle-card">
    <div class="vehicle-card-center">
      
      <div class="header">
        <div class="mr-2 icon-title"><img :src="require('@/assets/icons/lots-icon.svg')"  /></div>
        <div class="title_card">
          <div class="box-title-card">
            <span class="title_upperCase">Pacote {{ lot.ava_id }}</span> - Contém 
            <span class="title_upperCase">{{ lot.qtdVeic }} veículos</span><br/>
          </div>
        </div>
      </div>

      <div class="bottom container-bottom-card">

        <div class="right vehicle-card-left mr-2">
          <div class="box-proposal">
            <span class="title-box-proposal">{{ labelPrice }}:</span>
            <span
              class="vehicle-card-price"
              :style="`background: ${
                priceBackgroundColor ? priceBackgroundColor : '#0dc561'
              };`"
            >
              <span class="label">{{ labelAbouvePrice }}</span>
              <span class="price">
                  <span class="symbol">R$</span>
                  {{ getPriceBase(price) }}
                  <span class="end">,{{ getPriceDecimal(price) }}</span>
              </span>
            </span>
          </div>
        </div>

        <div class="right box-bottom-card">
          <div class="seller mr-1">
            <h3>{{ lot.em_nome }}</h3>
            <div class="rating">
              <v-rating
                v-bind:model-value="lot.em_nota_reviews"
                size="x-small"
                density="compact"
                color="#FF7A00"
                half-increments
                disabled
                readonly
              ></v-rating>
            </div>
          </div>
            <CardTimer v-if="isInProgress" :dt_ts_final="lot.dt_ts_final" :hideDescription="true" :isMobile="true" :isPackage="true"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VehicleCardMixin from "../../mixins/VehicleCardMixin";
import CardTimer from "../CardTimer"

export default {
  name: "LotCardDesktop",
  mixins: [VehicleCardMixin],
  components: { CardTimer },
  props: {
    lot: Object,
    label: String,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    labelAbouvePrice: String,
    price: String,
    labelPrice: String,
  },
};
</script>

<style lang="scss" scoped>

  .vehicle-card-timer{
      padding: 10px;
  }
  .container-bottom-card{
    display: flex;
    align-items: flex-end !important;
  }
  .box-title-card{
    line-height: 14px;
  }
  .box-bottom-card{
    flex:1;
  }
  .box-proposal{
    text-align: center;
  }
  .title-box-proposal{
    color: #373737;
    font-size: 12px;
    font-family: "Lexend Deca";
    font-weight: 700;
    word-wrap: break-word
  }

  .date_creation{
    color: #B4B4B4;
    font-size: 10px;
    font-family: "Lexend Deca";
    font-weight: 400;
    word-wrap: break-word
  }
  .buttons-right{
    text-align: center;
  }
  .button-show-all {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 7px;
  border: 1px solid #FF9900;
  background: #FF9900;
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.5%; /* 15.19px */
  letter-spacing: -0.28px;
  padding: 12px;
  text-decoration: none;
}

.button-alternate-values{
  align-items: center;
  display: flex;
  border-radius: 7px;
  border: 1px solid #0DC561;
  background: #fff;
  color: #0DC561;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.5%; /* 15.19px */
  letter-spacing: -0.28px;
  padding: 12px;
  text-decoration: none;
  opacity: 0.20;
  justify-content: flex-end;
}

.icon-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-title img{
  height: 30px;
}
.title_card{
  line-height: 21px;
  color: #373737;
  font-size: 14px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title_upperCase{
  font-weight: 700;
}
.vehicle-card {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 13px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;

  .vehicle-card-left {
    margin-right: 8px;
    flex: 1;

    .vehicle-card-image {
      position: relative;

      img {
        width: 100%;
        height: 130.465px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 6px;
      }

      .vehicle-card-image-overlay {
        width: 100%;
        height: 130.465px;
        position: absolute;
        border-radius: 6px;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 49.66%);
        top: 0;
      }
    }

    .vehicle-card-price {
      padding: 8px 28px;
      flex-shrink: 0;
      border-radius: 6px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .label {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 80%; /* 10.62px */
      }

      .price {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;

        .symbol {
          font-size: 12px;
          position: absolute;
          top: 2px;
          left: -18px;
        }

        .end {
          font-size: 12px;
          position: absolute;
          top: 2px;
          right: -18px;
        }
      }
    }
  }

  .vehicle-card-center {
    height: 100%;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    display: inherit;
    .header {
      display: flex;

      h3 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fipe-content {
        position: relative;

        .fipe-container {
          position: relative;
        }
        .percent {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 7px;
          letter-spacing: -0.11em;
          margin-top: 12px;
          margin-left: 11px;

          color: #ffffff;
        }
        .fipe {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: -0.11em;
          margin-left: 7.8px;

          color: #ffffff;
        }
      }
    }

    h5 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .status-item {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 21px;
          height: 21px;
          opacity: 0.7;
          mix-blend-mode: luminosity;
        }

        span {
          color: #696969;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .report {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 108.5%; /* 15.19px */
          letter-spacing: -0.28px;
          padding: 12px;
          text-decoration: none;
        }

        .date {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 16px;

          img {
            width: 21px;
            height: 21px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%; /* 17.36px */
          }
        }
      }

      .right {
        display: flex;

        .seller {
          padding: 4px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
        }

        .seller h3 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
        }

        .timer {
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 175px;
    background: #ededed;
  }

  .vehicle-card-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>