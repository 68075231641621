import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import {buyNow, getVehiclesByIdInPackage} from "@/services/api/BuyerServiceApi";
import {makeEvaluation} from "@/services/api/QuickViewModalServiceApi";

const DEFAULT_STATE = {
  modalIsOpen: false,
  loading: false,
  response: {},
  vehicle: null,
  vehiclePackages: [],
};

const makeEvaluationViewModalModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    toggleModal(state) {
      state.modalIsOpen = !state.modalIsOpen;
      if (getPlatform() === PLATFORMS.MOBILE) {
        if (state.modalIsOpen) {
          document.body.style.position = 'fixed';
        }
        if (!state.modalIsOpen) {
          document.body.style.position = '';
        }
      }
    },
    setVehicle(state, vehicle) {
      state.vehicle = vehicle;
      state.vehiclePackages = [];
    },
  },
  actions: {
    async makeEvaluation({ state }, data) {
      const { id_avaliacao, valor } = data;
      state.loading = true;
      try {
        let response = await makeEvaluation(id_avaliacao, valor);
        if (response.response) {
          state.response = response.response.data
        }
        else {
          state.response = response;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.response = error;
      }
    },
    async getVehiclesPackage({ state }, data) {
      const { id_avaliacao } = data;
      state.loading = true;
      try {
        let response = await getVehiclesByIdInPackage(id_avaliacao);
        if (response && response?.length > 0) {
          state.vehiclePackages = response;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.response = error;
      }
    },
    async buyNow({ state }, data) {
      const { id_avaliacao, valor } = data;
      state.loading = true;
      try {
        let response = await buyNow(id_avaliacao, valor);
        if (response.response) {
          state.response = response.response.data
        }
        else {
          state.response = response;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.response = error;
      }
    },
  },
};

export default makeEvaluationViewModalModule;
