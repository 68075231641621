import PLATFORMS from "@/constants/Platforms";

function getPlatform() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) || window.screen.width <= 1024
  ) {
    return PLATFORMS.MOBILE;
  }

  return PLATFORMS.DESKTOP;
}

export default getPlatform;
