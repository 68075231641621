<template>
    <div class="dashboard-header" :class="{'mobile': isMobile}">
        <div class="user-data">
            <h1 class="d-flex">
              {{ getGreeting() }}, <strong v-if="!loading" class="ml-2">{{ user?.name }}</strong>
              <v-skeleton-loader
                  v-else
                  type="text"
                  width="200px"
                  style="background-color: transparent;"
              ></v-skeleton-loader>
            </h1>
            <div class="counters">
              <div class="item" v-if="!loading">
                  <span
                      class="value"
                  >{{ purchasesLastMonth }}
                  </span>
                  <span class="label">Minhas <br> compras (Mês)</span>
              </div>
              <v-skeleton-loader
                v-else
                type="list-item-two-line"
                width="200px"
                style="background-color: transparent;"
              ></v-skeleton-loader>
              <div class="item" v-if="!loading">
                  <span class="label">{{ user?.city }}</span>
                  <span class="label">{{ user?.state }}</span>
              </div>
              <v-skeleton-loader
                v-else
                type="list-item-two-line"
                width="200px"
                style="background-color: transparent;"
              ></v-skeleton-loader>
              <div class="item" v-if="!loading">
                  <span class="value" >{{ adsFormatted }}</span>
                  <span class="label">veículos <br> disponíveis</span>
              </div>
              <v-skeleton-loader
                v-else
                type="list-item-two-line"
                width="200px"
                style="background-color: transparent;"
            ></v-skeleton-loader>
          </div>
        </div>
        <p>Navegue pelas categorias ou utilize os filtros para visualizar os veículos de seu interesse.</p>
        <v-container class="buttons showcase">
            <v-row class="row" align="center">
                <v-col cols="12" md="4" class="active">
                    <a href="#" class="vitrine"><i class="fa fa-tachometer"></i> Vitrine</a>
                </v-col>
                <v-col cols="12" md="4">
                    <a href="/anunciantes" class="anunciantes"><i class="fa fa-solid fa-bullhorn"></i> Anunciantes</a>
                </v-col>
                <v-col cols="12" md="4">
                    <a href="/favoritos" class="favoritos"><i class="fa fa-solid fa-heart"></i> Favoritos</a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
   
 <script> 
     import {mapState} from "vuex";
     import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
     import PLATFORMS from "@/constants/Platforms";
     import getPlatform from "@/helpers/getPlatform";

     export default {
         name: "DashboardHeader",
         props: {
            user: Object,
            ads: Number,
            adsFormatted: String,
            purchasesLastMonth: Number
         },
        components: { VSkeletonLoader },
         methods: {
            getGreeting() {
                const currentDate = new Date();
                const hours = currentDate.getHours();
                let greeting;

                if (hours >= 5 && hours < 12) {
                    greeting = "Bom dia";
                } else if (hours >= 12 && hours < 18) {
                    greeting = "Boa tarde";
                } else {
                    greeting = "Boa noite";
                }

                return greeting;
            }
        },

         computed: {
           ...mapState({
             loading: (state) => state.filterShowcase.dashboardLoading,
           }),
           isMobile: function () {
            const platform = getPlatform();
            return platform === PLATFORMS.MOBILE;
            }
         }
     };
 </script>
 
 <style lang="scss" scoped>
     .dashboard-header {
        color: #606060;
        padding:0 1em;

        &.mobile{
            .user-data{
                display:block;
                h1{
                    font-size: 1.2em;
                }
            }
            .buttons{
                &.showcase{
                    .v-col-12{
                        flex:auto;
                        margin:1em 1em;
                    }
                }
            }
            .v-container{
                padding:0;
            }
        }

        .buttons{
            &.showcase{
                .v-col-12{
                    flex:1;
                    margin:1em;
                    max-width: 100%;
                    box-shadow: 0 8px 20px -5px rgba(0,0,0,.15);
                    display: block;
                    transition: all .5s ease;
                    background: #FFF;
                    overflow: hidden;
                    border-radius: 8px !important;
                    border-left: 5px solid #FFF;
                    font-size: 16px;
                    text-decoration: none;
                    text-align: center;
                    text-transform: uppercase;
                    color:#111;
                    font-weight: 400;
                    border-radius: 5px;
                    border: 5px solid transparent;
                    padding:0;
                    &.active{                            
                        background-color: #feb32b;
                        a{
                            i{
                                bottom: .28em;
                            }
                        }
                    }
                    &:hover{
                        border-color: #feb32b;
                        background-color: #e1a307;
                    }
                    a{
                        padding:8px 0;
                        text-decoration: none;
                        color:#333;
                        display: block;
                        border-radius: 2px;
                        position:relative;
                        i{
                            position:absolute;
                            left:0.5em;
                            bottom:-2em;
                            font-size: 26px;
                            transition: all .2s;
                        }
                        &:hover{
                            box-shadow:  0 0 300px rgba(0,0,0,.1);
                            i{
                                bottom: .28em;
                            }
                        }
                        &.vitrine{
                            
                        }
                        &.anunciantes{
                            
                        }
                        &.favoritos{
                            
                        }
                    }
                }
            }
        }
     }
     .user-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
     }
     .user-data h1 {
        font-size: 24px;
        font-weight: normal;
        line-height: 40px;
        color: #555;
     }
     .user-data h1 strong {
        font-weight: 700;
     }
     .counters {
        display: flex;
        justify-content: space-around;
     }
     .counters .item {
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        margin: 0 12px;
     }
     .counters .item .value {
        font-weight: bold;
        font-size: 34px;
        line-height: 64px;
        line-height: 42px;
        color: #555;
     }
     .counters .item .label {
        font-weight: 600;
        letter-spacing: 1px;
        border-radius: 3px;
        font-size: 12px;
        text-transform: uppercase;
     }
 </style>
   