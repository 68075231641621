<template>
  <TopApp />
  <div class="app-content">
    <FilterSidebar v-if="!isMobile()" />
    <router-view v-slot="{ Component }">
      <!-- Use any custom transition and  to `fade` -->
      <transition name="slide" mode="out-in">
        <div id="buyer-result-app">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
    <WalletModal />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TopApp from "@/views/buyer/components/TopApp";
import FilterSidebar from "@/views/buyer/components/FilterSidebar/FilterSidebar.vue";
import WalletModal from "@/views/buyer/components/WalletModal";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import { mapMutations } from "vuex";
import {countsLeftMenu} from "@/services/api/BuyerServiceApi";

export default {
  name: "DashboardBuyer",
  mixins: [PlatformsMixins],
  components: {
    TopApp,
    FilterSidebar,
    WalletModal,
  },
  methods: {
    ...mapActions("buyer", ["getCountsLeftMenu"]),
    ...mapMutations("global", ["setCurrentMainMenu"]),
    ...mapMutations("buyer", ["setResultCountsLeftMenu"]),
    async getBuyerDetailsData() {
      countsLeftMenu()
          .then((response) =>
              this.setResultCountsLeftMenu(response)
          );
    },
  },
  computed: {
    slideLeaveActive: function () {
      if (this.isMobile()) {
        return "opacity 0.2s, transform 0.2s";
      }
      return "";
    },
    slideLeaveToOpacity: function () {
      if (this.isMobile()) {
        return "0";
      }
      return "";
    },
    slideLeaveToTransform: function () {
      if (this.isMobile()) {
        return "translateX(-100%)";
      }
      return "";
    },
  },
  mounted() {
    this.setCurrentMainMenu("buyer");
    this.getBuyerDetailsData();
    this.$emitter.on('refreshEvent', (from) => {
      if (from === 'ReleasePaymentViewModal') {
        this.getBuyerDetailsData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: v-bind(slideLeaveActive);
}
.slide-enter-from,
.slide-leave-to {
  opacity: v-bind(slideLeaveToOpacity);
  transform: v-bind(slideLeaveToTransform);
}

#buyer-result-app {
  flex: 78;
  padding: 1em;
  height: v-bind(contentHeight);
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    padding: 0;
    padding-bottom: 2em;
  }

}
</style>
