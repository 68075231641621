<template>
  <div class="select-container">
    <div class="select-box">
      <input
          v-model="searchTerm"
          ref="searchTerm"
          @input="filterOptions"
          type="text"
          class="search-input"
          @focus="filterBySelectedOption"
          @blur="handleBlur"
          :placeholder="loading ? 'Carregando...' : label"
          :disabled="loading"
      />
      <i 
        v-if="searchTerm"
        @click="resetSelectedItem"
        class="icon fa fa-close"
      />
      <span v-else-if="!loading" class="arrow" @click="arrowHandler">&#9662;</span>
      <span v-else>
        <v-progress-circular
          indeterminate
          :size="20"
          :width="3"
          color="#ff7a00"
          class="me-2"
        ></v-progress-circular>
      </span>
    </div>
    <ul v-if="showDropdown && !subItems" class="dropdown">
      <li
          v-for="(option) in filteredOptions"
          :key="option.id"
          @mousedown="disableItemSelected != null && disableItemSelected?.includes(option.id) ? () => {} : selectOption(option)"
          :class="disableItemSelected != null && disableItemSelected?.includes(option.id) ? 'dropdown-item-disabled' : 'dropdown-item'"
      >
        {{ option.name }}
      </li>
    </ul>
    <ul v-else-if="showDropdown" class="dropdown">
      <li
          v-for="(option) in filteredOptions"
          :key="option.id"
          class="dropdown-subitem"
      >
        <strong
            @mousedown.prevent="disableItemSelected != null && disableItemSelected?.includes(option.id) ? () => {} : selectOption(option)"
            class="d-block"
        >
          {{ option.name }}
        </strong>
        <ul>
          <li
              v-for="(subOption) in option.subItems"
              :key="subOption.id"
              class="dropdown-subitem-item"
          >
            <span
                @mousedown.prevent="disableItemSelected != null && disableItemSelected?.includes(subOption.id) ? () => {} : selectOption(subOption)"
                class="d-block">{{ subOption.name }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <small class="text-red" v-if="errorMessage">
      {{ errorMessage }}
    </small>
    <p v-if="!filteredOptions.length && searchTerm && !itemSelectedExists()">
      Nenhum resultado encontrado para "{{ searchTerm }}"
    </p>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    resetAfterSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableItemSelected: {
      type: Array,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    keyName: {
      type: String,
      required: false,
    },
    subItems: {
      type: Boolean,
      required: false,
      default: false
    },
    label: String,
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      searchTerm: "",
      filteredOptions: [],
      selectedOption: null,
      showDropdown: false,
      DEFAULT_SIZE_SELECT: 10,
    };
  },
  methods: {
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    filterOptions() {
      if (!this.subItems) {
        if (this.searchTerm) {
          this.filteredOptions = this.options.filter(option =>
              option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        } else {
          this.filteredOptions = this.options;
        }
      }
      else {
        if (this.searchTerm) {
          this.filteredOptions =
            this.options.map(option => {
              const filteredSubItems = option.subItems.filter(subOption =>
                  this.removeAccents(subOption.name).toLowerCase()
                      .includes(this.removeAccents(this.searchTerm).toLowerCase())
              );

              if (filteredSubItems.length > 0) {
                return {
                  ...option,
                  subItems: filteredSubItems
                };
              }

              return null;
            })
              .filter(option => option !== null);


        } else {
          this.filteredOptions = this.options;
        }
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.searchTerm = option.name.toUpperCase();
      this.filteredOptions = [];
      this.showDropdown = false;
      this.$emit('selectSearchSelectOption', { option, key: this.keyName ?? null })
    },
    resetSelectedItem() {
      this.searchTerm = "";
      this.selectedOption = null;
      this.$emit('selectSearchSelectOption', { option: null, key: this.keyName ?? null })
    },
    handleBlur() {
      let matchingOption;

      if (!this.subItems) {
        matchingOption = this.options.find(
            option => option.name.toLowerCase() === this.searchTerm.toLowerCase()
        );
      }
      else {
        matchingOption = this.options.map(option => {

          if (this.searchTerm.toLowerCase()) {
            const filteredSubItems = option.subItems.filter(subOption =>
                subOption.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
            if (filteredSubItems.length > 0 || option.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
              return {
                ...option,
                cities: filteredSubItems
              };
            }
          }

          return null;
        })
            .filter(option => option !== null)
            .length > 0
      }

      if (!matchingOption) {
        this.resetSelectedItem();
      }
      this.showDropdown = false;
    },
    filterBySelectedOption() {
      this.showDropdown = true;
      if (this.selectedOption) {
        this.filteredOptions = this.options.filter(option =>
            option.name.toLowerCase().includes(this.selectedOption?.id.toLowerCase())
        );
      } else {
        this.filteredOptions = this.options;
      }

      if (this.searchTerm && this.subItems) {
        this.filteredOptions =
            this.options.map(option => {
              const filteredSubItems = option.subItems.filter(subOption =>
                  this.removeAccents(subOption.name).toLowerCase()
                      .includes(this.removeAccents(this.searchTerm).toLowerCase())
              );

              if (filteredSubItems.length > 0) {
                return {
                  ...option,
                  subItems: filteredSubItems
                };
              }

              return null;
            })
                .filter(option => option !== null);


      }

    },
    arrowHandler() {
      this.$refs.searchTerm.focus();
      this.filterBySelectedOption()
    },
    itemSelectedExists() {
      if (!this.subItems) {
        return this.options.filter(option =>
            option.id.toLowerCase().includes(this.selectedOption?.id.toLowerCase())
        ).length > 0;
      }
      else {
        return this.options.map(option => {
          const filteredSubItems = option.subItems.filter(subOption =>
              subOption.name.toLowerCase().includes(this.selectedOption?.id.toLowerCase())
          );

          if (filteredSubItems.length > 0 || option.name.toLowerCase().includes(this.selectedOption?.id.toLowerCase())) {
            return {
              ...option,
              cities: filteredSubItems
            };
          }

          return null;
        })
          .filter(option => option !== null)
          .length > 0
      }
    },
  },
  watch: {
    resetAfterSelection(newVal) {
      if (newVal) {
        this.resetSelectedItem();
      }
    }
  },
  mounted() {
    this.filteredOptions = this.options;
  },
};
</script>
<style scoped>
.select-container {
  width: 100%;
  position: relative;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 5px;
  cursor: pointer;
  box-shadow: 1px 2px 10px 0 rgba(86, 86, 86, 0.35);
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}
.select-box:focus-within {
  border-radius: 10px 10px 0 0;
  border: 1px solid rgba(86, 86, 86, 0.35);
  border-bottom: none;
}
.search-input {
  width: 90%;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: inherit;
  padding: 5px 10px;
}
.search-input:disabled {
  cursor: not-allowed;
}
.search-input::placeholder {
  color: #ccc;
  text-transform: lowercase;
  font-weight: normal;
}
.arrow {
  font-size: 16px;
  padding-right: 15px;
  color: #000;
}
.icon {
  margin-right: 15px;
}
.dropdown {
  border: 1px solid lightgray;
  box-shadow: 1px 2px 10px 0 rgba(86, 86, 86, 0.35);
  border-top: none;
  max-height: 250px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.dropdown-item {
  font-size: 11px;
  padding: 8px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-item-disabled {
  padding: 8px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
.dropdown-subitem {
  font-size: 11px;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-subitem > strong {
  padding: 10px;
  cursor: pointer;
}
.dropdown-subitem-item {
  font-size: 11px;
  width: 100%;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-subitem-item > span {
  padding: 5px 5px 5px 30px;
  cursor: pointer;
}

.dropdown-subitem > strong:hover {
  background-color: #f0f0f0;
}
.dropdown-subitem-item > span:hover {
  background-color: #f0f0f0;
}
</style>