<template>
  <div id="filter-result-showcase-app" @scroll="onScroll" :class="(view == 'list') ? 'view-list' : 'view-grid'">
    <div class="mb-5 px-4" :class="!isMobile() ? 'd-flex justify-space-between align-center' : ''" :style="isMobile() ? 'padding: 10px 10px;' : ''">
      <h1 v-if="!isMobile() && !loading" class="d-flex title-campaign align-center" :title="carousel?.title ?? ''">
        <img v-if="carousel?.icon"
          :src="require(`@/assets/images/showcase/${carousel?.icon}`)"
          class="mr-2"
          style="width: 35px;"
        />
        {{ carousel?.title }}
      </h1>
      <v-skeleton-loader
        v-else-if="loading && !isMobile()"
        width="400px"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-else-if="loading && isMobile()"
        type="list-item-two-line"
        width="400px"
      ></v-skeleton-loader>
      <h1 v-else class="d-flex align-center" style="font-size: 18px;">
        <img v-if="carousel?.icon"
          :src="require(`@/assets/images/showcase/${carousel?.icon}`)"
          class="mr-2"
          style="width: 25px;"
        />
        {{ carousel?.title }}
      </h1>
      <p v-if="isMobile() && !loading" class="text-description" :style="!isMobile() ? 'margin-left: 10px;' : ''">
        {{ carousel?.desc }}
      </p>
      <div class="d-flex" v-if="!isMobile()">
        <view-select @change="setView" />
        <OrderSelect :mobile="false" />
      </div>
    </div>
    <div v-if="isMobile()" class="d-flex justify-space-around">
      <div class="filter-clear">
        <a class="cursor-pointer" @click="navigateToDashboardPage()"><i class="fa fa-close" /> Limpar filtros</a>
      </div>
      <OrderSelect :mobile="true" />
    </div>
    <p v-if="!isMobile() && !loading" class="text-center pb-5 mb-5" style="font-size: 16pt;  color: #555;">
      <span>{{ carousel?.desc }}</span>
    </p>
    <v-skeleton-loader
        v-else-if="!isMobile()"
      type="heading"
      width="350px"
      style="background-color: transparent;"
      class="mx-auto"
    ></v-skeleton-loader>
    <div class="filter-clear" style="margin-right: 15px;" v-if="!isMobile()">
      <a class="cursor-pointer" @click="navigateToDashboardPage()"><i class="fa fa-close" /> Limpar filtros</a>
    </div>
    <v-container v-if="loading && selectedPage <= 1" fluid>
      <v-row v-if="!isMobile()">
        <v-col
            v-for="(_, index) in Array.from(Array(8).keys())"
            v-bind:key="index"
            lg="4"
            xl="3"
            xxl="2"
        >
          <v-skeleton-loader
              class="mx-auto border card-skeleton"
              type="image, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
            v-for="(_, index) in Array.from(Array(8).keys())"
            v-bind:key="index"
            cols="12"
        >
          <v-skeleton-loader
              class="mx-auto border card-skeleton"
              type="article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container
          class="container-not-result not-result pl-6"
          v-if="vehicles.length === 0 && !isMobile()"
      >
        <v-row>
          <v-col>
            <span class="sorry-text">
              {{ textSorryNotResult }}
            </span>
          </v-col>
        </v-row>
      </v-container>

      <div v-else-if="vehicles.length === 0 && isMobile()" class="pa-6">
        <span class="sorry-text"> {{ textSorryNotResult }} </span><br />
      </div>

      <v-container
          v-if="vehicles.length > 0 && !isMobile()"
          v-bind:key="vehicles.length"
          fluid
      >
        <v-row v-show="view === 'grid'">
          <v-col
              v-for="(vehicle, index) in vehicles"
              v-bind:key="vehicle.id_avaliacao"
              v-bind:index="index"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              xxl="2"
          >
            <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
          </v-col>
        </v-row>

        <v-row v-show="view === 'list'">
          <v-col
              v-for="(vehicle, index) in vehicles"
              v-bind:key="vehicle.id_avaliacao"
              v-bind:index="index"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              xxl="6"
              >
            <VehicleItem view="list" :vehicle="vehicle" :index="index" :linha="index" />
          </v-col>
        </v-row>
      </v-container>
      <div v-else-if="vehicles.length > 0 && isMobile()">
        <div
            v-for="(vehicle, index) in vehicles"
            v-bind:key="vehicle.id_avaliacao"
        >
          <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
        </div>
      </div>
    </div>
    <LoadingItems  v-if="loading && selectedPage > 1" :loading="loading" :selectedPage="selectedPage" :totalPages="totalPages"/>
  </div>
</template>


<script>

import {mapState, mapActions, mapMutations} from "vuex";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import VehicleItem from "@/components/VehicleItem/index.vue";
import {VSkeletonLoader} from "vuetify/lib/labs/VSkeletonLoader";
import ViewSelect from "@/components/top/ViewSelect.vue";
import OrderSelect from "@/views/showcase/pages/Campaigns/components/OrderSelect.vue";
import NavigateMixings from "@/mixins/NavigateMixings";
import LoadingItems from "@/components/LoadingItems.vue";

export default {
  name: "DashboardShowcaseVehicles",
  components: {OrderSelect, ViewSelect, VSkeletonLoader, VehicleItem, LoadingItems},
  mixins: [PlatformsMixins, NavigateMixings],
  data() {
    return {
      showcaseKey: false,
      filters: [
        {
          id: "Menor Valor",
          name: "Menor Valor",
        },
        {
          id: "Maior Valor",
          name: "Maior Valor",
        },
        {
          id: "Maior tempo",
          name: "Maior tempo",
        },
        {
          id: "Menor tempo",
          name: "Menor tempo",
        },
        {
          id: "Marca Modelo",
          name: "Marca Modelo",
        },
        {
          id: "Visualizações",
          name: "Visualizações",
        },
        {
          id: "Menor KM",
          name: "Menor KM",
        },
        {
          id: "Pacotes",
          name: "Pacotes",
        },
        {
          id: "Recentes",
          name: "Recentes",
        },
        {
          id: "Valor tabela",
          name: "Valor tabela",
        },
        {
          id: "Tabela AA",
          name: "Tabela AA",
        },
        {
          id: "Melhores oportunidades",
          name: "Melhores oportunidades",
        },
        {
          id: "Recomendados para você",
          name: "Recomendados para você",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.filterShowcase.vehiclesCampaignLoading,
      vehicles: (state) => state.filterShowcase.result,
      carousel: (state) => state.filterShowcase.selectedCarousel,
      selectedPage: (state) => state.filterShowcase.page,
      totalPages: (state) => state.filterShowcase.total,
      totalResults: (state) => state.filterShowcase.totalResults,
      view: (state) => state.filterShowcase.view,
      orderBy: (state) => state.filterShowcase.orderBy,
    }),
    mobile: () => this.isMobile(),
  },
  methods: {
    ...mapActions("filterShowcase", ["search", "pagination"]),
    ...mapMutations("filterShowcase", ["setSlug", "setParam", "setView", "setVehiclesCampaignLoading"]),
    handlePaginate(page) {
      this.pagination(page);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      const reachedTheEndOfThePage = Math.round(scrollTop + clientHeight) + 1 >= scrollHeight;
      if (reachedTheEndOfThePage) {
        if (!this.loading) {
          const isTheLastPage = this.selectedPage + 1 > this.totalPages;
          if (isTheLastPage) {
            return;
          }
          this.handlePaginate(this.selectedPage + 1);
        }
      }
    },
    onResize() {
      const reachedTheEndOfThePage = parseInt(this.contentHeightWithButtonFooter) > document.querySelector("#filter-result-showcase-app .v-container").clientHeight;
      
      if (reachedTheEndOfThePage) {
        if (!this.loading) {
          
          const isTheLastPage = this.selectedPage + 1 > this.totalPages;
          if (isTheLastPage) {
            return;
          }
          this.handlePaginate(this.selectedPage + 1);
        }
      }
    },
    selectSearchSelectOption({ option }) {
      this.setVehiclesCampaignLoading(true)
      if (option) {
        this.setParam({orderBy: option.id})
      }
      else {
        this.setParam({orderBy: null})
      }
    }
  },
  mounted: async function () {
    this.page = this.selectedPage;
    if (this.$route.params.slug) {
      this.showcaseKey = this.$route.params.slug;
      this.setSlug(this.showcaseKey)
      await this.search();
    }
    if (!this.isMobile()) {
      window.addEventListener('resize', this.onResize);
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style scoped>

.title-campaign {
  line-height: 1.2;
  color: #555; overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@media screen and (max-width: 1500px) {
  .title-campaign {
    font-size: 24px;
  }
}

@media screen and (min-width: 1500px) {
  .title-campaign {
    font-size: 34px;
  }}

.text-description {
  font-size: 12px;
  color: #555;
}

.button-actions-container {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
}

#filter-result-showcase-app {
  flex: 78;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: v-bind(contentHeightWithButtonFooter);
}
.filter-clear {
  font-size: small;
  padding: 10px;
  text-align: right;
}
.filter-clear a {
  text-decoration: none;
  color: inherit;
}
</style>