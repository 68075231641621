import moment from "moment";
import { getGlobalFlagsCompany } from "@/services/api/GlobalFlagsCompanyServiceApi";

const GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_KEY = "GLOBALS_FLAGS_COMPANY";
const GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_EXPIRES_IN_HOURS = 12;

const DEFAULT_STATE = {
  flags: {
    RATINGS_REVIEW_LIMIT_START: "10",
    RATINGS_REVIEW_LIMIT_END: "60",
  },
};

const globalFlagsCompanyModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setGlobalFlagsCompany(state, globalFlagsCompany) {
      state.flags = globalFlagsCompany;
    },
  },
  actions: {
    getGlobalFlagsCompany({ commit }) {
      const globalFlagsCompanyOftheLocalStorage = localStorage.getItem(
        GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_KEY
      );
      let globalFlagsCompanyFromCache =
        typeof globalFlagsCompanyOftheLocalStorage == "string"
          ? JSON.parse(globalFlagsCompanyOftheLocalStorage)
          : false;

      if (globalFlagsCompanyFromCache) {
        const { expires } = globalFlagsCompanyFromCache;
        if (moment() > moment(expires)) {
          localStorage.removeItem(GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_KEY);
          globalFlagsCompanyFromCache = false;
        }
        if (moment() <= moment(expires)) {
          commit("setGlobalFlagsCompany", globalFlagsCompanyFromCache);
          return;
        }
      }
      if (!globalFlagsCompanyFromCache) {
        getGlobalFlagsCompany().then((globalFlagsCompany) => {
          const setGlobalFlagsCompanyFormatted = JSON.stringify({
            ...globalFlagsCompany,
            expires: moment().add(GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_EXPIRES_IN_HOURS, 'hours'),
          });
          localStorage.setItem(
            GLOBALS_FLAGS_COMPANY_LOCAL_STORAGE_KEY,
            setGlobalFlagsCompanyFormatted
          );
          commit("setGlobalFlagsCompany", globalFlagsCompany);
        });
      }
    },
  },
};

export default globalFlagsCompanyModule;
