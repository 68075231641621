<template>
  <div class="ads-card-item">
  </div>
</template>

<script>

export default {
  name: "AdsCardItem",
  props: {
    ads: Object,
  },
  data: function () {
    return {
    };
  },
  methods: {
  },
  mounted: function () {
  }
};
</script>

<style scoped>

</style>
