<template>
  <v-row v-if="vehicle?.bot_status == AUTOBID_STATUS.BID_EXECUTED">
    <v-alert class="text-center" style="line-height: 15px; margin-top: 10px" v-if="vehicle?.tem_lance == 1 && vehicle?.superado == 1" color="warning" variant="text">
      <span style="font-size: 9pt">
        Seu bot foi superado, dê um lance manual e fique com o veiculo!
      </span>
    </v-alert>
    <v-alert class="text-center" style="line-height: 15px; margin-top: 10px" v-else color="info" variant="text">
      <span style="font-size: 9pt">
        Seu bot enviou um lance!
      </span>
    </v-alert>
  </v-row>
  <v-row v-if="vehicle?.bot_status == AUTOBID_STATUS.BID_EXCEEDED">
    <v-alert class="text-center" style="line-height: 15px; margin-top: 10px" color="warning" variant="text">
      <span style="font-size: 9pt">
        Seu bot foi superado, dê um lance manual e fique com o veiculo!
      </span>
    </v-alert>
  </v-row>
  <v-row v-if="vehicle?.bot_status == AUTOBID_STATUS.BID_NOT_EXECUTED">
    <v-alert class="text-center" style="line-height: 15px; margin-top: 10px" color="info" variant="text">
      <span style="font-size: 9pt">
        Seu bot foi superado, dê um lance manual e fique com o veiculo!
      </span>
    </v-alert>
  </v-row>
  <v-row v-if="vehicle?.bot_status == AUTOBID_STATUS.DISABLED">
    <v-alert class="text-center" style="line-height: 15px; margin-top: 10px" color="info" variant="text">
      <span style="font-size: 9pt">
        Você desativou seu bot!
      </span>
    </v-alert>
  </v-row>
</template>
<script>

import {AUTOBID_STATUS} from "@/constants/AutoBid";

export default {
  name: "VehicleItemBidStatus",
  computed: {
    AUTOBID_STATUS() {
      return AUTOBID_STATUS
    }
  },
  props: {
    vehicle: Object,
  },
  data: function () {
    return {
      time: null,
      formatedTime: null,
      interval: null,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
