import moment from "moment";
import { getParticipantFlags } from "@/services/api/ParticipantFlagsServiceApi";

const PARTICIPANT_FLAGS_LOCAL_STORAGE_KEY = "PARTICIPANT_FLAGS_";
//const PARTICIPANT_FLAGS_LOCAL_STORAGE_EXPIRES_IN_HOURS = 12;

const DEFAULT_STATE = {
  flags: {
    HABILITA_CARTA_CREDITO: 0,
  },
};

const participantFlagsModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setFlags(state, participantFlags) {
        let flags = participantFlags.map( x => {
            let ob = {};
            ob[x.flag] = x.value;
          return ob
        });

      state.flags = Object.assign({}, ...flags);
    },
  },
  actions: {
    getParticipantFlags({ commit }) {
      const participantFlagsOftheLocalStorage = localStorage.getItem(
        PARTICIPANT_FLAGS_LOCAL_STORAGE_KEY
      );
      let participantFlagsFromCache =
        typeof participantFlagsOftheLocalStorage == "string"
          ? JSON.parse(participantFlagsOftheLocalStorage)
          : false;

      if (participantFlagsFromCache) {
        const { expires } = participantFlagsFromCache;
        if (moment() > moment(expires)) {
          localStorage.removeItem(PARTICIPANT_FLAGS_LOCAL_STORAGE_KEY);
          participantFlagsFromCache = false;
        }
        if (moment() <= moment(expires)) {
          commit("setFlags", participantFlagsFromCache);
          return;
        }
      }
      if (!participantFlagsFromCache) {
        getParticipantFlags().then((participantFlags) => {
          commit("setFlags", participantFlags);
        });
      }
    },
  },
};

export default participantFlagsModule;
