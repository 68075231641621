<template>
  <div v-if="!loading" class="breadcrumbs box" :class="{'bg-white':mobile, 'p-12':mobile}" id="buyer-breadcrumb">
    <p class="mobile pa-3" v-if="mobile">

      <a href="#" @click="navigateToDashboardPage()">
        <i class="mdi mdi-home"></i>
      </a>
      <span class="title-prev" @click="navigateToDashboardPage()">{{ page }} {{ carousel?.title ? ' > ' : ''}}</span>
      <span class="title-current"> {{ carousel?.title }}</span>

    </p>
    <p class="breadcrumbs" v-if="!mobile">
      <span class="title-current">Você está aqui: </span>
      <span class="title-prev text-orange" @click="navigateToDashboardPage()" id="breadcrumb-dashboard">{{ page }} > </span>
      <span class="title-current"> {{ carousel?.title }}</span>
    </p>
  </div>
  <v-skeleton-loader
    v-else
    type="text"
    width="200px"
    style="background-color: transparent;"
    class="mx-auto"
  ></v-skeleton-loader>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import {mapState} from "vuex";
import {VSkeletonLoader} from "vuetify/lib/labs/VSkeletonLoader";

export default {
  name: "AA-Breadcrumbs",
  components: {VSkeletonLoader},
  props: ["mobile"],
  mixins: [NavigateMixings],
  computed: {
    ...mapState({
      carousel: (state) => state.filterShowcase.selectedCarousel,
      loading: (state) => state.filterShowcase.vehiclesCampaignLoading,
      page: (state) => state.filterShowcase.titleBreadcrumbs,
    }),
  },
};
</script>

<style lang="scss" scoped>

$orange: #ff9900;

.title-current{
  color: #686868;
  font-size: 11px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word
}
.title-prev{
  color: #FF7A00;
  font-size: 11px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
}

.content-top {
  &.mobile {
    .breadcrumbs.box {
      height: auto;
      font-size: 0.8rem;
    }

    .breadcrumbs {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.breadcrumbs.box {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 3em;
  line-height: 12px;

  p.breadcrumbs {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    > span {
      color: #424242;
    }
  }
}
p.breadcrumbs,
p.breadcrumbs span {
  font-family: "Lexend Deca", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: -0.8px;
}
p.breadcrumbs span.label {
  text-transform: capitalize;
  color: #424242;
  font-size: inherit;
  font-weight: 400;
}
p.breadcrumbs i.fa,
.mdi {
  color: #ff9900 !important;
  font-size: 1.2rem !important;
  margin-right: 0.1em;
  position: relative;
  top: 2px;
  cursor: pointer;
}
p.breadcrumbs .caption {
  color: #ff9900;
  font-weight: 400;
  font-size: inherit;
  text-transform: capitalize;
  margin-right: 0.2em;
}

p.breadcrumbs a.home {
  text-decoration: none;
}

p.breadcrumbs .home {
  color: $orange !important;
}
.caption.home,
.caption.filtros {
  color: #aaa !important;
}
.nextArrow {
  margin: 0 0.3em;
}
.scroll-filters {
  display: inline-flex;
  white-space: nowrap;
  overflow-x: auto;
  top: 0;
  max-width: calc(100% - 20em);
  padding: 0.5em 0;
  margin-top: 0;
  margin-bottom: 0;
  /*
  @media (min-width: 1024px) {
    max-width: calc(35% + 2vw);
  }
  @media (min-width: 1200px) {
    max-width: calc(50% + -1vw);
  }
  @media (min-width: 1600px) {
    max-width: calc(60% + 1vw);
  }
  @media (min-width: 1920px) {
    max-width: calc(75% - 3vw);    
  }
  */

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  &::before {
    content: "";
    background: linear-gradient(to right, white, #fff0);
    display: block;
    height: 2em;
    width: 1.5em;
    position: fixed;
  }
}
.filter-aa:last-child .nextArrow {
  display: none;
}
</style>
