<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras concretizadas via cotação"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getQuotesCompleted"
    labelCard="Comprado por"
    buyerScope="QuotesCompleted"
    noVehicleLabel="Você ainda não cotou nenhum veículo…"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getQuotesCompleted } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "QuotesCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras concretizadas - Cotações",
      section: "Encerrados",
    };
  },
  methods: {
    getQuotesCompleted,
  },
};
</script>

<style scoped></style>
