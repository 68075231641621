<template>
    <div>OnDemandAssessmentPurchasesClosed</div>
</template>
  
  <script>
  import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";
  import PlatformsMixins from "@/mixins/PlatformsMixins";
  import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
  //import BuyerTwoColumnLot from "../BuyerTwoColumnLot";
  import { getLotsOngoing } from "@/services/api/BuyerServiceApi";
  
  export default {
    name: "OnDemandAssessmentPurchasesClosed",
    mixins: [PlatformsMixins, BuyerComponentPagesMixins],
    //components: { BuyerTwoColumnLot },
    data: function () {
      return {
        ...BUYER_SECTION_CLOSED,
        title:"Lotes",
        section: "Em andamento",
        pageDescription: "Pacote significa os veículos que você selecionou. Lote significa os veículos que o anunciante pré-selecionou."
      };
    },
    methods: {
      getLotsOngoing,
    },
  };
  </script>
  
  <style scoped>
  .lots-completed-purchases-page {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  
  .lots-completed-purchases-page-top {
    width: 100%;
  }
  
  .lots-completed-purchases-page-content {
    margin-top: 4px;
    background-color: white;
    flex: 1;
  }
  </style>
  