<template>
  <DashboardMobile v-if="isMobile()" :loading="loading" :payload="payload" />
  <DashboardDesktop v-else :loading="loading" :payload="payload" />
</template>

<script>
import { mapMutations } from "vuex";

import { BUYER } from "@/constants/Buyer";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import { details } from "@/services/api/BuyerServiceApi";

import DashboardMobile from "./DashboardMobile";
import DashboardDesktop from "./DashboardDesktop";

export default {
  name: "DashboardBuyer",
  components: {
    DashboardMobile,
    DashboardDesktop,
  },
  mixins: [PlatformsMixins],
  data() {
    return {
      loading: true,
      payload: [],
    };
  },
  methods: {
    ...mapMutations("buyer", [
      "setTitlePage",
      "setTitleSectionPage",
      "setResultCountsLeftMenu",
    ]),
    async getBuyerDetailsData() {
      Promise.all([
        details().then((response) => (this.payload = response)),
      ]).finally((this.loading = false));
    },
  },
  mounted() {
    this.setTitlePage(BUYER.TITLE_DASHBOARD);
    this.setTitleSectionPage(BUYER.TITLE_SECTION_DEFAULT);
    this.getBuyerDetailsData();
  },
};
</script>

<style scoped>
#dashboard {
  flex: 78;
  padding: 1.5em;
  justify-content: center;
  padding-right: 25px;
  overflow: hidden;
}

.dashboard-header h1 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
