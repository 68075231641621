import axios from "axios";
import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export const getAllOptions = async () => {
  const [res] = await Promise.all([axios.get(`${API_BASE}/optionals`, { headers: {
      token: API_TOKEN
    } })]);
  return res.data.payload;
};

export const getAllBrandings = async () => {
  const [res] = await Promise.all([axios.get(`${API_BASE}/brandings`, { headers: {
      token: API_TOKEN
    } })]);
  return res.data.payload;
};

export const getAllBrandingModels = async (branding) => {
  const [res] = await Promise.all([
    axios.get(`${API_BASE}/brandings/models/${branding}`, { headers: {
        token: API_TOKEN
      } }),
  ]);
  return res.data.payload;
};

export const getAllBrandingModelVersions = async (branding, model) => {
  const [res] = await Promise.all([
    axios.get(`${API_BASE}/brandings/versions/${branding}/${model}`, { headers: {
        token: API_TOKEN
      } }),
  ]);
  return res.data.payload;
};

export const getAllTransmissions = async () => {
  const [res] = await Promise.all([axios.get(`${API_BASE}/transmission-type`, { headers: {
      token: API_TOKEN
    } })]);
  return res.data.payload;
};

export const getAllFuels = async () => {
  const [res] = await Promise.all([axios.get(`${API_BASE}/fuel`, { headers: {
      token: API_TOKEN
    } })]);
  return res.data.payload;
};
