<template>
  <div class="checkboxes" v-if="!loading && (filteredOptions.length > 0 || (seeAll && typeof seeAll === 'object'))">
    <MenuTitle :text="title" />

    <div class="options">
      <label
        v-for="option in filteredOptions"
        v-bind:key="typeof option === 'string' ? option : option.title"
        class="option"
        :for="typeof option === 'string' ? option : option.title"
      >
        <input
          type="checkbox"
          v-model="params[id]"
          @change="$emit('update', params[id])"
          :name="title + '[]'"
          :value="option"
          :id="typeof option === 'string' ? option : option.title"
        />
        <span>
          {{ typeof option === "string" ? option : option.title }}
        </span>

        <VTooltip
          v-if="typeof option === 'object' && option.icon"
          placement="top-start"
          max-width="20px"
          width="20px"
        >
          <span class="ml-3">
            <img :src="option.icon" :alt="option.title" />
          </span>

          <template #popper>
            {{ option.tooltip }}
          </template>
        </VTooltip>
      </label>
    </div>

    <div v-if="seeAll && typeof seeAll === 'object'">
      <div class="text-right">
        <div class="seeAll">
          <div v-if="verifySelectedItem()">
            <p v-for="(item, i) in getSelectedItem()" v-bind:key="i" class="item-selected-container">
              <span class="item-selected-name" :title="item.name">{{ item.name }}</span>
              <button @click="() => deleteSelectedItem(item)" class="item-selected-button">
                <v-icon>mdi-close-circle-outline</v-icon>
              </button>
            </p>
          </div>
          <button
            :style="
              verifySelectedItem()
                ? 'border-top: 1px solid #e5e4e4; padding-top: 20px; margin-top: 5px;'
                : ''
            "
            @click="seeAllHandle"
            :id="`see-all-${seeAll.scrollTo}`"
          >
            {{ seeAll.title }}
            <v-icon class="ms-2">mdi-chevron-right</v-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuTitle from "@/components/typographies/MenuTitle.vue";
// import ToolTip from "@/components/ToolTip.vue";
import { mapMutations, mapState } from "vuex";
import store from "@/store";
import {capitalize} from "@/helpers/strings";

export default {
  name: "CheckBoxes",
  components: {
    MenuTitle,
    // ToolTip
  },
  data: function () {
    return {
      checkedValues: [],
      loading: true,
      filteredOptions: this.options,
    };
  },
  props: {
    modelValue: Array,
    title: String,
    options: Array,
    id: String,
    seeAll: {
      type: Object,
      required: false,
    },
  },
  mounted: function () {
    const i = setInterval(() => this.init(i), 1000)
  },
  methods: {
    ...mapMutations("drawer", [
      "showSeeAllDrawer",
      "setSeeAllDrawerObjDetails",
      "unsetSeeAllDrawerObjSelected",
      "setSeeAllDrawerObjSelected",
    ]),

    async init(i = null) {
      try {

        if (this.params[this.id] === undefined) {
          this.params[this.id] = [];
        } else {
          if (typeof this.params[this.id][0] == 'object') {
            const v = this.params[this.id].map(p => p.id);
            this.params[this.id] = v;
          }
          let ids = Object.values(this.params[this.id]);
          const values = this.options.filter(obj => ids.includes(obj.id.toString()));

          if (values.length !== ids.length) {
            ids = ids.filter(i => !values.includes(i));
            const array1 = ids;
            const array2 = values;
            const namesInArray2 = new Set(array2.map(item => item.id));
            const idOpcionaisSeeAll = array1.filter(value => !namesInArray2.has(value));
            const items = await this.startOptionalsData(idOpcionaisSeeAll)
            items.forEach(i => {
              values.push(i);
            })
          }
          this.params[this.id] = values;
        }
        if (i) {
          clearInterval(i)
        }
        this.loading = false
      }
      catch (err) {

        if (i) {
          clearInterval(i)
        }
        this.loading = false
      }
    },
    applyFilter(filters) {

      if (this.id !== 'adType') {
        this.filteredOptions = this.options?.filter((el) => {
          let filter = filters[this.aliasFilter[this.id]];
          let idLower = (el.filter ? el.filter : el.id);
          if (this.id === 'fuel' || this.id === 'transmission' || this.id === 'optionals') {
            return (Array.isArray(filter) && filter?.find(item => item.toLowerCase() === idLower.toLowerCase()));
          }
          return (filter && filter[idLower]?.toString() !== '0');
      });
      }
    },
    seeAllHandle: async function () {
      this.setSeeAllDrawerObjDetails({
        title: this.seeAll.menu,
        subtitle: this.seeAll.submenu,
        placeholder: this.seeAll.placeholder,
        handle: this.seeAll.handle,
        scrollTo: this.seeAll.scrollTo,
      });
      this.showSeeAllDrawer();
    },
    verifySelectedItem() {
      const key = "key-" + this.seeAll.scrollTo;
      return (
        Object.keys(this.seeAllDrawerObjSelected).filter(function (item) {
          return item === key;
        }).length > 0 && this.seeAllDrawerObjSelected[key].length > 0
      );
    },
    getSelectedItem() {
      const key = "key-" + this.seeAll.scrollTo;
      return this.seeAllDrawerObjSelected[key];
    },
    deleteSelectedItem(item) {
      const key = "key-" + this.seeAll.scrollTo;
      const data = {
        ...item,
        key,
      };
      this.unsetSeeAllDrawerObjSelected(data);
      if (this.seeAllDrawerObjSelected[key]) {
        store.commit("filterForm/setParam", {
          [this.seeAll.scrollTo]: [
            ...this.params[this.seeAll.scrollTo].filter(
              (elem) => elem.id !== item.id
            ),
            ...this.seeAllDrawerObjSelected[key],
          ].filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.id === value.id
              )
          ),
        });
      }
    },
    async startOptionalsData(optionals) {
      const optionalsData = await this.seeAll.handle();
      const key = "key-" + this.seeAll.scrollTo;
      this.unsetSeeAllDrawerObjSelected({ key, all: true });

      const items = [];
      optionals.forEach(opt => {
        const item = {
          id: opt.id ?? opt,
          name: capitalize(optionalsData.filter(optData => optData.id.toString() === opt.toString())[0]?.name ?? opt.name ?? opt.title)
        };
        items.push(item);
        this.setSeeAllDrawerObjSelected({
          key,
          value: item,
        });
      })
      return items;
    }
  },
  computed: {
    ...mapState({
      seeAllDrawerObjDetails: (state) => state.drawer.seeAllDrawerObjDetails,
      seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
      params: (state) => state.filterForm.params,
      filters: (state) => state.filterForm.filters,
      aliasFilter: (state) => state.filterForm.aliasFilter,
    }),
  },
  watch: {
    filters: {
      immediate: true,
      handler(newFilters) {
        this.applyFilter(newFilters);
      },
    },
  },
};
</script>

<style scoped>

.item-selected-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
}

.item-selected-name {
  margin-left: 45px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-selected-button {
  flex-shrink: 0;
}

.options {
  margin: 1rem auto;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #797979;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style : normal;
         src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.3.0");
         src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
               url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
               url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
               url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
               url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}

input[type="checkbox"]:after {
  font-family: FontAwesome;
  content: "\f00c";
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  display: none;
}

input[type="checkbox"]:checked {
  border: 1px solid #ff7a00;
  background: #ff7a00;
}

input[type="checkbox"]:checked:after {
  display: block;
}

label {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #797979;
  margin: 4px 0;
  cursor: pointer;
}

span {
  margin-left: 0.4rem;
}

.seeAll {
  color: #797979;
  padding: 0 0 20px 0;
}

.v-popper__popper > div {
  padding: 0 !important;
  background: black !important;
  font-size: 9pt !important;
  margin: 0 !important;
  line-height: 10px !important;
  max-width: 145px !important;
  text-align: center !important;
  border-radius: 3px !important;
}
</style>
