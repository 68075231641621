export function getCookie(name) {
  let cookieValue = document.cookie;

  if (cookieValue) {
    const cookie = cookieValue.split('; ')
      .find(row => row.startsWith(name + '='));

    if (cookie) {
      cookieValue = cookie.split('=')[1];
    } else {
      cookieValue = null;
    }
  }

  return cookieValue ? decodeURIComponent(cookieValue) : null;
}