<template>
  <BuyerTwoColumnPage
    :title="title"
    subTitle="Acompanhe suas avaliações em tempo real!"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getBidInProgress"
    buyerScope="InProgress"
    labelCard="Sua avaliação"
    :sectionLeft="{
      title: 'Avaliações ativas',
      labelCard: 'Sua avaliação',
      labelCardPackage: 'Sua avaliação',
      subTitle:
        'Saiba quando agir. Ativas? Você está liderando. Fique de olho para continuar no topo.',
    }"
    :sectionRight="{
      title: 'Avaliações superadas',
      labelCard: 'Última avaliação',
      labelCardPackage: 'Superada',
      subTitle:
        ' Superadas? Outra avaliação superou a sua, mas ainda há chances! Faça uma nova avaliação e volte à frente.',
    }"
    priceDescriptionPage="Sua última avaliação"
    noVehicleLabel="Você ainda não avaliou nenhum veículo…"
    noVehicleRightLabel="Você não possui avaliações superadas"
  />
  <QuickViewModal />
</template>

<script>
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";

import BuyerTwoColumnPage from "../BuyerTwoColumnPage";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import { getBidInProgress } from "@/services/api/BuyerServiceApi";
import QuickViewModal from "../../components/MakeEvaluationViewModal.vue";

export default {
  name: "BidInProgressPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerTwoColumnPage, QuickViewModal },
  data: function () {
    return {
      ...BUYER_SECTION_IN_PROGRESS,
      title: "Avaliações",
      section: "Em andamento",
    };
  },
  methods: {
    getBidInProgress,
  },
};
</script>

<style scoped></style>
