const urlParams = new URLSearchParams(window.location.search);

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;

// pega o tempo do b2b-portal vindo via iframe ou pega o tempo padrão
export const RATINGS_REVIEW_LIMIT_START =
  urlParams.get("RATINGS_REVIEW_LIMIT_START") ?? 10;
export const RATINGS_REVIEW_LIMIT_END =
  urlParams.get("RATINGS_REVIEW_LIMIT_END") ?? 60;
