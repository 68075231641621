<template>
  <div class="details">
    <div class="detail">
      <img
        :src="require('@/assets/icons/calendar-icon.svg')"
        height="18"
        width="18"
      />
      <span>{{ `${vehicle.v_anof}/${vehicle.v_anom}` }}</span>
    </div>
    <div class="detail fuel">
      <img
        :src="require('@/assets/icons/fuel-icon.svg')"
        height="18"
        width="18"
      />
      <span>{{ capitalize(vehicle.v_combustivel) }}</span>
    </div>
    <div class="detail transmission">
      <img
        :src="require('@/assets/icons/transmission-icon.svg')"
        height="18"
        width="18"
      />
      <span>{{ capitalize(vehicle.v_cambio) }}</span>
    </div>
    <div class="detail km">
      <img
        :src="require('@/assets/icons/km-icon.svg')"
        height="18"
        width="18"
        style="margin-left: 1px"
      />
      <span>{{ computeKMMasK(String(vehicle.v_km)) }}</span>
    </div>
    <div class="detail plate" v-if="showPlate">
      <div class="status-item mr-2">
        <img
          class="mr-1"
          :src="require('@/assets/icons/license-plate-icon.svg')"
        />
        <span>{{ formatLicense(vehicle.v_placa) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Mask } from "maska";
import { capitalize } from "@/helpers/strings";
import VehicleCardMixin from "@/views/buyer/mixins/VehicleCardMixin";


const mask = new Mask({ mask: ["#.###", "###.###.###"], reversed: true })

export default {
  name: "VehicleItemDetail",
  props: {
    vehicle: Object,
    showPlate: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [VehicleCardMixin],
  methods: {
    capitalize,
  },
  computed: {
    computeKMMasK: () => {
      return value => {
        return mask.masked(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0;
}

.detail span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  text-align: left;
  flex: 1;
  color: #373737;
}


@media only screen and (max-width: 920px) {
  .detail span {
    font-size: 10px;
  }

  .detail img {
    height: 13px;
    width: 13px;
  }
}
</style>
