import axios from "axios";

import API_BASE from "@/constants/ApiBase";

export const billetDiscount = async (id_avaliacao) => {
  return axios
    .post(`${API_BASE}/purchasers/wallet/executePayment/` + id_avaliacao,
    {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

export const checkPayment = async (id_avaliacao) => {
  return axios
    .get(`${API_BASE}/purchasers/wallet/checkPayment/` + id_avaliacao,
    {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => error);
};
