import {mapState, mapMutations, mapActions} from "vuex";

import { DETAILS_STEP, HOME_STEP, WALLET_BUY_PARTIAL_STEP } from "@/store/modules/walletModalModule";

const WalletModalMixin = {
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.walletModal.modalIsOpen,
      step: (state) => state.walletModal.step,
    }),

    isHomeStep() {
      return this.step === HOME_STEP;
    },
    isDetailsStep() {
      return this.step === DETAILS_STEP;
    },
    isWalletBuyPartialStep() {
      return this.step === WALLET_BUY_PARTIAL_STEP;
    },
  },
  methods: {
    ...mapMutations("walletModal", [
      "navigateWalletBuyPartialStep",
      "toggleModal",
      "setStep",
      "setVehicle",
      "navigateLastStep",
    ]),
    ...mapActions("walletModal", ["applyBilletDiscount"]),

    closeModal() {
      if (this.modalIsOpen) {
        this.toggleModal();
        this.setStep(HOME_STEP);
      }
    },
    openModal() {
      if (!this.modalIsOpen) {
        this.toggleModal();
      }
    },
    navigateToHomeStep() {
      this.setStep(HOME_STEP);
      this.setVehicle(null);
    }
  },
};

export default WalletModalMixin;

