<template>
  <span>
    {{ fipe }}
    <img :src="require('@/assets/icons/fipe-icon.svg')"  width="28" height="23"/>
  </span>
</template>
<script>
export default {
  name: "FipeIcon",
  props: {
    fipe: Number,
  },
};
</script>
<style scoped>
span {
  position: relative;
  margin-left: 6px;
  width: 24px;
  height: 24px;
  font-weight: 600;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-size: 12px;
  color: #ffffff;
  z-index: 1;
}
span img {
  position: absolute;
  z-index: -1;
}

</style>
