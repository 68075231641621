<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Propostas não concretizados em compras"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getRejectedUnfufilled"
    labelCard="Sua última proposta rejeitada"
    buyerScope="UnfulilledPurchases"
    noVehicleLabel="Você ainda não enviou proposta em nenhum veículo…"
    priceBackgroundColor="#F22300"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import { getRejectedUnfufilled } from "@/services/api/BuyerServiceApi";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "RejectedUnfulfilledPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Não concretizadas - Propostas Rejeitadas",
      section: "Encerrados",
    };
  },
  methods: {
    getRejectedUnfufilled,
  },
};
</script>

<style scoped></style>
