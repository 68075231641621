<template>
  <div class="buyer-service-page">
    <div class="buyer-service-page-top">
      <BuyerSectionItem
      :icon="this.icon"
      :title="this.title"
      :description="this.description"
      :shouldReturnToTheBuyerPage="true"
    />
    </div>

    <div class="buyer-service-page-content">
      <FilterSidebarInServiceItems />
    </div>
  </div>
</template>

<script>
import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import FilterSidebarInServiceItems from "@/views/buyer/components/FilterSidebar/components/FilterSidebarInServiceItems";
import { BUYER_SECTION_SERVICE } from "@/views/buyer/constants/BuyerSections";
import { mapMutations } from "vuex";
import { BUYER } from "@/constants/Buyer";

export default {
  name: "BuyerServicePage",
  components: { BuyerSectionItem, FilterSidebarInServiceItems },
  data: function () {
    return {
      ...BUYER_SECTION_SERVICE,
    };
  },
  methods: {
    ...mapMutations("buyer", [
        "setTitleSectionPage",
        "setTitlePage",
        "setSubTitlePage"
    ]),
  },
  mounted() {
    this.setTitleSectionPage(BUYER.TITLE_DASHBOARD);
    this.setTitlePage("Atendimento");
    this.setSubTitlePage("");
  },
};
</script>

<style scoped>
.buyer-service-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.buyer-service-page-top {
  width: 100%;
}

.buyer-service-page-content {
  margin-top: 4px;
  background-color: white;
}
</style>
