<template>
  <div class="vehicle-card" @click.stop="buyerScope.toLowerCase() === 'inprogress' ? gotoVdp(vehicle) : () => {}">
    <div class="vehicle-card-left">
      <!-- <div class="vehicle-card-image">
        <img :src="vehicle.foto" alt="" />
        <div class="vehicle-card-image-overlay"></div>
        <img
          :src="require('@/assets/icons/favorite-icon.svg')"
          class="favorite"
        />
        <span class="location" v-if="vehicle.cidade && vehicle.uf">{{ vehicle.cidade }}/{{ vehicle.uf }}</span>
      </div> -->
      <v-img
        :src="
          vehicle.foto === null
            ? require('@/assets/images/VehicleItemImage.png')
            : vehicle.foto
        "
        width="100%"
        height="100%"
        cover
        class="image mb-1"
        aspect-ratio="1"
        v-on:click.stop="onClick"
      >
        <div class="overlay">
          <span class="image-location" v-if="vehicle.cidade && vehicle.uf"
            >{{ vehicle.cidade }}/{{ vehicle.uf }}</span
          >
        </div>

        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular
              color="grey-lighten-4"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-slot:error>
          <v-img :src="require('@/assets/images/VehicleItemImage.png')"></v-img>
        </template>
      </v-img>
      <img
          :src="favorite ? require('@/assets/icons/favorite-icon-active.svg') : require('@/assets/icons/favorite-icon.svg')"
          class="favorite"
          v-if="showIconFavorite(buyerScope)"
          @click.stop="handleFavorite"
        />
      <span
        class="vehicle-card-price"
        :style="`background: ${
          priceBackgroundColor ? priceBackgroundColor : '#0dc561'
        };`"
      >
        <span class="label">{{ label }}</span>
        <span class="price" v-if="!showLabelOnly"
          ><span class="symbol">R$</span>{{ getPriceBase(price)
          }}<span class="end">,{{ getPriceDecimal(price) }}</span></span
        >
      </span>
    </div>
    <div class="vehicle-card-center">
      <div class="top">
        <div class="top-left">
          <div class="header">
            <h3>{{ capitalize(`${vehicle.v_marca} ${vehicle.v_modelo}`) }}</h3>
          </div>
          <h5 class="mt-1">{{ capitalize(vehicle.v_versao) }}</h5>
          <div class="status mt-1">
            <div class="status-item mr-2">
              <img
                class="mr-1"
                :src="require('@/assets/icons/calendar-alt-icon.svg')"
              />
              <span>{{ `${vehicle.v_anof}/${vehicle.v_anom}` }}</span>
            </div>
            <div class="status-item mr-2">
              <img
                class="mr-1"
                :src="require('@/assets/icons/speed-meter-icon.svg')"
              />
              <span>{{ vehicle.v_km }}</span>
            </div>
            <div class="status-item mr-2" v-if="!isInProgress">
              <img
                class="mr-1"
                :src="require('@/assets/icons/license-plate-icon.svg')"
              />
              <span>{{ formatLicense(vehicle.v_placa) }}</span>
            </div>
          </div>
        </div>
        <div class="top-right">
          <div class="fipe-content" v-if="vehicle.percentual_fipe !== null">
            <VTooltip placement="top-start" max-width="20px" width="20px">
              <span class="fipe-container">
                <span class="fipe">{{ parseInt(Math.abs(vehicle.percentual_fipe - 100)) }}</span>
                <span class="percent">%</span>
                <img :src="require('@/assets/icons/fipe-icon.svg')" />
              </span>

              <template #popper>
                {{
                  `${parseInt(
                    Math.abs(vehicle.percentual_fipe - 100)
                  )}% do VALOR FIPE`
                }}
              </template>
            </VTooltip>
          </div>
          <div class="status">
            <VTooltip
              placement="top-start"
              max-width="20px"
              width="20px"
              v-if="vehicle.v_laudo !== ''"
            >
              <span>
                <img
                  :src="require('@/assets/icons/report-icon.svg')"
                  alt="Veículo contém laudo aprovado"
                />
              </span>

              <template #popper> Veículo contém laudo aprovado </template>
            </VTooltip>
          </div>
        </div>
      </div>
      <div class="bottom mt-3">
        <div class="left mr-2">
          <div class="seller">
            <h3>{{ vehicle.em_nome }}</h3>
            <div class="rating">
              <v-rating
                v-bind:model-value="vehicle.em_nota_reviews"
                size="x-small"
                density="compact"
                color="#FF7A00"
                half-increments
                disabled
                readonly
              ></v-rating>
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="!isInProgress">
            <div class="date">
              <img
                class="mr-1"
                :src="require('@/assets/icons/calendar-icon.svg')"
              />
              <span>{{
                vehicle.dt_pagamento
                  ? formatDate(vehicle.dt_pagamento)
                  : formatDate(vehicle.datacadastro)
              }}</span>
            </div>
            <a
              class="mt-2"
              :href="vehicle.v_laudo ? vehicle.v_laudo : '#'"
              :class="['report', vehicle.v_laudo ? 'active' : 'disabled']"
              :target="vehicle.v_laudo ? '_blank' : ''"
              :id="`area_comprador_veiculo_laudo_${vehicle.ava_id}`"
              >Laudo do veículo</a
            >
          </div>
          <CardTimer
            v-else
            :dt_ts_final="vehicle.dt_ts_final"
            :isMobile="true"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="content actions-vehicle w-100 mt-3 mb-5 p-2" v-if="actionsOpen">
    <h3 class="py-3">Como deseja pagar a taxa deste veículo?</h3>
    <VehicleActionButtons
      :metadata="actions"
      :vehicle="vehicle"
      class="actions-buttons container py-1"
    />
  </div>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import VehicleCardMixin from "../../mixins/VehicleCardMixin";
import VehicleActionButtons from "./components/VehicleActionButtons";
import CardTimer from "../CardTimer";
import {gotoVdp} from "@/helpers/vdp";

export default {
  name: "VehicleCardMobile",
  methods: {gotoVdp},
  mixins: [NavigateMixings, VehicleCardMixin],
  components: { CardTimer, VehicleActionButtons },
  data() {
  },
  props: {
    vehicle: Object,
    onClick: Function,
    label: String,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    showLabelOnly: Boolean,
    price: String,
    buyerScope: String,
    favorite: Boolean,
    handleFavorite: Function,
    actionsOpen: Boolean || false,
  },
  computed: {
    actions() {
      return this.actionsByBuyerScope(this.buyerScope, this.wasOvercome);
    },
  },
};
</script>

<style lang="scss" scoped>
a.report{
  &.active {
    font-weight: 400;
  }

  &.disabled {
    cursor:default;
    opacity: 0.5;
  }
}
.actions-vehicle{
  text-align: center;
  h3{
    font-size: 12px;
  }
  .actions-buttons {
    max-width: 100%;
  }
}
.vehicle-card {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  padding: 12px 4px;

  .vehicle-card-left {
    position: relative;
    margin-right: 8px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .action-buttons-wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px 20px;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 10px;
    }

    .image {
      border-radius: 6px;
      border: 1px solid rgba(70, 70, 70, 0);

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 100%;
        border-radius: 5px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #000000 0.01%,
          rgba(0, 0, 0, 0) 33.11%
        );

        .image-location {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 6px;
          font-style: normal;
          font-weight: 600;
          line-height: 104%; /* 9.36px */
          position: absolute;
          top: 8px;
          left: 4px;
        }
      }
    }
    .favorite {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        width: 24px;
        height: 22px;
      }

    .vehicle-card-price {
      padding: 8px 24px;
      flex-shrink: 0;
      border-radius: 6px;
      background: #0dc561;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .label {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 79%; /* 10.62px */
      }

      .price {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;

        .symbol {
          font-size: 8px;
          position: absolute;
          top: 2px;
          left: -14px;
        }

        .end {
          font-size: 8px;
          position: absolute;
          top: 2px;
          right: -14px;
        }
      }
    }
  }

  .vehicle-card-center {
    flex: 8;
    flex-wrap: wrap;
    justify-content: space-between;

    .top {
      display: flex;
      justify-content: space-between;
      .top-left {
        .header {
          display: flex;

          h3 {
            color: #373737;
            font-family: "Lexend Deca";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-right: 8px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        h5 {
          color: #373737;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .status {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .status-item {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 14px;
              height: 14px;
              opacity: 0.7;
              mix-blend-mode: luminosity;
            }

            span {
              color: #696969;
              font-family: "Lexend Deca";
              font-size: 9px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .top-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .fipe-content {
          position: relative;

          .fipe-container {
            position: relative;
          }
          .percent {
            position: absolute;
            z-index: 100;
            font-family: "Lexend Deca";
            font-style: normal;
            font-weight: 700;
            font-size: 7px;
            letter-spacing: -0.11em;
            margin-top: 12px;
            margin-left: 11px;

            color: #ffffff;
          }
          .fipe {
            position: absolute;
            z-index: 100;
            font-family: "Lexend Deca";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            letter-spacing: -0.11em;
            margin-left: 7.8px;

            color: #ffffff;
          }
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .report {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 108.5%; /* 10.85px */
          letter-spacing: -0.2px;
          padding: 8px;
          text-align: center;
          text-decoration: none;
        }

        .date {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 20px;

          img {
            width: 12px;
            height: 12px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%; /* 9.765px */
          }
        }
      }

      .left {
        display: flex;

        .seller {
          padding: 8px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
        }

        .seller h3 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
        }

        .seller h4 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 175px;
    background: #ededed;
  }
}
</style>
