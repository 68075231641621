<template>
  <BuyerTwoColumnPage
    :title="title"
    subTitle="Acompanhe suas propostas!"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getProposalInProgress"
    labelCard="Sua avaliação"
    :sectionLeft="{
      title: 'Propostas ativas',
      labelCard: 'Sua proposta',
      subTitle:
        'Propostas ativas estão em análise. Aguarde a decisão do anunciante.',
    }"
    :sectionRight="{
      title: 'Propostas superadas',
      labelCard: 'Proposta superada',
      subTitle:
        'As superadas não foram aceitas. Considere enviar uma nova ajustada. ',
    }"
    priceDescriptionPage="Sua última proposta"
    noVehicleLabel="Você ainda não enviou proposta em nenhum veículo…"
    noVehicleRightLabel="Você ainda não possui propostas superadas"
    buyerScope="InProgress"
  />
  <QuickViewModal />
</template>

<script>
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import { getProposalInProgress } from "@/services/api/BuyerServiceApi";

import BuyerTwoColumnPage from "../BuyerTwoColumnPage";
import QuickViewModal from "../../components/MakeEvaluationViewModal.vue";

export default {
  name: "ProposalInProgressPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerTwoColumnPage, QuickViewModal },
  data: function () {
    return {
      ...BUYER_SECTION_IN_PROGRESS,
      title: "Propostas",
      section: "Em andamento",
    };
  },
  methods: {
    getProposalInProgress,
  },
};
</script>

<style scoped>
.proposal-in-progress-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.proposal-in-progress-page-top {
  width: 100%;
}

.proposal-in-progress-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
