<template>
  <div class="content-main" id="top-app">
    <div class="breadcrumbs-nav">
      <BreadcrumbsNav :mobile="mobile" />
    </div>
  </div>
</template>

<script>
import BreadcrumbsNav from "@/components/top/BreadcrumbsNav.vue";
export default {
  name: "TopAppMobileBuyer",
  components: {
    BreadcrumbsNav,
  },
  data() {
    return {
      mobile: true,
    }
  }
};
</script>

<style lang="scss" scoped>
#top-app-buyer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background-color: #FAFAFA;

  h1 {
    color: #373737;
    font-family: "Lexend Deca";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
