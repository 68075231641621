import {
    getDealersData,
    getDealerUfsData,
    getRegionsData,
} from "@/services/api/DealerServiceApi";

const DEFAULT_STATE = {
    modalIsOpen: false,
    ufs: [],
    selectedDealer: null,
    dealersLoading: false,
    dealerUfsLoading: false,
    regionsLoading: false,
    originalDealers: [],
    originalRegions: [],
    dealers: [],
    regions: [],
};

const dealerModule = {
    namespaced: true,
    state() {
        return DEFAULT_STATE;
    },
    mutations: {
        setResult(state, result) {
            state.originalDealers = result;
            state.dealers = result;
        },
        setDealers(state, dealers) {
            state.dealers = dealers;
        },
        setUfResult(state, ufs) {
            state.ufs = ufs;
        },
        setDealersLoading(state, loading) {
            state.dealersLoading = loading;
        },
        setDealerUfsLoading(state, loading) {
            state.dealerUfsLoading = loading;
        },
        setRegions(state, regions) {
            state.regions = regions;
        },
        setRegionsLoading(state, loading) {
            state.regionsLoading = loading;
        },
        toggleModal(state) {
            state.modalIsOpen = !state.modalIsOpen;
            if (!state.modalIsOpen) {
                state.ufs = [];
            }
        },
        setSelectedDealer(state, selectedDealer) {
            state.selectedDealer = selectedDealer
        },
    },
    actions: {
        async getDealersData({commit}) {
            commit("setDealersLoading", true);
            const [dealersData] = await Promise.all([
                getDealersData(),
            ]);
            commit("setResult", dealersData);
            commit("setDealersLoading", false);
        },
        async getRegionsData({commit}) {
            commit("setRegionsLoading", true);
            const [regionsData] = await Promise.all([
                getRegionsData(),
            ]);
            commit("setRegions", regionsData);
            commit("setRegionsLoading", false);
        },
        async getDealerUfsData({commit}, url) {
            commit("setDealerUfsLoading", true);
            const [ufsData] = await Promise.all([
                getDealerUfsData(url),
            ]);
            commit("setUfResult", ufsData);
            commit("setDealerUfsLoading", false);
        },
        filterDealers({commit, state}, dealer = null) {
            commit("setDealersLoading", true);
            if (dealer == null) {
                commit("setDealers", state.originalDealers);
            } else {
                const dealersData = state.originalDealers.filter(d => d.fantasia == dealer) ?? []
                commit("setDealers", dealersData);
            }
            commit("setDealersLoading", false);
        },

        filterDealersByRegion({commit, state}, regionName = null) {

            if (regionName == null) {
                commit("setDealers", state.originalDealers);
            }

            else {

                const ufs = function () {

                    regionName = regionName.toString().toLowerCase();

                    if (state.regions[regionName]) {
                        const region = state.regions[regionName];
                        return Object.keys(region.uf).map(ufKey => ufKey.toUpperCase());
                    }

                    for (const regionsName in state.regions) {
                        const region = state.regions[regionsName];
                        for (const ufKey in region.uf) {
                            if (region.uf[ufKey]?.name?.toString()?.toLowerCase() === regionName) {
                                return [ufKey.toUpperCase()];
                            }
                        }
                    }

                    return null;
                };

                if (ufs().length === 0) {
                    commit("setDealers", state.originalDealers);
                }
                else {
                    const dealersData = state.originalDealers.filter(dealer => {
                        const dealerUfs = dealer.ufs.split(',');
                        return dealerUfs.some(uf => ufs().includes(uf));
                    });
                    commit("setDealers", dealersData);
                }
            }

        },
    },
};

export default dealerModule;
