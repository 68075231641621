<template>
  <VehicleCardDesktop
    :vehicle="vehicle"
    v-if="!isMobile()"
    :label="label"
    :priceBackgroundColor="priceBackgroundColor"
    :isInProgress="isInProgress"
    :showLabelOnly="showLabelOnly"
    :buyerScope="buyerScope"
    :price="price"
    :wasOvercome="waOvercome"
    :favorite="favorite"
    :handleFavorite="handleFavorite"
  />
  <VehicleCardMobile
    :vehicle="vehicle"
    :onClick="onClick"
    :label="label"
    :priceBackgroundColor="priceBackgroundColor"
    :isInProgress="isInProgress"
    :showLabelOnly="showLabelOnly"
    :price="price"
    :wasOvercome="waOvercome"
    :buyerScope="buyerScope"
    :favorite="favorite"
    :handleFavorite="handleFavorite"
    v-else
  />
</template>

<script>
import PlatformsMixins from "@/mixins/PlatformsMixins";
import VehicleCardDesktop from "./VehicleCardDesktop.vue";
import VehicleCardMobile from "./VehicleCardMobile.vue";

export default {
  name: "VehicleCard",
  mixins: [PlatformsMixins],
  components: { VehicleCardDesktop, VehicleCardMobile },
  props: {
    vehicle: Object,
    onClick: Function,
    label: String,
    showLabelOnly: Boolean,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    buyerScope: String,
    waOvercome: String,
    favorite: Boolean,
    handleFavorite: Function,
  },
  computed: {
    price() {
      if (
        this.waOvercome &&
        parseFloat(this.vehicle.tem_oferta) > parseFloat(this.vehicle.valor)
      ) {
        return this.vehicle.tem_oferta;
      }

      return this.vehicle.valor;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
