import VueCookies from 'vue-cookies';
import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'

import store from "@/store";
import vuetify from "@/plugins/vuetify";
import router from '@/plugins/router';
import emitterPlugin from './emitter.js';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

export function registerPlugins(app) {
  app.use(router);
  app.use(VueCookies);
  app.use(vuetify)
  app.use(FloatingVue);
  app.use(store)
  app.use(emitterPlugin);
  app.use(Toast, {
    shareAppContext: true,
});
  app.config.errorHandler = (error) => {
    console.error(error);
  };
}
