<template>
  <v-container class="vehicle-card" fluid>
    <v-row fluid>
      <v-col cols="3" xl="4" class="vehicle-card-left cursor-pointer" @click.stop="buyerScope.toLowerCase() === 'inprogress' ? gotoVdp(vehicle) : () => {}">
        <!-- <div class="vehicle-card-image">
          <img :src="vehicle.foto" alt="" class="car_img" />
          <div class="vehicle-card-image-overlay"></div>
          <img
            :src="require('@/assets/icons/favorite-icon.svg')"
            class="favorite"
          />
          <span class="location" v-if="vehicle.cidade && vehicle.uf"
            >{{ vehicle.cidade }}/{{ vehicle.uf }}</span
          >
        </div> -->
        <v-img
          :src="
            vehicle.foto === null
              ? require('@/assets/images/VehicleItemImage.png')
              : vehicle.foto
          "
          width="100%"
          height="10px"
          cover
          class="image mb-1"
        >
          <div class="overlay">
            <span class="image-location" v-if="vehicle.cidade && vehicle.uf"
              >{{ vehicle.cidade }}/{{ vehicle.uf }}</span
            >
          </div>
          <img
            :src="favorite ? require('@/assets/icons/favorite-icon-active.svg') : require('@/assets/icons/favorite-icon.svg')"
            class="favorite"
            v-if="showIconFavorite(buyerScope)"
            @click.stop="handleFavorite"
          />
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
          <template v-slot:error>
            <v-img
              :src="require('@/assets/images/VehicleItemImage.png')"
            ></v-img>
          </template>
        </v-img>

        <span
        v-if="price"
          class="vehicle-card-price"
          :style="`background: ${
            priceBackgroundColor ? priceBackgroundColor : '#0dc561'
          };`"
        >
          <span :class="['label', showLabelOnly ? 'showLabelOnly' : '']">{{
            label
          }}</span>
          <span class="price" v-if="!showLabelOnly">
            <span class="symbol">R$</span>{{ getPriceBase(price) }}
            <span class="end">,{{ getPriceDecimal(price) }}</span>
        </span>
        </span>
      </v-col>
      <v-col cols="6" xl="5" class="vehicle-card-center cursor-pointer" @click.stop="buyerScope.toLowerCase() === 'inprogress' ? gotoVdp(vehicle) : () => {}">
        <div class="header">
          <h3>{{ capitalize(`${vehicle.v_marca} ${vehicle.v_modelo}`) }}</h3>
          <div class="fipe-content" v-if="vehicle.percentual_fipe !== null">
            <VTooltip placement="top-start" max-width="20px" width="20px">
              <span class="fipe-container">
                <span class="fipe">{{ parseInt(Math.abs(vehicle.percentual_fipe - 100)) }}</span>
                <span class="percent">%</span>
                <img :src="require('@/assets/icons/fipe-icon.svg')" />
              </span>

              <template #popper>
                {{
                  `${parseInt(
                    Math.abs(vehicle.percentual_fipe - 100)
                  )}% do VALOR FIPE`
                }}
              </template>
            </VTooltip>
          </div>
          <div class="status">
            <VTooltip
              placement="top-start"
              max-width="20px"
              width="20px"
              v-if="vehicle.v_laudo !== ''"
            >
              <span class="ml-3">
                <img
                  :src="require('@/assets/icons/report-icon.svg')"
                  alt="Veículo contém laudo aprovado"
                />
              </span>

              <template #popper> Veículo contém laudo aprovado </template>
            </VTooltip>
          </div>
        </div>
        <h5>{{ capitalize(vehicle.v_versao) }}</h5>
        <div class="status mt-2">
          <div class="status-item mr-2">
            <img
              class="mr-1"
              :src="require('@/assets/icons/calendar-alt-icon.svg')"
            />
            <span>{{ `${vehicle.v_anof}/${vehicle.v_anom}` }}</span>
          </div>
          <div class="status-item mr-2">
            <img
              class="mr-1"
              :src="require('@/assets/icons/speed-meter-icon.svg')"
            />
            <span>{{ vehicle.v_km }}</span>
          </div>
          <div class="status-item mr-2" v-if="!isInProgress">
            <img
              class="mr-1"
              :src="require('@/assets/icons/license-plate-icon.svg')"
            />
            <span>{{ formatLicense(vehicle.v_placa) }}</span>
          </div>
        </div>
        <div class="bottom mt-4">
          <div class="left mr-2" v-if="!isInProgress">
            <a
              :class="['report', vehicle.v_laudo ? 'active' : 'disabled']"
              :href="vehicle.v_laudo ? vehicle.v_laudo : '#'"
              :target="vehicle.v_laudo ? '_blank' : ''"
              :id="`area_comprador_veiculo_laudo_${vehicle.ava_id}`"
              >Laudo do veículo</a
            >
            <div class="date mt-1">
              <img
                class="mr-1"
                :src="require('@/assets/icons/calendar-icon.svg')"
              />
              <span>{{
                vehicle.dt_pagamento
                  ? formatDate(vehicle.dt_pagamento)
                  : formatDate(vehicle.datacadastro)
              }}</span>
            </div>
          </div>
          <div class="right mr-2">
            <div class="seller">
              <img :src="vehicle.dealer_img" height="31" width="66" />
              <h3>{{ vehicle.em_nome }}</h3>
              <div class="rating">
                <v-rating
                  v-bind:model-value="vehicle.em_nota_reviews"
                  size="x-small"
                  density="compact"
                  color="#FF7A00"
                  half-increments
                  disabled
                  readonly
                ></v-rating>
              </div>
            </div>
            <CardTimer v-if="isInProgress" :dt_ts_final="vehicle.dt_ts_final" />
          </div>
        </div>
      </v-col>
      <v-col
        cols="3"
        xl="4"
        justify-content="center"
        align-items="center"
        class="vehicle-card-right"
      >
        <!-- <div class="divider"></div> -->
        <span class="actions-label">Botões de ação</span>
        <VehicleActionButtons
          :metadata="actions"
          :vehicle="vehicle"
          class="mt-2 pl-4 actions-buttons"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VehicleCardMixin from "../../mixins/VehicleCardMixin";
import CardTimer from "../CardTimer";
import VehicleActionButtons from "./components/VehicleActionButtons";
import {gotoVdp} from "@/helpers/vdp";

export default {
  name: "VehicleCardDesktop",
  methods: {gotoVdp},
  mixins: [VehicleCardMixin],
  components: { VehicleActionButtons, CardTimer },
  props: {
    vehicle: Object,
    label: String,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    showLabelOnly: Boolean,
    buyerScope: String,
    price: String,
    wasOvercome: String,
    favorite: Boolean,
    handleFavorite: Function,
  },
  computed: {
    actions() {
      return this.actionsByBuyerScope(this.buyerScope, this.wasOvercome);
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.vehicle-card {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  .vehicle-card-left {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      border-radius: 6px;
      border: 1px solid rgba(70, 70, 70, 0);

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 100%;
        border-radius: 5px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #000000 0.01%,
          rgba(0, 0, 0, 0) 33.11%
        );

        .image-location {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 9px;
          font-style: normal;
          font-weight: 600;
          line-height: 104%; /* 9.36px */
          position: absolute;
          top: 8px;
          left: 4px;
        }
      }

      .favorite {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        width: 24px;
        height: 22px;
      }
    }

    .vehicle-card-price {
      padding: 8px 28px;
      flex-shrink: 0;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
      min-height: 39.803px;

      .label {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 80%;
        overflow: hidden;
        white-space: nowrap;
        /* 10.62px */
      }

      .showLabelOnly {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120% !important;
      }

      .price {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;

        .symbol {
          font-size: 12px;
          position: absolute;
          top: 2px;
          left: -18px;
        }

        .end {
          font-size: 12px;
          position: absolute;
          top: 2px;
          right: -18px;
        }
      }
    }
  }

  .vehicle-card-center {
    // flex: 5;
    .header {
      display: flex;

      h3 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fipe-content {
        position: relative;

        .fipe-container {
          position: relative;
        }

        .percent {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 7px;
          letter-spacing: -0.11em;
          margin-top: 12px;
          margin-left: 11px;

          color: #ffffff;
        }

        .fipe {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: -0.11em;
          margin-left: 7.8px;

          color: #ffffff;
        }
      }
    }

    h5 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }

    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .status-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -8.5px;

        img {
          width: 21px;
          height: 21px;
          opacity: 0.7;
          mix-blend-mode: luminosity;
        }

        span {
          color: #696969;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .report {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 108.5%;
          /* 15.19px */
          letter-spacing: -0.28px;
          padding: 12px;
          text-decoration: none;
        }

        .active {
          font-weight: 400;
        }

        .disabled {
          cursor:default;
          opacity: 0.5;
        }

        

        .date {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 16px;

          img {
            width: 21px;
            height: 21px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%;
            /* 17.36px */
          }
        }
      }

      .right {
        display: flex;

        .seller {
          padding: 4px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          width: 113.7px;
        }

        .seller h3 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
        }

        .timer {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 18px;

          span {
            color: #373737;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 108.5%;
            /* 17.36px */
          }
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 175px;
    background: #ededed;
  }

  .vehicle-card-right {
    position: relative;
    // background-color: red;
    // margin-left: 10px;

    .actions-label {
      font-family: "Lexend Deca";
      font-weight: 400;
      color: #b3b3b3;
      background-color: #ffffff;
      position: absolute;
      font-size: 12px;
      text-align: center;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 112px;
    }

    .actions-buttons {
      border-left: 1px solid;
      border-color: #ededed;
    }

    .action-buttons-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 0 5px;
    }
  }
}
</style>
