<template>
  <a id="vehicle-item-mobile" @click="gotoVdp(vehicle)">
    <div class="vehicle-item-header">
      <div class="vehicle-image">
        <span class="location">{{
          `${capitalize(vehicle.an_cidade)}/${vehicle.an_uf}`
        }}</span>
        <span class="favorite" :id="'fav-' + vehicle.id_avaliacao">
          <Heart @click.stop="handleSetFavorite(vehicle.id_avaliacao)" color="#ee2424" :size="14" fill="#ee2424" v-if="isFavorite"/>
          <Heart @click.stop="handleSetFavorite(vehicle.id_avaliacao)" color="white" :size="14" v-else />
        </span>
        <div class="price">
          <div class="price-content">
            <span class="currency">R$</span>
            {{
              parseFloat(vehicle.vlrMinOrder ?? vehicle.valor_inicial)
                .toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
                .replace("R$", "")
            }}
          </div>
        </div>
        <!-- <span class="report">Bid sob aprovação</span> -->
        <a :href="link" target="_parent">
          <img
            :src="
              image === 'Array' || image === null
                ? require('@/assets/images/VehicleItemImage.png')
                : image
            "
            alt="Veículo"
            :class="image === 'Array' || image === null ? 'withoutImage' : ''"
            :onerror="`this.src='${require('@/assets/images/VehicleItemImage.png')}';this.onerror='';this.className='withoutImage'`"
          />
        </a>
        <div class="overlay"></div>
      </div>
    </div>

    <div class="vehicle-item-content">
      <h3>{{ title }}</h3>
      <h4>{{ capitalize(version) }}</h4>

      <div class="vehicle-item-footer">
        <div class="vehicle-item-left">
          <VehicleItemDetail :vehicle="vehicle" v-if="!isPackage" />
          <VehiclePackageInfo
            :count="packageData.count"
            :disablePrevious="packageData.disablePrevious"
            :disableNext="packageData.disableNext"
            :nextSlide="packageData.nextSlide"
            :prevSlide="packageData.prevSlide"
            isMobile
            v-else
          />
        </div>
        <div class="vehicle-item-right">
          <VehicleItemStamps :vehicle="vehicle" v-if="!isPackage" />

          <div class="seller">
            <h3>
              {{ capitalize(vehicle.an_nome) }} -
              {{ capitalize(vehicle.em_nome) }}
            </h3>
          </div>

          <VehicleItemTimer
            :vehicle="vehicle"
            :hideDescription="true"
            isMobile
          />
        </div>
      </div>
      <div class="vehicle-footer-info-bid">
        <div v-if="showAutobidInfo">
          <VehicleItemBidStatus :vehicle="vehicle" />
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import { capitalize } from "@/helpers/strings";
import { Heart } from "lucide-vue-next";

import VehicleItemDetail from "./components/VehicleItemDetail.vue";
import VehicleItemStamps from "./components/VehicleItemStamps.vue";
import VehicleItemTimer from "./components/VehicleItemTimer.vue";
import VehiclePackageInfo from "./components/VehiclePackageInfo.vue";
import VehicleCardMixin from "@/views/buyer/mixins/VehicleCardMixin";
import {gotoVdp} from "@/helpers/vdp";
import VehicleItemBidStatus from "@/components/VehicleItem/components/VehicleItemBidStatus.vue";

export default {
  name: "VehicleItemMobile",
  mixins: [VehicleCardMixin],
  props: {
    vehicle: Object,
    index: Number,
    linha: Number,
    link: String,
    title: String,
    image: String,
    isPackage: Boolean,
    version: String,
    packageData:
      {
        count: Number,
        disablePrevious: Boolean,
        disableNext: Boolean,
        nextSlide: Function,
        prevSlide: Function,
      } || undefined,
    showAutobidInfo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VehicleItemBidStatus,
    VehicleItemDetail,
    VehicleItemStamps,
    VehicleItemTimer,
    VehiclePackageInfo,
    Heart,
  },
  computed: {
    paddingBottom: function () {
      if (this.isPackage) {
        return "10px";
      }
      return "4px";
    },
    footerMarginTop: function () {
      if (this.isPackage) {
        return "20px";
      }
      return "4px";
    },
  },
  methods: {
    gotoVdp,
    adsType(vehicle, slug = false) {
      const slugify = (text) =>
        text
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .trim()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-");
      let type = "";

      if (vehicle.id_cotacao) type = "Cotação";
      else if (
        vehicle.veiculo_processo_aprovacao_lance == 1 &&
        vehicle.vlr_processo_aprovacao_lance >= 0
      )
        type = "Bid sob aprovação";
      else type = "Repasse";
      return slug ? slugify(type) : type;
    },
    capitalize,
  },
};
</script>

<style lang="scss" scoped>
#vehicle-item-mobile {
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 6px;
  padding-bottom: v-bind(paddingBottom);
  text-decoration: none;
  flex-shrink: 0;
  margin-top: 10px;
}

.vehicle-image {
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vehicle-image img {
  border-radius: 12px;
  border: 4px solid #fff;
  width: 135px;
  height: 113px;
  object-fit: cover;
}

.vehicle-image img.withoutImage {
  object-fit: scale-down;
}

.vehicle-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 135px;
  height: 113px;
  border-radius: 12px;
  border: 4px solid #fff;
  background: linear-gradient(
    180deg,
    #fff 0%,
    #000 0.01%,
    rgba(0, 0, 0, 0) 39.58%
  );
  z-index: 0;
}

.vehicle-image .location {
  position: absolute;
  display: block;
  top: 8px;
  left: 8px;
  font-family: "Lexend Deca";
  color: #fff;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}
.vehicle-image .favorite {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}
.vehicle-image .price {
  position: absolute;
  border-radius: 5px;
  background: #f90;
  flex-shrink: 0;
  width: 111px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 12px;
  bottom: 0px;
  padding: 4px 0px;
  padding-left: 4px;

  color: #fff;
  text-align: center;
  font-family: "Lexend Deca";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 2;
}

.vehicle-image .price .price-content {
  position: relative;
}
.vehicle-image .price .currency {
  font-size: 8px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  position: absolute;
  top: 1px;
  left: -9px;
  font-family: "Lexend Deca";
}

.vehicle-image .report {
  position: absolute;
  border-radius: 5px;
  background: #0dc561;
  flex-shrink: 0;
  width: 101px;
  height: 19px;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 17px;
  bottom: 10px;
  padding: 0;

  color: #fff;
  font-family: "Lexend Deca";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}

.vehicle-item-content {
  margin-left: 6px;
  margin-top: 2px;
  flex: 1;
  display: grid;
}
.vehicle-header .price,
.vehicle-header .adsType {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}
.vehicle-header .adsType {
  right: inherit;
  left: 12px;
  font-weight: 100;
  &.repasse {
    background: #1872fa;
  }
  &.cotacao {
    background: #ff7a00;
  }
  &.bid-sob-aprovacao {
    background: #0dc561;
  }
}

.vehicle-item-content h3 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.vehicle-item-content h4 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vehicle-item-footer {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: v-bind(footerMarginTop);
}

.vehicle-item-footer .vehicle-item-left {
  display: flex;
  margin-right: 10px;
}

.vehicle-item-footer .vehicle-item-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}

.vehicle-item-footer .vehicle-item-right .seller {
  text-align: center;
  margin: 4px 0;
}

.vehicle-item-footer .vehicle-item-right .seller h3 {
  color: #373737;
  text-align: center;
  font-family: "Lexend Deca";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}
</style>
