<template>
  <BuyerTwoColumnLot
    :title="title"
    subTitle="Acompanhe seus Lotes!"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getLotsOngoing"
    :sectionLeft="{title: 'Lotes ativos', labelCard: 'Ativa'}"
    :sectionRight="{title: 'Lotes superados', labelCard: 'Proposta superada'}"
    noVehicleLabel="Você ainda não avaliou Lotes…"
    noVehicleRightLabel="Você ainda não possui Lotes superados"
  />
</template>

<script>
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import BuyerTwoColumnLot from "../BuyerTwoColumnLot";
import { getLotsOngoing } from "@/services/api/BuyerServiceApi";

export default {
  name: "LotsCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerTwoColumnLot },
  data: function () {
    return {  
      ...BUYER_SECTION_IN_PROGRESS,
      title:"Lotes",
      section: "Em andamento",
      pageDescription: "Pacote significa os veículos que você selecionou. Lote significa os veículos que o anunciante pré-selecionou."
    };
  },
  methods: {
    getLotsOngoing,
  },
};
</script>

<style scoped>
.lots-completed-purchases-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.lots-completed-purchases-page-top {
  width: 100%;
}

.lots-completed-purchases-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
