<template>
  <div class="buyer-one-column-page-mobile" v-if="isMobile()">
    <div class="buyer-one-column-page-mobile-top">
      <BuyerSectionItem
        :icon="icon"
        :title="section"
        :description="description"
        :shouldReturnToTheBuyerPage="true"
      />
    </div>
    <div v-if="!loading">
      <div class="buyer-one-column-page-mobile-content" v-if="lots.length > 0">
        <div
          class="buyer-one-column-page-item"
          v-for="(lot, key) in lots"
          :key="key"
        >
          <LotCard
            :lot="lot"
            :onClick="() => onClickInLot(lot)"
            :label="labelCard"
            :priceBackgroundColor="priceBackgroundColor"
          />
        </div>
      </div>
      <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
    </div>
    <div class="buyer-one-column-page-mobile-loading mt-2" v-else>
      <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
    </div>
  </div>

  <div class="buyer-one-column-page" v-else>
    <div class="buyer-one-column-page-container">
      <div class="buyer-one-column-page-top">
        <h1>{{ subTitle }} {{ loading ? "" : `(${lots.length})` }}</h1>
      </div>

      <div v-if="!loading">
        <div class="buyer-one-column-page-content" v-if="lots.length > 0">
          <div
            class="buyer-one-column-page-item"
            v-for="(lot, key) in lots"
            :key="key"
          >
            <LotCard
              :lot="lot"
              :label="labelCard"
              :priceBackgroundColor="priceBackgroundColor"
              :onClick="() => onClickInLot(lot)"
            />
          </div>
        </div>
        <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
      </div>
      <div class="buyer-one-column-page-content-loading" v-else>
        <div class="loading-item">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card"
            width="50%"
            class="ml-1"
          ></v-skeleton-loader>
        </div>
        <div class="loading-item mt-2">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
        </div>
        <div class="loading-footer mt-2">
          <v-skeleton-loader
            type="heading"
            width="50%"
            class="text-center"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import LotCard from "@/views/buyer/components/LotCard";
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  name: "BuyerOneColumnLot",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: {
    BuyerSectionItem,
    LotCard,
    VSkeletonLoader,
  },
  props: {
    title: String,
    subTitle: String,
    section: String,
    icon: String,
    description: String,
    labelCard: String,
    priceBackgroundColor: String || undefined,
    getContent: Function,
    noVehicleLabel: String,
  },
  data: function () {
    return {
      loading: true,
      lots: [],
      orderBy: null,
    };
  },
  methods: {
    ...mapMutations("buyerLotDetail", [
      "setTitlePage",
      "setTitleContainer",
      "setTotal",
      "setLotDetail",
      "setIsActive",
      "setCurrentLot",
    ]),
    ...mapMutations("buyer", ["setHandleRequestByPeriod", "setPeriod"]),
    onClickInLot: function (lot) {
      this.setTitlePage(`Lote comprado (${lot.qtdVeic} veículos) - Lote ${lot.ava_id}`);
      this.setTitleContainer(null);
      this.setLotDetail(lot);
      this.setTotal(lot.valor);
      this.setIsActive(true);
      this.natigateToInPackageDetailPage();
      this.setCurrentLot(lot)
    },
    setOrderBy: function (newOrderBy) {
      this.orderBy = newOrderBy;
      this.refresh();
    },
    refresh: function () {
      this.loading = true;
      this.getContent(this.orderBy)
        .then((lots) => {
          this.lots = lots;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted: function () {
    this.setPeriod("");

    this.getContent()
      .then((lots) => {
        this.lots = lots;
      })
      .finally(() => (this.loading = false));

    this.setHandleRequestByPeriod((period) => {
      this.loading = true;
      this.getContent(this.orderBy, period)
        .then((lots) => {
          this.lots = lots;
        })
        .finally(() => (this.loading = false));
    });
  },
};
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1700px) and (min-width: 1024px) {
  .buyer-one-column-page .buyer-one-column-page-content{
    width: 100% !important;
    display: block !important;
  }
  .buyer-one-column-page-container{
    display: block !important;
  }
  .buyer-one-column-page-content-right{
    margin-top: 30px !important;
    margin-left: 0 !important;
  }
  .buyer-one-column-page-item{
    width: 100% !important;
    display: block !important;
  }
}

.buyer-one-column-page {
  flex: 78;
  padding: 1.5em;
  justify-content: center;
  padding-right: 25px;

  .buyer-one-column-page-container {
    border-radius: 12px;
    background: #fff;
    height: 100%;
    flex-shrink: 0;
    min-height: 80vh;


    .buyer-one-column-page-top {
      display: flex;
      justify-content: space-between;
      padding: 19px 38px;
      padding-right: 19px;

      h1 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .select {
        position: relative;

        select {
          appearance: none;
          background-color: transparent;
          border-radius: 6px;
          border: 1px solid #808080;
          width: 140px;
          height: 33px;
          flex-shrink: 0;
          color: #808080;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 12px;
        }

        img {
          position: absolute;
          bottom: 8px;
          right: 16px;
        }
      }
    }

    .buyer-one-column-page-content {
      padding: 0 11px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      .buyer-one-column-page-item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 31px;
      }
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 40px;
    }

    .buyer-one-column-page-bottom {
      display: grid;
      justify-content: center;
      align-items: center;
    }

    .buyer-one-column-page-content-loading {
      display: flex;
      flex-direction: column;
      padding: 19px 38px;
      padding-right: 19px;
      padding-top: 0;

      .loading-item {
        display: flex;
        flex: 1;
        flex-direction: row;
      }

      .loading-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.buyer-one-column-page-mobile {
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;

  .buyer-one-column-page-mobile-loading {
    font-family: "Lexend Deca";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-vehicles {
    color: #9f9f9f;
    font-family: "Lexend Deca";
    font-size: 16px;
    line-height: normal;
    padding: 20px;
    background-color: #fff;
    display: flex;
  }

  .buyer-one-column-page-mobile-content {
    .buyer-one-column-page-item {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.buyer-one-column-page-page-top {
  width: 100%;
}

.buyer-one-column-page-page-content {
  margin-top: 4px;
  border-radius: 12px;
  background: #fff;
  flex: 1;
}
</style>
