<template>
  <div id="filter-app">
    <v-app>
      <v-main>
        <div class="buyer-header">
          <h2 class="cursor-pointer" @click="navigateToDashboardPage()">Área do Comprador</h2>
        </div>
        <div v-if="!countsLeftMenu">
          <v-skeleton-loader type="heading, subtitle"></v-skeleton-loader>
          <v-skeleton-loader type="heading, subtitle"></v-skeleton-loader>
          <v-skeleton-loader type="heading, subtitle"></v-skeleton-loader>
          <v-skeleton-loader type="heading, subtitle"></v-skeleton-loader>
        </div>
        <div v-else>
          <FilterSidebarInProgressSection />
          <FilterSidebarInPendingSection />
          <FilterSidebarInClosedSection />
          <FilterSidebarInServiceSection />
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
import FilterSidebarInProgressSection from './components/FilterSidebarInProgressSection.vue';
import FilterSidebarInPendingSection from './components/FilterSidebarInPendingSection.vue';
import FilterSidebarInClosedSection from './components/FilterSidebarInClosedSection.vue';
import FilterSidebarInServiceSection from './components/FilterSidebarInServiceSection.vue';
import PlatformsMixins from "@/mixins/PlatformsMixins";
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  name: 'FilterSidebar',
  mixins: [PlatformsMixins, NavigateMixings],
  props: {
    loading: Boolean
  },
  components: {
    VSkeletonLoader,
    FilterSidebarInProgressSection,
    FilterSidebarInPendingSection,
    FilterSidebarInClosedSection,
    FilterSidebarInServiceSection,
  },
  computed: {
    ...mapState({
      countsLeftMenu: (state) => state.buyer.countsLeftMenu,
    }),
  }
};
</script>
<style scoped>
.cursor-pointer{
  cursor: pointer;
}
#filter-app {
  height: v-bind(contentHeight);
  overflow-y: scroll;
}
#buyer-sidebar {
  flex: 22;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Lexend Deca", Helvetica, Arial;
  overflow-y: scroll;
  overflow-x: hidden;
}
#buyer-sidebar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#buyer-sidebar::-webkit-scrollbar-thumb {
  background-color: lightgray;
}
.buyer-header {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: #fff;
}
</style>
