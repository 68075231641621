<template>
  <div class="location">
    <MenuTitle text="Localização" />

    <v-autocomplete
      placeholder="Digite seu estado ou cidade"
      :model-value="selected"
      :items="list"
      bg-color="#FFFFFF"
      color="blue-grey-lighten-2"
      item-title="nome"
      item-value="item"
      return-object
      variant="solo"
      class="search"
      :search="searchingText"
      menu-icon="mdi-map-marker-outline"
      multiple
      chips
      @update:modelValue="update"
      @update:search="updateSearch"
      clearable
      @click:append="handleAppendClick"
      :menu-props="{ maxHeight: isMobile ? 156 : 300 }"
      :custom-filter="customFilter"
      no-data-text="Carregando..."
      :closable-chips="true"
      id="localizacao-input"
    >
      <template v-slot:loader="{ isActive }">
        <v-progress-linear
          :active="isActive"
          color="green-lighten-3"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>

      <template v-slot:item="{ props, item }">
        <div
          v-if="item.title == 'Estados' || item.title == 'Cidades'"
          class="header-item"
          :style="{ backgroundColor: '#D3D3D3' }"
        >
          <span :style="{ fontWeight: 'bold' }">{{ item.title }}</span>
        </div>

        <div class="location-item" v-else-if="props.value.nome">
          <div
            v-if="searchingText.length >= 2 && props.value.type == 'state'"
            class="header-item header-item-estado"
            :style="{ backgroundColor: '#D3D3D3' }"
          >
            Estados
          </div>
          <div
            v-if="searchingText.length >= 2 && props.value.type == 'city'"
            class="header-item header-item-cidade"
            :style="{ backgroundColor: '#D3D3D3' }"
          >
            Cidades
          </div>

          <v-list-item
            v-bind="props"
            :active="
              props.value.type == 'state'
                ? location.includes(props.value.uf.toUpperCase())
                : cities.includes(props.value.nome)
            "
            :class="{
              'd-none':
                location.length &&
                props.value.type == 'city' &&
                !location.includes(props.value.uf.toUpperCase()),
            }"
            :title="item.raw.nome"
            :id="`location-${item.raw.nome}`"
          ></v-list-item>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import MenuTitle from "@/components/typographies/MenuTitle.vue";
import { getAllSuggestions } from "@/services/api/SerchServiceApi";
import { getFilterData } from "@/services/api/FiltersServiceApi";
import { mapState } from "vuex";
import getPlatform from "@/helpers/getPlatform";
import PLATFORMS from "@/constants/Platforms";
import {capitalize} from "@/helpers/strings";

export default {
  name: "LocationInput",
  components: {
    MenuTitle,
  },
  data: function () {
    return {
      list: [],
      searchingText: "",
    };
  },
  methods: {
    updateSearch(value) {
      const headersCidade = document.querySelectorAll(".header-item-cidade");
      for (let i = 1; i < headersCidade.length; i++) {
        headersCidade[i].style.display = "none";
      }

      const headersEstado = document.querySelectorAll(".header-item-estado");
      for (let i = 1; i < headersEstado.length; i++) {
        headersEstado[i].style.display = "none";
      }

      this.searchingText = value;
    },
    getCityStateName(item) {
      if (item.value.type == "city") {
        return item.value.nome + "-" + item.value.uf.toUpperCase();
      }
      return item.value.uf.toUpperCase();
    },
    handleAppendClick() {
      this.clearSelection();
    },
    clearSelection() {
      this.$store.commit("filterForm/removeParam", "location");
      this.$store.commit("filterForm/removeParam", "cities");
    },
    mountList(data) {
      var regioes = Object.entries(data?.r?.regioes ?? {})?.map((v) => v[0])?.sort() ?? [];
      var cidades = ["Cidades"];
      var estados = ["Estados"];
      // regioes

      const orderDefault = (a, b) => {
        if (typeof (a) === String) {
          return -1;
        }

        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }

      regioes.forEach((element) => {
        var keyEstado = Object.keys(data.r.regioes[element]["uf"]).sort();
        // estados
        var indexEstado = 0;
        keyEstado.forEach((estado) => {
          indexEstado++;
          var nomeEstado = data.r.regioes[element]["uf"][estado].name;
          estados.push({
            nome: nomeEstado,
            type: "state",
            uf: estado,
            index: indexEstado,
          });
          var cities = Object.keys(
            data.r.regioes[element]["uf"][estado].cities
          ).sort();
          // cidades
          var indexCidade = 0;
          cities.forEach((cidade) => {
            indexCidade++;
            var city =
              data.r.regioes[element]["uf"][estado].cities[cidade].name;
            cidades.push({
              nome: `${capitalize(city)} - ${estado.toUpperCase()}`,
              type: "city",
              uf: estado,
              index: indexCidade,
            });
          });

          cidades.sort(orderDefault);
        });
      });
      estados.sort(orderDefault);
      let result = estados.concat(cidades);
      this.list = result;
    },
    update(newValues) {
      if (!Array.isArray(newValues)) return;
      this.searchingText = "";


      const allItemsHaveBeenRemoved = newValues.length === 0
      if (allItemsHaveBeenRemoved) {
        this.clearSelection();
        return;
      }

      const anItemHasBeenRemovedFromTheList = newValues.length < this.selected.length;
      if (anItemHasBeenRemovedFromTheList) {
        const newLocation = this.location.filter(location => newValues.indexOf(location) !== -1);
        const newCities = this.cities.filter(city => newValues.indexOf(city) !== -1);

        this.$store.commit("filterForm/setParam", {
            location: newLocation,
          });
          this.$store.commit("filterForm/setParam", {
            cities: newCities,
          });
        
        return;
      }

      const newValue = newValues[newValues.length - 1];
      if (newValue.type == "state") {
        if (!this.location.includes(newValue.uf.toUpperCase())) {
          this.$store.commit("filterForm/setParam", {
            location: [...this.location, newValue.uf.toUpperCase()],
          });
        } else {
          this.$store.commit("filterForm/setParam", {
            location: this.location.filter(
              (item) => item !== newValue.uf.toUpperCase()
            ),
          });
        }
      } else {
        if (!this.cities.includes(newValue.nome)) {
          this.$store.commit("filterForm/setParam", {
            cities: [...this.cities, newValue.nome],
          });
        } else {
          this.$store.commit("filterForm/setParam", {
            cities: this.cities.filter((item) => item !== newValue.nome),
          });
        }
      }
    },
    customFilter(value, query) {
      const format = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase(); 

      const valueFormatted = format(value);
      const queryFormatted = format(query);

      return valueFormatted.includes(queryFormatted);
    },
  },
  computed: {
    ...mapState({
      params: (state) => state.filterForm.params,
      location: (state) => state.filterForm.params["location"],
      cities: (state) => state.filterForm.params["cities"],
      selected: (state) => [
        ...(state.filterForm.params["location"] ?? []),
        ...(state.filterForm.params["cities"] ?? []),
      ],
    }),

    isMobile() {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
  },
  mounted() {
    getAllSuggestions();
    getFilterData().then((response) => {
      this.mountList(response.data.payload);
    });
    if (!this.params["location"]) {
      this.params["location"] = [];
    }
    if (!this.params["cities"]) {
      this.params["cities"] = [];
    }

    const disableLocation = () => {
      document.querySelector(".search input")?.blur();
    };
    var filterSidebarEl = document.getElementById("filter-sidebar");
    if(filterSidebarEl) filterSidebarEl.addEventListener("touchmove", disableLocation);
    var filterAppEl = document.getElementById("filter-app");
    if(filterAppEl) filterAppEl.addEventListener("scroll", disableLocation);
  },
};
</script>

<style>
.header-item-estado,
.header-item-cidade {
  display: none;
}

.header-item-estado:first-of-type,
.header-item-cidade:first-of-type {
  display: block;
}

.search {
  margin-top: 16px;
  box-shadow: 0;
  font-family: "Lexend Deca" !important;
}

div {
  box-shadow: 0 !important;
}

.notranslate {
  transform: none !important;
}

.location {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.header-item {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 0;
  padding-left: 4px;
  background-color: #373737;
}

.location-item {
  font-family: "Lexend Deca";
  color: #373737;
  font-size: 8px;
}
</style>
