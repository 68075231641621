<template>
    <div class="stepper-wrapper">
        <div v-for="(step, index) in steps" :key="index" :class="stepClasses(index)">
          <div v-if="step < steps" class="step-counter-completed"></div>
          <div v-if="step >= steps && currentStep === steps" class="step-counter-completed text-white">
            &#10003;
          </div>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: "ProgressBar",
    props: {
      steps: {
        type: Number,
        required: true
      },
      currentStep: {
        type: Number,
        required: true
      }
    },
    methods: {
      stepClasses(step) {
        let result = "stepper-item ";
        if (step + 1 <= this.currentStep) {
          return (result += "completed");
        }
        return result;
      }
    },
  };
</script>
  
<style scoped lang="scss">
    .stepper-wrapper {
        width: 100%;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 12px solid #EEEEEE;
        width: 100%;
        top: 7px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 12px solid #EEEEEE;
        width: 100%;
        top: 7px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: solid 3px white;
        border-radius: 50%;
        background: #EEEEEE;
    }

    .stepper-item .step-counter-completed {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: solid 3px white;
        border-radius: 50%;
        background: #0DC561;
    }

    .stepper-item .step-counter-completed-with-icon {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: solid 3px white;
        border-radius: 50%;
        background: #ff0000;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #0DC561;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 12px solid #0DC561;
        width: 100%;
        top: 7px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
      content: none;
    }
    .stepper-item:last-child::after {
      content: none;
    }
</style>
  