<template>
  <ReorganizationDrawer
    @eventHandler="reorganizationDrawerHandler"
    v-if="!seeAllDrawerEnabled"
  />
  <SeeAll v-if="seeAllDrawerEnabled" @scrollEventHandler="scrollToElement" />
  <div
    id="filter-sidebar"
    v-show="!reorganizationDrawerEnabled && !seeAllDrawerEnabled"
  >
    <div>
      <div v-for="section in sections" v-bind:key="section.key">
        <div
          id="location"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Location"
        >
          <Location />
        </div>

        <div
          id="advertiser-type"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.AdvertiserType"
        >
          <CheckBoxes
            title="Tipo de anunciante"
            @update="$store.commit('filterForm/setParam', { adType: $event })"
            :options="advertiserTypes"
            id="adType"
          />
        </div>

        <div
          id="vehicleType"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Category"
        >
          <VehicleType title="Categoria" />
        </div>

        <div
          id="purchase-method"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.PurchaseMode"
        >
          <CheckBoxes
            title="Modalidade de Compra"
            @update="
              $store.commit('filterForm/setParam', { quotation: $event })
            "
            :options="[
              { id: 0, title: 'Repasse', filter: 'repasse' },
              { id: 1, title: 'Cotação', filter: 'cotacao' },
              { id: 2, title: 'Bid sob aprovação', filter: 'bidSobAprovacao' },
            ]"
            id="quotation"
          />
        </div>

        <div
          id="vehicle-type"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.VehicleType"
        >
          <CheckBoxes
            title="Tipo de veículo"
            @update="
              $store.commit('filterForm/setParam', { typeVehicle: $event })
            "
            :options="[
              {
                id: 'novo',
                title: 'Novo',
                icon: require('@/assets/icons/vehicleCondition/new.svg'),
                tooltip: 'Veículo com menos de 1mil km rodados',
              },
              {
                id: 'usado',
                title: 'Usado',
              },
              {
                id: 'avariado',
                title: 'Avariado',
                icon: require('@/assets/icons/vehicleCondition/damaged.svg'),
                tooltip: 'Veículo contém múltiplas avarias',
              },
            ]"
            id="typeVehicle"
          />
        </div>

        <div
          id="vehicle-condition"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.VehicleCondition"
        >
          <CheckBoxes
            title="Condição do veículo"
            @update="
              $store.commit('filterForm/setParam', { vehicleCondition: $event })
            "
            :options="[
              {
                id: 'showroom',
                title: 'Showroom',
                icon: require('@/assets/icons/vehicleCondition/showroom.svg'),
                tooltip: 'Veículo com qualidade de showroom',
              },
              {
                id: 'blindado',
                title: 'Blindado',
                icon: require('@/assets/icons/vehicleCondition/armored.svg'),
                tooltip: 'Veículo blindado',
              },
            ]"
            id="vehicleCondition"
          />
        </div>

        <div
          id="report"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Report"
        >
          <CheckBoxes
            title="Laudo"
            @update="$store.commit('filterForm/updateReportStatus', $event)"
            :options="[
              {
                id: 'approved',
                filter: 'aprovado',
                title: 'Aprovado',
                icon: require('@/assets/icons/report/report-approved.svg'),
                tooltip: 'Veículo com laudo aprovado',
              },
              {
                id: 'restricted',
                filter: 'restricao',
                title: 'Aprovado com restrição',
                icon: require('@/assets/icons/report/report-approved-with-restrictrions.svg'),
                tooltip: 'Veículo contém laudo aprovado com restrição',
              },
              {
                id: 'reproved',
                filter: 'reprovado',
                title: 'Reprovado',
                icon: require('@/assets/icons/report/report-failed.svg'),
                tooltip: 'Veículo contém laudo reprovado',
              },
              {
                id: 'no',
                filter: 'semLaudo',
                title: 'Sem Laudo',
              },
            ]"
            id="reportStatus"
          />
        </div>

        <div
          id="optionals"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Opcional"
        >
          <CheckBoxes
            title="Opcionais"
            @update="
              $store.commit('filterForm/setParam', { optionals: $event })
            "
            :options="[
              { id: 'vidro', title: 'Vidro elétrico' },
              { id: 'dhe', title: 'Direção elétrica' },
              { id: 'abag', title: 'Air bags' },
              { id: 'ac', title: 'Ar condicionado' },
            ]"
            :see-all="{
              title: 'Ver todos os opcionais',
              placeholder: 'Digite a opção desejada',
              menu: 'Opcionais',
              submenu: 'Todos os opcionais',
              handle: () => optionalsData.optionals,
              scrollTo: 'optionals',
            }"
            id="optionals"
          />
        </div>

        <div
          id="fuel"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Fuel"
        >
          <CheckBoxes
            title="Combustível"
            @update="$store.commit('filterForm/setParam', { fuel: $event })"
            :options="[
              { id: 'diesel', title: 'Diesel' },
              { id: 'eletrico', title: 'Elétrico' },
              { id: 'etanol', title: 'Etanol' },
              { id: 'flex', title: 'Flex' },
              { id: 'gasolina', title: 'Gasolina' },
              { id: 'gnv', title: 'GNV' },
              { id: 'hibrido', title: 'Híbrido' },
            ]"
            id="fuel"
          />
        </div>

        <div
          id="transmission"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Transmission"
        >
          <CheckBoxes
            title="Transmissão"
            @update="
              $store.commit('filterForm/setParam', { transmission: $event })
            "
            :options="[
              { id: 'manual', title: 'Manual' },
              { id: 'automatico', title: 'Automático' },
              { id: 'dualogic', title: 'Dualogic' },
              { id: 'imotion', title: 'iMotion' },
              { id: 'semi-automatico', title: 'Semi-Automatico' },
            ]"
            id="transmission"
          />
        </div>

        <div
          id="fipe"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.Fipe"
        >
          <Fipe ref="fipeComponentRef" />
        </div>

        <div
          id="vehicle-branding"
          class="sidebar-item mt-5"
          v-if="section.key === SECTIONS_KEYS.VehicleBranding"
        >
          <VehicleBranding
            :see-all="{
              title: 'Ver todas as marcas',
              placeholder: 'Digite a marca desejada',
              menu: 'Marcas',
              submenu: 'Ver todas as marcas',
              handle: () => optionalsData.brands,
              scrollTo: 'vehicle-branding',
            }"
          />
        </div>

        <div
          id="vehicle-price"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.VehiclePrice"
        >
          <VehiclePriceRange />
        </div>

        <div
          id="vehicle-year"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.VehicleYear"
        >
          <VehicleYearRange />
        </div>

        <div
          id="vehicle-km"
          class="sidebar-item"
          v-if="section.key === SECTIONS_KEYS.VehicleKM"
        >
          <VehicleKMRange />
        </div>
      </div>

      <button class="clear-button" @click="() => limparFiltro($store)">
        <img
          :src="require('@/assets/icons/trash-icon.svg')"
          width="24"
          height="24"
        />
        <span id="filter-sidebar-limpar">Limpar</span>
      </button>

      <div id="autoavaliar-logo" class="mb-4">
        <img :src="require('@/assets/icons/autoavaliar-icon.svg')" />
      </div>
    </div>
  </div>

  <div class="container" v-if="isMobile"></div>
  <div class="footer" v-if="isMobile && seeAllDrawerEnabled === false">
    <v-btn
      variant="plain"
      id="button"
      width="248px"
      height="49px"
      @click="closeSidebar"
      class="text-none"
    >
      <span>Filtrar</span>
      <span v-if="totalResults > 0 && loading === false"
        >({{ totalResults }})</span
      >
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CheckBoxes from "@/components/inputs/CheckBoxes.vue";
import ReorganizationDrawer from "@/components/drawers/ReorganizationDrawer.vue";
import Location from "@/components/Location.vue";
import VehicleType from "@/components/VehicleType.vue";
import Fipe from "@/components/Fipe.vue";
import VehiclePriceRange from "./VehiclePriceRange.vue";
import VehicleYearRange from "./VehicleYearRange.vue";
import VehicleKMRange from "./VehicleKMRange.vue";
import SECTIONS_DEFAULT from "@/constants/SectionsDefault";
import SECTIONS_KEYS from "@/constants/SectionsKeys";
import getAllAdvertiserType from "@/services/api/FiltersServiceApi";
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import SeeAll from "@/components/drawers/SeeAll.vue";
import VehicleBranding from "@/components/VehicleBrandings.vue";
import testStorage from "@/services/localStorage";

export default {
  name: "FilterSidebar",
  components: {
    SeeAll,
    CheckBoxes,
    ReorganizationDrawer,
    Location,
    VehicleType,
    Fipe,
    VehiclePriceRange,
    VehicleYearRange,
    VehicleKMRange,
    VehicleBranding,
  },
  data: function () {
    return {
      advertiserTypes: [],
    };
  },
  props: {
    closeSidebar: Function,
  },
  created: function () {
    this.SECTIONS_KEYS = SECTIONS_KEYS;
  },
  mounted: function () {
    if(testStorage()){
    if (localStorage.getItem("sections")) {
      try {
        this.setSections(JSON.parse(localStorage.getItem("sections")));
      } catch (e) {
        localStorage.removeItem("sections");
      }
    } else {
      this.setSections(SECTIONS_DEFAULT);
    }
    this.loadFromCache();
    }else{
      this.setSections(SECTIONS_DEFAULT);
    }

    getAllAdvertiserType()
      .then((response) => (this.advertiserTypes = response.data.payload))
      .then(() => (this.setLoading(false)));
  },
  computed: {
    ...mapState({
      sections: (state) => state.sections.list,
      params: (state) => state.filterForm.params,
      optionalsData: (state) => state.filterForm.optionalsData,
      reorganizationDrawerEnabled: (state) =>
        state.drawer.reorganizationDrawerEnabled,
      seeAllDrawerEnabled: (state) => state.drawer.seeAllDrawerEnabled,
      loading: (state) => state.filterForm.loading,
      totalResults: (state) => state.filterForm.totalResults,
    }),
    isMobile: function () {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
  },
  methods: {
    ...mapMutations("sections", ["setSections"]),
    ...mapActions("filterForm", ["loadFromCache"]),
    ...mapMutations("filterForm", ["setLoading"]),
    ...mapMutations("drawer", [
      "hideReorganizationDrawer",
      "showReorganizationDrawer",
      "hideReorganizationDrawer",
      "showSeeAllDrawer",
    ]),
    reorganizationDrawerHandler() {
      if (localStorage.getItem("sections")) {
        try {
          this.setSections(JSON.parse(localStorage.getItem("sections")))
        } catch (e) {
          localStorage.removeItem("sections");
        }
      } else {
        this.setSections(SECTIONS_DEFAULT)
      }
      this.hideReorganizationDrawer();
    },

    scrollToElement(el) {
      setTimeout(function () {
        let div = document.querySelector("#filter-app");
        div.scrollTo({
          top: document.getElementById(el).offsetTop,
          behavior: "smooth",
        });
      }, 200);
    },
    limparFiltro($store) {
      $store.commit('filterForm/clear');
      $store.commit('drawer/clear');

      this.$refs.fipeComponentRef[0].changeRange();
    },
    filterAdTypes(data){
      const hide = ['Corretor', 'Publico', 'C2B', 'Rodoviario'];
      let result = data.filter(item => hide.indexOf(item.title) < 0);
      return result; 
    }
  },
};
</script>

<style>
#filter-sidebar {
  padding: 0 20px;
  background-color: #ffffff;
}

.sidebar-item {
  margin: 0.5rem auto;
  padding-bottom: 12px;
}

.loading {
  font-family: "Lexend Deca";
  text-align: center;
  color: #c6c6c6;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 77px;
  background: #efefef;
  box-shadow: 0px -2px 22px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1000000;
}

.container {
  padding: 40px;
}

.footer #button {
  background: #ff7a00;
  border-radius: 4px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: normal;
  width: 248px;
  height: 49px;
  opacity: 1;

  color: #ffffff;
}

.clear-button {
  display: flex;
  height: 27px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.clear-button:hover {
  opacity: 0.8;
}

.clear-button span {
  color: #000;
  font-size: 14px;
  font-family: "Lexend Deca";
  font-weight: 700;
  margin-left: 4px;
}

#autoavaliar-logo {
  margin-top: 28px;
  padding-bottom: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
