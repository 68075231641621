<template>
  <v-list v-model:opened="open" style="padding: 0">
    <v-list-group value="Open">

      <v-list-group value="Open" :style="`margin: ${isMobile ? '0' : '20px 0'}`">
        <div
          class="sub-item"
          v-bind="props"
          v-on:click="natigateToBidInProgressPage()"
          id="em_andamento_avaliacoes_menu_item"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-item-label">
            Avaliações
            <VTooltip placement="top-start" v-if="!isMobile">
              <v-icon
                class="info-icon ml-1"
                icon="mdi-information-slab-circle"
                size="small"
              ></v-icon>

              <template #popper>
                Acompanhe suas avaliações feitas via “Avaliações”.
              </template>
            </VTooltip>
          </div>
          <div class="append-icon">
            <img :src="require('@/assets/icons/right-icon.svg')" />
          </div>
        </div>
        <div class="sub-sub-item" v-for="([title, color], i) in bids" :key="i">
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-sub-item-label">
            <span>{{ title }}</span>
            <span :style="{ color: color, paddingLeft: '2px' }">({{ (i==0) ? countsLeftMenu.em_andamento.lances.ativos : countsLeftMenu.em_andamento.lances.superados }})</span>
          </div>
        </div>
      </v-list-group>

      <v-list-group value="Open" :style="`margin: ${isMobile ? '0' : '20px 0'}`">
        <div
          class="sub-item"
          v-bind="props"
          v-on:click="natigateToOnDemandAssessmentInProgressPage()"
          id="em_andamento_avaliacoes_sob_aprovacao_menu_item"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-item-label">
            Avaliações sob aprovação
            <VTooltip placement="top-start" v-if="!isMobile">
              <v-icon
                class="info-icon ml-1"
                icon="mdi-information-slab-circle"
                size="small"
              ></v-icon>

              <template #popper>
                Acompanhe suas avaliações feitas via “Avaliações sob aprovação”. 
              </template>
            </VTooltip>
          </div>
          <div class="append-icon">
            <img :src="require('@/assets/icons/right-icon.svg')" />
          </div>
        </div>
        <div class="sub-sub-item" v-for="([title, color], i) in assessment" :key="i">
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-sub-item-label">
            <span>{{ title }}</span>
            <span :style="{ color: color, paddingLeft: '2px' }">({{ (i==0) ? countsLeftMenu.em_andamento.avaliacao_sob_aprovacao.ativos : countsLeftMenu.em_andamento.avaliacao_sob_aprovacao.superados }})</span>
          </div>
        </div>
      </v-list-group>

      <v-list-group value="Open" style="margin: 20px 0">
        <div
          class="sub-item"
          v-bind="props"
          v-on:click="natigateToProposalInProgressPage()"
          id="em_andamento_propostas_menu_item"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-item-label">
            Propostas
            <VTooltip placement="top-start" v-if="!isMobile">
              <v-icon
                class="info-icon ml-1"
                icon="mdi-information-slab-circle"
                size="small"
              ></v-icon>

              <template #popper>
                Acompanhe suas propostas enviadas.
              </template>
            </VTooltip>
          </div>
          <div class="append-icon">
            <img :src="require('@/assets/icons/right-icon.svg')" />
          </div>
        </div>
        <div
          class="sub-sub-item"
          v-for="([title, color], i) in proposals"
          :key="i"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-sub-item-label">
            <span>{{ title }}</span>
            <span :style="{ color: color, paddingLeft: '2px' }">({{ (i==0) ? countsLeftMenu.em_andamento.propostas.ativas : countsLeftMenu.em_andamento.propostas.superadas }})</span>
          </div>
        </div>
      </v-list-group>

      <v-list-group value="Open" style="margin: 20px 0">
        <div
          class="sub-item"
          v-bind="props"
          v-on:click="natigateToQuotesInProgressPage()"
          id="em_andamento_cotacoes_menu_item"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-item-label">
            Cotações
            <VTooltip placement="top-start" v-if="!isMobile">
              <v-icon
                class="info-icon ml-1"
                icon="mdi-information-slab-circle"
                size="small"
              ></v-icon>

              <template #popper>
                Acompanhe suas Cotações Ativas e Superadas.
              </template>
            </VTooltip>
          </div>
          <div class="append-icon">
            <img :src="require('@/assets/icons/right-icon.svg')" />
          </div>
        </div>
        <div
          class="sub-sub-item"
          v-for="([title, color], i) in quotes"
          :key="i"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-sub-item-label">
            <span>{{ title }}</span>
            <span :style="{ color: color, paddingLeft: '2px' }">({{ (i==0) ? countsLeftMenu.em_andamento.cotacoes.ativas : countsLeftMenu.em_andamento.cotacoes.superadas }})</span>
          </div>
        </div>
      </v-list-group>

      <v-list-group value="Open" style="margin: 20px 0">
        <div
          class="sub-item"
          v-bind="props"
          v-on:click="natigateToAutoBidInProgressPage()"
          id="em_andamento_autobid_menu_item"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-item-label">
            AutoBid
            <VTooltip placement="top-start" v-if="!isMobile">
              <v-icon
                class="info-icon ml-1"
                icon="mdi-information-slab-circle"
                size="small"
              ></v-icon>
              <template #popper>
                Acompanhe suas avaliações com AutoBid Ativado e Superado.
              </template>
            </VTooltip>
          </div>
          <div class="append-icon">
            <img :src="require('@/assets/icons/right-icon.svg')" />
          </div>
        </div>
        <div
          class="sub-sub-item"
          v-for="([title, color], i) in autobids"
          :key="i"
        >
          <div class="prepend-icon">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="sub-sub-item-label">
            <span>{{ title }}</span>
            <span :style="{ color: color, paddingLeft: '2px' }">({{ (i==0) ? countsLeftMenu.em_andamento.autobid.ativos : countsLeftMenu.em_andamento.autobid.superados }})</span>
          </div>
        </div>
      </v-list-group>

    </v-list-group>
  </v-list>
</template>

<script>
import FilterSidebarSectionMixin from "@/views/buyer/components/FilterSidebar/mixins/FilterSidebarSectionMixin";
import NavigateMixings from "@/mixins/NavigateMixings";
import { mapState } from "vuex";

export default {
  name: "FilterSidebarInProgressItems",
  mixins: [FilterSidebarSectionMixin, NavigateMixings],
  props: {
    isMobile: Boolean,
  },
  data: () => ({
    bids: [
      ["Ativas", "#0dc561"],
      ["Superadas", "#f22300"],
    ],
    assessment: [
      ["Ativas", "#0dc561"],
      ["Superadas", "#f22300"],
    ],
    proposals: [
      ["Ativas", "#0dc561"],
      ["Superadas", "#f22300"],
    ],
    quotes: [
      ["Ativas", "#0dc561"],
      ["Superadas", "#f22300"],
    ],
    autobids: [
      ["Ativos", "#0dc561"],
      ["Superados", "#f22300"],
    ],
    lot: [
      ["Ativos", "#0dc561"],
      ["Superados", "#f22300"],
    ],
  }),
  computed: {
    ...mapState({
      countsLeftMenu: (state) => state.buyer.countsLeftMenu,
    }),
    subItemPadding: function () {
      if (this.isMobile) {
        return "8px 0";
      }
      return "";
    },
    subItemBackgroundColor: function () {
      if (this.isMobile) {
        return "#FAFAFA";
      }
      return "";
    },
  }
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  padding: 10px 0;
}
.item .prepend-icon {
  color: #f90;
  flex: 8;
  text-align: right;
}
.item .prepend-icon > * {
  vertical-align: bottom;
}
.item .item-label {
  flex: 73;
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}
.item .append-icon {
  flex: 8;
}
.sub-item {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  cursor: pointer;
  background-color: v-bind(subItemBackgroundColor);
  padding: v-bind(subItemPadding);
}
.sub-item .prepend-icon {
  color: #f90;
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 7;
}
.sub-item .prepend-icon > * {
  vertical-align: sub;
}
.sub-item .sub-item-label {
  color: #ff7a00;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex: 84;
  padding-left: 10px;
  display: flex;
}
.sub-item .sub-item-label > * {
  color: #e1e1e1;
}
.sub-item .append-icon {
  flex: 8;
}
.sub-sub-item {
  display: flex;
  flex-direction: row;
  padding: 2px 0;
}
.sub-sub-item .prepend-icon {
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 15;
  color: #5b5b5b;
}
.sub-sub-item-label {
  flex: 85;
  padding-left: 8px;
  color: #5b5b5b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}
.sub-item.pending {
  padding: 3px 0;
}
.sub-item.pending .prepend-icon {
  color: #6e6e6e;
}
.sub-item.pending .sub-item-label .label {
  color: #6e6e6e;
}
.sub-header {
  display: flex;
  flex-direction: row;
}
.sub-header .prepend-icon {
  flex: 8;
  text-align: right;
  font-size: 8px;
  padding: 5px 0;
}
.sub-header .prepend-icon > * {
  vertical-align: bottom;
}
.sub-header .prepend-icon.red {
  color: #f22300;
}
.sub-header .prepend-icon.green {
  color: #0dc561;
}
.sub-header .item-label {
  flex: 92;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}
#submit-button {
  padding: 20px;
  margin: 50px auto 100px auto;
  text-align: center;
  width: 80%;
  border: 3px solid #f90;
  color: #f90;
  font-weight: 600;
  border-radius: 3px;
}
</style>
