const SECTIONS_KEYS = {
  AdvertiserType: "AdvertiserType",
  PurchaseMode: "PurchaseMode",
  VehicleCondition: "VehicleCondition",
  Report: "Report",
  Opcional: "Opcional",
  Fuel: "Fuel",
  Transmission: "Transmission",
  Location: "Location",
  Fipe: "Fipe",
  VehiclePrice: "VehiclePrice",
  VehicleYear: "VehicleYear",
  VehicleKM: "VehicleKM",
  VehicleBranding: "VehicleBranding",
  VehicleType: "VehicleType",
  Category: "Category",
};

export default SECTIONS_KEYS;
