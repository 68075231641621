<template>
  <TopApp />
  <div class="app-content">
    <FilterApp />
    <router-view v-slot="{ Component }">
      <!-- Use any custom transition and  to `fade` -->
      <transition name="slide" mode="out-in">
        <div id="showcase-result-app" :class="isMobile() ? 'mobile' : ''">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TopApp from "@/views/showcase/components/TopApp";
import FilterApp from "@/views/showcase/components/FilterApp.vue";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import { mapMutations } from "vuex";
import {countsLeftMenu} from "@/services/api/BuyerServiceApi";

export default {
  name: "DashboardShowcase",
  mixins: [PlatformsMixins],
  components: {
    TopApp,
    FilterApp,
  },
  methods: {
    ...mapActions("buyer", ["getCountsLeftMenu"]),
    ...mapMutations("global", ["setCurrentMainMenu"]),
    ...mapMutations("buyer", ["setResultCountsLeftMenu"]),
    ...mapMutations("filterShowcase", ["setTitleBreadcrumb"]),
    async getBuyerDetailsData() {
      countsLeftMenu()
          .then((response) =>
              this.setResultCountsLeftMenu(response)
          );
    },
  },
  computed: {
    slideLeaveActive: function () {
      if (this.isMobile()) {
        return "opacity 0.2s, transform 0.2s";
      }
      return "";
    },
    slideLeaveToOpacity: function () {
      if (this.isMobile()) {
        return "0";
      }
      return "";
    },
    slideLeaveToTransform: function () {
      if (this.isMobile()) {
        return "translateX(-100%)";
      }
      return "";
    },
  },
  mounted() {
    this.setCurrentMainMenu("showcase");
    this.setTitleBreadcrumb('Dashboard');
    this.getBuyerDetailsData();
    this.$emitter.on('refreshEvent', (from) => {
      if (from === 'ReleasePaymentViewModal') {
        this.getBuyerDetailsData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: v-bind(slideLeaveActive);
}
.slide-enter-from,
.slide-leave-to {
  opacity: v-bind(slideLeaveToOpacity);
  transform: v-bind(slideLeaveToTransform);
}

#showcase-result-app {
  flex: 78;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: v-bind(contentHeightWithButtonFooter);
  &.mobile{
    padding-left: 0;
    padding:0 .5em;
  }
}
</style>
