import { mapMutations } from "vuex";
export default {
    methods: {
        ...mapMutations("buyer", [
            "setSubTitlePage",
            "setTitleSectionPage",
            "setTitlePage",
        ]),
    },
    mounted() {
        this.setSubTitlePage(this.title);
        this.setTitlePage(this.section);
        this.setTitleSectionPage("Área do comprador");
    },
};