<template>
  <div class="buyer-two-column-page-mobile" v-if="isMobile()">
    <div class="buyer-two-column-page-mobile-top">
      <BuyerSectionItem
        :icon="icon"
        :title="section"
        :description="description"
        :shouldReturnToTheBuyerPage="true"
      />
    </div>

    <div
      class="buyer-two-column-page-container"
      v-if="!loadingLeft && !loadingRight"
    >
      <v-list v-model:opened="openOne" style="padding: 0">
        <v-list-group value="Open">
          <template v-slot:activator="{ props }">
            <div class="top green" v-bind="props">
              <div class="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="white" />
                </svg>
                <span class="ml-2"
                  >{{ sectionLeft.title }}
                  {{
                    vehicles.ativos?.length > 0
                      ? `(${vehicles.ativos.length})`
                      : ``
                  }}</span
                >
              </div>
              <img
                :src="require('@/assets/icons/arrow-up-white-icon.svg')"
                :class="{
                  'css-that-rotates-this-icon': !isOpenOne,
                  'css-that-rotates-this-icon-init': isOpenOne,
                }"
              />
            </div>
          </template>

          <h4 class="subtitle-section" v-if="sectionLeft.subTitle">
            {{ sectionLeft.subTitle }}
          </h4>

          <div v-if="vehicles.ativos.length > 0">
            <div
              class="buyer-two-column-page-item mt-2"
              v-for="(vehicle, key) in vehicles.ativos"
              :key="key"
            >
              <VehicleCard
                v-if="isVehicleCard(vehicle)"
                :vehicle="vehicle"
                :label="sectionLeft.labelCard"
                priceBackgroundColor="#0DC561"
                :isInProgress="true"
                :buyerScope="buyerScope"
                :onClick="() => onClickInVehicle(vehicle)"
                :favorite="favorites[vehicle.ava_id]"
                :handleFavorite="() => handleFavorite(vehicle.ava_id)"
              />

              <LotCard
                v-if="isPackageCard(vehicle)"
                :lot="vehicle"
                :label="sectionLeft.labelCard"
                :isInProgress="true"
                priceBackgroundColor="#0DC561"
                :isActive="isActive"
                labelPrice="Sua avaliação"
                labelAbouvePrice="Ativa"
                :onClick="() => onClickInLot(vehicle)"
                :buyerScope="buyerScope"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
        </v-list-group>
      </v-list>

      <v-list
        v-model:opened="openTwo"
        style="padding: 0"
        class="mt-2"
        v-if="!hideSectionRight"
      >
        <v-list-group value="Open">
          <template v-slot:activator="{ props }">
            <div class="top red" v-bind="props">
              <div class="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="white" />
                </svg>
                <span class="ml-2"
                  >{{ sectionRight.title }}
                  {{
                    vehicles.superados?.length > 0
                      ? `(${vehicles.superados.length})`
                      : ``
                  }}</span
                >
              </div>
              <img
                :src="require('@/assets/icons/arrow-up-white-icon.svg')"
                :class="{
                  'css-that-rotates-this-icon': !isOpenTwo,
                  'css-that-rotates-this-icon-init': isOpenTwo,
                }"
              />
            </div>
          </template>

          <h4 class="subtitle-section" v-if="sectionRight.subTitle">
            {{ sectionRight.subTitle }}
          </h4>

          <div v-if="vehicles.superados.length > 0">
            <div
              class="buyer-two-column-page-item mt-2"
              v-for="(vehicle, key) in vehicles.superados"
              :key="key"
            >
              <VehicleCard
                v-if="isVehicleCard(vehicle)"
                :vehicle="vehicle"
                :label="sectionRight.labelCard"
                :isInProgress="true"
                :onClick="() => onClickInVehicle(vehicle)"
                priceBackgroundColor="#F22300"
                :buyerScope="buyerScope"
                :favorite="favorites[vehicle.ava_id]"
                :handleFavorite="() => handleFavorite(vehicle.ava_id)"
              />
              <LotCard
                v-if="isPackageCard(vehicle)"
                :lot="vehicle"
                :label="sectionRight.labelCard"
                :isInProgress="true"
                priceBackgroundColor="#F22300"
                :onClick="() => onClickInLot(vehicle, false)"
                :isActive="false"
                labelPrice="Superada"
                labelAbouvePrice="Última avaliação"
                :waOvercome="true"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{
            vehicles.ativos.length > 0 ? noVehicleRightLabel : noVehicleLabel
          }}</span>
        </v-list-group>
      </v-list>
    </div>
    <div v-else>
      <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
    </div>
  </div>
  <v-container class="buyer-two-column-page" v-else>
    <div class="buyer-two-column-page-top">
      <h1>{{ subTitle }}</h1>
      <h2 class="mt-2">{{ `${sectionLeft.subTitle} ${sectionRight.subTitle}` }}</h2>
    </div>

    <div class="buyer-two-column-page-container mt-4 pb-12">
      <div
        class="buyer-two-column-page-content buyer-two-column-page-content-left mr-1"
      >
        <div class="buyer-two-column-page-content-top mb-6">
          <div class="left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 26 26"
              fill="none"
            >
              <circle cx="13" cy="13" r="13" fill="#0DC561" />
            </svg>
            <span class="ml-2"
              >{{ sectionLeft.title }}
              {{
                vehicles.ativos?.length > 0 ? `(${vehicles.ativos.length})` : ``
              }}</span
            >
          </div>
          <div class="right">
            <div class="select">
              <select
                :value="orderLeftBy"
                @change="(event) => setOrderLeftBy(event.target.value)"
                id="area_comprador_ordenar_por_botao"
              >
                <option value="" id="area_comprador_ordenar_por_default_botao">
                  Ordenar por
                </option>
                <option
                  value="Mais recentes"
                  id="area_comprador_ordenar_por_mais_recentes_botao"
                >
                  Mais recentes
                </option>
                <option
                  value="Mais antigos"
                  id="area_comprador_ordenar_por_mais_antigos_botao"
                >
                  Mais antigos
                </option>
                <option
                  value="Maior Valor"
                  id="area_comprador_ordenar_por_maior_valor_botao"
                >
                  Maior valor
                </option>
                <option
                  value="Menor Valor"
                  id="area_comprador_ordenar_por_menos_valor_botao"
                >
                  Menor valor
                </option>
              </select>
              <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
            </div>
          </div>
        </div>

        <div v-if="!loadingLeft">
          <div v-if="vehicles.ativos.length > 0">
            <div
              class="buyer-two-column-page-item"
              v-for="(vehicle, key) in vehicles.ativos"
              :key="key"
            >
              <VehicleCard
                v-if="isVehicleCard(vehicle)"
                :vehicle="vehicle"
                :label="sectionLeft.labelCard"
                priceBackgroundColor="#0DC561"
                :isInProgress="true"
                :buyerScope="buyerScope"
                :favorite="favorites[vehicle.ava_id]"
                :handleFavorite="() => handleFavorite(vehicle.ava_id)"
              />
              <LotCard
                v-if="isPackageCard(vehicle)"
                :lot="vehicle"
                :label="sectionLeft.labelCard"
                :isInProgress="true"
                priceBackgroundColor="#0DC561"
                :buyerScope="buyerScopePackage"
                labelPrice="Sua avaliação"
                labelAbouvePrice="Ativa"
                :onClick="() => onClickInLot(vehicle)"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
        </div>
        <div v-else>
          <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
        </div>
      </div>
      <div
        class="buyer-two-column-page-content buyer-two-column-page-content-right ml-1"
        v-if="!hideSectionRight"
      >
        <div class="buyer-two-column-page-content-top mb-6">
          <div class="left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 26 26"
              fill="none"
            >
              <circle cx="13" cy="13" r="13" fill="#F22300" />
            </svg>
            <span class="ml-2"
              >{{ sectionRight.title }}
              {{
                vehicles.superados?.length > 0
                  ? `(${vehicles.superados.length})`
                  : ``
              }}</span
            >
          </div>
          <div class="right">
            <div class="select">
              <select
                :value="orderRightBy"
                @change="(event) => setOrderRightBy(event.target.value)"
              >
                <option value="">Ordenar por</option>
                <option value="Mais recentes">Mais recentes</option>
                <option value="Mais antigos">Mais antigos</option>
                <option value="Maior Valor">Maior valor</option>
                <option value="Menor Valor">Menor valor</option>
              </select>
              <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
            </div>
          </div>
        </div>

        <div v-if="!loadingRight">
          <div v-if="vehicles.superados.length > 0">
            <div
              class="buyer-two-column-page-item"
              v-for="(vehicle, key) in vehicles.superados"
              :key="key"
            >
              <VehicleCard
                v-if="isVehicleCard(vehicle)"
                :vehicle="vehicle"
                :label="sectionRight.labelCard"
                priceBackgroundColor="#F22300"
                :waOvercome="true"
                :buyerScope="buyerScope"
                :isInProgress="true"
                :favorite="favorites[vehicle.ava_id]"
                :handleFavorite="() => handleFavorite(vehicle.ava_id)"
              />
              <LotCard
                v-if="isPackageCard(vehicle)"
                :lot="vehicle"
                :label="sectionRight.labelCard"
                priceBackgroundColor="#F22300"
                :waOvercome="true"
                :buyerScope="buyerScopePackage"
                :isInProgress="true"
                labelPrice="Superada"
                labelAbouvePrice="Última avaliação"
                :onClick="() => onClickInLot(vehicle, false)"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{
            vehicles.ativos.length > 0 ? noVehicleRightLabel : noVehicleLabel
          }}</span>
        </div>
        <div v-else>
          <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import VehicleCard from "@/views/buyer/components/VehicleCard";
import NavigateMixings from "@/mixins/NavigateMixings";
import FilterSidebarSectionMixin from "@/views/buyer/components/FilterSidebar/mixins/FilterSidebarSectionMixin";
import LotCard from "@/views/buyer/components/LotCard";
import { setFavorite } from "@/services/api/BuyerServiceApi";

export default {
  name: "BuyerTwoColumnPage",
  mixins: [
    PlatformsMixins,
    BuyerComponentPagesMixins,
    NavigateMixings,
    FilterSidebarSectionMixin,
  ],
  components: { BuyerSectionItem, VehicleCard, VSkeletonLoader, LotCard },
  props: {
    title: String,
    subTitle: String,
    section: String,
    icon: String,
    description: String,
    labelCard: String,
    priceBackgroundColor: String || undefined,
    getContent: Function,
    priceDescriptionPage: String || undefined,
    sectionLeft: {
      title: String,
      subTitle: String || undefined,
      labelCard: String,
      labelCardPackage: String,
    },
    sectionRight: {
      title: String,
      labelCard: String,
      labelCardPackage: String,
    },
    noVehicleLabel: String,
    hideSectionRight: Boolean,
    noVehicleRightLabel: String,
    buyerScope: String,
  },
  data: function () {
    return {
      loadingLeft: true,
      loadingRight: true,
      vehicles: [],
      orderBy: null,
      orderLeftBy: null,
      orderRightBy: null,
      openOne: ["Open"],
      openTwo: ["Open"],
      favorites: {},
    };
  },
  computed: {
    buyerScopePackage: function () {
      return this.buyerScope + "Package";
    },
    isOpenOne: function () {
      return this.openOne.length > 0;
    },
    isOpenTwo: function () {
      return this.openTwo.length > 0;
    },
    buyerTwoColumnPageContentWidth: function () {
      if (this.hideSectionRight) {
        return "100%";
      }

      return "50%";
    },
    buyerTwoColumnPageItemWidth: function () {
      if (this.hideSectionRight) {
        return "50%";
      }

      return "100%";
    },
    buyerTwoColumnPageItemMargin: function () {
      if (this.hideSectionRight) {
        return "0 10px";
      }

      return "0";
    },
    ativosDisplay: function () {
      if (this.hideSectionRight) {
        return "flex";
      }

      return "";
    },
  },
  methods: {
    ...mapMutations("buyer", [
      "setDescriptionPage",
      "setVehicleDetail",
      "setIconPage",
      "setPriceDescriptionPage",
      "setBuyerScope",
      "setThirdTitlePage",
    ]),
    ...mapMutations("buyerLotDetail", [
      "setTitleContainer",
      "setTotal",
      "setLotDetail",
      "setIsActive",
      "setScope",
    ]),

    onClickInVehicle: function (vehicle) {
      this.setVehicleDetail(vehicle);
      this.setDescriptionPage(this.description);
      this.setPriceDescriptionPage(this.priceDescriptionPage);
      this.setBuyerScope(this.buyerScope);
      this.setIconPage(this.icon);
      this.natigateToInVehicleDetailPage();
    },
    onClickInLot: function (lot, isActive = true) {
      if (this.isMobile()) {
        this.setVehicleDetail(lot);
        this.setDescriptionPage(this.description);
        this.setPriceDescriptionPage(this.priceDescriptionPage);
        this.setBuyerScope(this.buyerScope);
        this.setIconPage(this.icon);
        this.natigateToInVehicleDetailPage();
        this.setThirdTitlePage(`Pacote ${lot.ava_id}`);
        this.setIsActive(isActive);
        return;
      }

      this.setScope(this.buyerScope);
      this.setTitlePage("Acompanhe suas avaliações por pacotes!");
      this.setTitleContainer(
        `Pacote ${isActive ? "ativo" : "superado"} (${
          lot.qtdVeic
        } veículos) - Pacote ${lot.ava_id}`
      );
      this.setLotDetail(lot);
      this.setTotal(lot.valor);
      this.setIsActive(isActive);
      this.natigateToInPackageDetailPage();
    },
    setOrderBy: function (newOrderBy) {
      this.orderBy = newOrderBy;
      this.refresh();
    },
    setOrderLeftBy: function (newOrderBy) {
      this.orderLeftBy = newOrderBy;
      this.loadingLeft = true;
      this.refresh();
    },
    setOrderRightBy: function (newOrderBy) {
      this.orderRightBy = newOrderBy;
      this.loadingRight = true;
      this.refresh();
    },
    refresh: function () {
      this.loadingLeft = true;
      this.loadingRight = true;
      this.getContent(this.orderLeftBy, this.orderRightBy)
        .then((vehicles) => {
          this.vehicles = vehicles;
          this.refreshFavorites(vehicles);
        })
        .finally(() => {
          this.loadingLeft = false;
          this.loadingRight = false;
        });
    },
    isVehicleCard: function (vehicle) {
      return vehicle.qtdVeic === undefined || vehicle.qtdVeic === 1;
    },
    isPackageCard: function (vehicle) {
      return vehicle.qtdVeic !== undefined && vehicle.qtdVeic > 1;
    },
    refreshFavorites(vehicles) {
      this.favorites = {};
      const allVehicles = [...vehicles.ativos, ...vehicles.superados];
      allVehicles.forEach(
        (vehicle) =>
          (this.favorites[vehicle.ava_id] = vehicle.favorito == "true")
      );
    },
    handleFavorite(vehicleAvaId) {
      this.favorites[vehicleAvaId] = !this.favorites[vehicleAvaId];
      setFavorite(vehicleAvaId)
        .then((response) => {
          if (response.statusCode != 200) {
            this.favorites[vehicleAvaId] = !this.favorites[vehicleAvaId];
          }

          this.getContent(this.orderLeftBy, this.orderRightBy)
            .then((vehicles) => {
              this.vehicles = vehicles;
              this.refreshFavorites(vehicles);
            })
            .finally(() => {
              this.loadingLeft = false;
              this.loadingRight = false;
            });
        })
        .catch(() => {
          this.favorites[vehicleAvaId] = !this.favorites[vehicleAvaId];
        });
    },
  },
  mounted: function () {
    this.getContent()
      .then((vehicles) => {
        this.vehicles = vehicles;
        this.refreshFavorites(vehicles);
      })
      .finally(() => {
        this.loadingLeft = false;
        this.loadingRight = false;
      });

    const onSuccess = () => {
      this.loadingLeft = true;
      this.loadingRight = true;
      this.getContent(this.orderLeftBy, this.orderRightBy)
        .then((vehicles) => {
          this.vehicles = vehicles;
          this.refreshFavorites(vehicles);
        })
        .finally(() => {
          this.loadingLeft = false;
          this.loadingRight = false;
        });
    };
    window.addEventListener("message", function (event) {
      if (event.data.message === "refreshBuyerResult") {
        onSuccess();
      }
    });

    this.$emitter.on("refreshEvent", (from) => {
      if (from === "MakeEvaluationViewModal") {
        onSuccess();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1535px) and (min-width: 1024px) {
  .buyer-two-column-page .buyer-two-column-page-content {
    width: 100% !important;
    display: block !important;
  }
  .buyer-two-column-page-container {
    display: block !important;
  }
  .buyer-two-column-page-content-right {
    margin-top: 30px !important;
    margin-left: 0 !important;
  }
  .buyer-two-column-page-item {
    width: 100% !important;
    display: block !important;
  }
}

.buyer-two-column-page {
  flex: 78;
  justify-content: center;
  padding: 0;

  .buyer-two-column-page-top {
    h1 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    h2 {
      color: #424242;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .buyer-two-column-page-container {
    display: flex;
    justify-content: space-between;
    min-height: 80vh;
  }

  .buyer-two-column-page-content {
    border-radius: 12px;
    background: #fff;
    padding: 17px;
    width: v-bind(buyerTwoColumnPageContentWidth);

    .buyer-two-column-page-content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #373737;
          font-family: "Lexend Deca";
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;

        .select {
          position: relative;

          select {
            appearance: none;
            background-color: transparent;
            border-radius: 6px;
            border: 1px solid #808080;
            width: 140px;
            height: 33px;
            flex-shrink: 0;
            color: #808080;
            font-family: "Lexend Deca";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 12px;
          }

          img {
            position: absolute;
            bottom: 8px;
            right: 16px;
          }
        }
      }
    }

    .buyer-two-column-page-item {
      width: v-bind(buyerTwoColumnPageItemWidth);
      margin: v-bind(buyerTwoColumnPageItemMargin);
      margin-bottom: 24px;
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ativos {
      display: v-bind(ativosDisplay);
      justify-content: space-between;
      align-items: center;
    }
  }
}

.buyer-two-column-page-mobile {
  .buyer-two-column-page-container {
    .subtitle-section {
      font-family: "Lexend Deca";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #909090;
      padding: 10px 12px;
    }
    .green {
      background: #0dc561;
    }

    .red {
      background: #f22300;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 12px;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .css-that-rotates-this-icon {
        transform: rotate(180deg);
        transition: 0.2s;
      }

      .css-that-rotates-this-icon-init {
        transform: rotate(0deg);
        transition: 0.2s;
      }
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  }
}
</style>
