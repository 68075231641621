<template>
  <div id="dashboard-buyer-mobile">
    <div>
      <div class="dashboard-header">
        <h1>Dashboard - Área do comprador</h1>
        <div id="search-wrapper">
          <div>
            <SearchAutocomplete />
          </div>
          <div class="d-none">
            <select>
              <option>07/12/23</option>
            </select>
            <select>
              <option>07/12/23</option>
            </select>
          </div>
        </div>
        <DashboardTopMobile />
      </div>
      <div class="line_up" v-if="loading">
        <div class="line_up_inner">
          <v-skeleton-loader class="skeleton" type="image, image"></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader class="skeleton" type="image, image"></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
          </div>
        </div>
        <div class="line_up_inner">
          <v-skeleton-loader class="skeleton" type="image, image"></v-skeleton-loader>
          <div class="line_up_inner_4">
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
            <v-skeleton-loader class="skeleton" type="image"></v-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="line_up" v-else>
        <div class="line_up_inner active">
          <div @click="natigateToInProgressPage()" id="btn-m-anuncios-ativos">
            <div>{{ payload?.anuncios?.ativos?.total || 0 }}</div>
            <div>Anúncios Ativos</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToAutoBidInProgressPage()" id="btn-m-compras-via-autobid">
              <div>{{ payload?.anuncios?.ativos?.via_autobid || 0 }}</div>
              <div>Via AutoBid</div>
            </div>
            <div @click="natigateToProposalInProgressPage()" id="btn-m-compras-via-proposta">
              <div>{{ payload?.anuncios?.ativos?.via_proposta || 0 }}</div>
              <div>Via Proposta</div>
            </div>
            <div @click="natigateToBidInProgressPage()" id="btn-m-compras-avaliacoes">
              <div>{{ payload?.anuncios?.ativos?.lances_manuais || 0 }}</div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToQuotesInProgressPage()" id="btn-m-compras-via-compre-ja">
              <div>{{ payload?.anuncios?.ativos?.via_cotacoes || 0 }}</div>
              <div>Via Cotação</div>
            </div>
          </div>
        </div>
        <div class="line_up_inner overcome">
          <div @click="natigateToClosedPage()" id="btn-m-anuncios-superados">
            <div>{{ payload?.anuncios?.superados?.total || 0 }}</div>
            <div>Anúncios Superados</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToAutoBidInProgressPage()" id="btn-m-anuncios-superados-via-cotacao">
              <div>{{ payload?.anuncios?.superados?.via_autobid || 0 }}</div>
              <div>Via AutoBid</div>
            </div>
            <div @click="natigateToProposalInProgressPage()" id="btn-m-anuncios-superados-via-proposta">
              <div>{{ payload?.anuncios?.superados?.via_proposta || 0 }}</div>
              <div>Via Proposta</div>
            </div>
            <div @click="natigateToBidInProgressPage()" id="btn-m-anuncios-superados-avaliacoes">
              <div>{{ payload?.anuncios?.superados?.lances_manuais || 0 }}</div>
              <div>Avaliações</div>
            </div>
            <div @click="natigateToQuotesInProgressPage()" id="btn-m-anuncios-superados-via-cotacao">
              <div>{{ payload?.anuncios?.superados?.via_cotacoes || 0 }}</div>
              <div>Via Cotação</div>
            </div>
          </div>
        </div>
        <div class="line_up_inner pending">
          <div @click="natigateToPedingPage()" id="btn-m-anuncios-com-pagamentos-pendentes">
            <div>{{ payload?.anuncios?.pagamento_pendente?.total || 0 }}</div>
            <div>Anúncios com pagamentos pendentes</div>
          </div>
          <div class="line_up_inner_4">
            <div @click="natigateToPaymentPendingPage()" id="btn-m-anuncios-pagamento-pendente-via-boleto">
              <div>
                {{ payload?.anuncios?.pagamento_pendente?.via_boleto || 0 }}
              </div>
              <div>Via Boleto</div>
            </div>
            <div @click="natigateToReleasePendingPage()" id="btn-m-anuncios-pagamento-pendente-via-autopay">
              <div>
                {{ payload?.anuncios?.pagamento_pendente?.via_autopay || 0 }}
              </div>
              <div>Via AutoPay</div>
            </div>
            <div @click="natigateToProposalPendingPage()" id="btn-m-anuncios-propostas-pendente">
              <div style="padding-left: unset !important;">
                {{
                  payload?.anuncios?.pagamento_pendente?.propostas_pendentes || 0
                }}
              </div>
              <div style="padding-left: unset !important;">Propostas Pendentes</div>
            </div>
            <div id="btn-m-anuncios-propostas-via-compre-ja">
              <div style="padding-left: unset !important;">
                {{ payload?.anuncios?.pagamento_pendente?.via_autobid || 0 }}
              </div>
              <div style="padding-left: unset !important;">Via Compre já</div>
            </div>
          </div>
        </div>
        
      </div>
      <!-- <div class="wallet-card-container" v-if="participantFlags.HABILITA_CARTA_CREDITO == 1"> -->
        <div class="wallet-card-container">
        <WalletCard  type="dashboard" @click="navigateToCreditLetterPage" />
      </div>
    </div>
  </div>
</template>

Avaliação sob aprovação<br/>pendente
<script>
import SearchAutocomplete from "@/components/top/SearchAutocomplete.vue";
import DashboardTopMobile from "./components/DashboardTopMobile";
import NavigateMixings from "@/mixins/NavigateMixings";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
import WalletCard from "@/views/buyer/components/WalletCard.vue";
import { mapState } from "vuex";

export default {
  name: "DashboardMobile",
  components: {
    DashboardTopMobile,
    SearchAutocomplete,
    VSkeletonLoader,
    WalletCard,
  },
  props: {
    loading: Boolean,
    payload: Object,
  },
  mixins: [NavigateMixings],
  computed: {
    ...mapState({
      participantFlags: (state) => state.participantFlags.flags
    })
  }
};
</script>

<style scoped>

#dashboard-buyer-mobile {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
}

.wallet-card-container {
  display: flex;
  padding: 10px;
}

#dashboard-buyer-mobile span {
  margin-top: 12px;
  font-family: "Lexend Deca";
  font-weight: 300;
  font-size: 12px;
}

.dashboard-header h1 {
  color: #373737;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 20px 0;
}

#search-wrapper {
  display: flex;
  padding-bottom: 15px;
}

#search-wrapper > div {
  flex-basis: content;
}

#search-wrapper > div:first-of-type {
  padding-left: 20px;
}
#search-wrapper > div:nth-of-type(2) {
  text-align: center;
}

#search-wrapper > div:nth-of-type(2) > select {
  color: #c0c0c0;
  font-size: 10px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 10px;
}
.line_up {
  display: flex;
  flex-direction: column;
  font-family: "Lexend Deca";
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0 10px;
  box-sizing: content-box;
}
.line_up > div {
  display: flex;
  flex-basis: 100%;
}
.line_up_inner {
  display: flex;
}
.line_up_inner > div {
  flex-basis: 50%;
}
.line_up_inner > div:not(.skeleton):first-of-type {
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  width: 125px;
  height: 116px;
  font-weight: 700;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  cursor: pointer;
}

.line_up_inner > div:is(.skeleton):first-of-type {
  width: 125px;
  height: 116px;
  background-color: transparent;
  overflow: hidden;
}
.line_up_inner.active > div:first-of-type {
  background-color: #0dc561;
}
.line_up_inner.overcome > div:first-of-type {
  background-color: #f22300;
}
.line_up_inner.pending > div:first-of-type {
  background-color: #ff7a00;
}
.line_up_inner > div:first-of-type > div:first-of-type {
  font-size: 45px;
}
.line_up_inner > div:first-of-type > div:nth-of-type(2) {
  font-size: 12px;
}
.line_up_inner_4 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.line_up_inner_4 > div:not(.skeleton) {
  display: flex;
  width: 42%;
  max-width: 82px;
  height: 52px;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  background-color: #fff;
  min-width: 72px;
  cursor: pointer;
}

.line_up_inner_4 > div:is(.skeleton) {
  width: 40%;
  max-width: 82px;
  height: 52px;
  overflow: hidden;
  margin: 4px;
}
.line_up_inner_4 > div > div:first-of-type {
  font-size: 1em;
  font-weight: 700;
  flex-basis: 33%;
  text-align: center;
  padding-left: 2px;
}
.line_up_inner_4 > div > div:nth-of-type(2) {
  font-size: 0.6em;
  font-weight: 400;
  flex-basis: 67%;
  line-height: 10px;
  text-align: left;
  padding-left: 0.1em;
}
.line_up_inner.active .line_up_inner_4 > div > div:first-of-type {
  color: #0dc561;
}
.line_up_inner.overcome .line_up_inner_4 > div > div:first-of-type {
  color: #f22300;
}
.line_up_inner.pending .line_up_inner_4 > div > div:first-of-type {
  color: #ff7a00;
}

@media (max-width: 374px) {
  .line_up_inner_4 > div {
    widows: 40%;
    min-width: 68px;
  }
  .line_up_inner_4 > div > div:first-of-type{
    font-size: 0.9em;
    flex-basis: 34%;
  }
  .line_up_inner_4 > div > div:nth-of-type(2){
    font-size: 0.5em;
    flex-basis: 66%;
  }
}
</style>
