<template>
  <v-container class="list-of-eligible-vehicles-step">
    <v-row>
      <h4>
        Lista de veículos elegíveis{{
          loadingVehicles ? "" : ` (${vehicles.length})`
        }}:
      </h4>
    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
          type="card"
          width="100%"
          class="mt-4"
          v-if="loadingVehicles"
        ></v-skeleton-loader>
        <div v-if="!loadingVehicles">
          <div class="vehicles" v-for="(vehicle, key) in vehicles" :key="key">
            <VehicleCard
              :id="'vehiclecard_' + vehicle.ava_id"
              :vehicle="vehicle"
              label="Você comprou por"
              :priceBackgroundColor="priceBackgroundColor"
              buyerScope="LetterOfCreditEligible"
              class="mt-4"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import VehicleCard from "../../VehicleCard";

export default {
  name: "ListOfEligibleVehiclesStep",
  components: {
    VehicleCard,
    VSkeletonLoader,
  },
  computed: {
    ...mapState({
      loadingVehicles: (state) => state.wallet.loadingVehicles,
      vehicles: (state) => state.wallet.vehicles,
    }),
  },
  methods: {
    ...mapActions("wallet", ["getVehicles"]),
  },
  mounted() {
    this.getVehicles();
  },
};
</script>

<style lang="scss" scoped>
.list-of-eligible-vehicles-step {
  padding: 0;

  h4 {
    color: #262626;
    font-family: "Lexend Deca";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
