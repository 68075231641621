<template>
  <div id="release-payment-modal-container" v-if="modalIsOpen">
    <v-container class="release-payment-modal">
      <button class="close-container" @click="closeModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M12.9703 1.65685L1.65662 12.9706M1.65662 1.65685L12.9703 12.9706"
            stroke="#B7B7B7"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="release-payment-header">
        <h2>Liberar Pagamento</h2>
      </div>
      <div class="content">
        <p>{{vehicle.qtdVeic > 1 ? "Deseja liberar o pagamento deste pacote pela Autopay?" : "Deseja liberar pagamento deste veículo pela AutoPay?"}} </p>
      </div>
      <div class="release-payment-footer">
        <button
          class="confirm-button"
          @click="confirm"
          :disabled="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          ></v-progress-circular>
          LIBERAR PAGAMENTO
        </button>
        <button class="out-button" @click="out">VOLTAR</button>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import VehicleCardMixin from "../mixins/VehicleCardMixin";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import toast from "@/plugins/toast";

export default {
  name: "ReleasePayment",
  mixins: [VehicleCardMixin, PlatformsMixins],
  data() {
    return {
      errorMessage: "",
    };
  },
  components: {
  },
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.releasePaymentViewModal.modalIsOpen,
      response: (state) => state.releasePaymentViewModal.response,
      vehicle: (state) => state.releasePaymentViewModal.vehicle,
      loading: (state) => state.releasePaymentViewModal.loading,
    })
  },
  methods: {
    ...mapMutations("releasePaymentViewModal", ["toggleModalReleasePayment"]),
    ...mapActions("releasePaymentViewModal", ["releasePayment"]),
    closeModal() {
      if (this.modalIsOpen) {
        this.toggleModalReleasePayment();
        this.setVehicleReleasePayment(null);
        this.errorMessage = "";
      }
    },
    async confirm() {
      await this.releasePayment({
        id_avaliacao: this.vehicle.ava_id,
      });
      if (this.response.statusCode !== 200) {
        if (this.response.statusCode === 400) {
          Object.keys(this.response.messages).forEach((key) => {
            this.errorMessage +=
              (Array.isArray(this.response.messages[key])
                ? this.response.messages[key][0]
                : this.response.messages[key]) + "\n";
          });
        } else {
          this.errorMessage = Object.values(this.response.messages)[0];
        }
        this.messageAction = this.errorMessage;
      } else {
        toast.success("Operação realizada com sucesso!");
        this.closeModal();
        this.$emitter.emit("refreshEvent", "ReleasePaymentViewModal");
      }
    },
    out() {
      this.closeModal();
    }
  },
};
</script>

<style lang="scss" scoped>
#release-payment-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  display: flex;
  overflow-y: v-bind(quickViewModalContainerOverflowY);
  background-color: rgb(0 0 0 / 58%);
  z-index: 9999;
  
  .release-payment-modal {
    position: relative;
    background: #fff;
    box-shadow: -26px 0px 20px 0px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    width: 40vw;
    margin: auto;
    .close-container {
      position: absolute;
      right: 20px;
      top: 10px;
    }

    .release-payment-header {
      padding: 10px;
      padding-top: 0px;
      margin: 15px;
      text-align: left;
      border-bottom: 1px solid #e4e4e4;
      h2 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 124%; /* 19.84px */
      }
    }

    .content {
      color: #808080;
      font-family: "Lexend Deca";
      font-style: normal;
      line-height: 97.5%; /* 19.5px */
      padding: 10px 0px 20px 20px;
      margin-bottom: 40px;
    }
  }
  .release-payment-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    font-weight: 700;
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    button {
      border-radius: 3px;
      font-size: 15px;
      padding: 5px 20px;
      font-weight: bold;
    }
    .confirm-button{
      background-color: #ffaf2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #fdad07;
      &:disabled {
        cursor: unset;
        opacity: 0.5;
      }
    }
    .out-button {
      color: #808080;
    }
  }
  @media (max-width: 900px) {
    .release-payment-modal {
      width: 90vw !important;
    }
  }
}
</style>
