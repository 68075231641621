<template>
  <header id="simple-header-mobile">
    <v-container class="simple-header-mobile-container">
      <v-row justify="center" align="center">
        <v-col cols="10" justify="center" align="end">
          <v-row justify="space-between" align="center">
            <BackToLegacyButton class="ml-2" />
            <div class="wish-list">
              <i class="fa fa-feed"></i>
              <button click="openModal">
                Lista de Desejos
              </button>
            </div>
            <div class="divider"></div>
          </v-row>
        </v-col>
        <v-col cols="2" align="end">
          <profile-modal />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="end" style="padding: 0; position: relative">
          <button class="open-modal-button mr-10 mb-8" @click="toggleModalMenuMobile" id="open-header-modal">
            <i class="menu-icon" aria-hidden="true" v-if="!modalMenuIsOpen"></i>
            <i
              class="menu-close-icon"
              aria-hidden="true"
              v-if="modalMenuIsOpen"
            ></i>
          </button>
        </v-col>
        <menu-modal-mobile v-if="modalMenuIsOpen" :toggleModal="toggleModalMenuMobile" />
      </v-row>
    </v-container>
    <ModalWishList />
  </header>
</template>

<script>
import BackToLegacyButton from "./components/BackToLegacyButton.vue";
import MenuModalMobile from "./components/MenuModalMobile.vue";
import ProfileModal from "./components/ProfileModal.vue";
import ModalWishList from "@/components/ModalWishList.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SimpleHeaderMobile",
  components: { BackToLegacyButton, MenuModalMobile, ProfileModal, ModalWishList },
  data: () => ({
    modalMenuIsOpen: false,
  }),
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.followCarModalModule.modalIsOpen,
    }),
    headerBackground() {
      if (this.modalMenuIsOpen) {
        return "linear-gradient(to bottom, #000, rgba(0, 0, 0, 0.9))";
      }
      return "linear-gradient(to right, #333, #000)";
    },
  },
  methods: {
    ...mapMutations("followCarModalModule", ["toggleModal"]),
    ...mapActions("followCarModalModule", ["getAlertList"]),
    openModal() {
      this.toggleModal();
      if (this.modalIsOpen) {
        this.getAlertList();
      }
    },
    toggleModalMenuMobile() {
      this.modalMenuIsOpen = !this.modalMenuIsOpen;
      if (this.modalMenuIsOpen) {
        document.getElementById('simple-header-mobile').style.position = "fixed";
        document.getElementById('simple-header-mobile').style.top = 0;
        document.getElementById('simple-header-mobile').style.zIndex = 9999;
      }
      if (!this.modalMenuIsOpen) {
        document.getElementById('simple-header-mobile').style.position = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 10px 10px;
  width: 100%;
  background: v-bind(headerBackground);

  .wish-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    gap: 10px;
  }
  .wish-list > button {
    font-size: 9px;
  }

  .simple-header-mobile-container {
    padding: 0;
    padding-top: 6px;

    .divider::before {
      border-left: 1px solid #363636;
      border-right: 1px solid #323232;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
      content: "";
      height: 100%;
      padding: 0;
      position: relative;
      top: 0;
      transform: translate(-50%, 0);
      width: 0;
    }

    .open-modal-button {
      color: #fff;
      position: relative;
      justify-content: center;
      align-items: center;
      z-index: 99999;

      .menu-icon {
        position: absolute;
        display: block;
        content: "";
        top: 0px;
        background: #fff;
        width: 24px;
        height: 2px;
      }

      .menu-icon:before {
        position: absolute;
        display: block;
        content: "";
        top: 8px;
        background: #fff;
        width: 24px;
        height: 2px;
      }
      .menu-icon:after {
        position: absolute;
        display: block;
        content: "";
        top: 16px;
        background: #fff;
        width: 24px;
        height: 2px;
      }

      .menu-close-icon:before {
        position: absolute;
        display: block;
        content: "";
        top: 12px;
        background: #fff;
        width: 24px;
        height: 2px;
        transform: rotate(45deg);
      }

      .menu-close-icon:after {
        position: absolute;
        display: block;
        content: "";
        top: 12px;
        background: #fff;
        width: 24px;
        height: 2px;
        transform: rotate(-45deg);
      }
    }

    i {
      font-size: 20px;
    }
  }
}
</style>
