<template>
  <div class="more-info">
    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="
        vehicle?.percentual_fipe != null &&
        vehicle?.percentual_fipe != '' &&
        parseInt(vehicle?.percentual_fipe) != 0
      "
    >
      <span class="ml-3 fipe-container" v-if="parseInt(Math.abs(vehicle.percentual_fipe - 100)) > 0">
        <div class="content">
          <span class="fipe">{{
            parseInt(Math.abs(vehicle.percentual_fipe - 100))
          }}</span>
          <span class="percent">%</span>
        </div>
        <img :src="require('@/assets/icons/fipe-icon.svg')" />
      </span>

      <template #popper>
        {{
          `${parseInt(Math.abs(vehicle.percentual_fipe - 100))}% do VALOR FIPE`
        }}
      </template>
    </VTooltip>

    <v-tooltip
      location="top"
      :text="`O preço deste veículo está em até ${parseInt(
        vehicle.percentual_fipe
      )}% da FIPE`"
      v-if="
        vehicle?.percentual_fipe != null &&
        vehicle?.percentual_fipe != '' &&
        parseInt(vehicle?.percentual_fipe) != 0
      "
    >
      <template v-slot:activator="{ props }">
        <span v-bind="props">
          <span class="fipe">{{
            parseInt(Math.abs(vehicle.percentual_fipe - 100))
          }}</span>
          <span class="percent">%</span>
          <img :src="require('@/assets/icons/fipe-icon.svg')" />
        </span>
      </template>
    </v-tooltip>

    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.v_id_laudo_status == '1'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/report-icon.svg')"
          alt="Veículo contém laudo aprovado"
        />
      </span>

      <template #popper> Veículo contém laudo aprovado </template>
    </VTooltip>
    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.v_id_laudo_status == '2'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/failed-report-icon.svg')"
          alt="Veículo contém laudo reprovado"
        />
      </span>

      <template #popper> Veículo contém laudo reprovado </template>
    </VTooltip>
    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.v_id_laudo_status == '3'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/restriction-report-icon.svg')"
          alt="Veículo contém laudo aprovado com restrição"
        />
      </span>

      <template #popper> Veículo contém laudo aprovado com restrição </template>
    </VTooltip>

    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.showroom === 'S'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/showroom-icon.svg')"
          alt="Veículo com qualidade de showroom"
        />
      </span>

      <template #popper> Veículo com qualidade de showroom </template>
    </VTooltip>
    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.v_blindado != '0'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/armored-icon.svg')"
          alt="Veículo blindado"
        />
      </span>

      <template #popper> Veículo blindado </template>
    </VTooltip>
    <VTooltip
      placement="top-start"
      max-width="20px"
      width="20px"
      v-if="vehicle.v_tipo.toLowerCase() === 'novo'"
    >
      <span class="ml-3">
        <img
          :src="require('@/assets/icons/new-vehicle-icon.svg')"
          alt="Veículo com menos de 1mil km rodados"
        />
      </span>

      <template #popper> Veículo com menos de 1mil km rodados </template>
    </VTooltip>
  </div>
</template>
<script>
export default {
  name: "VehicleItemStamps",
  props: {
    vehicle: Object,
  },
};
</script>

<style lang="scss" scoped>
.more-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 920px) {
  .more-info {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.fipe-container {
  display: flex;
  position: relative;
  margin-bottom: 4px;
}

.fipe-container .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 23px;
  display: flex;
  flex-direction: column;
}

.more-info .fipe {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: -5px;

  color: #fff;
  align-self: center;
}
.more-info .percent {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 7px;

  color: #ffffff;
  align-self: center;
}

.seller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  height: 107px;
  width: 107px;
}
</style>
