<template>
  <TopApp />
  <div class="app-content">
    <FilterApp />
    <FilterResultApp />
  </div>
</template>

<script>
import TopApp from '@/views/vehicles/components/TopApp.vue';
import FilterApp from '@/views/vehicles/components/FilterApp.vue';
import FilterResultApp from '@/views/vehicles/components/FilterResultApp.vue';
import { mapMutations } from "vuex";

export default {
  name: "VehiclesView",
  components: {
    TopApp,
    FilterApp,
    FilterResultApp
  },
  methods: {
    ...mapMutations("global", [
        "setCurrentMainMenu",
    ]),
  },
  mounted() {
    this.setCurrentMainMenu("vehicles");
  }
};
</script>
