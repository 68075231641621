import { HEADER_SECTIONS, HEADER_SUB_MENU_SECTIONS } from "@/constants/Header";
import { getCookie } from "@/helpers/cookies";

export default {
  computed: {
    sections() {
      return HEADER_SECTIONS;
    },
    subMenuSections() {
      return HEADER_SUB_MENU_SECTIONS;
    },
    profile() {
      const portalUsername = getCookie("PORTAL_USER_NAME");
      const firstLetter = portalUsername[0];
      const lastLetter = portalUsername[portalUsername.indexOf('+') + 1];
      return `${firstLetter + lastLetter}`.toUpperCase();
    },
    profileDesktop() {
      const portalUsername = getCookie("PORTAL_USER_NAME");
      const firstLetter = portalUsername[0];
      return `${firstLetter}`.toUpperCase() + portalUsername.split('+')[0].substring(1).toLowerCase();
    }
  },
};
