<template>
  <div :class="['d-flex action-buttons-wrapper', buttons.length <= 2 ? 'flex-column' : '']">
    <div v-for="(btn, index) in metadata.filter(n=>Object.keys(n).length !== 0)" :key="index" :class="['action-button',  buttons.length <= 2 ? 'my-1 p-2' : '']" :id="getBtnId(btn)">
      <button
        type="button"
        @click="openWindowActionsButton(vehicle, btn)"
        :class="['btn', btn.class]"
        :id="'btn_' + getBtnId(btn)"
      >
        <span v-if="btn.label === 'Ativar AutoBid'">
          Ativar <span class="btn-autobid">Auto<span style="color: #fff">Bid</span></span>
        </span>
        <span v-else-if="btn.label === 'Enviar uma oferta'">
          Enviar uma <span style="font-weight: 700;">oferta</span>
        </span>
        <span v-else>{{ btn.label }}</span>
      </button>

      <div class="invoice-arrow" v-if="btn.isInvoice && showInvoiceButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
        >
          <path
            d="M6.86603 9.5C6.48113 10.1667 5.51887 10.1667 5.13397 9.5L0.803847 2C0.418946 1.33333 0.900072 0.500001 1.66987 0.500001L10.3301 0.5C11.0999 0.5 11.5811 1.33333 11.1962 2L6.86603 9.5Z"
            fill="#2A2A2A"
          />
        </svg>
      </div>

      <v-scroll-y-transition>
        <div class="invoice-button" v-if="btn.isInvoice && showInvoiceButton">
          <button
            :class="
              [btn.downloadOfTheInvoiceAutoAvaliar !== null ? '' : 'disabled', 'top']
            "
            :onClick="btn.downloadOfTheInvoiceAutoAvaliar"
          >
            <span>NF Auto Avaliar</span>
            <img
              :src="require('@/assets/icons/download-icon.svg')"
              height="14"
              width="14"
            />
          </button>
          <div class="divider one"></div>
          <div class="divider"></div>
          <button
            :class="[btn.downloadOfTheInvoiceAutoPay !== null ? '' : 'disabled', 'bottom']"
            :onClick="btn.downloadOfTheInvoiceAutoPay"
          >
            <span>NF Auto Pay</span>
            <img
              :src="require('@/assets/icons/download-icon.svg')"
              height="14"
              width="14"
            />
          </button>
        </div>
      </v-scroll-y-transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "VehicleActionButtons",
  props: {
    metadata: Object,
    vehicle: Object,
  },
  data: () => ({
    showInvoiceButton: false,
  }),
  computed: {
    buttons() {
      return this.metadata.filter(n=>Object.keys(n).length !== 0);
    }
  },
  methods: {
    openWindowActionsButton(payload, attributes) {
      if (attributes.isInvoice) {
        this.toggleInvoiceButton();
        return;
      }

      if (attributes.onClick) {
        attributes.onClick();
        return;
      }

      if (attributes.url) {
        if (
          typeof attributes.param == "string" ||
          attributes.param === undefined
        ) {
          window.open(
            attributes.url +
              (payload[attributes.param] ?? attributes.value ?? 0),
            attributes.target
          );
        } else {
          for (const item of attributes.param) {
            window.open(
              attributes.url + (payload[item] ?? attributes.value ?? 0),
              attributes.target
            );
          }
        }
      }
    },
    toggleInvoiceButton() {
      this.showInvoiceButton = !this.showInvoiceButton;
    },
    getBtnId(btn) {
      return `area_do_comprador_${btn.label}_${this.vehicle.ava_id}`.toLowerCase().replace(/ /g, "_");
    },
  },
};
</script>
<style lang="scss">
.actions-buttons{
  [id^="area_do_comprador_pagar_taxa_com_boleto"]{
      display: none;
  }
}
.justify-content-center{
  justify-content: center;
}
.justify-space-around{
  justify-content: space-around;
}
.action-button {
  position: relative;

  .invoice-arrow {
    z-index: 1001;
    position: absolute;
    margin-top: -12px;
    left: 46%;
  }

  .invoice-button {
    position: absolute;
    z-index: 1000;
    border-radius: 6px;
    background: #3a3a3a;
    box-shadow: 0px 4px 6.5px 0px rgba(0, 0, 0, 0.25);
    margin-top: 8px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .divider {
      background: #454545;
      width: 100%;
      height: 1px;
    }

    .one {
      background: #303030;
    }

    button {
      color: #fff;
      font-feature-settings: "sups" on;
      font-family: "Lexend Deca";
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        flex: 1;
      }
    }

    button.disabled {
      background: #636363;

      color: #737373;
      cursor: auto;
    }

    button.top {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    } 

    button.bottom {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    } 
  }
}

.btn {
  display: flex;
  font-size: 12px;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  height: 38px;
  align-items: center;
  padding: 0 5px;
  font-weight: 600;
  font-family: "Lexend Deca";
  text-align: center;

  &.btn-orange:not(.btn-outline) {
    background-color: #ff9900;
    color: #ffffff;
    border: thin solid #ff9900;
  }

  &.btn-orange:is(.btn-outline) {
    background-color: #ffffff;
    color: #ff9900;
    border: thin solid #ff9900;
  }

  &.btn-red:not(.btn-outline) {
    background-color: #F22300;
    color: #ffffff;
    border: thin solid #F22300;
  }

  &.btn-red:is(.btn-outline) {
    background-color: #ffffff;
    color: #F22300;
    border: thin solid #F22300;
  }

  &.btn-black {
    background-color: #2a2a2a;
    color: #ffffff;
    border: thisn solid #2a2a2a;
  }

  &.btn-green {
    background-color: #0dc561;
    color: #ffffff;
    border: thin solid #0dc561;
  }

  &.btn-green:not(.btn-outline) {
    background-color: #0dc561;
    color: #ffffff;
    border: thin solid #0dc561;
  }

  &.btn-green:is(.btn-outline) {
    background-color: #ffffff;
    color: #0dc561;
    border: thin solid #0dc561;
  }

  &:is(a) {
    text-decoration: none;
  }

  &:is(.disabled) {
    cursor: unset;
    opacity: 0.5;
  }

  &.btn-gray {
    background-color: #C5C5C5;
    color: #363636;
    border: thin solid #C5C5C5;
  }

  .btn-autobid {
    font-weight: 900;
    color: #F90;
  }
}
</style>
