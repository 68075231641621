import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export function getBalance() {
  return axios
  .get(`${API_BASE}/purchasers/dashboard/balance`, {
    headers: {
      token: API_TOKEN,
    },
  })
  .then((response) => response.data.payload);
}