<template>
  <MenuTitle text="Marca do Veículo" />
  <v-sheet
    class="mx-auto branding-wrapper"
    max-width="600"
    v-if="brands?.length === 0"
  >
    <v-slide-group>
      <v-slide-group-item v-for="(branding, index) in Brandings" :key="index">
        <div class="branding-item" @click="selectItem(branding)" :id="`marca-veiculo-input-${index}`">
          <v-img
            class="branding-img"
            aspect-ratio="1/1"
            :src="require(`@/assets/images/brandings/${branding.image}`)"
          ></v-img>
        </div>
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>

  <div v-if="seeAll && typeof seeAll === 'object'">
    <div class="text-right">
      <div class="seeAll">
        <div>
          <div
            v-for="(item, index) in getSelectedItem()"
            :key="index"
            style="overflow: hidden; margin-top: 25px"
          >
            <span class="text-uppercase">
              <span
                style="
                  display: inline-block;
                  width: 30%;
                  vertical-align: bottom;
                  float: left;
                "
              >
                <v-img
                  :width="100"
                  aspect-ratio="1/1"
                  :src="require(`@/assets/images/brandings/${item.image}`)"
                ></v-img>
              </span>
              <span
                style="
                  border-bottom: 1px solid #e5e4e4;
                  display: inline-block;
                  width: 70%;
                  text-align: left;
                "
              >
                {{ item?.value ?? item.name }}
                <button
                  @click="() => deleteSelectedItem(item)"
                  :id="`marca-veiculo-delete-item-${index}`"
                  style="float: right"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </button>
              </span>
            </span>
            <div v-if="verifySelectedItem()">
              <ModelScope
                reference="model"
                :see-all="{
                  title: 'Todos os modelos',
                  placeholder: 'Digite o modelo desejado',
                  menu: 'Modelos',
                  submenu: 'Todos os modelos',
                  handle: () => this.getAllModelsHandler(index, item.uuid),
                  scope: item.name,
                  parentUuid: item.uuid,
                }"
              />
              <ModelScope
                reference="version"
                :see-all="{
                  title: 'Todos as versões',
                  placeholder: 'Digite a versão desejado',
                  menu: 'Versões',
                  submenu: 'Todos as versões',
                  handle: () => this.getAllVersionsHandler(item.name, item.uuid),
                  scope: item.name,
                  parentUuid: item.uuid,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <button @click="seeAllhandle" class="seeAll" id="adicionar-mais-veiculo">
        {{ verifySelectedItem() ? "Adicionar mais um veículo" : seeAll.title }}
        <v-icon class="ms-2">{{
          verifySelectedItem() ? "mdi-plus" : "mdi-chevron-right"
        }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

import MenuTitle from "@/components/typographies/MenuTitle.vue";
import ModelScope from "@/components/VehicleModelScope.vue";
import { Brandings } from "@/constants/Brandings";

import {
  getAllBrandingModels,
  getAllBrandingModelVersions,
} from "@/services/api/ItemMenuSeeAll/SeeAllService";
import store from "@/store";

export default {
  name: "VehicleBranding",
  components: { MenuTitle, ModelScope },
  data() {
    return {
      Brandings,
    };
  },
  props: {
    seeAll: {
      type: Object,
      required: false,
    },
  },
  created() {
    const i = setInterval(() => this.startSelectItem(i), 1000)
  },
  methods: {
    ...mapMutations("drawer", [
      "showSeeAllDrawer",
      "setSeeAllDrawerObjDetails",
      "setSeeAllDrawerObjSelected",
      "unsetSeeAllDrawerObjSelected",
      "clear",
      "selectBrandWithUuid",
    ]),
    async seeAllhandle() {
      this.setSeeAllDrawerObjDetails({
        title: this.seeAll.menu,
        subtitle: this.seeAll.submenu,
        placeholder: this.seeAll.placeholder,
        handle: this.seeAll.handle,
        scrollTo: this.seeAll.scrollTo,
      });
      this.showSeeAllDrawer();
    },
    selectItem(value) {
      const key = "key-" + this.seeAll.scrollTo;

      this.setSeeAllDrawerObjSelected({
        key,
        value,
      });

      const find = this.params["brands"].findIndex(
        (branding) => branding.value == value.branding
      );

      if (find < 0) {
        this.$store.commit("filterForm/setParam", {
          brands: [
            ...this.params.brands,
            {
              id: value.branding,
              value: value.branding,
              uuid: value.uuid
            },
          ],
        });
      }

      this.$forceUpdate();

      if (this.seeAll.scrollTo) {
        this.$emit("scrollEventHandler", this.seeAll.scrollTo);
      }
    },
    startSelectItem(interval) {

      clearInterval(interval)

      if (this.params['brands']?.length > 0) {
        const stringBrands = this.params['brands']
        const values = stringBrands;
        const key = "key-" + this.seeAll.scrollTo;
        const data = [];
        values.forEach(brand => {
          const brandWithImage = this.Brandings.filter(b => b.id.toLowerCase() == brand.id.toLowerCase());
          const isDrawer = (!this.seeAllDrawerObjSelected[key] || this.seeAllDrawerObjSelected[key].filter(el => el.id.toLowerCase() === brand.id.toLowerCase()).length === 0);

          if (brandWithImage?.length > 0) {
            data.push(brandWithImage[0])
            if (isDrawer) {
              this.setSeeAllDrawerObjSelected({
                key,
                value: brandWithImage[0],
              });
            }
          }
          else {
            const item = {
              id: brand.id,
              branding: brand.id,
              image: null,
              name: brand.id.toUpperCase(),
              value: brand.id.toUpperCase(),
            };
            if (brand?.uuid) {
              item.uuid = brand.uuid
            }
            data.push(item)
            if (isDrawer) {        
              this.setSeeAllDrawerObjSelected({
                key,
                value: item,
              });
            }
          }
        })

        if (data.length > 0) {
          this.params['brands'] = data;
        }
        else {
          this.params['brands'] = [];
        }

      }
      else {
        this.params['brands'] = [];
      }
    },
    verifySelectedItem() {
      const key = "key-" + this.seeAll.scrollTo;
      if (this.seeAllDrawerObjSelected) {
        return (
          Object.keys(this.seeAllDrawerObjSelected).filter((item) => {
            return item === key;
          }).length > 0 && this.seeAllDrawerObjSelected[key].length > 0
        );
      }
      return false;
    },
    getSelectedItem() {
      const key = "key-" + this.seeAll.scrollTo;
      return this.seeAllDrawerObjSelected[key]?.map((item) => {
        let image = "car-label.png";

        const name = item.name.toLowerCase().replace(" ", "-");

        try {
          if (
            require(`@/assets/images/brandings/${name.toLowerCase()}/${name.toLowerCase()}.png`)
          ) {
            image = `${name.toLowerCase()}/${name.toLowerCase()}.png`;
          }
        } catch {
          /** continue */
        }

        item.image = image;

        const index = this.params["brands"].findIndex(
          (branding) => branding.id.toLowerCase() === item.id.toLowerCase()
        );

        if (index < 0) {
          this.$store.commit("filterForm/setParam", {
            brands: [
              ...this.params.brands,
              {
                ...item
              },
            ],
          });
        }

        return item;
      });
    },
    deleteAllItems() {
      this.clear();
    },
    deleteSelectedItem(item) {

      const brand = this.params.brands.filter(
          (branding) => branding.id.toLowerCase() === item.id.toLowerCase()
      )[0];
      const brandIndex = this.params.brands.findIndex(
          (branding) => branding.id.toLowerCase() === item.id.toLowerCase()
      );
      const modelsIndex = this.params.models.findIndex(
          (model) => model.parentUuid.toLowerCase() === brand.uuid.toLowerCase()
      );
      const versionsIndex = this.params.versions.findIndex(
          (m) => m.parentUuid.toLowerCase() === brand.uuid.toLowerCase()
      );

      if (brandIndex >= 0) {

        if (versionsIndex >= 0) {
          if (this.params.versions?.length === 1) {
            this.params.versions = []
            this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
          } else {
            this.params.versions.splice(versionsIndex, 1);
            this.unsetSeeAllDrawerObjSelected({ key: `key-${item.name.toUpperCase()}-Versões`, uuid: brand.uuid });
          }
        }

        if (modelsIndex >= 0) {
          if (this.params.models?.length === 1) {
            this.params.models = []
            this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
          } else {
            this.params.models.splice(modelsIndex, 1);
            this.unsetSeeAllDrawerObjSelected({ key: `key-${item.name.toUpperCase()}-Modelos`, uuid: brand.uuid });
          }
        }

        if (this.params.brands?.length === 1) {
          this.params.brands = [];
          this.unsetSeeAllDrawerObjSelected({ key: 'key-vehicle-branding', all: true });
        }
        else {
          this.params.brands.splice(brandIndex, 1);
          this.unsetSeeAllDrawerObjSelected({ key: 'key-vehicle-branding', uuid: brand.uuid });
        }
        store.commit("filterForm/refreshSearch");
      }
    },
    async getAllModelsHandler(position, brandUuid) {
      const branding =
        this.seeAllDrawerObjSelected["key-" + this.seeAll.scrollTo][position][
          "name"
        ].toLowerCase();
      this.selectBrandWithUuid(brandUuid);

      return await getAllBrandingModels(branding);
    },
    async getAllVersionsHandler(scope, brandUuid) {
      const branding =
        this.seeAllDrawerObjSelected["key-" + this.seeAll.scrollTo]
            .filter(m => m.id.toLowerCase() === scope.toLowerCase() || m.uuid == brandUuid)[0]?.id?.toLowerCase();
      const model =
        this.seeAllDrawerObjSelected[`key-${scope}-Modelos`][0][
          "value"
        ].toLowerCase();
        this.selectBrandWithUuid(brandUuid);
      
      return await getAllBrandingModelVersions(branding, model);
    },
  },
  watch: {},
  computed: {
    ...mapState({
      seeAllDrawerObjDetails: (state) => state.drawer.seeAllDrawerObjDetails,
      seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
      params: (state) => state.filterForm.params,
      brands: (state) => state.filterForm.params.brands,
      models: (state) => state.filterForm.params.models,
      loading: (state) => state.filterForm.loading,
    }),
  },
};
</script>
<style scoped>
.branding-wrapper {
  margin: 10px 0;
}
.branding-item {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 5px 5px 5px 1px;
  position: relative;
}
.branding-img {
  width: 90%;
  position: absolute;
  margin-left: 50%;
  margin-top: 50%;
  transform: translate(-50%, -50%);
}
.seeAll {
  cursor: pointer;
  color: #797979;
  text-align: right;
  padding-top: 10px;
  overflow: hidden;
}
</style>
