import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export const getUserData = async () => {
  return axios
    .get(`${API_BASE}/showcase/userData`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `showcase userData ${error}`);
};
export const getCarousel = async () => {
  return axios
    .get(`${API_BASE}/showcase/carousel/vehicles`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `showcase carousel ${error}`);
}
