export const Brandings = [
    {
      id: "chevrolet",
      branding: "chevrolet",
      image: "chevrolet/chevrolet.png",
      name: "CHEVROLET",
    },
    {
      id: "fiat",
      branding: "fiat",
      image: "fiat/fiat.png",
      name: "FIAT",
    },
    {
      id: "citroen",
      branding: "citroen",
      image: "citroen/citroen.png",
      name: "CITROEN",
    },
    {
      id: "volkswagen",
      branding: "volkswagen",
      image: "volkswagen/volkswagen.png",
      name: "VOLKSWAGEN",
    },
    {
      id: "renault",
      branding: "renault",
      image: "renault/renault.png",
      name: "RENAULT",
    },
    {
      id: "peugeot",
      branding: "peugeot",
      image: "peugeot/peugeot.png",
      name: "PEUGEOT",
    },
  ];
  