export const HOME_STEP = 'HOME_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';
export const WALLET_BUY_PARTIAL_STEP = 'WALLET_BUY_PARTIAL_STEP';
import {billetDiscount, checkPayment} from "@/services/api/WalletServiceApi";

const DEFAULT_STATE = {
  modalIsOpen: false,
  vehicle: null,
  discount: 0,
  discount_type: null,
  loading: false,
  response: {},
  lastStep: HOME_STEP,
  step: HOME_STEP,
};

const walletModalModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    toggleModal(state) {
      state.modalIsOpen = !state.modalIsOpen;
    },
    setStep(state, step) {
      state.lastStep = state.step;
      state.step = step;
    },
    setVehicle(state, vehicle) {
      state.vehicle = vehicle;
    },
    setDiscount(state, value){
      state.discount = value;
    },
    setDiscountType(state, value){
      state.discount_type = value;
    },
    navigateLastStep(state) {
      state.step = state.lastStep;
    },
    navigateWalletBuyPartialStep(state, vehicle) {
      checkPayment(vehicle.ava_id).then((res) => {
        if (res.data) {
          state.response = res.data;
          state.discount = res.data.valor;
          state.discount_type = res.data.pagamento_disponivel;
        }
        else {
          state.response = res;
          state.discount = null;
        }
      });
      state.step = WALLET_BUY_PARTIAL_STEP;
      state.vehicle = vehicle;
    },
  },
  actions: {
    async handlePeriod({ commit, dispatch }, period) {
      commit('setPeriod', period);
      dispatch('refreshRequestByPeriod');
    },
    async applyBilletDiscount({ state }, data) {
      const { id_avaliacao } = data;
      state.loading = true;
      try {
        return await billetDiscount(id_avaliacao)
        .then((res) => {
          state.loading = false;
          return res;
        });
      } catch (error) {
        state.loading = false;
        state.response = error;
        return error;
      }
    },
    async checkPayment( { state }, id_avaliacao ) {
      state.loading = true;
      try {
        let response = await checkPayment(id_avaliacao);
        if (response.payload.data) {
          state.response = response.payload.data;
          state.discount = response.data.valor;
          state.discount_type = response.data.pagamento_disponivel;
        }
        else {
          state.response = response;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.response = error;
      }
    },
  },
};

export default walletModalModule;
