<template>
  <div id="header" :style="{ borderColor }">
    <div id="header-enabled" v-if="reorganizationDrawerEnabled">
      <ButtonWithIcon
        icon="restore-default-icon"
        text="Restaurar padrão"
        @click="restoreDefaultAndCloseDrawer"
      />
      <ButtonWithIcon
        icon="apply-icon"
        text="Aplicar"
        @click="apply"
        color="#FF7A00"
      />
    </div>
    <ButtonWithIcon
      icon="reorganization-icon"
      text="Reordenar filtros"
      @click="showReorganizationDrawer"
      v-else
    />
  </div>

  <div v-if="reorganizationDrawerEnabled">
    <draggable
      :list="sectionsCache"
      :disabled="!enabled"
      item-key="name"
      class="list-group"
      ghost-class="ghost"
      :move="checkMove"
      @start="dragging = true"
      @end="dragging = false"
      delay="200"
      :delay-on-touch-only="true"
    >
      <template #item="{ element }">
        <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
          <img :src="require('@/assets/icons/reorganization-item-icon.svg')" />
          <span>{{ element.name }}</span>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";

import ButtonWithIcon from "@/components/buttons/ButtonWithIcon.vue";

export default {
  name: "ReornanizationDrawer",
  components: {
    ButtonWithIcon,
    draggable,
  },
  emits: ['eventHandler'],
  data: () => ({
    sectionsCache: null,
    enabled: true,
    dragging: false,
  }),
  updated: function () {
    const isNotStarted = this.sectionsCache === null;
    if (isNotStarted) {
      this.sectionsCache = this.sections;
    }
  },
  computed: {
    ...mapState({
      sections: (state) => {
        return [...state.sections.list];
      },
      reorganizationDrawerEnabled: (state) =>
        state.drawer.reorganizationDrawerEnabled,
      borderColor: (state) =>
        state.drawer.reorganizationDrawerEnabled ? "#e8e8e8" : "#ffffff",
    }),
  },
  methods: {
    ...mapMutations("drawer", [
      "hideReorganizationDrawer",
      "showReorganizationDrawer",
    ]),
    ...mapMutations("sections", ["setSections", "restoreSectionsDefault", "initSectionsModule"]),
    restoreDefaultAndCloseDrawer: function () {
      this.restoreSectionsDefault();
      this.sectionsCache = null;
      this.$emit('eventHandler');
    },
    apply: function () {
      const parsed = JSON.stringify(this.sections);
      localStorage.setItem("sections", parsed);
      this.$emit('eventHandler');
    },
  },
  mounted: function() {
    this.initSectionsModule();
  }
};
</script>

<style scoped>
#header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
  border-bottom: 0.4px solid #797979;
}

#header-enabled {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: opacity 2s linear 2s;
  opacity: 1;
}

.list-group-item {
  box-sizing: border-box;

  /* Auto layout */
  align-items: center;

  padding: 20px;

  background: #ffffff;
  border: 0.5px solid #e4e4e4;

  font-family: "Lexend Deca";
  font-size: 16px;
  line-height: 20px;
  color: #393939;
}

.list-group-item img {
  margin-right: 2em;
}
</style>
