<template>
  <div class="select-container">
    <div class="select-box">
      <input
          v-model="inputValue"
          :type="type"
          class="search-input"
          :placeholder="label"
          @input="updateModelInputValue"
      />
    </div>
    <small class="text-red" v-if="errorMessage">
      {{ errorMessage }}
    </small>
  </div>
</template>
<script>
import { Mask } from "maska";
const mask = new Mask({ mask: ["#.###,##", "###.###.###,##"], reversed: true })
export default {
    props: {
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: false,
    },
    keyName: {
      type: String,
      required: false,
    },
    label: String
  },
  data() {
    return {
      inputValue: this.modelValue,
      stepValue : '',
      currency: {
      mask: ["#.###", "###.###.###.###.###.###"],
      reversed: true,
      },
    };
  },
  watch: {
    modelValue(val) {
      this.inputValue = val;
    }
  },
  computed: {
    getType() {
      if (this.type == 'money') {
        //return 'number';
      }
      return this.type;
    }
  },
  methods: {
    updateModelInputValue(event) {
    let value = event.target.value;

    if (this.keyName === "percentageAATable") {
      let isNegative = value.startsWith("-");

      value = value.replace(/[^0-9]/g, "");

      if (value.length > 3) {
        value = value.slice(0, 3);
      }

      if (isNegative && value.length > 0) {
        value = `-${value}`;
      }
    }

    if (this.type == 'number') {
      value = this.extractNumber(event.target.value);
    } else if (this.type == 'money') {
      value = mask.masked(event.target.value, this.currency.mask);
    }

    this.inputValue = value;

    this.$emit('inputValue', { option: { id: value, value }, key: this.keyName ?? null });
  },
    formatToBRL(value) {
      return new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency'
      }).format(value);
    },
    extractAndFormatBRL(str) {
      const numbers = str.match(/\d+/g);
      if (numbers) {
        const rawNumber = parseInt(numbers.join(''));
        return this.formatToBRL(rawNumber);
      }
      return null;
    },
    extractNumber(str) {
      const numbers = str.match(/\d+/g);
      if (numbers) {
        return parseInt(numbers.join(''));
      }
      return null;
    }
},
};
</script>
<style scoped>
* {
  font-weight: normal;
}
.select-container {
  width: 100%;
  max-width: 300px;
  position: relative;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}
.search-input {
  width: 90%;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: inherit;
}
.search-input[type=money]{
  -moz-appearance:textfield;  
}
.search-input::placeholder {
  color: #000000;
  text-transform: uppercase;
}
.arrow {
  font-size: 16px;
  padding-right: 5px;
  color: #000;
}
.dropdown {
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.dropdown-item {
  padding: 8px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
}
.dropdown-item-disabled {
  padding: 8px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>