const PATHS = {
  Vehicles: "/veiculos",
  Showcase: {
    Root: '/dashboard',
    vehicles: '/campanhas/:slug'
  },
  Favorites: "/favoritos",
  Dealers: "/anunciantes",
  Buyer: {
    Root: "/area-comprador",
    VehicleDetail: "/buyer/vehicle-detail",
    LotDetail: "/buyer/lot-detail",
    /** Buyer -> In Progress */
    InProgress: "/buyer/in-progress",
    BidInProgress: "/buyer/in-progress/bid-in-progess",
    ProposalInProgress: "/buyer/in-progress/proposal-in-progess",
    QuotesInProgress: "/buyer/in-progress/quotes-in-progess",
    AutoBidInProgress: "/buyer/in-progress/autobid-in-progess",
    LotsInProgress: "/buyer/in-progress/lots-in-progess",
    OnDemandAssessmentInProgress: "/buyer/in-progress/on-demand-assessment-progess",
    /** Buyer -> Pending */
    Pending: "/buyer/pending",
    PaymentPending: "/buyer/pending/payment-pending",
    ReleasePending: "/buyer/pending/release-pending",
    ProposalPending: "/buyer/pending/proposal-pending",
    BidPending: "/buyer/pending/bid-pending",
    ProposOnDemandAssessmentalPending: "/buyer/pending/on-demand-assessment-progess",
    /** Buyer -> service */
    Service: "/buyer/service",
    /** Buyer -> Closed */
    Closed: "/buyer/closed",
    AnnouncementCompletedPurchasesClosed: "/buyer/closed/completed-purchases/announcement",
    ProposalCompletedPurchasesClosed: "/buyer/closed/completed-purchases/proposal",
    QuotesCompletedPurchasesClosed: "/buyer/closed/completed-purchases/quotes",
    AutoBidCompletedPurchasesClosed: "/buyer/closed/completed-purchases/auto-bid",
    BuyNowCompletedPurchasesClosed: "/buyer/closed/completed-purchases/buy-now",
    LotsCompletedPurchasesClosed: "/buyer/closed/completed-purchases/lots",
    AnnouncementUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/announcement",
    QuotesUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/quotes",
    AutoBidUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/auto-bid",
    LotsUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/lots",
    CanceledUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/canceled",
    OnDemandAssessmentPurchasesClosed: "/buyer/closed/unfulfilled-purchases/on-demand-assessment-closed",
    AssessmentUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/assessment",
    RejectedUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/rejected",
    OnDemandAssessmentUnfulfilledPurchasesClosed: "/buyer/closed/unfulfilled-purchases/on-demand-assessment",
    CreditLetter: "/buyer/credit-letter",
  },
};

export default PATHS;
