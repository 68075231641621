<template>
  <v-container v-if="logs && logs.length > 0 && !loading">
    <p class="aa-subhead aa-opacity-7 pl-lg ml-md">Continue buscando</p>
    <p class="boxed hdu boxed-icon-link tag cursor-pointer" v-for="(obj, index) in logs" v-bind:key="index" @click="filter(obj.tag)">
      <strong>{{ obj.tag }}</strong>
      <small> ({{ obj.time }})</small>
      <v-icon class="ms-2">mdi-chevron-right</v-icon>
    </p>
  </v-container>
</template>
<script>
import {mapState} from "vuex";
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  name: "HistoryChip",
  mixins: [NavigateMixings],
  props: {
    logs: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.filterShowcase.loading,
    })
  },
  methods: {
    filter: function (query) {
      this.navigateTo('/veiculos?query=' + query)
    },
  }
};
</script>

<style scoped>

.aa-subhead {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 4px;
  color: #505050;
}
.aa-opacity-7 {
  opacity: .7;
}

p {
  font-size: 12px;
  margin: 10px 0;
}
.cursor-pointer {
  cursor: pointer;
}
.boxed.tag {
  display: inline-block;
  border-radius: 12px;
  margin: 4px;
  padding: 6px 8px;
}
a[href] {
  cursor: pointer;
}
.boxed {
  display: block;
  background: #FFF;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 8px 30px -5px rgba(0, 0, 0, .3);
}
.tag {
  display: inline-block;
  padding: 3px 5px;
  font-size: .9em;
}
a {
  text-decoration: none;
  color: #000;
  cursor: default;
}
</style>
