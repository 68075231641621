<template>
  <div class="breadcrumbs box" :class="{'bg-white':mobile, 'p-12':mobile}" id="buyer-breadcrumb">
    <p class="mobile pa-3" v-if="mobile">

      <a href="#" @click="navigateToDashboardPage()">
        <i class="mdi mdi-home"></i>
      </a>
      <span class="title-prev" @click="navigateToDashboardPage()">{{ sectionPage }} > </span>
      <span class="title-current"> {{ titlePage }}</span>
      <span class="title-current" v-if="subTitlePage.length"> {{ subTitlePage === 'Saldo disponível' ? '' : '>' }} {{ subTitlePage }}</span>
      <span class="title-current" v-if="thirdTitlePage.length && $route.path === '/buyer/vehicle-detail'"> > {{ thirdTitlePage }}</span>
      
    </p>
    <p class="breadcrumbs" v-if="!mobile">
      <span class="title-current">Você está aqui: </span>
      <span class="title-prev text-orange" @click="navigateToDashboardPage()" id="breadcrumb-dashboard">{{ sectionPage }} > </span>
      <span class="title-current"> {{ titlePage }}</span>

      <span class="title-current" v-if="subTitlePage.length && $route.path!='/buyer/lot-detail'"> > {{ subTitlePage }}</span>

      <!-- if have lot detail -->
      <span class="title-prev text-orange" v-if="$route.path==='/buyer/lot-detail' && currentStatusLote=='progress'" @click="natigateToLotsInProgressPage()"> > {{ subTitlePage }} > </span>
      <span class="title-prev text-orange" v-if="$route.path==='/buyer/lot-detail' && currentStatusLote=='completed'" @click="natigateToAutoLotsCompletedPurchasesClosedPage()"> > {{ subTitlePage }} > </span>
      <span class="title-current" v-if="$route.path==='/buyer/lot-detail'">
        > <span class="title-prev" @click="() => $router.go(-1)">
          <span class="text-orange" v-if="buyerPackageDetailScope === 'AnnouncementCompleted'" id="breadcrumb-pagamento-pendente">Compras Concretizadas - Avaliações ></span>
          <span class="text-orange" v-if="buyerPackageDetailScope === 'UnfulilledPurchases'" id="breadcrumb-compras-nao-concretizadas-avaliacoes">Compras não concretizadas - Avaliações ></span>
          <span class="text-orange" v-if="buyerPackageDetailScope === 'InProgress'" id="breadcrumb-em-progresso-avaliacaoes">Avaliações ></span>
          <span class="text-orange" v-if="buyerPackageDetailScope === 'PaymentPending'" id="breadcrumb-pagamento-boleto">Pagamento pendente - Boleto ></span>
          <span class="text-orange" v-if="buyerPackageDetailScope === 'ReleasePending'" id="breadcrumb-pagamento-pendente-autopay"> Pagamento Pendente AutoPay ></span>
        </span>
         Pacote {{ lot.ava_id }}
      </span>

    </p>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions, mapMutations } from "vuex";
import store from "@/store";
import { watch, ref, reactive, computed } from "vue";
import NavigateMixings from "@/mixins/NavigateMixings";
import { BUYER } from "@/constants/Buyer";

export default {
  name: "AA-Breadcrumbs",
  props: ["mobile"],
  mixins: [NavigateMixings],
  data() {
    return {
      page: "Página Inicial",
      titleDashboad: BUYER.TITLE_DASHBOARD,
      total: null,
      filtersActive: [],
      filtersLang: {
        location: "Local",
        cities: "Cidade",
        entity: "entity",
        byEntity: "bgEntity",
        brands: "Marca",
        brandings: "Marca",
        models: "Modelo",
        versions: "Versão",
        adType: "Tipo de Anunciante",
        AdvertiserType: "Tipo de Anunciante",
        group: "Grupo",
        priceMin: "Preço Min.",
        priceMax: "Preço Máx.",
        kmMin: "Km Min.",
        kmMax: "Km Máx.",
        yearMin: "Ano Min.",
        yearMax: "Ano Máx.",
        order: "Ordem",
        category: "Categoria",
        relevance: "Relevância",
        query: "Consulta",
        campaign: "Campanha",
        optionals: "Opcionais",
        report: "Laudo",
        fipe: "Fipe",
        query: "Busca",
        VehicleCondition: "Condição do Veículo",
        typeVehicle: "Tipo",
        fuel: "Combustível",
        reportStatus: "Laudo",
        quotation: "Modalidade de Compra",
        transmission: "Transmissão",
      },
      params: {},
      filters: {
        location: [],
        cities: [],
        entity: [],
        byEntity: false,
        brands: [],
        models: [],
        versions: [],
        adType: [],
        group: null,
        priceMin: "",
        priceMax: "",
        kmMin: "",
        kmMax: "",
        yearMin: null,
        yearMax: null,
        order: "",
        category: "",
        relevance: null,
        query: "",
        campaign: null,
        optionals: [],
        fipe: "0",
        reportStatus: [],
      },
      filtersHide: ["fipe", "vehicle-branding"]
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      params: (state) => state.filterForm.params,
      seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
      titlePage: (state) => state.buyer.titlePage,
      subTitlePage: (state) => state.buyer.subTitlePage,
      thirdTitlePage: (state) => state.buyer.thirdTitlePage,
      sectionPage: (state) => state.buyer.sectionPage,
      locurrentLotIdt: (state) => state.buyerLotDetail.currentLotId,
      lot: (state) => state.buyerLotDetail.lotDetail,
      currentStatusLote: (state) => state.buyer.currentStatusLote,
      buyerPackageDetailScope: (state) => state.buyerLotDetail.scope,
    }),
  },
  methods: {
    ...mapActions("filterForm", ["search"]),
    ...mapMutations("drawer", [
        "showSeeAllDrawer",
        "setSeeAllDrawerObjDetails",
        "unsetSeeAllDrawerObjSelected",
        "clearSeeAllDrawerObjSelected",
        "clear"
      ]),
    init() {
      this.filtersActive = store.state.filterForm.params;
    },
    removeFilter(param) {
      if(param == 'brands'){
        this.unsetSeeAllDrawerObjSelected({ key: "key-vehicle-branding", all: true });
        this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
        this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
        store.commit("filterForm/removeParam", 'models');
        store.commit("filterForm/removeParam", 'versions');
      }
      if(param == 'models'){
        this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
        this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
        store.commit("filterForm/removeParam", 'versions');        
      }
      if(param == 'versions'){
        this.unsetSeeAllDrawerObjSelected( {key: 'key-Versões', all: true} );
        store.commit("filterForm/removeParam", 'versions');        
      }

      this.$store.commit("filterForm/removeParam", param);
      if(param == 'fipe'){
        let range = document.getElementById("range_fipe");
        range.value = 0;
        range.style.backgroundSize = '0%';
      }

      const key = "key-" + param;
      this.$store.commit("drawer/clearSeeAllDrawerObjSelected", key);

      this.params[param] = [];
      //delete this.filtersActive[param];

      return;
    },
    select(field) {
      let el = document.querySelector("div[data-name=" + field + "]");
      if (!el) return;
      //el.scrollIntoView();
      el.focus();
      el.click();
    },
    getValue(v = false, k = false) {
      let sufix = '';
      if(k == 'fipe') sufix = '%';
      if (typeof v === "null") return false;
      if (typeof v === "undefined") return false;
      if (typeof v === "array" || typeof v === "object") {
        v = v.map((elem) => {
          if (typeof(elem) === 'string') return elem;
          if ("title" in elem) return elem.title;
          if ("name" in elem) return elem.name;
          if ("value" in elem) return elem.value;
          return elem;
        });
      }
      if (typeof v === "object") v = v.join(", ");
      return v + sufix;
    },
    updateStore() {},
    onWheel(ev) {
      let mov = ev.deltaY;
      document.querySelector(".scroll-filters").scrollLeft += mov / 3;
    }
  },
};
</script>

<style lang="scss" scoped>

$orange: #ff9900;

.title-current{
  color: #686868;
  font-size: 11px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word
}
.title-prev{
  color: #FF7A00;
  font-size: 11px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
}

.content-top {
  &.mobile {
    .breadcrumbs.box {
      height: auto;
      font-size: 0.8rem;
    }

    .breadcrumbs {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.breadcrumbs.box {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 3em;
  line-height: 12px;

  p.breadcrumbs {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    > span {
      color: #424242;
    }
  }
}
p.breadcrumbs,
p.breadcrumbs span {
  font-family: "Lexend Deca", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: -0.8px;
}
p.breadcrumbs span.label {
  text-transform: capitalize;
  color: #424242;
  font-size: inherit;
  font-weight: 400;
}
p.breadcrumbs i.fa,
.mdi {
  color: #ff9900 !important;
  font-size: 1.2rem !important;
  margin-right: 0.1em;
  position: relative;
  top: 2px;
  cursor: pointer;
}
p.breadcrumbs .caption {
  color: #ff9900;
  font-weight: 400;
  font-size: inherit;
  text-transform: capitalize;
  margin-right: 0.2em;
}

p.breadcrumbs a.home {
  text-decoration: none;
}

p.breadcrumbs .home {
  color: $orange !important;
}
.caption.home,
.caption.filtros {
  color: #aaa !important;
}
.nextArrow {
  margin: 0 0.3em;
}
.scroll-filters {
  display: inline-flex;
  white-space: nowrap;
  overflow-x: auto;
  top: 0;
  max-width: calc(100% - 20em);
  padding: 0.5em 0;
  margin-top: 0;
  margin-bottom: 0;
  /*
  @media (min-width: 1024px) {
    max-width: calc(35% + 2vw);
  }
  @media (min-width: 1200px) {
    max-width: calc(50% + -1vw);
  }
  @media (min-width: 1600px) {
    max-width: calc(60% + 1vw);
  }
  @media (min-width: 1920px) {
    max-width: calc(75% - 3vw);    
  }
  */

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  &::before {
    content: "";
    background: linear-gradient(to right, white, #fff0);
    display: block;
    height: 2em;
    width: 1.5em;
    position: fixed;
  }
}
.filter-aa:last-child .nextArrow {
  display: none;
}
</style>
