<template>
  <div class="vehicle" ref="vehicleBox">
    <div class="vehicle-item horizontal v-row" :class="{ package: is_package, pushCard: animate }">
      <div class="vehicle-header">
        <span class="location">
          {{
            `${capitalize(getValue(vehicle.an_cidade))}/${getValue(
              vehicle.an_uf
            )}`
          }}
        </span>
        <span class="favorite" :id="'fav-' + vehicle.id_avaliacao">
          <Heart @click="handleSetFavorite(vehicle.id_avaliacao)" color="#ee2424" :size="22" fill="#ee2424"
            v-if="isFavorite" />
          <Heart @click="handleSetFavorite(vehicle.id_avaliacao)" color="white" :size="22" v-else />
        </span>
        <span class="adsType" :class="adsType(vehicle, true)">
          {{ adsType(vehicle) }}
        </span>
        
        <span class="nav-bullets" v-if="is_package">
          <i v-for="n in parseInt(vehicle.qtdeVeic)" :key="n" @click="packageData.setSlide(n - 1)"
            :class="{ active: packageData.currentSlide == n - 1 }"></i>
        </span>
        <a href="#" @click="gotoVdp(vehicle)">
          <div class="ph-12">
            <v-img :src="image === 'Array' || image === null
                ? require('@/assets/images/VehicleItemImage.png')
                : image
              " width="100%" height="234px" cover class="image">
              <div class="overlay"></div>
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                </div>
              </template>
              <template v-slot:error>
                <v-img :src="require('@/assets/images/VehicleItemImage.png')"></v-img>
              </template>
            </v-img>
          </div>
        </a>
      </div>

      <div class="description">
        <div class="">
          <h3 class="v-col-12 p-0">
            {{ title }}
          </h3>
          <p class="version v-col-12 p-0">{{ capitalize(version) }}</p>

        <VehiclePackageInfo :count="packageData.count" :disablePrevious="packageData.disablePrevious"
          :disableNext="packageData.disableNext" :nextSlide="packageData.nextSlide" :prevSlide="packageData.prevSlide"
          v-if="is_package" />
        <VehicleItemDetail :showPlate="true" :vehicle="vehicle" v-if="!is_package" />
        <VehicleItemStamps :vehicle="vehicle" v-if="!is_package" />

        </div>
      </div>

      <div class="seller">
        <img :src="vehicle.dealer_img" height="31" width="66" :alt="'imagem' + vehicle.em_nome" />
        <h3>{{ capitalize(vehicle.em_nome) }}</h3>
        <h4 :title="capitalize(vehicle.an_nome)">
          {{ capitalize(vehicle.an_nome) }}
        </h4>
        <span class="rating">
          <v-rating v-bind:model-value="vehicle.empresa_nota_reviews" size="x-small" density="compact" color="#FF7A00"
            half-increments disabled readonly></v-rating>
          {{ vehicle.empresa_nota_reviews > 0 ? vehicle.empresa_nota_reviews : '' }}</span>
      </div>

      <div class="vehicle-footer">
        <h3 class="my-3">
          <span class="price" v-if="vehicle.valor_inicial != 0">
            {{
              parseFloat(getValue(vehicle.valor_inicial)).toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )
            }}
          </span>
        </h3>
        <div class="v-row">
          <VehicleItemTimer class="my-3 mx-auto" :vehicle="vehicle" />
        </div>
        <a :href="link" target="_blank" :id="'btn-' + vehicle.id_avaliacao">Visualizar →</a>
      </div>
    </div>
    <div class="vehicle-item clone" v-if="is_package" :class="{ pushCardClone: animate }"></div>
    <div class="vehicle-item clone" v-if="is_package" :class="{ pushCardClone: animate }"></div>
  </div>
</template>
<script>
import { capitalize } from "@/helpers/strings";
import { Heart } from "lucide-vue-next";

import VehicleItemDetail from "./components/VehicleItemDetail.vue";
import VehicleItemStamps from "./components/VehicleItemStamps.vue";
import VehicleItemTimer from "./components/VehicleItemTimer.vue";
import VehiclePackageInfo from "./components/VehiclePackageInfo.vue";
import VehicleCardMixin from "@/views/buyer/mixins/VehicleCardMixin";
import { mapState } from "vuex";
import { gotoVdp } from "@/helpers/vdp";

export default {
  name: "VehicleItemDesktop",
  mixins: [VehicleCardMixin],
  props: {
    vehicle: Object,
    index: Number,
    linha: Number,
    link: String,
    title: String,
    image: String,
    version: String,
    isPackage: Boolean,
    animate: Boolean,
    packageData:
      {
        count: Number,
        currentSlide: Number,
        disablePrevious: Boolean,
        disableNext: Boolean,
        nextSlide: Function,
        prevSlide: Function,
        setSlide: Function,
      } || undefined,
  },
  components: {
    VehicleItemDetail,
    VehicleItemStamps,
    VehicleItemTimer,
    VehiclePackageInfo,
    Heart,
  },
  data: () => {
    return {
      time: null,
      interval: null,
      is_package: false,
      currentSlide: 0,
      rating: 5,
      imageHeight: "",
    };
  },
  methods: {
    gotoVdp,
    getValue: function (str) {
      if (!this.is_package) return str;
      let parts = str.split(",");
      if (this.currentSlide > parts.length) return str;
      return parts[this.currentSlide] ?? str;
    },
    capitalize,
    formatTime: function (time) {
      return time < 10 ? "0" + time : time;
    },
    subtractSeconds: function () {
      const newTime = new Date(this.time);
      newTime.setSeconds(newTime.getSeconds() - 1);
      this.time = newTime;
    },
    adsType(vehicle, slug = false) {
      const quotations = this.params['quotation'];
      if (!quotations) return;

      const slugify = (text) =>
        text
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .trim()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-");

      if (quotations.length === 1) {
        const title = quotations[0].title;
        return slug ? slugify(title) : title;
      }


      let type = "";
      if (vehicle.id_cotacao) type = "Cotação";
      else if (
        vehicle.veiculo_processo_aprovacao_lance == 1 &&
        (vehicle.vlr_processo_aprovacao_lance > 0 ||
          vehicle.vlr_processo_aprovacao_lance !== null) &&
        (quotations.length > 0
          ? quotations.filter(quotation => quotation.title === 'Bid sob aprovação').length > 0
          : true)
      )
        type = "Bid sob aprovação";
      else type = "Repasse";
      return slug ? slugify(type) : type;
    },
  },
  mounted: function () {
    this.is_package = parseInt(this.vehicle.qtdeVeic) > 1;
  },
  computed: {
    ...mapState({
      params: (state) => state.filterForm.params,
    }),
  },
};
</script>

<style lang="scss">
@keyframes pushCard {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
    top: 0;
    right: -2px;
  }

  70% {
    opacity: 0.8;
    right: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes pushCardClone {
  0% {}

  50% {
    top: -1px;
    right: -2px;
  }

  100% {
    top: 0;
    right: initial;
  }
}

.pushCard {
  animation-name: pushCard;
  animation-duration: 0.5s;
  animation-direction: normal;
  animation-timing-function: ease;
  transition: all;
}

.pushCardClone {
  animation: pushCardClone 0.8s;
  animation-direction: normal;
  transition: all;
}

.vehicle {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 1;
  display: flex;
  position: relative;
  margin-right: v-bind(vehicleMarginRight);

  .vehicle-item {
    background-color: #fff;
    position: relative;
    z-index: 2;
    border-radius: 12px;

    &.package {
      position: relative;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }

    &.clone {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      right: -6px;
      animation-duration: 0.3s;

      &:nth-child(2) {
        z-index: 0;
        right: -12px;
        animation-duration: 0.4s;
      }
    }

    &.horizontal {
      .p-0{
        padding:0;
      }
      .description{
        h3{
          font-family: Lexend Deca;
          font-size: 1.3em;
          font-weight: 700;
          line-height: 17.5px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;         
          color:#373737;
        }
        .version{
          font-family: Lexend Deca;
          font-size: 1em;
          font-weight: 400;
          line-height: 15px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;          
          margin:.8em 0;
          color:#373737;
        }
          .details{
            display:flex;
            flex-wrap: wrap;
          div.detail{
            display:flex;
            flex-direction: row;
            margin-right: 1em;
            width:max-content;
            .status-item{
              display: contents;
            }
          }
        }
      }
      .more-info{
        flex-direction:row;
        justify-content: flex-start;
        margin-left:-1em;
        // posicionar ao lado da placa
        //position: relative;
        //top: -1.8em;
        .v-popper:first-child{
          //padding-left: 5.6em;
        }

        .fipe{
          margin:0;
        }
      }
      &.vehicle-item{
        margin:0.5em;
        .version{
        }
        .detail{
          /*
          &.fuel{
            width: 50% !important;
          }
          &.km{
            width: 50% !important;
          }
          &.transmission{
          }
          */
        }
      }
      .divider {
        display: none;
      }

      .vehicle-header {
        width: 28%;

        .price,
        .adsType {
          bottom: 1em;
        }
      }

      .description {
          width: 25%;
        @media screen and (max-width: 1279px) {
          width: 35%;
        }
        align-items: center;
      }
      .seller{
        display:block;
        text-align: center;
        padding:.5em;
        height:auto;
        margin: 0 auto;
        align-content: center;
        align-self:center;
        font-size:1.2em;
        flex-wrap: wrap;
        flex-direction: row;
        width: 17%;
        @media screen and (max-width: 1279px) {
          display:none; 
        }
        img{
          width:5em;
          margin: 0 auto;
        }
        h3{
          font-size: 0.9rem;
          font-weight: normal;
        }
        h4{
          font-weight: normal;
          font-size: 0.7rem;
        }
      }

      .vehicle-footer {
        width: 25%;
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        align-content: center;
        border-left: 1px solid #DDD;
        padding: 1em;
        .price{
          color:#272727;
          font-family: Lexend Deca;
          font-size: clamp(1.1em, 2vw, 1.6em);
          font-weight: 700;
          line-height: 25px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;          
        }
        a{
          padding:.5em 0;
        }
      }
      .footer-info {
        padding-bottom: .8em;
        display: flex;
        flex: 1;
        justify-content: center;
        padding-bottom: .8em;
        .timer{
          zoom: 1.3;
          padding:.5vmax 0;
          @media screen and (max-width: 1400px) {
            zoom: 1.1;
          }
        }
      }
    }
  }
}

.vehicle-header {
  position: relative;
}

.vehicle-header .location {
  position: absolute;
  margin-top: 12px;
  margin-left: 12px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  z-index: 1;
}

.vehicle-header .favorite {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 1;
}

.vehicle-header img {
  width: 100%;
  border: 7px solid #ffffff;
  border-radius: 12px;
  object-fit: cover;
}

.vehicle-header .image {
  border: 7px solid #ffffff;
  border-radius: 12px;
  z-index: -1;
}

.vehicle-header .price {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}

.vehicle-header .price,
.vehicle-header .adsType {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}

.vehicle-header .adsType {
  right: inherit;
  left: 12px;
  font-size: 11px;
  font-weight: 700;

  &.repasse {
    background: #1872fa;
  }

  &.cotacao {
    background: #ff7a00;
  }

  &.bid-sob-aprovacao {
    background: #0dc561;
  }
}

.vehicle-header h3 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: #373737;
  margin-left: 12px;
  text-transform: capitalize;
}

.vehicle-header h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  word-wrap: break-word;
  max-width: 300px;
}

.vehicle-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffffff 0%,
      #000000 0.01%,
      rgba(0, 0, 0, 0) 33.11%);
  z-index: 0;
}

.divider {
  width: 100%;
  padding: 2px 10px;
  margin: 6px 0;
}

.divider .content {
  width: 100%;
  border: 0.8px solid #ededed;
}

.description {
  display: flex;
  margin: 4px 12px;
}

.detail span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  text-align: left;
  flex: 1;
}

.more-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.more-info .fipe {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.11em;

  color: #ffffff;
}

.more-info .percent {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  letter-spacing: -0.11em;
  margin-top: 12px;
  margin-left: 11px;

  color: #ffffff;
}

.seller {
}

.seller h3 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  text-align: center;
}

.seller h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.seller img {
  object-fit: cover;
}

.seller .rating {
  color: #ff7a00;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vehicle-footer {
  display: flex;
  flex-direction: row;
  margin: 8px;
  justify-content: center;
  align-items: center;
}

.timer {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  justify-content: center;
}

.timer span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  display: flex;
  align-items: center;

  color: #373737;
}

.timer h4 {
  margin-top: -4px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  white-space: nowrap;
  align-items: center;
  color: #373737;
}

.vehicle-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ff7a00;
  border-radius: 7px;
  padding: 0 8px;
  height: 28px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  align-items: center;
  white-space: nowrap;
  color: #ff7a00;
  padding: 0 15px;
  text-decoration: none;
}

.vehicle-footer a:hover {
  opacity: 0.8;
}

.nav-bullets {
  position: absolute;
  z-index: 1;
  bottom: 4em;
  left: 1em;

  i {
    cursor: pointer;
    margin: 3px;
    color: #000f;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid #fff;
    border-radius: 1em;

    &.active {
      color: #fff;
      background-color: #fff;

      &:hover {
        background-color: #fff;
      }
    }
  }
}

.fipe-container {
  position: relative;
}

.details-package {
  img {
    float: left;
  }

  strong {
    display: inline-block;
  }

  .qtd {}

  figcaption {
    line-height: 1.2em;
    font-size: 0.92em;
    text-indent: 0.4em;
  }

  .controls {
    font-size: 0.85em;
    align-content: space-between;
    display: flex;
    margin-top: 2em;
    border-top: 1px solid #eee;
    padding-top: 1.5em;

    a {
      color: #373737;
      text-decoration: none;
      flex: auto;
      transition: 0.2s all;

      &.disable {
        color: lightgray;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 1220px) {
  .vehicle-footer a {
    padding: 0 5px;
  }
  .timer h4 {
    font-size: 10px;
  }
}
</style>
