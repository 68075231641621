<template>
  <v-container fluid>
    <div class="error--container">
      <v-img
          :width="200"
          cover
          :src="require('@/assets/logo.png')"
          class="logo"
      ></v-img>
      <div class="card large">
        <h1 class="md-display-4 img-2">404</h1>
        <div class="error-info">
          <span id="counter">{{ secs }}</span>
          <h2 class="text-center black-90">Desculpe, esta página não existe.</h2>
          <p>
            <small>clique <a href="#" @click="goToHomePage">aqui</a> e volte a <a href="#" @click="goToHomePage"> página
              principal</a>.</small>
          </p>
        </div>
      </div>
    </div>
  </v-container>

</template>

<script>

import NavigateMixings from "@/mixins/NavigateMixings";
import {mapMutations, mapState} from "vuex";

export default {
  name: "NotFoundView",
  mixins: [NavigateMixings],

  data() {
    return {
      secs: 1000,
      interval: null
    }
  },
  computed: {
    ...mapState({
      isNotFoundPage: (state) => state.global.isNotFoundPage
    })
  },
  methods: {
    counter() {
      this.interval = setInterval(this.subtractSeconds, 1000);
    },
    subtractSeconds() {
      this.secs -= 1;
      if (this.secs === 0) {
        clearInterval(this.interval);  
        this.goToHomePage();      
      }
    },
    goToHomePage() {
      this.setNotFoundPage({status: false})
      this.navigateToHomePage();
    },
    ...mapMutations("global", [
      "setNotFoundPage",
    ]),
  },
  mounted() {
    this.setNotFoundPage({status: true})
    this.counter();
  },
};
</script>

<style scoped>
* {
  font-family: "Roboto", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #5c5c5c;
}

.error--container {
  background: #f0f0f0;
  min-height: 100%;
  margin: 0;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.error--container:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0%;
  background: rgb(181, 181, 181);
  z-index: 0;
  border-bottom: 7px solid #666
}

.error--container:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 0%;
  background: rgb(181, 181, 181);
  z-index: 0;
  border-top: 7px solid #666
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none;
}

.black-90 {
  margin-top: 30px;
}

.img-2 {
  background: url('../../assets/bg-card-error.jpeg') no-repeat bottom center;
  background-size: cover;
}

.error--container h1 {
  display: block;
  height: 150px;
  text-align: center;
  color: #FFF;
  font-weight: bolder !important;
  font-size: 80px;
  margin: 0;
  line-height: 115px;
  border-radius: 12px 12px 0 0;
}

.error--container p {
  text-align: center;
  font-size: 18px;
  margin: 10px 0;
}

.error--container button {
  position: absolute;
  right: 30px;
  top: -28px;
  background: #333;
  margin: 0;
  color: #FFF;
}

.error--container .error-info {
  position: relative;
  padding: 20px 10px 10px;
}

.error--container small {
  font-size: 0.6em;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 27%;
  filter: grayscale(100%);
}

.card {
  position: absolute;
  width: 36%;
  left: 52%;
  margin-left: -20%;
  top: 50%;
  margin-top: -150px;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}

#counter {
  position: absolute;
  display: block;
  font-size: 16px;
  width: 50px;
  text-align: center;
  height: 50px;
  padding: 15px 0;
  background: #feb32b;
  border-radius: 50%;
  color: #FFF;
  font-weight: bold;
  top: -25px;
  right: 0;
  left: 0;
  margin: auto;
  text-shadow: 1px 1px 4px #00000080;
  opacity: .9;
}

a {
  font-weight: bold;
  color: #4c4c4c;
}

a:hover {
  color: #444
}


@media (min-width: 721px) and (max-width: 1366px) {
  .card {
    width: 50%;
    left: 45%;
  }
}

@media (max-width: 720px) {
  .card {
    width: 80%;
    left: 50%;
    margin-left: -40%;
  }
  .logo {
    top: 20%;
  }
}


</style>