<template>
    <v-container>
        <v-row>
            <MenuTitle text="Quilometragem" />
        </v-row>
        <v-row>
            <v-col class="ps-0">
                <v-text-field
                  label="De"
                  variant="outlined"
                  hide-details="auto"
                  v-maska:[km]
                  id="km_from"
                  @keyup="kmChange"
                  :model-value="kmMin"
                  :maxlength="11"
                  @input="(event) => setKmMinHandle(event.target.value)"
                  color="#939292"
                  base-color="#939292"
                  :persistent-placeholder="true"
                  :placeholder="kmMinPlaceholder"
                  >
                <template v-slot:label>
                  <span class="custom-text-field--label">
                    De
                  </span>
                </template>
              </v-text-field>
              <small class="input-field-message" id="km_from_message"></small>
            </v-col>
            <v-col>
                <v-text-field
                  label="Até"
                  variant="outlined"
                  hide-details="auto"
                  v-maska:[km]
                  id="km_at"
                  @keyup="kmChange"
                  :model-value="kmMax"
                  :maxlength="11"
                  @input="(event) => setKmMaxHandle(event.target.value)"
                  color="#939292"
                  base-color="#939292"
                  :persistent-placeholder="true"
                  :placeholder="kmMaxPlaceholder"
                >
                <template v-slot:label>
                <span class="custom-text-field--label">
                    Até
                </span>
                </template>
            </v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { vMaska } from "maska";
import MenuTitle from "@/components/typographies/MenuTitle.vue";
import { getDefaultValueFilterInputs } from "@/services/api/FiltersServiceApi";
import { mapMutations, mapState } from "vuex";
import {debounce} from "@/helpers/strings";

export default {
    name: "VehicleKMRange",
    components: { MenuTitle },
    directives: { maska: vMaska },
    data: () => ({
        km: {
            mask: ["#.###", "###.###.###"],
            reversed: true,
        },
        kmMaxPlaceholder: '',
        kmMinPlaceholder: '',
    }),
    computed: {
    ...mapState({
            kmMin: (state) => state.filterForm.params.kmMin ?? state.filterForm.kmMin,
            kmMax: (state) => state.filterForm.params.kmMax ?? state.filterForm.kmMax,
      }),
    },
    methods: {
        ...mapMutations("filterForm", [
            "setKmMin",
            "setKmMax",
        ]),
      kmChange() {

        const km_from = document.getElementById("km_from");
        const km_at = document.getElementById("km_at");

        const km_from_int = parseInt(km_from.value.replace(/\./g,''));
        const km_at_int = parseInt(km_at.value.replace(/\./g,''));

        const tam_km_from = km_from.value.replace(/\./g,'').length;

        const from_parent_closest = km_from.closest(".v-input");
        const e_parent_closest = km_at.closest(".v-input");
        const message_place = document.getElementById("km_from_message");

        if (km_at_int && km_from_int && tam_km_from >= 3) {
          if (km_from_int > km_at_int) {
            from_parent_closest.classList.add("input-field-error");
          message_place.innerText = "Quilometragem inicial maior que final";
            message_place.classList.add("error");

            e_parent_closest.classList.add("input-field-error");
          } else {
            from_parent_closest.classList.remove("input-field-error");
            message_place.innerText = "";
            message_place.classList.remove("error");

            e_parent_closest.classList.remove("input-field-error");
          }
        }
        else {
          from_parent_closest.classList.remove("input-field-error");
          message_place.innerText = "";
          message_place.classList.remove("error");

          e_parent_closest.classList.remove("input-field-error");
        }
      },
      setDefaultDataValue(response){
          const { km_max, km_min } = response.data;
          this.kmMaxPlaceholder = km_max?.toString() || '';
          this.kmMinPlaceholder = km_min?.toString() || '';
      },
      setKmMinHandle: debounce(function (km) {
        this.setKmMin(km);
      }, 1000),
      setKmMaxHandle: debounce(function (km) {
        this.setKmMax(km);
      }, 1000),
    },
    mounted() {
      getDefaultValueFilterInputs().then((response) => {
        this.setDefaultDataValue(response.data.payload);
      });
  },
}
</script>
<style scoped>
.field-example {
  color: #939292;
  display: inline-block;
  margin-top: 5px;
}
.v-field--variant-outlined .v-field__outline__start.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__start{
  border-radius:12px 0 0 12px !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
}
.v-field--variant-outlined .v-field__outline__end.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__end{
  border-radius:0 12px 12px 0 !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.v-field--variant-outlined .v-field__outline__notch::before{
  border-width: 2px 0 0;
}
.v-field--variant-outlined .v-field__outline__notch::after{
  border-width: 0 0 2px;
}

.my-input.v-input .v-input__slot {
  border-radius: 100px !important;
}

.custom-text-field--label, .v-text-field__prefix{
  color:#939292;
  font-weight: 600;
}
.field-example {
  color: #cdcdcd;
  display: inline-block;
  margin-top: 5px;
}
.input-field-error {
  color: red;
}
.input-field-message {
  display: none;
  font-size: 12px;
}
.input-field-message.error {
  display: block;
  color: red;
}
</style>