<template>
  <div class="vehicle-detail-page" v-if="!isPackage">
    <BuyerSectionItem
      :icon="icon"
      :title="title"
      :description="description"
      :shouldReturnToTheBuyerPage="true"
    />
    <div class="vehicle-detail-content mt-1">
      <div class="card">
        <div class="card-image">
          <img
            :src="
              foto === null
                ? require('@/assets/images/VehicleItemImage.png')
                : foto
            "
            alt=""
          />
          <div class="card-image-overlay"></div>
          <img
            :src="
              isFavorite
                ? require('@/assets/icons/favorite-icon-active.svg')
                : require('@/assets/icons/favorite-icon.svg')
            "
            class="favorite"
            v-if="showIconFavorite(buyerScope)"
            @click="handleSetFavorite(vehicle.ava_id)"
          />
          <span class="city">{{ vehicle.cidade }}/{{ vehicle.uf }}</span>
          <div class="price-content">
            <span class="description"
              >{{
                priceDescription ? priceDescription : "Você comprou por"
              }}&nbsp;</span
            >
            <span class="price">R${{ getPriceBase(vehicle.valor) }},00</span>
          </div>
        </div>

        <div class="card-content">
          <div class="left">
            <h1>{{ capitalize(`${vehicle.v_marca} ${vehicle.v_modelo}`) }}</h1>
            <h2>{{ capitalize(vehicle.v_versao) }}</h2>
            <div class="status">
              <div class="status-item mr-1">
                <img
                  class="mr-2"
                  :src="require('@/assets/icons/calendar-alt-icon.svg')"
                />
                <span>{{ `${vehicle.v_anof}/${vehicle.v_anom}` }}</span>
              </div>
              <div class="status-item mr-2">
                <img
                  class="mr-1"
                  :src="require('@/assets/icons/speed-meter-icon.svg')"
                />
                <span>{{ vehicle.v_km }}</span>
              </div>
              <div class="status-item mr-2">
                <img
                  class="mr-1"
                  :src="require('@/assets/icons/license-plate-icon.svg')"
                />
                <span>{{ formatLicense(vehicle.v_placa) }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="fipe-content" v-if="vehicle.percentual_fipe !== null">
              <VTooltip placement="top-start" max-width="20px" width="20px">
                <span class="fipe-container">
                  <span class="fipe">{{
                    parseInt(Math.abs(vehicle.percentual_fipe - 100))
                  }}</span>
                  <span class="percent">%</span>
                  <img :src="require('@/assets/icons/fipe-icon.svg')" />
                </span>

                <template #popper>
                  {{
                    `${parseInt(
                      Math.abs(vehicle.percentual_fipe - 100)
                    )}% do VALOR FIPE`
                  }}
                </template>
              </VTooltip>
            </div>
            <div class="status">
              <VTooltip
                placement="top-start"
                max-width="20px"
                width="20px"
                v-if="vehicle.v_laudo !== ''"
              >
                <span>
                  <img
                    :src="require('@/assets/icons/report-icon.svg')"
                    alt="Veículo contém laudo aprovado"
                  />
                </span>

                <template #popper> Veículo contém laudo aprovado </template>
              </VTooltip>
            </div>
          </div>
        </div>

        <div class="card-bottom">
          <div class="seller mr-2">
            <h3>{{ vehicle.em_nome }}</h3>
            <div class="rating">
              <v-rating
                v-bind:model-value="vehicle.em_nota_reviews"
                size="x-small"
                density="compact"
                color="#FF7A00"
                half-increments
                disabled
                readonly
              ></v-rating>
            </div>
          </div>
          <CardTimer
            v-if="
              buyerScope === 'InProgress' ||
              buyerScope === 'OnDemandAssesmentInProgress'
            "
            :dt_ts_final="vehicle.dt_ts_final"
          />
          <div
            class="date"
            v-if="
              buyerScope != 'InProgress' &&
              buyerScope != 'OnDemandAssesmentInProgress'
            "
          >
            <img :src="require('@/assets/icons/calendar-icon.svg')" />
            <span class="mt-2">{{ formatDate(vehicle.datacadastro) }}</span>
          </div>
          <a
            class="vehicle-detail ml-2"
            :href="link"
            target="_blank"
            v-if="isShowLinkForVdp"
            >Visualizar Veículo →</a
          >
          <a
            class="ml-2"
            :href="vehicle.v_laudo ? vehicle.v_laudo : '#'"
            :target="vehicle.v_laudo ? '_blank' : ''"
            :class="['report', vehicle.v_laudo ? 'active' : 'disabled']"
            :id="`area_comprador_veiculo_laudo_${vehicle.ava_id}`"
            v-else
            >Laudo do veículo</a
          >
        </div>
      </div>
      <div class="action-buttons" v-if="actions.length">
        <span class="actions-label">Botões de Ação</span>
        <VehicleActionButtons :metadata="actions" :vehicle="vehicle" />
      </div>
    </div>
  </div>
  <div class="vehicle-detail-page" v-else-if="isPackage && !loading">
    <BuyerSectionItem
      :icon="icon"
      :title="title"
      :description="description"
      :shouldReturnToTheBuyerPage="true"
    />
    <div class="vehicle-detail-content mt-1">
      <div class="card">


        <v-carousel
            height="auto"
            show-arrows="show-arrows"
            hide-delimiter-background
            hide-delimiters
            :continuous="false"
        >
          <template v-slot:prev="{ props }">
            <span
                class="arrow previous"
                @click="props.onClick"
                :style="isMobile() ? 'top: 135px' : ''"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </span>
          </template>
          <template v-slot:next="{ props }">
            <span
                class="arrow next"
                @click="props.onClick"
                :style="isMobile() ? 'top: 135px' : ''"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </span>
          </template>

          <v-carousel-item
              v-for="(vehicle, i) in vehiclePackages"
              :key="i"
          >

        <div class="card-image">
          <img
            :src="
              vehicle.foto === null
                ? require('@/assets/images/VehicleItemImage.png')
                : vehicle.foto
            "
            :alt="'imagem carro ' +  capitalize(`${vehicle.v_marca} ${vehicle.v_modelo}`)"
            width="100%"
            style="height: 234px"
          />
          <div class="card-image-overlay"></div>
          <img
            :src="
              isFavorite
                ? require('@/assets/icons/favorite-icon-active.svg')
                : require('@/assets/icons/favorite-icon.svg')
            "
            class="favorite"
            v-if="showIconFavorite(buyerScope)"
            @click="handleSetFavorite(vehicle.ava_id)"
          />
          <span class="city">{{ vehicle.cidade }}/{{ vehicle.uf }}</span>
          <div class="price-content">
            <span class="description"
              >{{
                priceDescription ? priceDescription : "Você comprou por"
              }}&nbsp;</span
            >
            <span class="price">R${{ getPriceBase(vehicle.valor) }},00</span>
          </div>
        </div>

        <div class="card-content">
          <div class="left">
            <h1>{{ capitalize(`${vehicle.v_marca} ${vehicle.v_modelo}`) }}</h1>
            <h2>{{ capitalize(vehicle.v_versao) }}</h2>
            <div class="status">
              <div class="status-item mr-1">
                <img
                  class="mr-2"
                  :src="require('@/assets/icons/calendar-alt-icon.svg')"
                />
                <span>{{ `${vehicle.v_anof}/${vehicle.v_anom}` }}</span>
              </div>
              <div class="status-item mr-2">
                <img
                  class="mr-1"
                  :src="require('@/assets/icons/speed-meter-icon.svg')"
                />
                <span>{{ vehicle.v_km }}</span>
              </div>
              <div class="status-item mr-2">
                <img
                  class="mr-1"
                  :src="require('@/assets/icons/license-plate-icon.svg')"
                />
                <span>{{ formatLicense(vehicle.v_placa) }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="fipe-content" v-if="vehicle.percentual_fipe !== null">
              <VTooltip placement="top-start" max-width="20px" width="20px">
                <span class="fipe-container">
                  <span class="fipe">{{
                    parseInt(Math.abs(vehicle.percentual_fipe - 100))
                  }}</span>
                  <span class="percent">%</span>
                  <img :src="require('@/assets/icons/fipe-icon.svg')" />
                </span>

                <template #popper>
                  {{
                    `${parseInt(
                      Math.abs(vehicle.percentual_fipe - 100)
                    )}% do VALOR FIPE`
                  }}
                </template>
              </VTooltip>
            </div>
            <div class="status">
              <VTooltip
                placement="top-start"
                max-width="20px"
                width="20px"
                v-if="vehicle.v_laudo !== ''"
              >
                <span>
                  <img
                    :src="require('@/assets/icons/report-icon.svg')"
                    alt="Veículo contém laudo aprovado"
                  />
                </span>

                <template #popper> Veículo contém laudo aprovado </template>
              </VTooltip>
            </div>
          </div>
        </div>
          </v-carousel-item>
        </v-carousel>

        <div class="card-bottom">
          <div class="seller mr-2">
            <h3>{{ vehicle.em_nome }}</h3>
            <div class="rating">
              <v-rating
                v-bind:model-value="vehicle.em_nota_reviews"
                size="x-small"
                density="compact"
                color="#FF7A00"
                half-increments
                disabled
                readonly
              ></v-rating>
            </div>
          </div>
          <CardTimer
            v-if="
              buyerScope === 'InProgress' ||
              buyerScope === 'OnDemandAssesmentInProgress'
            "
            :dt_ts_final="vehicle.dt_ts_final"
          />
          <div
            class="date"
            v-if="
              buyerScope != 'InProgress' &&
              buyerScope != 'OnDemandAssesmentInProgress'
            "
          >
            <img :src="require('@/assets/icons/calendar-icon.svg')" />
            <span class="mt-2">{{ formatDate(vehicle.datacadastro) }}</span>
          </div>
          <a
            class="vehicle-detail ml-2"
            :href="link"
            target="_blank"
            v-if="isShowLinkForVdp"
            >Visualizar Pacote →</a
          >
          <a
            class="ml-2"
            :href="vehicle.v_laudo ? vehicle.v_laudo : '#'"
            :target="vehicle.v_laudo ? '_blank' : ''"
            :class="['report', vehicle.v_laudo ? 'active' : 'disabled']"
            :id="`area_comprador_veiculo_laudo_${vehicle.ava_id}`"
            v-else
            >Laudo do veículo</a
          >
        </div>
      </div>
      <div class="action-buttons" v-if="actions.length">
        <span class="actions-label">Botões de Ação</span>
        <VehicleActionButtons :metadata="actions" :vehicle="vehicle" />
      </div>
    </div>
  </div>
  <div class="vehicle-detail-page" v-else-if="loading">
    <p>
      Carregando ...
    </p>
  </div>
  <QuickViewModal />
  <ReleasePaymentViewModal />
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { getLinkForVdp } from "@/helpers/vdp";

import VehicleCardMixin from "../mixins/VehicleCardMixin";
import BuyerSectionItem from "../components/BuyerSectionItem.vue";
import VehicleActionButtons from "../components/VehicleCard/components/VehicleActionButtons.vue";
import CardTimer from "../components/CardTimer";
import QuickViewModal from "../components/MakeEvaluationViewModal.vue";
import ReleasePaymentViewModal from "../components/ReleasePaymentViewModal.vue";
import {getVehiclesByIdInPackage} from "@/services/api/BuyerServiceApi";
import PlatformsMixins from "@/mixins/PlatformsMixins";

export default {
  name: "VehicleDetailPage",
  mixins: [VehicleCardMixin, PlatformsMixins],
  components: {
    BuyerSectionItem,
    VehicleActionButtons,
    CardTimer,
    QuickViewModal,
    ReleasePaymentViewModal,
  },
  data: () => ({
    stepPackagePhoto: 0,
    vehiclePackages: [],
    loading: false,
  }),
  computed: {
    ...mapState({
      title: (state) => state.buyer.titlePage,
      description: (state) => state.buyer.descriptionPage,
      icon: (state) => state.buyer.iconPage,
      vehicle: (state) => state.buyer.vehicleDetail,
      priceDescription: (state) => state.buyer.priceDescriptionPage,
      buyerScope: (state) => state.buyer.buyerScope,
      vehicleDetailIsFavorite: (state) => state.buyer.vehicleDetailIsFavorite,
      isActive: (state) => state.buyerLotDetail.isActive,
    }),
    link: function () {
      return getLinkForVdp(this.vehicle);
    },
    isShowLinkForVdp() {
      return this.title === "Em andamento";
    },
    isPackage() {
      return this.vehicle.qtdVeic > 1;
    },
    actions() {
      return this.actionsByBuyerScope(this.buyerScope);
    },
    fotosPackage() {
      if (this.vehicle.foto === null) {
        return [];
      }

      return this.vehicle.foto.split(",");
    },
    foto() {
      if (this.isPackage) {
        if (this.vehicle.foto === null) {
          return null;
        }

        return this.fotosPackage[this.stepPackagePhoto];
      }

      return this.vehicle.foto;
    },
    priceContentBackground() {
      return this.isActive || this.buyerScope === "PaymentPending"
        ? "#0dc561"
        : "rgb(242, 35, 0)";
    },
  },
  methods: {
    ...mapMutations("buyer", ["setThirdTitlePage"]),
    setStepPackagePhoto(stepPackagePhoto) {
      this.stepPackagePhoto = stepPackagePhoto;
    },
    previousStepPackagePhoto() {
      if (this.stepPackagePhoto - 1 >= 0) {
        this.setStepPackagePhoto(this.stepPackagePhoto - 1);
        return;
      }
      this.setStepPackagePhoto(this.fotosPackage.length - 1);
    },
    nextStepPackagePhoto() {
      if (this.stepPackagePhoto + 1 < this.fotosPackage.length) {
        this.setStepPackagePhoto(this.stepPackagePhoto + 1);
        return;
      }

      this.setStepPackagePhoto(0);
    },
    async getVehiclesPackage() {
      const id_avaliacao = this.vehicle.ava_id;
      this.loading = true;
      try {
        let response = await getVehiclesByIdInPackage(id_avaliacao);
        if (response && response?.length > 0) {
          this.vehiclePackages = response;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.$emitter.on("refreshEvent", (from) => {
      if (from === "ReleasePaymentViewModal") {
        this.$router.go(-1);
      }
    });

    if (this.isPackage) {
      await this.getVehiclesPackage();
    }
  },
  unmounted() {
    this.setThirdTitlePage("");
  },
};
</script>

<style lang="scss" scoped>
a.report {
  &.active {
    font-weight: 400;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}
.vehicle-detail-page {
  display: flex;
  flex: 76;
  flex-direction: column;
  height: 105vh !important;

  .vehicle-detail-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    flex-direction: column;
    .card {
      padding: 8px 4px;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
      width: 100%;
      flex-shrink: 0;

      .card-image {
        position: relative;
        display: flex;

        img {
          width: 100%;
          flex-shrink: 0;
          border-radius: 12px;
          border: 7px solid #fff;
        }

        .card-image-overlay {
          width: 97.2%;
          height: 100%;
          position: absolute;
          border-radius: 12px;
          background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 49.66%);
          top: 0;
          left: 4.5px;
        }

        .favorite {
          position: absolute;
          top: 12px;
          right: 12px;
          cursor: pointer;
          z-index: 1;
          width: 28px;
          height: 26px;
          border-radius: 0;
          border: 0;
        }

        .city {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          position: absolute;
          top: 10px;
          left: 12px;
        }

        .price-content {
          border-radius: 5px;
          background: v-bind(priceContentBackground);
          position: absolute;
          bottom: 0;
          right: 16px;
          padding: 4px 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          .description {
            color: #fff;
            font-family: "Lexend Deca";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .price {
            color: #fff;
            font-family: "Lexend Deca";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .nav-bullets {
          position: absolute;
          z-index: 100;
          bottom: 24px;
          right: 14px;
          i {
            cursor: pointer;
            margin: 3px;
            color: #000f;
            text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white,
              0 0 2px white;
            display: inline-block;
            width: 1em;
            height: 1em;
            border: 1px solid #ff7a00;
            border-radius: 1em;
            &.active {
              color: #fff;
              background-color: #fff;
              &:hover {
                background-color: #fff;
              }
            }
          }
        }

        .arrow {
          position: absolute;
          border: 1px solid #ff7a00;
          background-color: #ffffff79;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          color: #ff7a00;
          top: 50%;
          transform: translateY(-50%);
        }

        .previous {
          left: -8px;
        }

        .next {
          right: -8px;
        }
      }

      .card-content {
        display: flex;
        padding: 8px 12px;

        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          h1 {
            color: #373737;
            font-family: "Lexend Deca";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          h2 {
            color: #373737;
            font-family: "Lexend Deca";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .status {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .status-item {
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                opacity: 0.7;
                mix-blend-mode: luminosity;
              }

              span {
                color: #696969;
                font-family: "Lexend Deca";
                font-size: 9px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }

        .right {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .fipe-content {
            position: relative;

            .fipe-container {
              position: relative;
            }
            .percent {
              position: absolute;
              z-index: 100;
              font-family: "Lexend Deca";
              font-style: normal;
              font-weight: 700;
              font-size: 7px;
              letter-spacing: -0.11em;
              margin-top: 12px;
              margin-left: 11px;

              color: #ffffff;
            }
            .fipe {
              position: absolute;
              z-index: 100;
              font-family: "Lexend Deca";
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              letter-spacing: -0.11em;
              margin-left: 7.8px;

              color: #ffffff;
            }
          }
        }
      }

      .card-bottom {
        display: flex;
        padding: 8px;

        .seller {
          padding: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;

          h3 {
            color: #373737;
            text-align: center;
            font-family: "Lexend Deca";
            font-size: 9px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          h4 {
            color: #373737;
            text-align: center;
            font-family: "Lexend Deca";
            font-size: 9px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .date {
          flex: 1;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 20px;

          img {
            width: 18px;
            height: 18px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%; /* 13.02px */
          }
        }

        .report {
          flex: 1;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 8px;
          text-align: center;
          text-decoration: none;
        }

        .vehicle-detail {
          border-radius: 7px;
          border: 1px solid #ff7a00;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          text-align: center;
          color: #ff7a00;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }
      }
    }

    .action-buttons {
      border-top: thin solid #ececec;
      margin-top: 25px;
      padding-top: 20px;
      position: relative;

      .actions-label {
        font-family: "Lexend Deca";
        font-weight: 400;
        color: #b3b3b3;
        background-color: #fafafa;
        position: absolute;
        font-size: 12px;
        padding: 0 10px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 112px;
        top: -10px;
      }

      .action-buttons-wrapper {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
      }
      .action-buttons-wrapper::v-deep .action-button {
        width: 48% !important;
      }
    }
  }
}

.arrow {
  position: absolute;
  border: 1px solid #ff7a00;
  background-color: #ffffff79;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #ff7a00;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.previous {
  left: 0;
}

.next {
  right: 0;
}
</style>
