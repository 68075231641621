import getFavorites, { getAutoBidsFavorite, getDealersFavorite } from "@/services/api/FavoriteServiceApi";

const favoriteModule = {
  namespaced: true,
  state() {
    return {
      vehicles: [],
      autobid: [],
      dealers: [],
      maxVehicleItens: 8,
      maxAutoBidItens: 8,
      maxDealersItens: 8,
    };
  },
  mutations: {
    setResult(state, result) {
      state.vehicles = result;
    },
    setDealersResult(state, result) {
      state.dealers = result;
    },
    setMaxVehicleItens(state, maxVehicleItens) {
      state.maxVehicleItens = maxVehicleItens;
    },
    setMaxAutoBidItens(state, maxAutoBidItens) {
      state.maxAutoBidItens = maxAutoBidItens;
    },
    setMaxDealersItens(state, maxDealersItens) {
      state.maxDealersItens = maxDealersItens;
    },
    setAutoBidResult(state, response) {
      if(response.data && response.data.result) {        
        state.autobid = response.data.result.activeBids ?? [];
      }
    },
},
actions: {
  async getFavorites({ commit }, key) {
    let response = await getFavorites(key);
    commit("setResult", response);
  },
  async getAutoBidsFavorite({ commit }, key) {
    let response = await getAutoBidsFavorite(key);
    commit("setAutoBidResult", response);
  },
  async getDealersFavorite({ commit }, key) {
    let response = await getDealersFavorite(key);
    commit("setDealersResult", response);
  }
}
}

export default favoriteModule;
