<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Anúncios não concretizados em compra (4)"
    :icon="icon"
    :section="section"
    :description="description"
    :vehicles="vehicles"
    labelCard="Sua última avaliação"
    priceBackgroundColor="#F22300"
    parent="AnnouncementUnfulfilled"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "AnnouncementUnfulfilledPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Anúncios",
      section: "Encerrados",
      vehicles: [
        {
          marca: "Ford Fiesta",
          versao: "1.6 Titanium Sedan 16v Flex 4p Powershift",
          fipe: 20,
          foto: "https://cdn.autoavaliar.com/veic_810811_0.jpg?1684169357",
          anof: "2020/2020",
          date: "27/02/2023",
          price: "35.200",
        },
        {
          marca: "Volkswagen Up",
          versao: "1.0 Mpi Total Flex 4p Manual",
          fipe: 88,
          foto: "https://autoavaliar-dev.storage.googleapis.com/6045d2d8f019d47b2bd3fcaa6329f42b-blob.jpg",
          anof: "2020/2020",
          date: "12/07/2023",
          price: "66.000",
        },
        {
          marca: "Honda Civic",
          versao: "2.0 Ecoboost Gasolina Lariat FX4",
          fipe: 10,
          foto: "https://storage.googleapis.com/photo-b2b/veic-94c64adcbf529de4cc9ba7337ba5de1613c1644e-1.jpg?1619437433",
          anof: "2020/2020",
          date: "27/02/2023",
          price: "254.200",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
