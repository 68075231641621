<template>
  <section id="credit-letter-home-page">
    <div class="top">
      <span class="brand"> Novidade </span>
      <div v-if="!walletCompact">
        <h1>Saldo disponível para taxas</h1>
        <h2>
          Agora você poderá pagar taxas dos seus veículos comprados, utilizando
          saldo disponível em conta!
        </h2>
      </div>
      <WalletCard v-if="!walletCompact" type="dashboard" linkText="Entenda esse valor →" hideLabel  @click="toggleDetailsStep" />
      <WalletCard v-if="walletCompact" type="compact"  hideLabel  @click="toggleDetailsStep" />
    </div>

    <v-container  v-if="isDetailsStep" > 
        <DetailsStep/>    
    </v-container>

    <div class="content" v-if="!isDetailsStep">
      <h3 v-if="!isWalletBuyPartialStep">
        Veículos elegíveis para uso do saldo
        {{ !loadingVehicles ? `(${vehicles.length})` : "" }}
      </h3>

      <v-row>
        <v-col>
          <v-skeleton-loader
            type="card"
            width="100%"
            class="mt-4"
            v-if="loadingVehicles"
          ></v-skeleton-loader>
          <div v-if="!loadingVehicles" :class="!isWalletBuyPartialStep ? 'mt-4' : ''">
            <div class="vehicles" v-for="(vehicle, key) in vehicles" :key="key" @click="toggleActions(vehicle.ava_id)">
              <VehicleCard
                v-if="(step === walletBuyPartialStep && vehicle.ava_id == vehicleId) || vehicleId == null"
                :actionsOpen="(step === 'HOME_STEP') && (openActionsVehicleId == vehicle.ava_id)"
                :id="'vehiclecard_' + vehicle.ava_id"
                :vehicle="vehicle"
                label="Você comprou por"
                :priceBackgroundColor="priceBackgroundColor"
                buyerScope="LetterOfCreditEligible"
                :class="!isWalletBuyPartialStep ? 'mt-4' : ''"
                />
              <div class="v-container" v-if="step === 'WALLET_BUY_PARTIAL_STEP' && vehicle.ava_id == vehicleId">
                <WalletBuyPartialStep @finished="getVehicles()" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>  
</template>

<script>
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
import { mapState, mapMutations, mapActions } from "vuex";

import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import DetailsStep from "../../components/WalletModal/components/DetailsStep.vue";
import WalletCard from "../../components/WalletCard.vue";
import WalletModalMixin from "../../mixins/WalletModalMixin";
import WalletBuyPartialStep from "../../components/WalletModal/components/WalletBuyPartialStep.vue";
import VehicleCard from "../../components/VehicleCard";
import { WALLET_BUY_PARTIAL_STEP, DETAILS_STEP, HOME_STEP } from "@/store/modules/walletModalModule";

export default {
  name: "CreditLetterHomePage",
  mixins: [WalletModalMixin, BuyerComponentPagesMixins],
  data: () => ({
    title: "Saldo disponível",
    openActionsVehicleId: null,
  }),
  components: {
    VSkeletonLoader,
    WalletCard,
    WalletBuyPartialStep,
    DetailsStep,
    VehicleCard,
  },
  computed: {
    ...mapState({
      loadingVehicles: (state) => state.wallet.loadingVehicles,
      vehicles: (state) => state.wallet.vehicles,
      step: (state) => state.walletModal.step,
      vehicleId: (state) => state.walletModal.vehicle ? state.walletModal.vehicle.ava_id : null,
      modalIsOpen: (state) => state.walletModal.modalIsOpen,
    }),
    walletCompact() {
      return this.step === 'WALLET_BUY_PARTIAL_STEP';
    },
    isDetailsStep() {
      return this.step === DETAILS_STEP;
    },
    walletBuyPartialStep() {
      return WALLET_BUY_PARTIAL_STEP;
    }
  },
  methods: {
    ...mapActions("wallet", ["getVehicles"]),
    ...mapMutations("walletModal", ["toggleModal", "openModal", "setStep"]),
    toggleActions(vehicleId) {
      if (this.openActionsVehicleId == vehicleId) {
        this.openActionsVehicleId = null;
        return;
      }
      this.openActionsVehicleId = vehicleId;
    },
    toggleDetailsStep() {
      if (this.step === HOME_STEP) {
        this.setStep(DETAILS_STEP);
        return;
      }
      this.setStep(HOME_STEP);
    },
  },
  mounted() {
    this.getVehicles();
    document.getElementById("buyer-result-app").scrollTo({
      top: 0,
    });

    this.$emitter.on("refreshCreditLetterList", () => {
      this.getVehicles();
    });
  },
  unmounted() {
    this.setStep(HOME_STEP);
  }
};
</script>

<style lang="scss" scoped>
#credit-letter-home-page {
  padding: 0;

  .top {
    position: relative;
    margin-top: 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 12px 8px;

    .brand {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #f22300;
      border-radius: 9px;
      padding: 1px 12px;
      font-family: "Lexend Deca";
      color: #fff;
      font-size: 10px;
      font-weight: 900;
      line-height: 12px;
      letter-spacing: 0.275em;
      text-align: left;
      text-transform: uppercase;
    }

    h1 {
      text-align: center;
      margin-top: 16px;
      font-family: "Lexend Deca";
      font-size: 24px;
      font-weight: 700;
      line-height: 23.4px;
      text-align: center;
    }

    h2 {
      font-family: "Lexend Deca";
      margin-top: 16px;
      margin-bottom: 24px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16.12px;
      text-align: center;
    }
  }

  .content {
    margin-top: 12px;

    h3 {
      font-family: "Lexend Deca";
      font-size: 13px;
      font-weight: 700;
      line-height: 12.68px;
      text-align: center;
    }
  }
}
</style>
