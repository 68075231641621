<script>
import { watch, ref, reactive, computed } from "vue";
import { mapMutations } from "vuex";
import { autoComplete } from "@/services/api/SerchServiceApi";
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import { getLinkForVdp } from "@/helpers/vdp";
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  mixins: [NavigateMixings],
  setup() {
    const waitingTime = 800;

    let result = reactive({ data: [] });
    let term = ref("");
    let resultBox = ref(false);
    let isSelected = ref(false);
    let isLoading = ref(false);

    let setTime = 0;

    watch(term, (newValue, oldValue) => {
      sendTerm(newValue, oldValue);
    });

    const isMobile = computed(() => {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    });

    const sendTerm = (newValue, oldValue) => {
      clearTimeout(setTime);
      if (term.value.length >= 3) {
        setTime = setTimeout(() => {
          isLoading.value = true;
          searchInAPI();
        }, waitingTime);
      }
      if (newValue.length < oldValue.length && term.value.length === 0)
        clearTerm();
    };

    const clearTerm = () => {
      term.value = "";
      result.data = [];
      resultBox.value = false;
    };

    const searchInAPI = async () => {
      try {
        const { data } = await autoComplete(term.value);
        result.data = data.payload;
        resultBox.value = true;
        isLoading.value = false;
      } catch (error) {
        resultBox.value = false;
        isLoading.value = false;
        closeSuggestions();
        return;
      }
    };

    const closeSuggestions = () => {
      isSelected.value = false;
      term.value = "";
    };

    const goTo = (url) => {
      parent.window.location.href = url;
      closeSuggestions();
    };

    const goToVdp = (vehicle) => {
      const url = getLinkForVdp({
        id_avaliacao: vehicle.id,
        v_marca: vehicle.marca,
        v_modelo: vehicle.model,
        v_versao: vehicle.version,
      });

      return goTo(url);
    };

    return {
      result,
      term,
      resultBox,
      isSelected,
      isLoading,
      isMobile,
      sendTerm,
      clearTerm,
      closeSuggestions,
      goToVdp,
      goTo,
    };
  },
  methods: {
    ...mapMutations("filterForm", ["setParam"]),
    changeQuery: function (newQuery) {
      this.setParam({ query: newQuery });
      this.term = newQuery;
      this.closeSuggestions();
    },
    navigateFor(url) {
      if (url.length > 1) {
        this.navigatePortalTo(url.slice(1) + "?desativar_novos_filtros=1");
      }
    }
  },
};
</script>

<template>
  <div :class="['content-search', { mobile: isMobile }]">
    <div :class="['content-input', { selected: isSelected }]">
      <div :class="['input-search', { selected: isSelected }]">
        <input
          type="text"
          name="term"
          id="term"
          class="search-vehicle"
          @keyup.enter="changeQuery(term)"
          v-model="term"
          :placeholder="
            isSelected ? '' : 'Pesquise por veículos ou anunciantes'
          "
          @click="isSelected = true"
        />
      </div>
      <div
        :class="['button-search', { selected: isSelected }]"
        @click="changeQuery(term)"
      >
        <span class="icon">
          <v-icon icon="mdi-magnify"></v-icon>
        </span>
      </div>
    </div>
    <div class="ballum" v-if="isSelected">
      <div class="content-suggestions">
        <div class="content-header">
          <div class="close" @click="closeSuggestions">
            <v-icon icon="mdi-close"></v-icon>
          </div>
          <div>
            <span v-if="!term">Pesquise por veículos ou anunciantes.</span>
            <div v-if="isLoading" class="loader"></div>
          </div>
        </div>
        <div class="content-result" v-if="resultBox">
          <div>
            <template v-if="result.data.total === 0">
              Nenhum resultado para pesquisa
              <strong>{{ result.data.queries.raw }}!</strong>
            </template>
            <template v-else>
              <div
                class="suggestion-line"
                @click="changeQuery(term)"
              >
                <div>
                  <strong>Buscar:</strong> {{ term }}
                </div>
                <v-icon icon="mdi-arrow-right-thick" size="small"></v-icon>
              </div>
              <div>
                <div
                  v-for="(entity, index) in result.data.entities"
                  :key="index"
                >
                  <div
                    class="suggestion-line"
                    @click="navigateFor(entity.url)"
                  >
                    <div class="content-image">
                      <img class="logo mr-1" :src="entity.logo" alt="" />
                      <strong>{{ entity.name }}</strong>
                    </div>
                    <v-icon icon="mdi-arrow-right-thick" size="small"></v-icon>
                  </div>
                </div>
              </div>
              <div>
                <div
                  v-for="(query, index) in result.data.queries.q"
                  :key="index"
                >
                  <div
                    class="suggestion-line"
                    @click="
                      changeQuery(
                        `${term} ${query.text}`.toLowerCase()
                      )
                    "
                  >
                    <div class="content-query">
                      <strong>{{ result.data.queries.raw }}</strong>
                      {{ query.text }}
                    </div>
                    <v-icon icon="mdi-arrow-right-thick" size="small"></v-icon>
                  </div>
                </div>
              </div>
              <div class="content-vehicle">
                <div
                  v-for="(vehicle, index) in result.data.vehicles"
                  :key="index"
                >
                  <div class="card-vehicle" @click="goToVdp(vehicle)">
                    <div class="image">
                      <img :src="vehicle.image" alt="" />
                    </div>
                    <div class="model">{{ vehicle.model }}</div>
                    <div class="version">{{ vehicle.version }}</div>
                    <div class="price">R$ {{ vehicle.price }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-search {
  margin-left: 10px;
  width: 100%;
  position: static;
  font-family: "Lexend Deca";
}

.content-search.mobile {
  margin-left: 0;
}

.content-search .content-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
}

.content-search .content-input.selected {
  box-shadow: 1px 2px 5px #d6d6d6;
}

.content-search .content-input .input-search {
  width: calc(100% - 50px);
  border: solid 1px #d5d5d5;
  border-right: transparent;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.content-search .content-input .input-search.selected {
  border: none;
}

.content-search .content-input .input-search input {
  width: 100%;
  height: 48px;
  padding-left: 15px;
  background-color: white;
  border: none;
}

.content-search .content-input .input-search input:focus {
  outline: none;
}

::placeholder {
  font-family: "Lexend Deca";
  font-size: 14px;
  color: gray;
}

.content-search .content-input .button-search {
  width: 55px;
  border: solid 1px #fdad07;
  background-color: #fdad07;
  height: 50px;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  display: flex;
  color: white;
  padding-left: 4px;
  cursor: pointer;
}

.content-search .content-input .button-search:hover {
  background-color: white;
  border-color: #d5d5d5;
  border-left: none;
}

.content-search .content-input .button-search:hover .icon {
  color: #fdad07;
}

.content-search .content-input .button-search .icon {
  transform: rotate(90deg);
  font-size: 28px;
}

.content-search .content-input .button-search.selected {
  border: solid 1px transparent;
  background-color: white;
}

.content-search .content-input .button-search.selected:hover {
  background-color: #fdad07;
  border-color: #fdad07;
}

.content-search .content-input .button-search.selected:hover .icon {
  color: white;
}

.content-search .content-input .button-search.selected .icon {
  color: #fdad07;
}

.content-search .content-suggestions {
  position: absolute;
  background: white;
  border: solid 1px #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 2px 4px 13px #d6d6d6;
  width: 100%;
}

.content-search.mobile .content-suggestions {
  min-width: 100%;
  max-width: 100%;
}

.content-search .content-suggestions .content-header .close i {
  color: #a4a4a480;
}
.ballum {
  position: relative;
}

.ballum:after {
  /*Triangulo*/
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
  top: -1;
  left: 1%;
  z-index: 1150;
}

.content-search .content-suggestions .content-header {
  display: flex;
  flex-direction: column;
}

.content-search .content-suggestions .content-header .close {
  display: flex;
  align-items: end;
  justify-content: end;
  color: #474747;
  cursor: pointer;
  border-bottom: solid 1px #eeeeee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.content-search .content-suggestions .content-header .close i {
  margin-right: 2px;
}

.content-search .content-suggestions .suggestion-line {
  height: 45px;
  padding: 0 2px 0 4px;
  border-bottom: solid 1px #eeeeee;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-search .content-suggestions .suggestion-line .content-image {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.content-search .content-suggestions .suggestion-line .content-query strong {
  text-transform: uppercase;
}

.content-search .content-suggestions .suggestion-line .logo {
  width: 35px;
  height: auto;
}

.content-search .content-suggestions .suggestion-line:hover {
  background: #eeeeee;
}

.content-search .content-suggestions .content-vehicle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.content-search .content-suggestions .content-vehicle .card-vehicle {
  width: 160px;
  border: solid 1px #eeeeee;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 5px;
}

.content-search.mobile .content-suggestions .content-vehicle .card-vehicle {
  width: 150px;
}

.content-search .content-suggestions .content-vehicle .card-vehicle:hover {
  box-shadow: 1px 2px 5px #d6d6d6;
  cursor: pointer;
}

.content-search .content-suggestions .content-vehicle .card-vehicle .image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 110px;
}

.content-search .content-suggestions .content-vehicle .card-vehicle .image img {
  max-width: 150px;
  max-height: 110px;
  border-radius: 5px;
  overflow: hidden;
}

.content-search .content-suggestions .content-vehicle .card-vehicle .model {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.content-search .content-suggestions .content-vehicle .card-vehicle .version {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 8px;
  font-weight: 100;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-search .content-suggestions .content-vehicle .card-vehicle .price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.content-suggestions {
  max-height: 80vh;
}

.content-result {
  overflow-y: auto;
  padding: 0 4px;
}

.content-result::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.content-result::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

@media screen and (min-width: 1660px) {
  .content-search .content-input {
    max-width: 380px;
  }
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #fdad07;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
