<template>
  <div class="page-under-construction">
    <p>Página: <b>`{{ titlePage }}`</b> em construção...</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: String,
  },
  computed: {
    ...mapState({
      titlePage: (state) => state.buyer.titlePage,
    }),
  }
};
</script>

<style scoped>
.page-under-construction {
  flex: 78;
  padding: 1.5em;
  justify-content: center;
  padding-right: 25px;
  overflow: hidden;
}

.page-under-construction p {
  color: #373737;
  font-family: 'Lexend Deca';
  font-size: 26px;
  font-style: normal;
  line-height: normal;
}
</style>
