<template>
  <TopApp />
  <div class="app-content">
    <FilterApp />
    <div v-if="!isMobile()" id="favorite-result-app">
      <h3>
        <i class="fa fa-heart-o"></i>
        <span class="favoriteTitle">Meus veículos favoritos</span> - 
        <span class="qtdItens">{{ vehicles.length }} favoritos</span>
      </h3>
      <v-container
        class="container-list"
        v-if="vehicles.length > 0"
        v-bind:key="vehicles.length"
        fluid
      >
        <v-row>
          <v-col
            v-for="(vehicle, index) in vehicles"
            v-bind:key="vehicle.ava_id"
            v-bind:index="index"
            sm="6"
            md="4"
            lg="3"
            xl="3"
            xxl="2"
          >
            <VehicleItem v-if="index < maxVehicleItens" :vehicle="vehicle" :index="index" :linha="index" />
          </v-col>
        </v-row>
        <div v-if="vehicles.length > 8" class="more" @click="seeMoreVehicleItens()">Veja mais</div>
      </v-container>
      <div v-else class="message">Você ainda não tem favoritos</div>
      <hr/>
      <h3 class="topicTitle">
        <i class="fa fa-clock-o"></i>
        <span class="favoriteTitle">AutoBID</span> - 
        <span class="qtdItens">{{ vehiclesAutobid.vehicles?.length }} ativos</span>
      </h3>
      <v-container
          v-if="vehiclesAutobid.vehicles?.length > 0"
          fluid
        >
          <v-row>
            <v-col
              v-for="(vehicle, index) in vehiclesAutobid.vehicles"
              v-bind:key="vehicle.id_avaliacao"
              v-bind:index="index"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              xxl="2"
            >
              <VehicleItem :vehicle="vehicle" :index="index" :linha="index" :show-autobid-info="true" />
            </v-col>
          </v-row>
          <div v-if="vehiclesAutobid.vehicles.length > 8" class="more" @click="seeMoreAutoBidItens()">Veja mais</div>
          <div id="footerSpace"/>
      </v-container>
      <div v-else class="message">Você ainda não tem anúncios ativos</div>
      <hr/>
      <h3 class="topicTitle">
        <i class="fa fa-heart-o"></i>
        <span class="favoriteTitle">Meus anunciantes favoritos - </span>
        <span class="qtdItens">{{ dealers.length }} favoritos</span>
      </h3>
      <v-container
          class="container-list"
          v-if="dealers.length > 0"
          fluid
        >
          <v-row class="dealer-list">
            <v-col
              v-for="(dealer, index) in dealers"
              v-bind:key="dealer.id_empresa"
              v-bind:index="index"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              xxl="2"
            >
              <DealerItem :dealer="dealer" :index="index" :linha="index" />
            </v-col>
          </v-row>
        </v-container>
        <div v-else class="message">Você ainda não tem favoritos</div>
        <div id="footerPage"/>
    </div>
    <div v-else id="favorite-result-app">
      <FavoritesMobile />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import TopApp from "@/views/showcase/components/TopApp";
import FilterApp from "@/views/favorites/components/FilterApp.vue";
import VehicleItem from "@/components/VehicleItem/index.vue";
import DealerItem from "@/components/DealerItem/index.vue";
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import FavoritesMobile from "./components/FavoritesMobile.vue";

export default {
  name: "FavoriteView",
  components: {
    TopApp,
    FilterApp,
    VehicleItem,
    DealerItem,
    FavoritesMobile,
  },
  mixins: [PlatformsMixins],
  computed: {
    ...mapState({
      vehicles: (state) => state.favorite.vehicles ?? [],
      vehiclesAutobid: (state) => state.favorite.autobid ?? [],
      dealers: (state) => state.favorite.dealers ?? [],
      maxVehicleItens: (state) => state.favorite.maxVehicleItens,
      maxAutoBidItens: (state) => state.favorite.maxAutoBidItens,
    }),
  },
  methods: {
    ...mapActions("favorite", ["getFavorites", "getAutoBidsFavorite", "getDealersFavorite"]),
    isMobile() {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
    seeMoreVehicleItens() {
      this.$store.commit("favorite/setMaxVehicleItens", this.maxVehicleItens + 8);
    },
    seeMoreAutoBidItens() {
      this.$store.commit("favorite/setMaxAutoBidItens", this.maxAutoBidItens + 8);
    },
    seeMoreDelerItens() {
      this.$store.commit("favorite/setMaxDealersItens", this.maxDealersItens + 8);
    }
  },
  mounted: function () {
    this.getFavorites();
    this.getAutoBidsFavorite();
    this.getDealersFavorite();
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: v-bind(slideLeaveActive);
}
.slide-enter-from,
.slide-leave-to {
  opacity: v-bind(slideLeaveToOpacity);
  transform: v-bind(slideLeaveToTransform);
}
.message {
  padding: 12px;
  color: #333;
  margin: 4px;
  text-align: center;
  font-family: "Lexend Deca", Helvetica, Arial;
  color: #373737;
}
.topicTitle {
  margin-top: 18px;
}
.favoriteTitle {
  font-size: 24px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.qtdItens {
  font-size: 16px;
}
.more {
  margin: 10px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Lexend Deca", Helvetica, Arial;
  color: #373737;
}

#favorite-result-app {
  flex: 78;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 30px;
  height: v-bind(contentHeightWithButtonFooter);
  color: #555;
  &.mobile{
    padding-left: 0;
    padding:0 .5em;
  }
}

#footerSpace {
  height: 40px;
}

#footerPage {
  height: 120px;
}


@media screen and (max-width: 768px) {
  #favorite-result-app {
    flex: auto;
    padding: 0px;
    width: 100%;
  }
  #favorite-result-app h3 {
    padding: 10px 20px;
  }
  .container-list {
    padding: 0px;
    margin: 0px;
  }
  .dealer-list {
    flex-direction: column;
  }
}
</style>
<style lang="scss" scoped>
// regra para tela intermediaria entre LG e MD
@mixin between-1480-and-1280 {
  @media (max-width: 1480px) and (min-width: 1280px) {
    @content;
  }
}
@include between-1480-and-1280{
  .vehicle{
    zoom:0.9;
    .vehicle-item{
      .vehicle-footer{
        flex-direction: column !important;
      }    
    }
  }
  .vehicle-header .adsType{
    letter-spacing: -0.5px;
  }
}
</style>
