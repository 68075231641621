<template>
  <div id="viewSelect" class="d-inline-flex">
    <button @click="toggleView('grid')">
      <i :class="gridIconClass"></i>
    </button>
    <button @click="toggleView('list')">
      <i :class="listIconClass"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedView: 'grid', // Define a visualização inicial como "grid"
    };
  },
  computed: {
    gridIconClass() {
      return this.selectedView === 'grid' ? 'fa fa-th-large selected-icon' : 'fa fa-th-large default-icon';
    },
    listIconClass() {
      return this.selectedView === 'list' ? 'fa fa-list selected-icon' : 'fa fa-list default-icon';
    },
  },
  methods: {
    toggleView(view) {
      this.selectedView = view; // Alterna a visualização
      this.$emit('change', view); // Emite um evento para os componentes superiores
    },
  },
};
</script>

<style scoped>
.selected-icon {
  font-size: 24px;
  padding: 6px;
}
.default-icon {
  font-size: 24px;
  padding: 6px;
  color: #c7c7c7;
}
</style>
