import {mapActions, mapMutations, mapState} from "vuex";
import moment from "moment";

import {capitalize} from "@/helpers/strings";
import { URL } from "@/constants/Buyer";
import { downloadOfTheInvoiceByCodeAndName, setFavorite } from "@/services/api/BuyerServiceApi";

const VehicleCardMixin = {
  data: function () {
    return {
      isFavorite: null
    };
  },
  mounted() {
    this.isFavorite = (this.vehicle?.favorito=='true' || this.vehicleMakeEvaluationViewModal?.favorito ==='true') ? true : false;
  },
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.makeEvaluationViewModal.modalIsOpen,
      modalReleasePaymentIsOpen: (state) => state.releasePaymentViewModal.modalIsOpen,
      flags: (state) => state.globalFlagsCompany.flags,
      vehicleMakeEvaluationViewModal: (state) => state.makeEvaluationViewModal.vehicle,
      balance: (state) => state.wallet.balance,
    }),

  },
  methods: {
    ...mapMutations("walletModal", [
      "navigateWalletBuyPartialStep",
    ]),
    capitalize,
    ...mapMutations("makeEvaluationViewModal", 
    [
      "setVehicle",
      "toggleModal",
    ]),
    ...mapActions("makeEvaluationViewModal",
    [
        "getVehiclesPackage"
    ]),
    ...mapMutations("releasePaymentViewModal", [
      "setVehicleReleasePayment",
      "toggleModalReleasePayment",
    ]),
    async openModal(vehicle) {
      if (!this.modalIsOpen) {
        this.toggleModal();
        if (vehicle.qtdVeic > 1) {
          await this.getVehiclesPackage({
            id_avaliacao: vehicle.ava_id,
          })
        }
        else {
          this.setVehicle(vehicle)
        }
      }
    },
    openModalReleasePayment(vehicle) {
      if (!this.modalReleasePaymentIsOpen) {
        this.setVehicleReleasePayment(vehicle)
        this.toggleModalReleasePayment();
      }
    },

    async handleSetFavorite(vehicleId) {
      this.isFavorite = !this.isFavorite;
      try {
        const response = await setFavorite(vehicleId);
        if (response.statusCode != 200) {
          this.isFavorite = !this.isFavorite;
        }
        this.$store.dispatch("favorite/getFavorites", null, { root: true });
      } catch (error) {
        this.isFavorite = !this.isFavorite;
        console.error('Ocorreu um erro ao chamar setFavorite:', error);
      }
    },
    showIconFavorite(buyerScope) {
      return (buyerScope == "InProgress" || buyerScope == "OnDemandAssesmentInProgress");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatLicense(license) {
      if (license.length <= 0) {
        return license;
      }

      const firstChar = license[0].toUpperCase();
      const lastChar = license[license.length - 1].toUpperCase();
      return `${firstChar}***-**${lastChar}`;
    },
    getPriceBase(price) {
      return Math.trunc(Number(price)).toLocaleString("pt-br", {
        currency: "BRL",
      });
    },
    returnTheReviewButton(vehicle, disabled = false) {
      const creationDate = moment(vehicle.dt_ts_pagamento * 1000).startOf(
        "day"
      );
      const nowDiffInDays = moment().startOf("day").diff(creationDate, "days");
      const isShow =
        disabled === false &&
        vehicle.reviewed === "false" &&
        nowDiffInDays >= this.flags.RATINGS_REVIEW_LIMIT_START &&
        nowDiffInDays <= this.flags.RATINGS_REVIEW_LIMIT_END;

      return {
        label: "Review disponível",
        class: `btn-orange ${isShow ? "" : "disabled"}`,
        url: isShow ? URL.BUYER_ACTION_BUTTON_REVIEWS : "",
        target: isShow ? "_blank" : "",
        value: "",
      };
    },
    returnTheBuyButton(vehicle, disabled = false) {
      return {
        label: "Fazer avaliação",
        class: `btn-orange ${disabled ? "disabled" : ""}`,
        onClick: disabled
          ? undefined
          : () => this.openModal(vehicle),
      };
    },
    returnTheInvoiceButton(vehicle, disabled = false) {
      const codes = ["cod_nf_autoavaliar"];

      if (vehicle && vehicle.cod_nf_autopay !== null) {
        codes.push("cod_nf_autopay");
      }

      return {
        label: "Baixar Nota Fiscal",
        class: `btn-black ${disabled ? "disabled" : ""}`,
        isInvoice: disabled ? false : true,
        downloadOfTheInvoiceAutoPay:
          vehicle && vehicle.cod_nf_autopay !== null
            ? () =>
                downloadOfTheInvoiceByCodeAndName(
                  vehicle.cod_nf_autopay,
                  "NF Auto Pay"
                )
            : null,
        downloadOfTheInvoiceAutoAvaliar:
          vehicle && vehicle.cod_nf_autoavaliar !== null
            ? () =>
                downloadOfTheInvoiceByCodeAndName(
                  vehicle.cod_nf_autoavaliar,
                  "NF Auto Avaliar"
                )
            : null,
      };
    },
    returnActionsButtonsAnnouncementCompleted(vehicle) {
      return [
        {
          label: "Gerar boleto do carro",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsAnnouncementCompletedPackage(
      pack,
      navigateForPackageDetail
    ) {
      return [
        {
          label: "Ver veículos → ",
          class: "btn-green",
          onClick: navigateForPackageDetail,
        },
        this.returnTheReviewButton(pack),
        this.returnTheInvoiceButton(pack, false),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsReleasePendingPackage(
      pack,
      navigateForPackageDetail
    ) {
      return [
        {
          label: "Liberar pagamento",
          class: "btn-green",
          onClick: () => this.openModalReleasePayment(pack),
        },
        {
          label: "Review disponível",
          class: "btn-orange disabled",
        },        
        {
          label: "Ver veículos → ",
          class: "btn-green btn-outline",
          onClick: navigateForPackageDetail,
        },
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsProposalCompleted(vehicle) {
      return [
        {
          label: "Gerar boleto do carro",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsAutoBidCompleted(vehicle) {
      return [
        {
          label: "Gerar boleto do carro",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsQuotesCompleted(vehicle) {
      return [
        {
          label: "Gerar boleto do carro",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsBuyNowCompleted(vehicle) {
      return [
        {
          label: "Liberar Pagamento",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsLotsClosed(vehicle) {
      return [
        {
          label: "Liberar Pagamento",
          class: "btn-green disabled",
        },
        this.returnTheReviewButton(vehicle),
        this.returnTheInvoiceButton(vehicle),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsAnnouncementUnfulfilled() {
      return [];
    },
    returnActionsButtonsAutoBidUnfulfilled() {
      return [];
    },
    returnActionsButtonsCanceledUnfulfilled() {
      return [];
    },
    returnActionsButtonsQuotesUnfulfilled() {
      return [];
    },
    returnActionsButtonsPaymentPending(vehicle) {
      return [
        {
          label: "Gerar boleto do carro",
          class: "btn-green disabled",
        },
        {
          label: "Gerar boleto da taxa",
          class: "btn-orange",
          url: URL.BUYER_ACTION_BUTTON_BOLETO,
          target: "_blank",
          param: "ava_id",
        },
        this.returnTheInvoiceButton(vehicle, true),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsRealeasePending(vehicle, disableReleasePayment = false) {
      return [
        {
          label: "Liberar pagamento",
          class: `btn-green ${disableReleasePayment ? 'disabled' : ''}`,
          onClick: disableReleasePayment ? undefined :  () => this.openModalReleasePayment(vehicle),
          },
        {
          label: "Review disponível",
          class: "btn-orange disabled",
        },
        this.returnTheInvoiceButton(vehicle, true),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline disabled",
        },
      ];
    },
    returnActionsButtonsProposalPending(vehicle) {
      return [
        {
          label: "Liberar pagamento",
          class: "btn-green disabled",
        },
        {
          label: "Review disponível",
          class: "btn-orange disabled",
        },
        this.returnTheInvoiceButton(vehicle, true),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline disabled",
        },
      ];
    },
    returnActionsButtonsUnfufilled(vehicle) {
      return [
        {
          label: "Liberar pagamento",
          class: "btn-green disabled",
        },
        {
          label: "Review disponível",
          class: "btn-orange disabled",
        },
        this.returnTheInvoiceButton(vehicle, true),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline disabled",
        },
      ];
    },
    returnActionsButtonsInProgress(vehicle) {
      return [
        this.returnTheBuyButton(vehicle),
        {
          label: "Compre já",
          class: "btn-green disabled",
        },
        {
          label: "Ativar AutoBid",
          class: "btn-black disabled",
        },
        {
          label: "Enviar uma oferta",
          class: "btn-gray disabled",
        },
      ];
    },
    returnActionsButtonsOnDemandAssesmentInProgress(vehicle, wasOvercome = false) {
      return [
        this.returnTheBuyButton(vehicle, !wasOvercome),
        {
          label: "Compre já",
          class: "btn-green disabled",
        },
        {
          label: "Ativar AutoBid",
          class: "btn-black disabled",
        },
        {
          label: "Enviar uma oferta",
          class: "btn-gray disabled",
        },
      ];
    },
    returnActionsButtonsOnDemandAssessmentInProgress(vehicle) {
      return [
        this.returnTheBuyButton(vehicle, true),
        {
          label: "Compre já",
          class: "btn-green disabled",
        },
        {
          label: "Ativar AutoBid",
          class: "btn-black disabled",
        },
        {
          label: "Enviar uma oferta",
          class: "btn-gray disabled",
        },
      ];
    },
    returnActionsButtonsBidInProgressPackage(
      vehicle,
      navigateForPackageDetail
    ) {
      return [
        this.returnTheBuyButton(vehicle),
        {
          label: "Ver veículos → ",
          class: "btn-green",
          onClick: navigateForPackageDetail,
        },
        {
          label: "Ativar AutoBid",
          class: "btn-black disabled",
        },
        {
          label: "Enviar uma oferta",
          class: "btn-gray disabled",
        },
      ];
    },
    returnActionsButtonsUnfulilledPurchasesPackage(
      vehicle
    ) {
      return [
        {
          label: "Liberar pagamento",
          class: "btn-green disabled",
        },
        {
          label: "Review disponível",
          class: "btn-orange disabled",
        },
        this.returnTheInvoiceButton(vehicle, true),
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline disabled",
        },
      ];
    },
    returnActionsButtonsUnfulilledPurchasesForPackage(
      vehicle,
      navigateForPackageDetail
    ) {
      return [
        {
          label: "Liberar pagamento",
          class: "btn-green disabled",
        },
        {
          label: "Gerar boleto da taxa",
          class: "btn-orange disabled",
        },
        {
          label: "Ver veículos → ",
          class: "btn-red btn-outline",
          onClick: navigateForPackageDetail,
        },
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline disabled",
        },
      ];
    },
    returnActionsButtonsPaymentPendingPackage(
      vehicle,
      navigateForPackageDetail
    ) {
      const isAutoPay = vehicle.isAutoApay === 1;

      return [
        {
          label: "Gerar boleto AutoPay",
          class: `btn-green ${isAutoPay ? "" : "disabled"}`,
          url: isAutoPay ? URL.BUYER_ACTION_BUTTON_BOLETO : '',
          target: isAutoPay ? "_blank" : "",
          param: isAutoPay ? "ava_id" : "",
        },
        {
          label: "Gerar boleto taxa",
          class: `btn-orange ${isAutoPay ? "disabled" : ""}`,
          url: !isAutoPay ? URL.BUYER_ACTION_BUTTON_BOLETO : '',
          target: !isAutoPay ? "_blank" : "",
          param: !isAutoPay ? "ava_id" : "",
        },
        {
          label: "Ver veículos →",
          class: "btn-green btn-outline",
          onClick: navigateForPackageDetail,
        },
        {
          label: "Solicitar cancelamento",
          class: "btn-orange btn-outline",
          url: URL.BUYER_ACTION_BUTTON_CANCELAMENTO,
          target: "_blank",
          value: "7211",
        },
      ];
    },
    returnActionsButtonsLetterOfCreditEligiblePending(vehicle) {

      const isShow = this.balance && (this.balance > 0);

      return [
        {
          label: "Pagar taxa com boleto",
          class: "btn-green",
        },
        {
          label: "Pagar taxa com saldo",
          class: `btn-black ${isShow ? "" : "disabled"}`,
          onClick: () => isShow ? this.navigateWalletBuyPartialStep(vehicle) : undefined,
        },
        {},
        {},
      ];
    },
    getPriceDecimal(price) {
      const numericPrice = Number(price);
  
      return numericPrice.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).split(",")[1] || "00";
    },
    actionsByBuyerScope(buyerScope, wasOvercome = false) {
      if (buyerScope == "AnnouncementCompleted") {
        return this.returnActionsButtonsAnnouncementCompleted(this.vehicle);
      } else if (buyerScope == "AnnouncementCompletedPackage") {
        return this.returnActionsButtonsUnfufilled(
          this.lot
        );
      } else if (buyerScope == "ProposalCompleted") {
        return this.returnActionsButtonsProposalCompleted(this.vehicle);
      } else if (buyerScope == "QuotesCompleted") {
        return this.returnActionsButtonsQuotesCompleted(this.vehicle);
      } else if (buyerScope == "AutoBidCompleted") {
        return this.returnActionsButtonsAutoBidCompleted(this.vehicle);
      } else if (buyerScope == "BuyNowCompleted") {
        return this.returnActionsButtonsBuyNowCompleted(this.vehicle);
      } else if (buyerScope == "PackageDetailPageClosed") {
        return this.returnActionsButtonsLotsClosed(this.vehicle);
      } else if (buyerScope == "AnnouncementUnfulfilled") {
        return this.returnActionsButtonsAnnouncementUnfulfilled(this.vehicle);
      } else if (buyerScope == "AutoBidUnfulfilled") {
        return this.returnActionsButtonsAutoBidUnfulfilled(this.vehicle);
      } else if (buyerScope == "QuotesUnfulfilled") {
        return this.returnActionsButtonsQuotesUnfulfilled(this.vehicle);
      } else if (buyerScope == "PaymentPending") {
        return this.returnActionsButtonsPaymentPending(this.vehicle);
      } else if (buyerScope == "ProposalPending") {
        return this.returnActionsButtonsProposalPending(this.vehicle);
      } else if (buyerScope == "ReleasePending") {
        return this.returnActionsButtonsRealeasePending(this.vehicle);
      } else if (buyerScope == "UnfulilledPurchases") {
        return this.returnActionsButtonsUnfufilled(this.vehicle);
      } else if (buyerScope == "InProgress") {
        return this.returnActionsButtonsInProgress(this.vehicle);
      } else if (buyerScope == "OnDemandAssesmentInProgress") {
        return this.returnActionsButtonsOnDemandAssesmentInProgress(
          this.vehicle,
          wasOvercome
        );
      } else if (buyerScope == "InProgressPackage") {
        return this.returnActionsButtonsOnDemandAssessmentInProgress(this.lot);
      } else if (buyerScope == "UnfulilledPurchasesPackage") {
        return this.returnActionsButtonsUnfulilledPurchasesPackage(this.lot);
      } else if (buyerScope == "PaymentPendingPackage") {
        return this.returnActionsButtonsProposalPending(this.lot);
      }  else if (buyerScope == "LetterOfCreditEligible") {
        return this.returnActionsButtonsLetterOfCreditEligiblePending(this.vehicle);
      } else if (buyerScope == "ReleasePendingPackage") {
        return this.returnActionsButtonsRealeasePending(this.vehicle, true);
      } else {
        return [];
      }
    },
    actionsByBuyerScopeForPackage(buyerScope, navigateForPackageDetail) {
      if (buyerScope == "InProgressPackage") {
        return this.returnActionsButtonsBidInProgressPackage(
          this.lot,
          navigateForPackageDetail
        );
      } else if (buyerScope == "PaymentPendingPackage") {
        return this.returnActionsButtonsPaymentPendingPackage(
          this.lot,
          navigateForPackageDetail
        );
      } else if (buyerScope == "UnfulilledPurchasesPackage") {
        return this.returnActionsButtonsUnfulilledPurchasesForPackage(
          this.lot,
          navigateForPackageDetail
        );
      } else if (buyerScope == "AnnouncementCompletedPackage") {
        return this.returnActionsButtonsAnnouncementCompletedPackage(
          this.lot,
          navigateForPackageDetail
        );
      } else if (buyerScope == "ReleasePendingPackage" || buyerScope == "ReleasePending") {
        return this.returnActionsButtonsReleasePendingPackage(
          this.lot,
          navigateForPackageDetail
        );
      } else {
        return [];
      }
    },
  },
};

export default VehicleCardMixin;
