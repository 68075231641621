<template>
  <h4>{{ text }}</h4>
</template>

<script>
export default {
  name: "MenuTitle",
  props: {
    text: String,
  },
};
</script>

<style scoped>
h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #393939;
}
</style>
