<template>
      <BuyerOneColumnPage
            :title="title"
            subTitle="Suas compras concretizadas via Compre Já"
            :icon="icon"
            :section="section"
            :description="description"
            :getContent="getRealizedBuyNow"
            labelCard="Comprado por"
            parent="BuyNowCompleted"
            buyerScope="BuyNowCompleted"
            noVehicleLabel="Você ainda não enviou proposta em nenhum veículo…"
        />
</template>
<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getRealizedBuyNow } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "BuyNowCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras concretizadas - Compre Já",
      section: "Encerrados",
    };
  },
  methods: {
    getRealizedBuyNow,
  },
};
</script>

<style scoped></style>