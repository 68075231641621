<template>
    <div class="scroll-loading">
    <v-progress-circular indeterminate :size="24" :width="6" color="#ff7a00"></v-progress-circular>
    <span>Carregando mais veículos</span>
  </div>
  <div
        class="scroll-loading"
        v-if="!loading && selectedPage + 1 > totalPages"
    >
      <p>
        Parece que você explorou todas nossas ofertas. Não encontrou o que
        procurava? A cada dia, novos carros.
      </p>
    </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    selectedPage: Number,
    totalPages: Number
  }
}
</script>

<style scoped>

.scroll-loading {
  margin-top: 30px;
  margin-bottom: 100px;
}

.scroll-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  text-align: center;
}

.scroll-loading span {
  font-family: "Lexend Deca";
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-loading p {
  font-family: "Lexend Deca";
  font-size: 18px;
  font-weight: bold;
  padding: 0 32%;
  text-align: center;
}

@media only screen and (max-width: 920px) {
  .scroll-loading p {
    padding: 0;
  }
  .scroll-loading span {
    font-size: 16px;
  }

  .scroll-loading p {
    font-size: 14px;
  }
}

.scroll-loading p a {
  color: #ff7a00;
}
</style>