<template>
  <div
    :class="`razao ${isMobile() ? '' : 'mr-8'}`"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    @auxclick="closeSubMenu"
    id="profile-modal"
  >
    <span class="icon" @click="onClick">{{ profile }}</span>
    <span class="text mr-1 ml-1" v-if="!isMobile()">{{ profileDesktop }}</span>
    <i
      :class="`fa fa-angle-down ${isMobile() ? 'ml-1' : ''}`"
      aria-hidden="true"
      @click="onClick"
    ></i>

    <v-scroll-y-transition>
      <ul class="sub-menu" v-if="subMenuOpened" @mouseover="openSubMenu">
        <li v-for="(section, key) in subMenuSections" :key="key">
          <a
            :href="section.link"
            @click="section.path ? $router.push(section.path) : ''"
            :id="`header-profile-menu-item-${section.id}`"
          >
            <i :class="`fa ${section.icon} mr-2`"></i>
            {{ section.label }}</a
          >
        </li>
      </ul>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import PlatformsMixins from "@/mixins/PlatformsMixins";
import SimpleHeaderMixin from "../mixins/SimpleHeaderMixin";

export default {
  name: "ProfileModal",
  mixins: [SimpleHeaderMixin, PlatformsMixins],
  data: () => ({
    subMenuOpened: false,
  }),
  computed: {
    razaoTextAlign() {
      if (this.isMobile()) {
        return "right";
      }
      return "left";
    },
    alignIndicatorProfile() {
      if (this.isMobile()) {
        return "84%";
      }
      return "71%";
    }
  },
  methods: {
    openSubMenu() {
      if (this.isMobile()) {
        return;
      }
      this.subMenuOpened = true;
    },
    closeSubMenu() {
      if (this.isMobile()) {
        return;
      }
      this.subMenuOpened = false;
    },
    mouseOver() {
      if (this.isMobile()) {
        return;
      }
      this.openSubMenu();
    },
    mouseLeave() {
      if (this.isMobile()) {
        return;
      }
      this.closeSubMenu();
    },
    onClick() {
      if (!this.isMobile()) {
        return;
      }
      this.subMenuOpened = !this.subMenuOpened;
    },
  },
  mounted() {
    const root = document.getElementById("app");
    root.addEventListener("click", (event) => {
      if (!event.target.matches(".razao")) {
        this.closeSubMenu();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.razao {
  position: relative;
  color: white;
  font-size: 12px;
  font-weight: normal;
  text-align: v-bind(razaoTextAlign);
  cursor: pointer;

  .icon {
    border: 2px solid white;
    border-radius: 100%;
    padding: 6px;
    font-size: 9px;
  }

  .text {
    font-size: 9px;
  }

  .sub-menu {
    top: 40px;
    right: 0;
    position: absolute;
    list-style-type: none;
    margin: 0;
    float: left;
    background: #fff;
    border-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    padding: 3.25px 0;
    z-index: 2;

    li {
      a {
        display: block;
        color: black;
        text-align: center;
        padding: 10px;
        text-decoration: none;
        font-size: 12px;
        font-family: "Roboto", Roboto, Helvetica, sans-serif;
        cursor: pointer;

        display: block;
        line-height: 24px;
        overflow: hidden;
        padding: 5.5px 15px;
        user-select: none;
        z-index: 1;
        font-size: 11px;
        width: 100%;
        text-align: left;
      }

      border-bottom: 1px solid #aaa;
    }

    li:last-child {
      border-bottom: 0;
    }

    li:hover {
      opacity: 0.8;
    }
  }

  .sub-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    left: v-bind(alignIndicatorProfile);
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid white;
  }
}
</style>
