<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="AutoBids não concretizados"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getAutoBidUnfufilled"
    labelCard="Sua última avaliação"
    buyerScope="UnfulilledPurchases"
    noVehicleLabel="Você não possui AutoBids ativos…"
    priceBackgroundColor="#F22300"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import { getAutoBidUnfufilled } from "@/services/api/BuyerServiceApi";
import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "AutoBidUnfulfilledPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Não concretizadas - AutoBid",
      section: "Encerrados",
    };
  },
  methods: {
    getAutoBidUnfufilled,
  },
};
</script>

<style scoped></style>
