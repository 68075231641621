<template>
  <BuyerTwoColumnPage
    :title="title"
    subTitle="Acompanhe seus AutoBids!"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getAutoBidInProgress"
    labelCard="Sua avaliação"
    :sectionLeft="{
      title: 'AutoBids ativos',
      labelCard: 'Valor máximo definido',
      subTitle:
        'Autobid Ativo: O Autobid está aumentando seus lances até o limite definido. Você está na liderança! ',
    }"
    :sectionRight="{
      title: 'AutoBids superados',
      labelCard: 'Valor superado',
      subTitle:
        'Autobid Superado: O valor do veículo ultrapassou seu máximo. Considere fazer uma nova avaliação.',
    }"
    priceDescriptionPage="Sua última avaliação"
    noVehicleLabel="Você não possui AutoBids ativos…"
    noVehicleRightLabel="Você não possui AutoBids superados"
    buyerScope="InProgress"
  />
  <QuickViewModal />
</template>

<script>
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import { getAutoBidInProgress } from "@/services/api/BuyerServiceApi";

import BuyerTwoColumnPage from "../BuyerTwoColumnPage";
import QuickViewModal from "../../components/MakeEvaluationViewModal.vue";

export default {
  name: "AutoBidInProgressPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerTwoColumnPage, QuickViewModal },
  data: function () {
    return {
      ...BUYER_SECTION_IN_PROGRESS,
      title: "AutoBid",
      section: "Em andamento",
    };
  },
  methods: {
    getAutoBidInProgress,
  },
};
</script>

<style scoped>
.auto-bid-in-progress-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.auto-bid-in-progress-page-top {
  width: 100%;
}

.auto-bid-in-progress-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
