<template>
  <div class="buyer-one-column-page-mobile" v-if="isMobile()">
    <div class="buyer-one-column-page-mobile-top">
      <BuyerSectionItem
        :icon="icon"
        :title="section"
        :description="description"
        :shouldReturnToTheBuyerPage="true"
      />
      <div class="select" v-if="isShowPeriodSelectInput">
        <span class="mr-2">Selecione o período:</span>
        <select
          :value="period"
          @change="(event) => handlePeriod(event.target.value)"
        >
          <option value="">Selecione</option>
          <option value="15">Últimos 15 dias</option>
          <option value="30">Últimos 30 dias</option>
          <option value="45">Últimos 45 dias</option>
          <option value="60">Últimos 60 dias</option>
        </select>
        <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
      </div>
    </div>

    <div v-if="!loading">
      <div
        class="buyer-one-column-page-mobile-content"
        v-if="vehicles.length > 0"
      >
        <div
          class="buyer-one-column-page-item"
          v-for="(vehicle, key) in vehicles"
          :key="key"
        >
          <VehicleCard
            v-if="isVehicleCard(vehicle)"
            :vehicle="vehicle"
            :onClick="() => onClickInVehicle(vehicle)"
            :label="labelCard"
            :priceBackgroundColor="priceBackgroundColor"
            :buyerScope="buyerScope"
          />
          <LotCard
            v-if="isPackageCard(vehicle)"
            :lot="vehicle"
            :priceBackgroundColor="priceBackgroundColor"
            :waOvercome="true"
            :isInProgress="true"
            labelPrice="Sua avaliação"
            :labelAbouvePrice="
              buyerScope === 'PaymentPending'
                ? 'Você comprou por'
                : 'Comprado por'
            "
            :buyerScope="`${buyerScope}Package`"
            :onClick="() => onClickInLot(vehicle)"
          />
        </div>
      </div>
      <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>

      <div
        class="buyer-one-column-page-bottom pb-8"
        v-if="section === 'Encerrados'"
      >
        <PaginationProducts
          class="mt-5"
          :selectedPage="page"
          :length="totalPages"
          :handlePagination="handlePagination"
        />
      </div>
    </div>
    <div class="buyer-one-column-page-mobile-loading mt-2" v-else>
      <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
    </div>
  </div>
  <v-container class="buyer-one-column-page" v-else>
    <div class="buyer-one-column-page-container">
      <div class="buyer-one-column-page-top">
        <h1>{{ subTitle }} {{ loading ? "" : `(${totalItems && totalItems > 0 ? totalItems : vehicles.length})` }}</h1>
        <div class="select">
          <select
            :value="orderBy"
            @change="(event) => setOrderBy(event.target.value)"
            id="area_comprador_ordenar_por_botao"
          >
            <option value="" id="area_comprador_ordenar_por_default_botao">
              Ordenar por
            </option>
            <option
              value="Mais recentes"
              id="area_comprador_ordenar_por_mais_recentes_botao"
            >
              Mais recentes
            </option>
            <option
              value="Mais antigos"
              id="area_comprador_ordenar_por_mais_antigos_botao"
            >
              Mais antigos
            </option>
            <option
              value="Maior Valor"
              id="area_comprador_ordenar_por_maior_valor_botao"
            >
              Maior valor
            </option>
            <option
              value="Menor Valor"
              id="area_comprador_ordenar_por_menos_valor_botao"
            >
              Menor valor
            </option>
          </select>
          <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
        </div>
      </div>

      <div v-if="!loading">
        <div class="buyer-one-column-page-content" v-if="vehicles.length > 0">
          <div
            class="buyer-one-column-page-item"
            v-for="(vehicle, key) in vehicles"
            :key="key"
          >
            <VehicleCard
              v-if="isVehicleCard(vehicle)"
              :vehicle="vehicle"
              :label="labelCard"
              :priceBackgroundColor="priceBackgroundColor"
              :buyerScope="buyerScope"
            />
            <LotCard
              v-if="isPackageCard(vehicle)"
              :lot="vehicle"
              :priceBackgroundColor="priceBackgroundColor"
              :waOvercome="true"
              :isInProgress="true"
              labelPrice="Sua avaliação"
              labelAbouvePrice="Comprado por"
              :buyerScope="`${buyerScope}Package`"
              :onClick="() => onClickInLot(vehicle)"
            />
          </div>
        </div>
        <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>

        <div
          class="buyer-one-column-page-bottom mb-12"
          v-if="section === 'Encerrados'"
        >
          <PaginationProducts
            class="mb-8"
            :selectedPage="page"
            :length="totalPages"
            :handlePagination="handlePagination"
          />
        </div>
      </div>
      <div class="buyer-one-column-page-content-loading" v-else>
        <div class="loading-item">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card"
            width="50%"
            class="ml-1"
          ></v-skeleton-loader>
        </div>
        <div class="loading-item mt-2">
          <v-skeleton-loader
            type="card"
            width="50%"
            class="mr-1"
          ></v-skeleton-loader>
        </div>
        <div class="loading-footer mt-2">
          <v-skeleton-loader
            type="heading"
            width="50%"
            class="text-center"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import PaginationProducts from "@/components/pagination/PaginationProducts";

import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import VehicleCard from "@/views/buyer/components/VehicleCard";
import LotCard from "@/views/buyer/components/LotCard";
import NavigateMixings from "@/mixins/NavigateMixings";

import { BUYER } from "@/constants/Buyer";

export default {
  name: "BuyerOneColumnPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: {
    BuyerSectionItem,
    VehicleCard,
    PaginationProducts,
    VSkeletonLoader,
    LotCard,
  },
  props: {
    title: String,
    subTitle: String,
    section: String,
    icon: String,
    description: String,
    labelCard: String,
    priceDescriptionPage: String || undefined,
    priceBackgroundColor: String || undefined,
    getContent: Function,
    buyerScope: String,
    noVehicleLabel: String,
  },
  data: function () {
    return {
      loading: true,
      vehicles: [],
      orderBy: null,
      withPagination: null,
      page: 1,
      totalPages: 0,
      totalItems: 0,
    };
  },
  methods: {
    ...mapMutations("buyer", [
      "setDescriptionPage",
      "setVehicleDetail",
      "setIconPage",
      "setPriceDescriptionPage",
      "setBuyerScope",
      "setHandleRequestByPeriod",
      "setPeriod",
      "setThirdTitlePage",
    ]),
    ...mapMutations("buyerLotDetail", [
      "setTitlePage",
      "setTitleContainer",
      "setTotal",
      "setLotDetail",
      "setIsActive",
      "setCurrentLot",
      "setScope",
    ]),
    ...mapActions("buyer", ["handlePeriod"]),
    onClickInVehicle: function (vehicle) {
      this.setVehicleDetail(vehicle);
      this.setDescriptionPage(this.description);
      this.setPriceDescriptionPage(this.priceDescriptionPage);
      this.setIconPage(this.icon);
      this.natigateToInVehicleDetailPage();
      this.setBuyerScope(this.buyerScope);
    },
    onClickInLot: function (lot) {
      if (this.isMobile()) {
        this.setVehicleDetail(lot);
        this.setDescriptionPage(this.description);
        this.setPriceDescriptionPage(this.priceDescriptionPage);
        this.setBuyerScope(this.buyerScope);
        this.setIconPage(this.icon);
        this.natigateToInVehicleDetailPage();
        this.setThirdTitlePage(`Pacote ${lot.ava_id}`);
        return;
      }

      this.setTitlePage(null);
      if (this.buyerScope == "UnfulilledPurchases") {
        this.setTitleContainer(
          `Avaliação superada (${lot.qtdVeic} veículos) - Pacote ${lot.ava_id}`
        );
      } else if (this.buyerScope == "ReleasePending") {
        this.setTitleContainer(
          `Pacote comprado (${lot.qtdVeic} veículos) - Pacote ${lot.ava_id}`
        );
      } else if (this.buyerScope == "PaymentPending") {
        this.setTitleContainer(
          `Pacote comprado (${lot.qtdVeic} veículos) - Pacote ${lot.ava_id}`
        );
      } else {
        this.setTitleContainer(
          `Pacote comprado (${lot.qtdVeic} veículos) - Pacote ${lot.ava_id}`
        );
      }
      this.setLotDetail(lot);
      this.setTotal(lot.valor);
      this.setIsActive(true);
      this.natigateToInPackageDetailPage();
      this.setCurrentLot(lot);
      this.setScope(this.buyerScope);
    },
    setOrderBy: function (newOrderBy) {
      this.orderBy = newOrderBy;
      this.page = 1;
      this.handleContent();
    },
    handleContent(orderBy = this.orderBy, period = this.period) {

      this.loading = true;

      const handleSuccess = (data) => {
        this.withPagination = data.page !== undefined;

        if (this.withPagination) {
          this.page = data.page === 0 ? 1 : Number(data.page);
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.vehicles = data.result[Object.keys(data.result)[0]];
          return;
        }

        this.vehicles = data;
      };
      const handleFinally = () => (this.loading = false);

      if (this.withPagination === true) {
        this.getContent(orderBy, period, this.page)
          .then(handleSuccess)
          .finally(handleFinally);
        return;
      }

      this.getContent(orderBy, period)
        .then(handleSuccess)
        .finally(handleFinally);
    },
    handlePagination(page) {
      this.page = page;
      this.handleContent();
    },
    isVehicleCard: function (vehicle) {
      return vehicle.qtdVeic === undefined || vehicle.qtdVeic === 1;
    },
    isPackageCard: function (vehicle) {
      return vehicle.qtdVeic !== undefined && vehicle.qtdVeic > 1;
    },
    onSuccess() {
      const period = this.$route.query?.period ?? BUYER.DEFAULT_PERIOD_VALUE;
      this.setPeriod(period);
      this.handleContent(null);
      this.setHandleRequestByPeriod((period) => {
        this.loading = true;
        this.page = 1;
        this.handleContent(this.orderBy, period);
      });
    },
  },
  computed: {
    isShowPeriodSelectInput() {
      return this.$route.path.includes("closed");
    },
    ...mapState({
      period: (state) => state.buyer.period,
    }),
  },
  mounted: function () {
    this.onSuccess();
    this.$emitter.on("refreshEvent", (from) => {
      if (from === "ReleasePaymentViewModal") {
        this.onSuccess();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1535px) and (min-width: 1024px) {
  .buyer-one-column-page .buyer-one-column-page-content {
    width: 100% !important;
    display: block !important;
  }
  .buyer-one-column-page-container {
    display: block !important;
  }
  .buyer-one-column-page-content-right {
    margin-top: 30px !important;
    margin-left: 0 !important;
  }

  .buyer-one-column-page-item {
    width: 100% !important;
    display: block !important;
  }
}

.buyer-one-column-page {
  flex: 78;
  padding: 0;
  justify-content: center;

  .buyer-one-column-page-container {
    border-radius: 12px;
    background: #fff;
    height: 100%;
    flex-shrink: 0;
    min-height: 80vh;

    .buyer-one-column-page-top {
      display: flex;
      justify-content: space-between;
      padding: 19px 38px;
      padding-right: 19px;

      h1 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .select {
        position: relative;

        select {
          appearance: none;
          background-color: transparent;
          border-radius: 6px;
          border: 1px solid #808080;
          width: 140px;
          height: 33px;
          flex-shrink: 0;
          color: #808080;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 12px;
        }

        img {
          position: absolute;
          bottom: 8px;
          right: 16px;
        }
      }
    }

    .buyer-one-column-page-content {
      padding: 0 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .buyer-one-column-page-item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 31px;
      }
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 40px;
    }

    .buyer-one-column-page-bottom {
      display: grid;
      justify-content: center;
      align-items: center;
    }

    .buyer-one-column-page-content-loading {
      display: flex;
      flex-direction: column;
      padding: 19px 38px;
      padding-right: 19px;
      padding-top: 0;

      .loading-item {
        display: flex;
        flex: 1;
        flex-direction: row;
      }

      .loading-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.buyer-one-column-page-mobile {
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;

  .buyer-one-column-page-mobile-top {
    .select {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 12px 10px;
      background: #fafafa;
      position: relative;

      span {
        color: #797979;
        font-family: "Lexend Deca";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      select {
        border-radius: 6px;
        border: 1px solid #cfcfcf;
        flex: 1;
        padding: 9px 17px;

        color: #757575;
        font-family: "Lexend Deca";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      img {
        position: absolute;
        top: 22px;
        right: 20px;
      }
    }
  }

  .no-vehicles {
    color: #9f9f9f;
    font-family: "Lexend Deca";
    font-size: 16px;
    line-height: normal;
    padding: 20px;
    background-color: #fff;
    display: flex;
  }

  .buyer-one-column-page-mobile-loading {
    font-family: "Lexend Deca";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buyer-one-column-page-mobile-content {
    .buyer-one-column-page-item {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.buyer-one-column-page-page-top {
  width: 100%;
}

.buyer-one-column-page-page-content {
  margin-top: 4px;
  border-radius: 12px;
  background: #fff;
  flex: 1;
}
</style>
