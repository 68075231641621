import { releasePayment } from "@/services/api/ReleasePaymentModalServiceApi";

const DEFAULT_STATE = {
  modalIsOpen: false,
  loading: false,
  response: {},
  vehicle: null
};

const releasePaymentViewModalModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    toggleModalReleasePayment(state) {
      state.modalIsOpen = !state.modalIsOpen;
    },
    setVehicleReleasePayment(state, vehicle) {
      state.vehicle = vehicle;
    }
  },
  actions: {
    async releasePayment({ state }, data) {
      const { id_avaliacao, valor } = data;
      state.loading = true;
      try {
        let response = await releasePayment(id_avaliacao, valor);
        if (response.response) {
          state.response = response.response.data
        }
        else {
          state.response = response;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.response = error;
      }
    },
  },
};

export default releasePaymentViewModalModule;
