<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras com liberação de pagamento pendente"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getReleasePeding"
    labelCard="Você comprou por"
    buyerScope="ReleasePending"
    priceDescriptionPage="Você comprou por"
    noVehicleLabel="Você ainda não possui propostas pendentes"
  />
  <ReleasePayment />
</template>

<script>
import { BUYER_SECTION_PENDING } from "@/views/buyer/constants/BuyerSections";

import { getReleasePeding } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import NavigateMixings from "@/mixins/NavigateMixings";

import BuyerOneColumnPage from "../BuyerOneColumnPage";
import ReleasePayment from "../../components/ReleasePaymentViewModal.vue";


export default {
  name: "ReleasePendingPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: { BuyerOneColumnPage, ReleasePayment },
  data: function () {
    return {
      ...BUYER_SECTION_PENDING,
      title: "Liberação pendente (AutoPay)",
      section: "Pendentes",
    };
  },
  methods: {
    getReleasePeding
  }
};
</script>

<style lang="scss" scoped></style>
