<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras concretizadas via avaliação sob aprovação"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getOndemandAssesmentCompleted"
    labelCard="Comprado por"
    buyerScope="AnnouncementCompleted"
    noVehicleLabel="Você ainda não cotou nenhum veículo…"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getOndemandAssesmentCompleted } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "OnDemandAssessmentCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras concretizadas - Avaliações sob aprovação",
      section: "Encerrados",
    };
  },
  methods: {
    getOndemandAssesmentCompleted,
  },
};
</script>

<style scoped></style>
