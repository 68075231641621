<template>
  <div class="px-5 px-md-15 py-5 py-md-15 dealer-container">
    <div >
      <h1 class="d-flex title-campaign align-center">
        <img
          :src="require(`@/assets/images/showcase/car-highlights.png`)"
          class="mr-2"
          style="width: 35px;"
          alt="Anuciantes"
        />
        Anunciantes
      </h1>
      <p class="text-description" style="margin-left: 20px">
        Escolha <strong>anunciantes</strong> e visualize anúncios, ou <a href="#" @click="gotoAdvertisementsPage()" style="font-weight: bold;text-decoration: none;color: black;">clique aqui</a> para visualizar todos.
      </p>
    </div>
    <v-row justify="space-between" class="mt-2" >
      <v-col cols="12" md="5">
        <SelectSearchAutocomplete
          label="Selecione um anunciante..."
          :options="dealerNames"
          @selectSearchSelectOption="selectSearchSelectOption"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12" md="2" class="text-center">
        <i class="aa-opacity-4"> ou </i>
      </v-col>
      <v-col cols="12" md="5">
        <SelectSearchAutocomplete
          label="Selecione uma localização..."
          :options="cityNames"
          sub-items
          @selectSearchSelectOption="selectRegionSelectOption"
          :loading="dealerRegionsLoading"
        />
      </v-col>
      <v-col cols="12">
        <v-progress-linear
          :model-value="progressValue"
          class="mt-4"
          color="primary"
          :style="{ width: `${progressValue}%` }"
          bg-color="transparent"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="loading" class="mt-0 mt-md-10 pt-10">
      <v-col
        v-for="(_, index) in Array.from(Array(8).keys())"
        v-bind:key="index"
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="3"
        xxl="2"
      >
        <v-skeleton-loader
          class="mx-auto border card-skeleton"
          type="article, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-carousel
      v-model="currentSlide"
      hide-delimiters
      show-arrows-on-hover
      v-else
      class="mt-0 mt-md-10 pt-10 h-auto"
      :interval="null"
    >
      <v-carousel-item class="overflow-visible" v-for="(group, index) in itemGroups" :key="index">
        <v-row>
          <v-col
            v-for="(dealer, index) in group"
            v-bind:key="dealer.id_empresa"
            v-bind:index="index"
            cols="12"       
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <DealerItem
              :dealer="dealer"
              :index="index"
              :linha="index"
              @handleClick="handleClick"
            />
          </v-col>
        </v-row>
        <div class="spaceFotter"></div>
      </v-carousel-item>

      <template v-slot:prev="{ props }">
        <v-btn
          @click="handleSlideButton(props.onClick)"
          class="carroselNavigateLeft"
          variant="text"
        >
          <v-icon size="x-large">mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <template v-slot:next="{ props }">
        <v-btn
          @click="handleSlideButton(props.onClick)"
          class="carroselNavigateRight"
          variant="text"
        >
          <v-icon size="x-large">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-carousel>
    
    <ModalDealerUfs />
  </div>
</template>

<script>
import SelectSearchAutocomplete from "@/views/dealers/componentes/SelectSearchAutocomplete.vue";
import DealerItem from "@/components/DealerItem/index.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {VSkeletonLoader} from "vuetify/lib/labs/VSkeletonLoader";
import {gotoAdvertisementsPage, gotoDealerPage} from "@/helpers/vdp";
import ModalDealerUfs from "@/views/dealers/componentes/ModalDealerUfs.vue";
import NavigateMixings from "@/mixins/NavigateMixings";
import PlatformsMixins from "@/mixins/PlatformsMixins";

export default {
  name: "DealerView",
  mixins: [PlatformsMixins,NavigateMixings],
  components: {
    ModalDealerUfs,
    VSkeletonLoader,
    DealerItem,
    SelectSearchAutocomplete,
  },
  data() {
    return {
      currentSlide: 0,
      progressValue: 0,
      interval: null,
    }
  },
  computed: {
    ...mapState({
      dealers: (state) => state.dealer.dealers,
      regions: (state) => state.dealer.regions,
      dealerRegionsLoading: (state) => state.dealer.regionsLoading,
      loading: (state) => state.dealer.dealersLoading,
      dealerNames: (state) => state.dealer.dealers.map(dealer => ({ id: dealer.fantasia, name: dealer.fantasia })),
      cityNames: (state) => {
        const result = [];

        for (const regionKey in state.dealer.regions) {
          if (!regionKey || !state.dealer.regions[regionKey].name) continue;

          const region = state.dealer.regions[regionKey];
          const regionObj = {
            id: region.name,
            name: region.name,
            subItems: []
          };

          const addedStates = new Set();
          for (const stateKey in region.uf) {
            const state = region.uf[stateKey];
            if (!state || !state.name || addedStates.has(stateKey)) continue;

            regionObj.subItems.push({ id: state.name, name: `${state.name} (${stateKey.toUpperCase()})` });
            addedStates.add(stateKey);
          }
          result.push(regionObj);
        }

        return result;
      },
    }),
    itemGroups() {
      const groupSize = 8;
      return this.dealers.reduce((groups, item, index) => {
        if (index % groupSize === 0) {
          groups.push([]);
        }
        groups[groups.length - 1].push(item);
        return groups;
      }, []);
    },
    totalSlides() {
      return this.itemGroups.length;
    },
  },
  methods: {
    gotoAdvertisementsPage,
    ...mapActions("dealer", ["getDealersData", "getDealerUfsData", "getRegionsData", "filterDealers", "filterDealersByRegion"]),
    ...mapMutations("dealer", ["toggleModal", "setSelectedDealer"]),

    selectSearchSelectOption(selectedItem) {
      const id = selectedItem.option?.id ?? null;
      this.filterDealers(id);
      if (id) {
        const selectedDealer = this.itemGroups.find(el => el.fantasia === selectedItem.fantasia );
        if (selectedDealer[0]) {
          this.handleClick(selectedDealer[0]);
        }
      }
    },
    async selectRegionSelectOption(selectedItem) {
      await this.filterDealersByRegion(selectedItem.option?.id ?? null);
    },
    async handleClick(dealer) {
      if (dealer.n_ufs > 1) {
        this.setSelectedDealer(dealer)
        this.toggleModal()
        await this.getDealerUfsData(dealer.url)
      }
      else {
        gotoDealerPage(dealer, '')
      }
    },
    startProgress() {
      this.interval = setInterval(() => {
        this.progressValue += 1;
        if (this.progressValue >= 100) {
          this.nextSlide();
        }
      }, 100);
    },
    nextSlide() {
      this.progressValue = 0;
      this.currentSlide =
          (this.currentSlide + 1) % this.totalSlides;
    },
    stopProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    handleSlideButton(handle) {
      this.progressValue = 0;
      this.stopProgress();
      this.startProgress();
      handle()
    },
  },
  async mounted() {
    await Promise.all([
      this.getDealersData(),
      this.getRegionsData()
    ]);
    this.startProgress();
  },
  unmounted() {
    this.stopProgress();
  },
};
</script>

<style scoped>
.title-campaign {
  line-height: 64px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-description {
  font-size: 12px;
  color: #555;
}
.dealer-container {
  height: v-bind(contentHeight);
  overflow-y: auto;
}
.carroselNavigateRight {
  position: absolute;
  bottom: 10px;
  right: 5px;
}
.carroselNavigateLeft {
  position: absolute;
  bottom: 10px;
  right: 80px;
}
.spaceFotter {
  height: 60px;
}
</style>
