<template>
    <div class="breadcrumbs box">
     <p class="mobile" v-if="mobile">
      <i class="mdi mdi-home" @click="navigateToDashboardPage()"></i>/ <span class="text-orange" @click="navigateToHomePage()">...</span> / Lista de Resultados
     </p>
      <p class="breadcrumbs" v-if="!mobile">
        Você está aqui:&nbsp;<span class="caption home" :id="'bc-'+ toSlug(sectionPage)">{{ sectionPage }}</span>
        <span
          class="breadcrumbs"
          v-if="filtersActive && Object.keys(filtersActive).length > 0"
        >
          <span
            v-if="Object.keys(filtersActive).length > 0"
            class="caption filtros"
          >
            &gt; Filtros
          </span>
  
          <span class="scroll-filters" @wheel="onWheel">
            <span
              v-show="(f.length > 0) || (getValue(f) > 0)"
              class="filter-aa"
              v-for="(f, k) in filtersActive"
              :key="f"
            >
              <span class="nextArrow">&gt;</span>
              <span class="label" :class="k">{{ filtersLang[k] }}: </span>
              <span
                class="caption caption-item" :id="'bc-'+ toSlug(getValueText(el))"
                @click="removeFilterItem(k, el)"
                v-for="(el, i) in stringToArray(f)"
                :key="el"
                title="Remover item"
              >
                {{ getValueText(el) + (filtersLang[k] === "Fipe" ? '%' : '') }}
                {{ (stringToArray(f).length -1 == i ? '' : ', ' ) }}
              </span>
              <span @click="removeFilter(k)" class="mdi mdi-close-circle"></span>
            </span>
          </span>
        </span>
      </p>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { mapState, mapActions, mapMutations } from "vuex";
  import store from "@/store";
  import { watch, ref, reactive, computed } from "vue";
  import NavigateMixings from "@/mixins/NavigateMixings";
  import { BUYER } from "@/constants/Buyer";
  
  export default {
    name: "AA-Breadcrumbs",
    props: ["mobile"],
    mixins: [NavigateMixings],
    data() {
      return {
        page: "Página Inicial",
        total: null,
        titleDashboad: BUYER.TITLE_DASHBOARD,
        filtersActive: [],
        filtersLang: {
          location: "Local",
          cities: "Cidade",
          entity: "entity",
          byEntity: "bgEntity",
          brands: "Marca",
          brandings: "Marca",
          models: "Modelo",
          versions: "Versão",
          adType: "Tipo de Anunciante",
          AdvertiserType: "Tipo de Anunciante",
          group: "Grupo",
          priceMin: "Preço Min.",
          priceMax: "Preço Máx.",
          kmMin: "Km Min.",
          kmMax: "Km Máx.",
          yearMin: "Ano Min.",
          yearMax: "Ano Máx.",
          order: "Ordem",
          category: "Categoria",
          relevance: "Relevância",
          campaign: "Campanha",
          optionals: "Opcionais",
          report: "Laudo",
          fipe: "Fipe",
          query: "Busca",
          typeVehicle: "Tipo",
          fuel: "Combustível",
          reportStatus: "Laudo",
          quotation: "Modalidade de Compra",
          transmission: "Transmissão",
          vehicleCondition: "Condição do Veículo",

        },
        filters: {
          location: [],
          cities: [],
          entity: [],
          byEntity: false,
          brands: [],
          models: [],
          versions: [],
          adType: [],
          group: null,
          priceMin: "",
          priceMax: "",
          kmMin: "",
          kmMax: "",
          yearMin: null,
          yearMax: null,
          order: "",
          category: "",
          relevance: null,
          query: "",
          campaign: null,
          optionals: [],
          fipe: "0",
          reportStatus: [],
        },
        filtersHide: ["fipe", "vehicle-branding"],
      };
    },
    mounted() {
      this.init();
    },
    computed: {
      ...mapState({
        params: (state) => state.filterForm.params,
        seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
        sectionPage: (state) => state.buyer.sectionPage,
      }),
    },
    methods: {
      ...mapActions("filterForm", ["search"]),
      ...mapMutations("drawer", [
          "showSeeAllDrawer",
          "setSeeAllDrawerObjDetails",
          "unsetSeeAllDrawerObjSelected",
          "clearSeeAllDrawerObjSelected",
          "clear"
        ]),
      init() {
        //this.params = store.state.filterForm.params;
        this.filtersActive = store.state.filterForm.params;
      },
      stringToArray(el) {
        return typeof el === 'string' ? [el] : el;
      },
      removeFilterItem(param, item) {
        if (param == 'brands') {
          this.deleteBrabdingItem(item);
        } else if (param == 'models' || param == 'versions') {
          this.deleteModelVersionItem(param, item);
        } else {
          if (['fuel', 'optionals', 'transmission'].includes(param)) {
            this.filtersActive[param] = this.filtersActive[param].filter((el) => {
              if (item.uuid !== undefined) {
                return item.uuid !== el.uuid;
              } else if (item.id !== undefined) {
                return item.id !== el.id;
              }
              return false;
            });
            this.unsetSeeAllDrawerObjSelected({ key: `key-${param}`, uuid: item.uuid });
          }
          else {
            if (typeof this.filtersActive[param] === 'string') {
              this.$store.commit("filterForm/removeParam", param);
              if (param == 'fipe') {
                let range = document.getElementById("range_fipe");
                range.value = 0;
                range.style.backgroundSize = '0%';
              }
            } else if (typeof item === 'object') {
              this.filtersActive[param] = this.filtersActive[param].filter((el) => {
                if (item.uuid !== undefined) {
                  return item.uuid !== el.uuid;
                } else if (item.id !== undefined) {
                  return item.id !== el.id;
                }
                return false;
              });
            } else if (typeof item === 'string') {
              this.filtersActive[param] = this.filtersActive[param].filter(el => el !== item);
            }
          }
        }
        store.commit("filterForm/refreshSearch");
        return;
      },
      removeFilter(param) {
        if(param == 'brands'){
          this.unsetSeeAllDrawerObjSelected({ key: "key-vehicle-branding", all: true });
          this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
          this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
          store.commit("filterForm/removeParam", 'models');
          store.commit("filterForm/removeParam", 'versions');
        }
        if(param == 'models'){
          
          this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
          this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
          store.commit("filterForm/removeParam", 'models');
          store.commit("filterForm/removeParam", 'versions');
        }
        if(param == 'versions'){
          
          this.unsetSeeAllDrawerObjSelected( {key: 'key-Versões', all: true} );
          store.commit("filterForm/removeParam", 'versions');        
        }
  
        this.$store.commit("filterForm/removeParam", param);
        if(param == 'fipe'){
          let range = document.getElementById("range_fipe");
          range.value = 0;
          range.style.backgroundSize = '0%';
        }
  
        const key = "key-" + param;
        this.$store.commit("drawer/clearSeeAllDrawerObjSelected", key);
  
        this.params[param] = [];
        //delete this.filtersActive[param];
  
        return;
      },
      deleteBrabdingItem(item) {
        const brand = this.filtersActive.brands.filter(
            (branding) => branding.id.toLowerCase() === item.id.toLowerCase()
        )[0];
        const brandIndex = this.filtersActive.brands.findIndex(
            (branding) => branding.id.toLowerCase() === item.id.toLowerCase()
        );
        const modelsIndex = this.filtersActive.models.findIndex(
            (model) => model.parentUuid.toLowerCase() === brand.uuid.toLowerCase()
        );
        const versionsIndex = this.filtersActive.versions.findIndex(
            (m) => m.parentUuid.toLowerCase() === brand.uuid.toLowerCase()
        );

        if (brandIndex >= 0) {

          if (versionsIndex >= 0) {
            if (this.filtersActive.versions?.length === 1) {
              this.filtersActive.versions = []
              this.unsetSeeAllDrawerObjSelected({ key: "key-Versões", all: true });
            } else {
              this.filtersActive.versions.splice(versionsIndex, 1);
              this.unsetSeeAllDrawerObjSelected({ key: `key-${item.name.toUpperCase()}-Versões`, uuid: brand.uuid });
            }
          }

          if (modelsIndex >= 0) {
            if (this.filtersActive.models?.length === 1) {
              this.filtersActive.models = []
              this.unsetSeeAllDrawerObjSelected({ key: "key-Modelos", all: true });
            } else {
              this.filtersActive.models.splice(modelsIndex, 1);
              this.unsetSeeAllDrawerObjSelected({ key: `key-${item.name.toUpperCase()}-Modelos`, uuid: brand.uuid });
            }
          }

          if (this.filtersActive.brands?.length === 1) {
            this.filtersActive.brands = [];
            this.unsetSeeAllDrawerObjSelected({ key: 'key-vehicle-branding', all: true });
          }
          else {
            this.filtersActive.brands.splice(brandIndex, 1);
            this.unsetSeeAllDrawerObjSelected({ key: 'key-vehicle-branding', uuid: brand.uuid });
          }
        }
      },
      deleteModelVersionItem(scope, item) {
        var key = "key-" + (item.marca ? `${item.marca.toUpperCase()}-` : "");

        const modelsIndex = this.filtersActive.models.findIndex(
          (model) => model.value.toLowerCase() === item.value.toLowerCase());
        const versionsIndex = this.filtersActive.versions.findIndex(
            (v) => v.value.toLowerCase() === item.value.toLowerCase()
        );

        if (scope === "models" && modelsIndex >= 0) {
          key = key + "Modelos";
          this.unsetSeeAllDrawerObjSelected({ key, uuid: this.filtersActive.models[modelsIndex].uuid });
          this.filtersActive.models.splice(modelsIndex, 1);
          const versionChildrenIndex = this.filtersActive.versions.findIndex(
              (v) => v.modelo.toLowerCase() === item.value.toLowerCase()
          );
          if (versionChildrenIndex >= 0) {
            this.unsetSeeAllDrawerObjSelected({ key: key.replace('Modelos', 'Versões'), uuid: this.filtersActive.versions[versionChildrenIndex].uuid });
            this.filtersActive.versions.splice(versionChildrenIndex, 1);
          }
        }

        if (scope === "versions" && versionsIndex >= 0) {
          const model = this.filtersActive.models.filter(
            (model) => model.value.toLowerCase() === item.modelo.toLowerCase()
          )[0];
          const marca = model.marca;
          key = key + (marca ? `${marca.toUpperCase()}-` : "")
            + "Versões";
          this.unsetSeeAllDrawerObjSelected({ key, uuid: this.filtersActive.versions[versionsIndex].uuid });
          this.filtersActive.versions.splice(versionsIndex, 1);
        }
      },
      select(field) {
        let el = document.querySelector("div[data-name=" + field + "]");
        if (!el) return;
        //el.scrollIntoView();
        el.focus();
        el.click();
      },
      getValue(v = false, k = false) {
        let sufix = '';
        if(k == 'fipe') sufix = '%';
        if (typeof v === "null") return false;
        if (typeof v === "undefined") return false;
        if (typeof v === "array" || typeof v === "object") {
          v = v.map((elem) => {
            if (typeof(elem) === 'string') return elem;
            if ("title" in elem) return elem.title;
            if ("name" in elem) return elem.name;
            if ("value" in elem) return elem.value;
            return elem;
          });
        }
        if (typeof v === "object") v = v.join(", ");
        return v + sufix;
      },
      getValueText(v) {
        if (typeof v === "null") return false;
        if (typeof v === "undefined") return false;
        if (typeof(v) === 'string') return v;
        if (typeof v === "object") {
          if ("value" in v) return v.value;
          if ("title" in v) return v.title;
          if ("name" in v) return v.name;
        }
        return false;
      },
      updateStore() {},
      onWheel(ev) {
        let mov = ev.deltaY;
        document.querySelector(".scroll-filters").scrollLeft += mov / 3;
      },
      toSlug(text) {
        return text
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim()
                .replace(/\s+/g, '-')
                .replace(/[^\w-]+/g, '')
                .replace(/--+/g, '-');
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  $orange: #ff9900;
  
  .content-top {
    &.mobile {
      .breadcrumbs.box {
        height: auto;
        font-size: 0.8rem;
      }
  
      .breadcrumbs {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
      }
  
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .breadcrumbs.box {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    height: 3em;
    p.breadcrumbs {
      width: 100%;
      position: relative;
      overflow-y: hidden;
      > span {
        color: #424242;
      }
    }
  }
  p.breadcrumbs,
  p.breadcrumbs span {
    font-family: "Lexend Deca", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    letter-spacing: -0.2px;
  }
  p.breadcrumbs span.label {
    text-transform: capitalize;
    color: #424242;
    font-size: inherit;
    font-weight: 400;
  }
  p.breadcrumbs i.fa,
  .mdi {
    color: #ff9900 !important;
    font-size: 1.2rem !important;
    margin-right: 0.1em;
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  p.breadcrumbs .caption {
    color: #ff9900;
    font-weight: 400;
    font-size: inherit;
    text-transform: capitalize;
    margin-right: 0.2em;
  }
  p.breadcrumbs .caption-item:hover {
    color: red;
    position: relative;
    cursor: pointer;
  }
  p.breadcrumbs a.home {
    text-decoration: none;
  }
  
  p.breadcrumbs .home {
    color: $orange !important;
  }
  .caption.home,
  .caption.filtros {
    color: #aaa !important;
  }
  .nextArrow {
    margin: 0 0.3em;
  }
  .scroll-filters {
    display: inline-flex;
    white-space: nowrap;
    overflow-x: auto;
    top: 0;
    max-width: calc(100% - 20em);
    padding: 0.5em 0;
    margin-top: 0;
    margin-bottom: 0;
    /*
    @media (min-width: 1024px) {
      max-width: calc(35% + 2vw);
    }
    @media (min-width: 1200px) {
      max-width: calc(50% + -1vw);
    }
    @media (min-width: 1600px) {
      max-width: calc(60% + 1vw);
    }
    @media (min-width: 1920px) {
      max-width: calc(75% - 3vw);    
    }
    */
  
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: lightgray;
    }
    &::before {
      content: "";
      background: linear-gradient(to right, white, #fff0);
      display: block;
      height: 2em;
      width: 1.5em;
      position: fixed;
    }
  }
  </style>  