const drawerModule = {
  namespaced: true,
  state() {
    return {
      reorganizationDrawerEnabled: false,
      seeAllDrawerEnabled: false,
      seeAllDrawerObjDetails: {
        placeholder: "",
        title: "",
        subtitle: "",
        sections: "",
        scope: null,
      },
      seeAllDrawerObjSelected: [],
      brandSelectUuid: null,
    };
  },
  mutations: {
    showReorganizationDrawer(state) {
      state.reorganizationDrawerEnabled = true;
    },
    hideReorganizationDrawer(state) {
      state.reorganizationDrawerEnabled = false;
    },
    showSeeAllDrawer(state) {
      state.seeAllDrawerEnabled = true;
    },
    hideSeeAllDrawer(state) {
      state.seeAllDrawerEnabled = false;
    },
    setSeeAllDrawerObjDetails(state, data) {
      state.seeAllDrawerObjDetails.placeholder = data.placeholder;
      state.seeAllDrawerObjDetails.title = data.title;
      state.seeAllDrawerObjDetails.subtitle = data.subtitle;
      state.seeAllDrawerObjDetails.handle = data.handle;
      state.seeAllDrawerObjDetails.scrollTo = data.scrollTo;
      state.seeAllDrawerObjDetails.scope = data.scope;
    },
    setSeeAllDrawerObjSelected(state, data) {
      const key = data.key;
      const value = data.value;
      value['uuid'] = data?.uuid ?? Math.random().toString(16).slice(2);

      const parentUuid = data?.parentUuid ?? state.brandSelectUuid;
      if (data.key.toLowerCase().includes("branding")) {
        data.type = "brand";
      }
      if (data.key.toLowerCase().includes("modelos")) {
        data.type = "model";
        value['parentUuid'] = parentUuid;
      }
      if (data.key.toLowerCase().includes("versões")) {
        data.type = "version";
        value['parentUuid'] = parentUuid;
      }

      let newValue = {};
      newValue = JSON.stringify(value)
      newValue = JSON.parse(newValue)

      if (!state.seeAllDrawerObjSelected[key]) {
        state.seeAllDrawerObjSelected[key] = [];
      }
      const exists =
        state.seeAllDrawerObjSelected[key]
          .filter((e) => e.uuid === newValue.uuid)
          .length > 0;
      if (!exists) state.seeAllDrawerObjSelected[key].push(newValue);

    },
    unsetSeeAllDrawerObjSelected(state, data) {
      const { item, key, uuid } = data;
      const all = data.all ?? false;
      let param = key.search('Versões') > 0 ? 'versions' : false;
      param = key.search('Modelos') > 0 ? 'models' : param;

      if (uuid) {
        state.seeAllDrawerObjSelected[key] = state.seeAllDrawerObjSelected[key].filter(e => e.uuid !== uuid);
        return;
      }
      if (state.seeAllDrawerObjSelected[key] || all) {
        if (item) {
          const index = state.seeAllDrawerObjSelected[key].indexOf(e => e.uuid === item.uuid);
          if (index > -1) {
            state.seeAllDrawerObjSelected[key].splice(index, 1);            
          }
        } else {
          if(all){
            let endKey = key.split('-')[1];
            let objSelected = Object.keys(state.seeAllDrawerObjSelected);
            const deleteKeys = objSelected.filter((item) => {
              return item.search(endKey) > 0;
            });
            deleteKeys.forEach(deleteKey => {
              delete state.seeAllDrawerObjSelected[deleteKey];
            });  
          }else{
            delete state.seeAllDrawerObjSelected[key];
          }
        }
        if(param){
          this.commit("filterForm/removeParam", param);
        }
      }
    },
    clearSeeAllDrawerObjSelected(state, key) {
      state.seeAllDrawerObjSelected[key] = [];
    },
    clear(state) {
      state.seeAllDrawerObjSelected = [];
    },
    selectBrandWithUuid(state, uuid) {
      state.brandSelectUuid = uuid;
    }
  },
};

export default drawerModule;
