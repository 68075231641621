<template>
  <v-container>
    <v-row>
      <MenuTitle text="Preço" />
    </v-row>
    <v-row>
      <v-col class="ps-0">
        <v-text-field
          label="De"
          variant="outlined"
          hide-details="auto"
          prefix="R$"
          v-maska:[currency]
          id="price_from"
          @keyup="priceChange"
          :model-value="priceMin"
          :maxlength="23" 
          @input="(event) => setPriceMinHandle(event.target.value)"
          color="#939292"
          base-color="#939292"
          :persistent-placeholder="true"
          :placeholder="priceMinPlaceholder"
        >
        <template v-slot:label>
          <span class="custom-text-field--label">
            De
          </span>
        </template>
      </v-text-field>
      <small class="input-field-message" id="price_from_message"></small>
      </v-col>
      <v-col>
        <v-text-field
          label="Até"
          variant="outlined"
          hide-details="auto"
          prefix="R$"
          v-maska:[currency]
          id="price_at"
          @keyup="priceChange"
          :model-value="priceMax"
          :maxlength="23" 
          @input="(event) => setPriceMaxHandle(event.target.value)"
          color="#939292"
          base-color="#939292"
          :persistent-placeholder="true"
          :placeholder="priceMaxPlaceholder"
        >
        <template v-slot:label>
          <span class="custom-text-field--label">
            Até
          </span>
        </template>
      </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { vMaska } from "maska";
import MenuTitle from "@/components/typographies/MenuTitle.vue";
import { getDefaultValueFilterInputs } from "@/services/api/FiltersServiceApi";
import { mapMutations, mapState } from "vuex";
import {debounce} from "@/helpers/strings";

export default {
  name: "VehiclePrice",
  components: { MenuTitle },
  directives: { maska: vMaska },
  data: () => ({
    currency: {
      mask: ["#.###", "###.###.###.###.###.###"],
      reversed: true,
    },
    priceMaxPlaceholder: '',
    priceMinPlaceholder: '',
  }),
  computed: {
    ...mapState({
      priceMin: (state) => state.filterForm.params.priceMin ?? state.filterForm.priceMin,
      priceMax: (state) => state.filterForm.params.priceMax ?? state.filterForm.priceMax,
    }),
  },
  methods: {
    ...mapMutations("filterForm", ["setPriceMin", "setPriceMax"]
    ),
    priceChange() {

      const price_from = document.getElementById("price_from");
      const price_at = document.getElementById("price_at");

      const price_from_int = parseInt(price_from.value.replace(/\./g,''));
      const price_at_int = parseInt(price_at.value.replace(/\./g,''));
      
      const from_parent_closest = price_from.closest(".v-input");
      const e_parent_closest = price_at.closest(".v-input");
      const message_place = document.getElementById("price_from_message");        
  
      if (price_at_int && price_from_int) {
          if (price_from_int > price_at_int) {
            from_parent_closest.classList.add("input-field-error");
            message_place.innerText = "Preço inicial maior que final";
            message_place.classList.add("error");

            e_parent_closest.classList.add("input-field-error");
          } else {
            from_parent_closest.classList.remove("input-field-error");
            message_place.innerText = "";
            message_place.classList.remove("error");

            e_parent_closest.classList.remove("input-field-error");
          }
      }
      else {
        from_parent_closest.classList.remove("input-field-error");
        message_place.innerText = "";
        message_place.classList.remove("error");

        e_parent_closest.classList.remove("input-field-error");
      }
    },
    setDefaultDataValue(response){
        const { valor_max, valor_min } = response.data;
        const valorMax = formatarMilhagem(valor_max);
        const valorMin = formatarMilhagem(valor_min);
        this.priceMaxPlaceholder = valorMax?.toString() || '';
        this.priceMinPlaceholder = valorMin?.toString() || '';
    },
    setPriceMinHandle: debounce(function (price) {
      this.setPriceMin(price);
    }, 1000),
    setPriceMaxHandle: debounce(function (price) {
      this.setPriceMax(price);
    }, 1000),
  },
  mounted() {
    getDefaultValueFilterInputs().then((response) => {
      this.setDefaultDataValue(response.data.payload);
    });
  },
};
function formatarMilhagem(valor) {
    const reversedValue = String(valor).split('').reverse().join('');
    const partesFormatadas = [];
    for (let i = 0; i < reversedValue.length; i += 3) {
        partesFormatadas.push(reversedValue.slice(i, i + 3));
    }
    const valorFormatado = partesFormatadas.join('.').split('').reverse().join('');
    return valorFormatado;
}
</script>

<style>
.v-field--variant-outlined .v-field__outline__start.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__start{
  border-radius:12px 0 0 12px !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
}
.v-field--variant-outlined .v-field__outline__end.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__end{
  border-radius:0 12px 12px 0 !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.v-field--variant-outlined .v-field__outline__notch::before{
  border-width: 2px 0 0;
}
.v-field--variant-outlined .v-field__outline__notch::after{
  border-width: 0 0 2px;
}

.my-input.v-input .v-input__slot {
  border-radius: 100px !important;
}

.custom-text-field--label, .v-text-field__prefix{
  color:#939292;
  font-weight: 600;
}
.field-example {
  color: #cdcdcd;
  display: inline-block;
  margin-top: 5px;
}
.input-field-error {
  color: red;
}
.input-field-message {
  display: none;
  font-size: 12px;
}
.input-field-message.error {
  display: block;
  color: red;
}
</style>
