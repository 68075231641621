<template>
  <div class="lots-unfulfilled-purchases-page" v-if="isMobile()">
    <div class="lots-unfulfilled-purchases-page-top">
      <BuyerSectionItem
        :icon="this.icon"
        :title="this.title"
        :description="this.description"
        :shouldReturnToTheBuyerPage="true"
      />
    </div>

    <div class="lots-unfulfilled-purchases-page-content">
      <PageUnderConstruction
        title="{{ title }}"
      />
    </div>
  </div>
  <PageUnderConstruction
    title="{{ title }}"
    v-else
  />
</template>

<script>
import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";
import PageUnderConstruction from "@/components/PageUnderConstruction";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

export default {
  name: "LotsUnfulfilledPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerSectionItem, PageUnderConstruction },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title:"Lotes",
      section: "Encerrados"
    };
  },
};
</script>

<style scoped>
.lots-unfulfilled-purchases-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.lots-unfulfilled-purchases-page-top {
  width: 100%;
}

.lots-unfulfilled-purchases-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
