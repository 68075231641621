<template>
  <div class="container-all-tooltip">
  <VTooltip
      placement="top-start"
      @click="toggleTooltipMobile"
  >
    <template #popper>{{tooltipText}}</template>
    <v-icon class="info-icon" icon="mdi-information-slab-circle"></v-icon>

    <div v-if="showTooltipMobile" class="container-tooltip-mobile">
      {{tooltipText}}
      <div class="caret-down"></div>
    </div>
    
  </VTooltip>
  </div>
</template>
<script>
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
export default {
    name: "FipeIconInfo",
    data() {
      return {
        tooltipText: 'Porcentagem do valor correspondente à tabela FIPE',
        showTooltipMobile: false
      }
    },
    methods: {
    isMobile() {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
    toggleTooltipMobile() {
      if(this.isMobile()){
        this.showTooltipMobile = !this.showTooltipMobile;
      }
    }
  }
};
</script>
<style scoped>
.container-all-tooltip{
  position: relative;
}
.caret-down {
  bottom:-5px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #201f1f;
  left: 102px;
}
.container-tooltip-mobile{
  text-align: center;
  width: 300px;
  position: absolute;
  bottom:30px;
  left: -90px;
  font-size: 13px;
  background-color: #201f1f;
  color: #fff;
  padding: 5px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
span {
  position: relative;
  margin-left: 6px;
  width: 24px;
  height: 24px;
  font-weight: 600;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-size: 12px;
  color: #ffffff;
  z-index: 1;
}
span img {
  position: absolute;
  z-index: -1;
}

.info-icon {
  margin-bottom: 1px;
  margin-left: 5px;
  color: lightgray;
  cursor: pointer;
}

</style>
