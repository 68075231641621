import { createStore } from "vuex";

import drawerModule from "./modules/drawerModule";
import sectionsModule from "./modules/sectionsModule";
import filterFormModule from "./modules/filterFormModule";
import favoriteModule from "./modules/favoriteModule";
import dealerModule from "./modules/dealerModule";
import campaignModule from "./modules/campaignModule";
import buyerModule from "./modules/buyerModule";
import globalModule from "./modules/globalModule";
import buyerLotDetailModule from "./modules/buyerLotDetailModule";
import walletModalModule from "./modules/walletModalModule";
import makeEvaluationViewModalModule from "./modules/makeEvaluationViewModalModule";
import releasePaymentViewModalModule from "./modules/releasePaymentViewModalModule";
import walletModule from "./modules/walletModule";
import globalFlagsCompanyModule from "./modules/globalFlagsCompanyModule";
import participantFlagsModule from "./modules/participantFlagsModule";
import filterShowcaseModule from "./modules/filterShowcaseModule";
import followCarModalModule from "@/store/modules/followCarModalModule";

export default createStore({
  modules: {
    drawer: drawerModule,
    sections: sectionsModule,
    filterForm: filterFormModule,
    favorite: favoriteModule,
    filterShowcase: filterShowcaseModule,
    ads: campaignModule,
    buyer: buyerModule,
    dealer: dealerModule,
    buyerLotDetail: buyerLotDetailModule,
    global: globalModule,
    walletModal: walletModalModule,
    makeEvaluationViewModal: makeEvaluationViewModalModule,
    followCarModalModule: followCarModalModule,
    releasePaymentViewModal: releasePaymentViewModalModule,
    wallet: walletModule,
    globalFlagsCompany: globalFlagsCompanyModule,
    participantFlags: participantFlagsModule,
  },
});
