<template>
  <header>
    <v-container fluid class="header-container">
      <v-row>
        <v-col>
          <v-img
            :width="200"
            cover
            :src="require('@/assets/logo.png')"
            @click="reloadHomePageNewPortal"
            class="logo"
            id="aav-logo"
          ></v-img>
        </v-col>
        <v-col justify="center" align="end">
          <v-container class="header-container-right" fluid>
            <v-row justify="end" align="center" fluid>
              <div class="mr-8 d-flex">
                <BackToLegacyButton />
              </div>
              <div class="wish-list">
                <i class="fa fa-feed"></i>
                <button
                    @click="openModal"
                >
                  Lista de Desejos
                </button>
              </div>
              <div class="divider-vertical mr-12 ml-8"></div>

              <profile-modal />
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="sections mt-6">
        <v-col>
          <ul>
            <li v-for="(section, key) in sections" :key="key">
              <a
                :href="section.link"
                @click="section.path ? $router.push(section.path) : ''"
                :id="`header-menu-item-${section.id}`"
              >
                <img width="15" height="12" v-if="section.icon.endsWith('.svg')" :src="section.icon" :alt="section.id" />
                <i v-else :class="`fa ${section.icon}`"></i>
                {{ section.label }}</a
              >
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </header>
  <ModalWishList />

</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import SimpleHeaderMixin from "./mixins/SimpleHeaderMixin";
import BackToLegacyButton from "./components/BackToLegacyButton.vue";
import ProfileModal from "./components/ProfileModal.vue";
import ModalWishList from "@/components/ModalWishList.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SimpleHeaderDesktop",
  mixins: [NavigateMixings, SimpleHeaderMixin],
  data: () => ({
    subMenuOpened: false,
  }),
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.followCarModalModule.modalIsOpen,
    }),
  },
  methods: {
    ...mapMutations("followCarModalModule", ["toggleModal"]),
    ...mapActions("followCarModalModule", ["getAlertList"]),
    openModal() {
      this.toggleModal();
      if (this.modalIsOpen) {
        this.getAlertList();
      }
    }
  },
  components: { BackToLegacyButton, ProfileModal, ModalWishList },
};
</script>

<style lang="scss" scoped>
header {
  padding: 14px 23px;
  padding-bottom: 0px;
  background: linear-gradient(to right, #333, #000);

  .logo {
    cursor: pointer;
    margin-top: 3px;
  }

  .header-container {
    padding-bottom: 6px;

    h1 {
      color: white;
    }

    p {
      color: white;
    }

    .sections {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
          float: left;
          border-bottom: 2px solid rgba(255, 255, 255, 0);

          a {
            display: block;
            color: white;
            text-align: center;
            padding: 10px;
            text-decoration: none;
            font-size: 12px;
            font-weight: 400;
            font-family: "Roboto", Roboto, Helvetica, sans-serif;
            line-height: 17px;
            cursor: pointer;
          }
        }

        li:hover {
          border-bottom: 2px solid white;
        }
      }
    }

    .header-container-right {
      .follow-car-button {
        font-family: "Roboto", Roboto, Helvetica, sans-serif;
        color: white;
        text-decoration: none;
        font-size: 12px;
        font-weight: normal;
      }

      .wish-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        gap: 10px;


      }

      .wish-list > button {
        font-size: 9px;
      }

      .divider-vertical::before {
        border-left: 1px solid #363636;
        border-right: 1px solid #323232;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
        content: "";
        height: 100%;
        padding: 0;
        position: relative;
        top: 0;
        transform: translate(-50%, 0);
        width: 0;
      }

      .razao {
        position: relative;
        color: white;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        cursor: pointer;

        .icon {
          border: 2px solid white;
          border-radius: 100%;
          padding: 6px;
          font-size: 9px;
        }

        .text {
          font-size: 9px;
        }

        .sub-menu {
          top: 40px;
          right: 0;
          position: absolute;
          list-style-type: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          float: left;
          background: #fff;
          border-color: #fff;
          border-radius: 4px;
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
          min-width: 200px;
          padding: 3.25px 0;
          z-index: 2;

          li {
            a {
              display: block;
              color: black;
              text-align: center;
              padding: 10px;
              text-decoration: none;
              font-size: 12px;
              font-family: "Roboto", Roboto, Helvetica, sans-serif;
              cursor: pointer;

              display: block;
              line-height: 24px;
              overflow: hidden;
              padding: 5.5px 15px;
              user-select: none;
              z-index: 1;
              font-size: 11px;
              width: 100%;
              text-align: left;
            }

            border-bottom: 1px solid #aaa;
          }

          li:last-child {
            border-bottom: 0;
          }

          li:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
