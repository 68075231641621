<template>
  <TopAppMobile v-if="isMobile()"  />
  <TopAppDesktop v-else />
</template>

<script>
import TopAppMobile from "./TopAppMobile";
import TopAppDesktop from "./TopAppDesktop";
import PlatformsMixins from "@/mixins/PlatformsMixins";

export default {
  name: "TopApp",
  inheritAttrs: false,
  customOptions: {},
  mixins: [PlatformsMixins],
  components: {
    TopAppMobile,
    TopAppDesktop,
  },
};
</script>
