<template>
  <div v-if="this.reference == 'model'" class="model-scope-item">
    <div
      v-if="verifySelectedItem('Modelos')"
      style="border-bottom: 1px solid #e5e4e4"
    >
      <span>
        {{ getSelectedItem()["value"]?.toUpperCase() ?? getSelectedItem()["name"]?.toUpperCase() }}
        <button
          @click="() => deleteSelectedItem('models', getSelectedItem())"
          style="float: right"
          :id="`marca-veiculo-delete-item-${getSelectedItem()['name']}`"
          
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </button>
      </span>
    </div>
    <button
      v-if="!verifySelectedItem('Modelos')"
      @click="this.seeAllhandle('models')"
      :id="`marca-veiculo-todos-os-modelos`"
      
    >
      Todos os modelos
      <v-icon class="ms-2">mdi-chevron-right</v-icon>
    </button>
  </div>
  <div v-if="this.reference == 'version'" class="model-scope-item">
    <div v-if="verifySelectedItem('Versões')">
      <span>
        <span style="font-size: 0.84em">
        {{ getSelectedItem()["value"]?.toUpperCase() ?? getSelectedItem()["name"]?.toUpperCase() }}
        </span>
        <button
          @click="
            () => deleteSelectedItem('version', getSelectedItem())
          "
          style="float: right"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </button>
      </span>
    </div>
    <button
      v-if="!verifySelectedItem('Versões')"
      @click="this.seeAllhandle('versions')"
      :disabled="!verifySelectedItem('Modelos')"
      :style="!verifySelectedItem('Modelos') ? 'opacity: 0.5' : ''"
      :id="`marca-veiculo-todos-as-versoes`"
    >
      Todas as versões
      <v-icon class="ms-2">mdi-chevron-right</v-icon>
    </button>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import store from "@/store";

export default {
  name: "VehicleModelScope",
  data() {
    return {
      last_scope: null,
    };
  },
  props: {
    reference: {
      type: String,
      required: true,
    },
    seeAll: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.reference === 'model') {
      if (this.models?.length > 0) {
        const modelsVar = JSON.stringify(this.params["models"]);
        this.startSelectModelItems(modelsVar);
      } else if (this.models === undefined) {
        this.params["models"] = [];
      }
    }
    if (this.reference === 'version') {
      if (this.versions?.length > 0) {
        const versionVar = JSON.stringify(this.params["versions"]);
        this.startSelectVersionItems(versionVar);
      } else if (this.versions === undefined) {
        this.params["versions"] = [];
      }
    }
  },
  methods: {
    ...mapMutations("drawer", [
      "showSeeAllDrawer",
      "setSeeAllDrawerObjDetails",
      "unsetSeeAllDrawerObjSelected",
      "setSeeAllDrawerObjSelected",
      "clear",
    ]),
    async seeAllhandle(scope) {
      this.last_scope = scope;

      this.setSeeAllDrawerObjDetails({
        title: this.seeAll.menu,
        subtitle: this.seeAll.submenu,
        placeholder: this.seeAll.placeholder,
        handle: this.seeAll.handle,
        scope: this.seeAll.scope,
      });
      this.showSeeAllDrawer();
    },
    verifySelectedItem(reference) {
      if (!reference && !this.seeAll.scrollTo) return false;
      const key =
        "key-" +
        (this.seeAll.scope ? `${this.seeAll.scope}-` : "") +
        (reference ?? this.seeAll.scrollTo);
      let objSelected = Object.keys(this.seeAllDrawerObjSelected);

      return (
        objSelected.filter((item) => {
          return item === key;
        }).length > 0 &&
        this.seeAllDrawerObjSelected[key].length > 0 &&
        this.seeAllDrawerObjSelected[key].filter(
          (item) => item.parentUuid === this.seeAll.parentUuid
        ).length > 0
      );
    },
    getSelectedItem() {
      const key =
        "key-" +
        (this.seeAll.scope ? `${this.seeAll.scope}-` : "") +
        (this.seeAll.scrollTo ?? this.seeAll.menu);

      const result = this.seeAllDrawerObjSelected[key].filter(
        (item) => item.parentUuid === this.seeAll.parentUuid
      )[0];
      const name = result.name.toLowerCase().replace(" ", "-");
      const value = result?.value;
      const marca = result?.marca;
      const uuid = result?.uuid;
      const modelo = result?.modelo;

      if (this.last_scope) {
        if (this.last_scope == "models") {
          const index_model = this.params["models"].findIndex(
            (model) => model.id == name
          );

          if (index_model < 0) {
            this.$store.commit("filterForm/setParam", {
              models: [
                ...this.params.models,
                {
                  id: name,
                  value: value ?? name,
                  uuid,
                  parentUuid: this.seeAll.parentUuid,
                  marca
                },
              ],
            });
          }
        }

        if (this.last_scope == "versions") {
          const index_version = this.params["versions"].findIndex(
            (version) => version.id == name
          );

          if (index_version < 0) {
            this.$store.commit("filterForm/setParam", {
              versions: [
                ...this.params.versions,
                {
                  id: name,
                  value: value ?? name,
                  uuid,
                  parentUuid: this.seeAll.parentUuid,
                  modelo
                },
              ],
            });
          }
        }
      }

      return result;
    },
    deleteSelectedItem(scope, item) {

      const key =
        "key-" +
        (this.seeAll.scope ? `${this.seeAll.scope}-` : "") +
        (this.seeAll.scrollTo ?? this.seeAll.menu);

      const modelsIndex = this.params.models.findIndex(
          (model) => model.value.toLowerCase() === item.value.toLowerCase()
      );
      const versionsIndex = this.params.versions.findIndex(
          (v) => v.value.toLowerCase() === item.value.toLowerCase()
      );

      if (scope === "models" && modelsIndex >= 0) {
        this.unsetSeeAllDrawerObjSelected({ key, uuid: this.params.models[modelsIndex].uuid });
        this.params.models.splice(modelsIndex, 1);
        const versionChildrenIndex = this.params.versions.findIndex(
            (v) => v.modelo.toLowerCase() === item.value.toLowerCase()
        );
        if (versionChildrenIndex >= 0) {
          this.unsetSeeAllDrawerObjSelected({ key: key.replace('Modelos', 'Versões'), uuid: this.params.versions[versionChildrenIndex].uuid });
          this.params.versions.splice(versionChildrenIndex, 1);
        }
      }

      if (scope === "version" && versionsIndex >= 0) {
        this.unsetSeeAllDrawerObjSelected({ key, uuid: this.params.versions[versionsIndex].uuid });
        this.params.versions.splice(versionsIndex, 1);
      }

      store.commit("filterForm/refreshSearch");

    },
    startSelectModelItems(values) {
      values = JSON.parse(values);
      values = values.filter(m => m?.marca?.toLowerCase().toString() === this.seeAll.scope.toLowerCase().toString())
      const key =
          "key-" +
          (this.seeAll.scope ? `${this.seeAll.scope}-` : "") +
          (this.seeAll.scrollTo ?? this.seeAll.menu);

      const brandUuid = this.brands.filter(b => b.id.toLocaleLowerCase().toString() === this.seeAll.scope.toLowerCase().toString())[0]?.uuid ?? null
      values.forEach(m => {

        const notExists = (!this.seeAllDrawerObjSelected[key] || this.seeAllDrawerObjSelected[key].filter(
            el => el.id.toLowerCase() === m.id.toLowerCase() || el.id.toLowerCase() === m.value.toLowerCase()
        ).length === 0);

        const item = {
          id: m.id.toLowerCase(),
          name: m.title,
          value: m.value,
          marca: m.marca,
          uuid: Math.random().toString(16).slice(2),
          parentUuid: brandUuid,
        };
        const index = this.models.findIndex(item => item.id === m.id);

        if (index !== -1 && notExists) {
          this.params['models'][index] = item;

          this.setSeeAllDrawerObjSelected({
            key,
            value: item,
            parentUuid: brandUuid,
            uuid: item.uuid
          });
        }
      })
    },
    startSelectVersionItems(values) {
      values = JSON.parse(values);
      const modelo = this.models.filter(m => m.marca.toLowerCase().toString() === this.seeAll.scope.toLowerCase())[0] ?? null
      const modelUuid = modelo?.parentUuid ?? null

      values = values.filter(v => v?.modelo?.toLowerCase() === modelo?.value?.toLowerCase())

      const key =
          "key-" +
          (this.seeAll.scope ? `${this.seeAll.scope}-` : "") +
          (this.seeAll.scrollTo ?? this.seeAll.menu);

      values.forEach(m => {

        const notExists = (!this.seeAllDrawerObjSelected[key] || this.seeAllDrawerObjSelected[key].filter(
            el => el.id.toLowerCase() === m.id.toLowerCase() || el.id.toLowerCase() === m.value.toLowerCase()
        ).length === 0);

        const item = {
          id: m.id.toLowerCase(),
          name: m.title,
          value: m.value,
          modelo: m.modelo,
          parentUuid: modelUuid,
        };
        const index = this.versions.findIndex(item => item.id === m.id);

        if (index !== -1 && notExists) {
          this.params['versions'][index] = item;

          this.setSeeAllDrawerObjSelected({
            key,
            value: item,
            parentUuid: modelUuid
          });
        }
      })
    },
  },
  computed: {
    ...mapState({
      seeAllDrawerObjDetails: (state) => state.drawer.seeAllDrawerObjDetails,
      seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
      params: (state) => state.filterForm.params,
      models: (state) => state.filterForm.params["models"],
      brands: (state) => state.filterForm.params["brands"],
      versions: (state) => state.filterForm.params["versions"],
      loading: (state) => state.filterForm.loading,
    }),
  },
};
</script>
<style scoped>
.model-scope-item {
  text-align: left;
  width: 70%;
  float: right;
  padding: 15px 0 5px 0;
}
</style>
