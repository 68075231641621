import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export const getDealersData = async () => {
  return axios
    .get(`${API_BASE}/dealer`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `dealer ${error}`);
};

export const getDealerUfsData = async (url) => {
  return axios
    .get(`${API_BASE}/dealer/${url}/ufs`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `dealer ufs ${error}`);
};

export const favoriteDealer = async (id) => {
  return axios
    .get(`${API_BASE}/dealer/favorite/add/${id}`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `dealer ufs ${error}`);
};

export const unFavoriteDealer = async (id) => {
  return axios
    .get(`${API_BASE}/dealer/favorite/remove/${id}`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `dealer ufs ${error}`);
};


export const getRegionsData = async () => {
  return axios
    .get(`${API_BASE}/regions`, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `regions ${error}`);
};
