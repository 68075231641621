import axios from "axios";

import API_BASE from "@/constants/ApiBase";

export default function getFavorites() {
  return axios
    .get(`${API_BASE}/evaluation/favorite`, [], {
      withCredentials: true,
    })
    .then((response) => response.data.payload)
    .catch((error) => `getFavorites ${error}`);
}
export function getAutoBidsFavorite() {
  return axios
    .get(`${API_BASE}/autoBid/favorite`, [], {
      withCredentials: true,
    })
    .then((response) => response.data.payload)
    .catch((error) => `getAutoBidsFavorite ${error}`);
}
export function getDealersFavorite() {
  return axios
    .get(`${API_BASE}/dealer/favorite`, [], {
      withCredentials: true,
    })
    .then((response) => response.data.payload)
    .catch((error) => `getDealersFavorite ${error}`);
}