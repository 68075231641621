import PATHS from "@/constants/Paths";
import PORTAL_BASE from "@/constants/PortalBase";

export function getLinkForVdp(vehicle) {
  const base = `${PORTAL_BASE}/avaliacoes/autoavaliar`;
  const vehicleFormatted =
    `${vehicle.id_avaliacao ?  vehicle.id_avaliacao : vehicle.ava_id}/${vehicle.v_marca ? vehicle.v_marca : "."} ${vehicle.v_modelo} ${vehicle.v_versao}`
      .replace(".", "")
      .replace(/\s/g, "-")
      .toLowerCase();
  return `${base}/${vehicleFormatted}`;
}

export function gotoVdp(vehicle) {
  window.open(getLinkForVdp(vehicle), '_blank')
}

export function gotoDealerPage(dealer, uf = '') {
  let base = `${PORTAL_BASE}/avaliacoes/${dealer.url}`;

  if (uf != '') {
    base += `?location=${uf.toLowerCase()}`
  }

  window.open(base, '_blank')
}

export function gotoAdvertisementsPage() {
  let base = `${PATHS.Vehicles}`;
  window.open(base, '_blank')
}