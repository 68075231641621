import { BUYER } from "@/constants/Buyer";
import countsLeftMenu from "@/services/api/BuyerServiceApi";

const DEFAULT_STATE = {
  sectionPage: BUYER.TITLE_SECTION_DEFAULT,
  titlePage: BUYER.TITLE_DASHBOARD,
  subTitlePage: "",
  thirdTitlePage: "",
  descriptionPage: "",
  iconPage: "",
  priceDescriptionPage: "",
  vehicleDetail: null,
  lotDetail: null,
  countsLeftMenu: null,
  buyerScope: null,
  period: BUYER.DEFAULT_PERIOD_VALUE,
  handleRequestByPeriod: null,
  currentStatusLote:''
};

const buyerModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setTitlePage(state, title) {
      state.titlePage = title;
    },
    setTitleSectionPage(state, title) {
      state.sectionPage = title;
    },
    setSubTitlePage(state, title) {
      state.subTitlePage = title;
    },
    setDescriptionPage(state, descriptionPage) {
      state.descriptionPage = descriptionPage;
    },
    setIconPage(state, iconPage) {
      state.iconPage = iconPage;
    },
    setVehicleDetail(state, vehicleDetail) {
      state.vehicleDetail = vehicleDetail;
    },
    setLotDetail(state, lotDetail) {
      state.lotDetail = lotDetail;
    },
    setPriceDescriptionPage(state, priceDescriptionPage) {
      state.priceDescriptionPage = priceDescriptionPage;
    },
    setResultCountsLeftMenu(state, result) {
      state.countsLeftMenu = result;
    },
    setBuyerScope(state, scope) {
      state.buyerScope = scope;
    },
    setPeriod(state, period) {
      state.period = period;
    },
    setHandleRequestByPeriod(state, handleRequestByPeriod) {
      state.handleRequestByPeriod = handleRequestByPeriod;
    },
    setCurrentStatusLote(state, statusLote) {
      state.currentStatusLote = statusLote;
    },
    setThirdTitlePage(state, thirdTitlePage) {
      state.thirdTitlePage = thirdTitlePage;
    }
  },
  actions: {
    async getCountsLeftMenu({ commit }) {
      try {
        let response = await countsLeftMenu();
        commit("setResultCountsLeftMenu", response);
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    },
    async refreshRequestByPeriod({ state }) {
      await state.handleRequestByPeriod(state.period);
    },
    async handlePeriod({ commit, dispatch }, period) {
      commit('setPeriod', period);
      dispatch('refreshRequestByPeriod');
    },
  },
};

export default buyerModule;
