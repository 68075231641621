const DEFAULT_STATE = {
  titlePage: '',
  titleContainer: '',
  total: 0,
  lotDetail: null,
  isActive: true,
  scope: null,
  currentLotId: ''
};

const buyerLotDetailModule = {
  namespaced: true,
  state() {
    return DEFAULT_STATE;
  },
  mutations: {
    setTitlePage(state, titlePage) {
      state.titlePage = titlePage;
    },
    setTitleContainer(state, titleContainer) {
      state.titleContainer = titleContainer;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setLotDetail(state, lotDetail) {
      state.lotDetail = lotDetail;
    },
    setIsActive(state, isActive) {
      state.isActive = isActive;
    },
    setCurrentLot(state, currentLot) {
      state.currentLotId = currentLot.ava_id;
    },
    setScope(state, scope) {
      state.scope = scope;
    },
  },
};

export default buyerLotDetailModule;
