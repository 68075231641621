import axios from "axios";
import API_BASE from "@/constants/ApiBase";

export const getAlertList = async () => {
    return axios
        .get(`${API_BASE}/followcar/alerts`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => response?.data?.payload ?? [])
        .catch((error) => error);
};
export const deleteAlertItem = async (id) => {
    return axios
        .delete(`${API_BASE}/followcar/delete/${id}`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => response)
        .catch((error) => error);
};
export const saveAlertItem = async (data) => {
    return axios
        .post(`${API_BASE}/followcar/save`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => response)
        .catch((error) => error);
};
