import axios from "axios";

import API_BASE from "@/constants/ApiBase";

export const releasePayment = async (id_avaliacao) => {
  return axios
    .post(`${API_BASE}/purchasers/releasePayment/${id_avaliacao}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
};