<template>
  <button>
    <img
      :src="require('@/assets/icons/' + icon + '.svg')"
      height="10.62"
      width="14.88"
    />
    <span :style="{ color, textDecorationColor: color }">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "ButtonWithIcon",
  props: {
    icon: String,
    text: String,
    color: String,
  },
};
</script>

<style scoped>
button {
  border: none;
  text-decoration: none;
  background: #ffffff;
  color: #393939;
  cursor: pointer;
  font-family: "Lexend Deca";
  font-size: 16px;
  margin: 0;
  padding: 0;
}

button:hover {
  opacity: 0.9;
}

img {
  margin-right: 0.1em;
}

span {
  text-decoration-line: underline;
}
</style>
