<template>
  <div id="dashboard-top-mobile">
    <BuyerSectionItem
      v-for="(item, index) in sections"
      :key="index"
      :icon="item.icon"
      :title="item.title"
      :description="item.description"
      :action="item.action"
    />
  </div>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import { BUYER_SECTION_IN_PROGRESS, BUYER_SECTION_PENDING, BUYER_SECTION_CLOSED, BUYER_SECTION_SERVICE } from "@/views/buyer/constants/BuyerSections";

export default {
  name: "DashboardTopMobile",
  mixins: [NavigateMixings],
  components: { BuyerSectionItem },
  data: function () {
    return {
      sections: [
        { ...BUYER_SECTION_IN_PROGRESS, action: this.natigateToInProgressPage },
        { ...BUYER_SECTION_PENDING, action: this.natigateToPedingPage },
        { ...BUYER_SECTION_CLOSED, action: this.natigateToClosedPage },
        { ...BUYER_SECTION_SERVICE, action: this.natigateToServicePage },
      ],
    };
  },
};
</script>

<style scoped>
#dashboard-top-mobile {
  display: flex;
  flex-direction: column;
}
</style>
