import axios from "axios";

import PORTAL_BASE from "@/constants/PortalBase";

export default function search(params = []) {
  params.per_page = 12;
  params.p = params.p ?? 1;
  params.quotation = params.quotation ?? -1;
  params.category = Object.hasOwnProperty.call(params, "category")
    ? params.category.join(",")
    : [];

  if (Array.isArray(params.vehicleCondition)) {
    params.vehicleCondition.forEach(function (type) {
      if (type === "blindado") {
        params.armoured = "yes";
      } else if (type === "showroom") {
        params.showroom = "yes";
      }
    });
    delete params.vehicleCondition;
  }

  if (params.reportStatus !== "" && params.reportStatus?.length > 0) {
    params.report = "yes";

    if (
      params.reportStatus.length === 1 &&
      params.reportStatus.indexOf("no") !== -1
    ) {
      params.report = "no";
      params.reportStatus = "";
    }
  }
  return axios
    .postForm(`${PORTAL_BASE}/async/veiculos`, params, {
      withCredentials: true,
    })
    .then((response) => response.data)
    .catch((error) => `search ${error}`);
}


