<template>
  <v-list v-model:opened="open" style="padding: 0">
    <v-list-group value="Open">
      <v-list-group
        value="Open"
        :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
      >
        <div class="sub-header" v-bind="props">
          <div class="prepend-icon green">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="item-label">Compras concretizadas</div>
        </div>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToAnnouncementCompletedPurchasesClosedPage()"
            id="encerrados_concretizados_avaliacoes_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Avaliações
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToOnDemandAssessmentCompletedPurchasesPage()"
            id="encerrados_concretizados_avaliacoes_sob_aprovacao_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Avaliações sob aprovação
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToProposalCompletedPurchasesClosedPage()"
            id="encerrados_concretizados_propostas_aceitas_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Propostas aceitas
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToQuotesCompletedPurchasesClosedPage()"
            id="encerrados_concretizados_cotacoes_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Cotações
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToAutoBidCompletedPurchasesClosedPage()"
            id="encerrados_concretizados_autobid_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              AutoBid
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToBuyNowCompletedPurchasesClosedPage()"
            id="encerrados_concretizados_compre_ja_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Compre Já
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>

      </v-list-group>

      <v-list-group
        value="Open"
        :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
      >
        <div class="sub-header" v-bind="props">
          <div class="prepend-icon red">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="item-label">Não concretizadas</div>
        </div>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToAssessmentUnfulfilledPurchasesClosedPage()"
            id="encerrados_nao_concretizadas_avaliacoes_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Avaliações superadas
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToOnDemandAssessmentUnfulfilledPurchasesClosedPage()"
            id="encerrados_nao_concretizadas_avaliacoes_sob_aprovacao_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Avaliações sob aprovação<br/>rejeitadas
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToRejectedUnfulfilledPurchasesClosedPage()"
            id="encerrados_nao_concretizadas_propostas_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Propostas rejeitadas
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToQuotesUnfulfilledPurchasesClosedPage()"
            id="encerrados_nao_concretizadas_cotacoes_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Cotações
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToAutoBidUnfulfilledPurchasesClosedPage()"
            id="encerrados_nao_concretizadas_autobid_menu_item"

          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              AutoBid
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>

      </v-list-group>

      <v-list-group
        value="Open"
        :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
      >
        <div class="sub-header" v-bind="props">
          <div class="prepend-icon">
            <i class="fa-solid fa-circle" style="color: #ff7a00"></i>
          </div>
          <div class="item-label">Cancelamentos</div>
        </div>
        <v-list-group
          value="Open"
          :style="`margin: ${isMobile ? '10px 0' : '20px 0'}`"
        >
          <div
            class="sub-item"
            v-bind="props"
            v-on:click="natigateToCanceledUnfulfilledPurchasesClosedPage()"
            id="encerrados_cancelamentos_compras_canceladas_menu_item"
          >
            <div class="prepend-icon">
              <i class="fa-solid fa-circle"></i>
            </div>
            <div class="sub-item-label">
              Compras canceladas
            </div>
            <div class="append-icon">
              <img :src="require('@/assets/icons/right-icon.svg')" />
            </div>
          </div>
        </v-list-group>
      </v-list-group>
    </v-list-group>
  </v-list>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import FilterSidebarSectionMixin from "@/views/buyer/components/FilterSidebar/mixins/FilterSidebarSectionMixin";
import { mapState } from "vuex";

export default {
  name: "FilterSidebarInClosedItems",
  mixins: [FilterSidebarSectionMixin, NavigateMixings],
  props: {
    isMobile: Boolean,
  },
  computed: {
    ...mapState({
      countsLeftMenu: (state) => state.buyer.countsLeftMenu,
    }),
    subItemPadding: function () {
      if (this.isMobile) {
        return "8px 0";
      }
      return "";
    },
    subItemBackgroundColor: function () {
      if (this.isMobile) {
        return "#FAFAFA";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  padding: 10px 0;
}
.item .prepend-icon {
  color: #f90;
  flex: 8;
  text-align: right;
}
.item .prepend-icon > * {
  vertical-align: bottom;
}
.item .item-label {
  flex: 73;
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}
.item .append-icon {
  flex: 8;
}
.sub-item {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  cursor: pointer;
  background-color: v-bind(subItemBackgroundColor);
  padding: v-bind(subItemPadding);
}
.sub-item .prepend-icon {
  color: #f90;
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 7;
}
.sub-item .prepend-icon > * {
  vertical-align: sub;
}
.sub-item .sub-item-label {
  color: #ff7a00;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex: 84;
  padding-left: 10px;
  display: flex;
}
.sub-item .sub-item-label > * {
  color: #e1e1e1;
}
.sub-item .append-icon {
  flex: 8;
}
.sub-sub-item {
  display: flex;
  flex-direction: row;
  padding: 2px 0;
}
.sub-sub-item .prepend-icon {
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 15;
  color: #5b5b5b;
}
.sub-sub-item-label {
  flex: 85;
  padding-left: 8px;
  color: #5b5b5b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}
.sub-item.pending {
  padding: 3px 0;
}
.sub-item.pending .prepend-icon {
  color: #6e6e6e;
}
.sub-item.pending .sub-item-label .label {
  color: #6e6e6e;
}
.sub-header {
  display: flex;
  flex-direction: row;
}
.sub-header .prepend-icon {
  flex: 8;
  text-align: right;
  font-size: 8px;
  padding: 5px 0;
}
.sub-header .prepend-icon > * {
  vertical-align: bottom;
}
.sub-header .prepend-icon.red {
  color: #f22300;
}
.sub-header .prepend-icon.green {
  color: #0dc561;
}
.sub-header .item-label {
  flex: 92;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}
</style>
