import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";
import { URL } from "@/constants/Buyer";
import { PER_PAGE_BUYER_PAGINATION } from "@/views/buyer/constants/BuyerPagination";
import removeDuplicateObjectApi from "@/helpers/removeDuplicateObjectApi";
import { filterPackageInMobile } from "@/helpers/packageHelper";

export const setFavorite = async (vehicleId) => {
  return axios
    .post(`${API_BASE}/purchasers/evaluation/favorite/${vehicleId}`, {}, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => `buyer details ${error}`);
};

export const details = async () => {
  return axios
    .get(`${API_BASE}/purchasers/dashboard/counts`, {
      headers: {
        token: API_TOKEN,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer details ${error}`);
};

export const countsLeftMenu = async () => {
  return axios
    .get(`${API_BASE}/purchasers/counts`, {
      headers: {
        token: API_TOKEN,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getPaymentPeding = async (orderBy = null) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/pending/boletos`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.pendentes || (Array.isArray(payload) && payload.length === 0)) {
        return [];
      }
      return response.data.payload.pendentes.boletos // return all data with packages
    }
    )
    .catch((error) => `buyer counts ${error}`);
};

export const getBidPending = async (orderBy = null) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/pending/sob_aprovacao`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.pendentes || (Array.isArray(payload) && payload.length === 0)) {
        return [];
      }
      return filterPackageInMobile(response.data.payload.pendentes.sob_aprovacao)
    })
    .catch((error) => `buyer counts ${error}`);
};

export const getReleasePeding = async (orderBy = null) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/pending/autopay`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
      },
    })
    .then((response) => response.data.payload?.pendentes?.autopay ?? [])
    .catch((error) => `buyer counts ${error}`);
};
export const getProposalPeding = (orderBy = null) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/pending/aprovacao`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
      },
    })
    .then((response) => response.data.payload?.pendentes?.aprovacao ?? [])
    .catch((error) => `buyer counts ${error}`);
};

export const getLetterOfCreditEligibleVehiclesPending = () => {
  return axios
    .get(`${API_BASE}/purchasers/side/pending/veiculos_elegiveis`, {
      headers: {
        token: API_TOKEN,
      },
    })
    .then((response) => response.data.payload?.pendentes?.veiculos_elegiveis ?? [])
    .then(vehicles => vehicles.length > 0 ? vehicles.filter(vehicle => vehicle.qtdVeic <= 1) : [])
    .catch((error) => `buyer counts ${error}`);
};

export const getBidInProgress = (activeOrderBy = null, closedOrderBy) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/lances`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
        return {
          ativos: [],
          superados: [],
        };
      }

      const resultAtivos = removeDuplicateObjectApi(
        response.data.payload.em_andamento.lances.ativos,
        "ava_id"
      );
      const resultSuperados = removeDuplicateObjectApi(
        response.data.payload.em_andamento.lances.superados,
        "ava_id"
      );

      const result = {
        ativos: resultAtivos,
        superados: resultSuperados,
      };

      return result;
    })
    .catch((error) => `buyer counts ${error}`);
};

export const getProposalInProgress = (activeOrderBy = null, closedOrderBy) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/propostas`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
        return {
          ativas: [],
          superadas: [],
        };
      }
      return response.data.payload.em_andamento.propostas;
    })
    .then((result) => ({ ativos: result.ativas, superados: result.superadas }))
    .catch((error) => `buyer counts ${error}`);
};

export const getQuotesInProgress = (activeOrderBy = null, closedOrderBy) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/cotacoes`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
        return {
          ativas: [],
          superadas: [],
        };
      }
      return response.data.payload.em_andamento.cotacoes
    })
    .then((result) => ({ ativos: result.ativas, superados: result.superadas }))
    .catch((error) => `buyer counts ${error}`);
};

export const getAutoBidInProgress = (activeOrderBy = null, closedOrderBy) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/autobid`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
        return {
          ativos: [],
          superados: [],
        };
      }
      return response.data.payload.em_andamento.autobid;
    })
    .catch((error) => `buyer counts ${error}`);
};

export const getVehiclesByIdInPackage = (id) => {
  return axios
    .get(`${API_BASE}/purchasers/packages/${id}/vehicles`, {
      headers: {
        token: API_TOKEN,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer getVehiclesByIdInPackage ${error}`);
};

export const getLotsOngoing = async (activeOrderBy = null, closedOrderBy) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/lotes`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then((response) => {
        const payload = response.data.payload;
        if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
            return {
                ativos: [],
                superados: [],
            };
        }
        return response.data.payload.em_andamento.lotes;
    })
    .catch((error) => `buyer counts ${error}`);
};

export const getLotsRealized = async (orderBy = null, period) => {
  const order = orderBy === "" ? null : orderBy;

  return (
    axios
      .get(`${API_BASE}/purchasers/side/realized/lotes`, {
        headers: {
          token: API_TOKEN,
        },
        params: {
          order,
          period,
        },
      })
      // .then((response) => response.data.payload.em_andamento.lotes) // Forma correta até a api estiver retornando dados
      .then((response) => response.data.payload?.result?.lotes ?? [])
      .catch((error) => `buyer counts ${error}`)
  );
};

export const getCanceledUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/cancel`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload.canceladas)
    .catch((error) => `buyer counts ${error}`);
};

export const getBidCompleted = async (
  orderBy = null,
  period = null,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/lances`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getProposalCompleted = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/propostas_aceitas`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getQuotesCompleted = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/cotacoes`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getAutoBidCompleted = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/autobid`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getOndemandAssesmentCompleted = async (
  orderBy = null,
  period = null,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/avaliacoes_sob_aprovacao`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getRealizedBuyNow = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/realized/compre_ja`, {
      headers: {
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getOnDemandAssessmentInProgress = (
  activeOrderBy = null,
  closedOrderBy
) => {
  const activeOrder = activeOrderBy === "" ? null : activeOrderBy;
  const closedOrder = closedOrderBy === "" ? null : closedOrderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/ongoing/avaliacao_sob_aprovacao`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        activeOrder,
        closedOrder,
      },
    })
    .then(function (response) {
      const payload = response.data.payload;
      if (!payload.em_andamento || (Array.isArray(payload) && payload.length === 0)) {
        return {
          ativos: [],
          superados: [],
        };
      }
      return response.data.payload.em_andamento.avaliacao_sob_aprovacao;
    })
    .then((result) => ({
      ativos: result.ativos ?? [],
      superados: result.superados ?? [],
    }))
    .catch((error) => `buyer counts ${error}`);
};

export const getQuotesUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/not-realized/cotacoes`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getAssessmentUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/not-realized/lances_superados`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getAutoBidUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/not-realized/autobid`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getOnDemandAssessmentdUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/not-realized/avaliacoes_sob_aprovacao`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const getRejectedUnfufilled = async (
  orderBy = null,
  period,
  page,
  per_page = PER_PAGE_BUYER_PAGINATION
) => {
  const order = orderBy === "" ? null : orderBy;

  return axios
    .get(`${API_BASE}/purchasers/side/not-realized/propostas_rejeitadas`, {
      headers: {
        token: API_TOKEN,
      },
      params: {
        order,
        period,
        page,
        per_page,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => `buyer counts ${error}`);
};

export const downloadOfTheInvoiceByCodeAndName = async (code, name) => {
  function downloadPDF(pdfBase64) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + pdfBase64;
    link.download = `${name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (code !== null && code !== undefined) {
    return fetch(URL.BUYER_ACTION_BUTTON_NOTA_FISCAL + code, {
      headers: {
        token: API_TOKEN,
      },
    })
      .then((response) => response.text())
      .then((data) => downloadPDF(data))
      .catch((error) => console.error("Erro ao baixar o PDF:", error));
  }
};

export const buyNow = async (id_avaliacao, value) => {
  return axios
    .post(`${API_BASE}/purchasers/buyNow`, {
        id_avaliacao,
        value
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
};



