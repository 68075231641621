import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export default function getCampaign(key = String) {
  return axios
    .get(`${API_BASE}/campaign` + '/' + key, { headers: {
      token: API_TOKEN
    } })
    .then((response) => response.data.payload)
    .catch((error) => `search ${error}`);
}
