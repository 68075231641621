<template>
  <div class="vehicle-type">
    <MenuTitle :text="title" />

    <div class="container">
      <div class="row">
        <div
          v-for="vehicleType in vehicleTypes"
          :key="vehicleType.id"
          :id="`categoria-input-${vehicleType.id}`"
          class="option"
          @click="() => selectVehicleType(vehicleType)"
        >
          <img
            v-if="!isVehicleTypeSelected(vehicleType)"
            :src="
              require('@/assets/icons/' + vehicleType.icon_default + '.svg')
            "
            :width="47"
            :height="37"
          />
          <img
            v-else
            :src="
              require('@/assets/icons/' + vehicleType.icon_selected + '.svg')
            "
            :width="47"
            :height="37"
          />
          <span
            :style="`color:${
              isVehicleTypeSelected(vehicleType) ? '#FF7A00' : '#6f6f6f'
            }`"
            >{{ vehicleType.title }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuTitle from "@/components/typographies/MenuTitle.vue";
import { mapState } from "vuex";

export default {
  name: "VehicleType",
  components: {
    MenuTitle,
  },
  props: {
    title: String,
  },
  data: function () {
    return {
      vehicleTypes: [
        {
          id: "carros",
          title: "Carros",
          icon_default: "car-icon",
          icon_selected: "car-icon-primary-color",
        },
        {
          id: "motos",
          title: "Motos",
          icon_default: "motorbike-icon",
          icon_selected: "motorbike-icon-primary-color",
        },
        {
          id: "pesados",
          title: "Pesados",
          icon_default: "truck-icon",
          icon_selected: "truck-icon-primary-color",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      category: state => state.filterForm.params.category?.length > 0 ? state.filterForm.params.category[0] : null,
      vehiclesTypeSelected: (state) => state.filterForm.params["category"] ?? [],
      params: (state) => state.filterForm.params
    }),
  },
  mounted() {
    if (this.category != null) {
      this.params["category"] = this.vehicleTypes.filter(v => v.id == this.category);
    }
  },
  methods: { 
    selectVehicleType: function (vehicleType) {
      if (this.isVehicleTypeSelected(vehicleType)) {
        this.$store.commit("filterForm/setParam", {
          category: this.vehiclesTypeSelected.filter(
            (vehicleTypeSelected) => (vehicleTypeSelected.id ?? vehicleTypeSelected) !== vehicleType.id
          ),
        });
        return;
      }

      this.$store.commit("filterForm/setParam", {
        category: [vehicleType],
      });
    },
    isVehicleTypeSelected: function (vehicleType) {
      return (
        this.vehiclesTypeSelected
            .find(vehicleTypeSelected => (vehicleTypeSelected.id ?? vehicleTypeSelected) === vehicleType.id) !== undefined
      );
    },
  }
};
</script>

<style scoped>
.container {
  flex: 1;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 24px;
}

.row {
  direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.option span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6f6f6f;
}
</style>
