import PATHS from "@/constants/Paths";
import { mapMutations, mapState } from "vuex";
import { BUYER } from "@/constants/Buyer";
import { SHOWCASE } from "@/constants/Showcase";
import VEHICLES from "@/constants/Vehicles";
import PORTAL_BASE from "@/constants/PortalBase";

export default {
  computed: {
    ...mapState({
      currentMainMenuSelected: (state) => state.global.currentMainMenuSelected,
    }),
  },
  methods: {
    ...mapMutations("buyer", [
      "setTitlePage",
      "setTitleSectionPage",
      "setSubTitlePage",
      "setCurrentStatusLote"
    ]),
    ...mapMutations("filterShowcase", [
      "setSelectedCarousel"
    ]),
    navigateTo: function (url, params) {
      this.$router.push(url, params);
    },
    navigatePortalTo: function (url) {
      window.location.href = `${PORTAL_BASE}/${url}`;
    },
    navigateToHomePage: function() {
      this.navigateTo(PATHS.Vehicles);
    },
    navigateToDashboardPage: function () {
      this.setSubTitlePage("");
      if (this.currentMainMenuSelected == "vehicles") {
        this.setTitlePage(VEHICLES.TITLE_DASHBOARD);
        this.setTitleSectionPage(VEHICLES.TITLE_SECTION_DEFAULT);
        this.navigateTo(PATHS.Showcase.Root);
      }
      else if (this.currentMainMenuSelected == "showcase") {
        this.setTitlePage(SHOWCASE.TITLE_DASHBOARD);
        this.setTitleSectionPage(SHOWCASE.TITLE_SECTION_DEFAULT);
        this.navigateTo(PATHS.Showcase.Root);
        this.setSelectedCarousel(null);
      }
      else {
        this.setTitlePage(BUYER.TITLE_DASHBOARD);
        this.setTitleSectionPage(BUYER.TITLE_SECTION_DEFAULT);
        this.navigateTo(PATHS.Buyer.Root);
      }
    },
    natigateToInVehicleDetailPage: function (params = {}) {
      this.navigateTo(PATHS.Buyer.VehicleDetail, params);
    },
    natigateToInPackageDetailPage: function (params = {}) {
      this.navigateTo(PATHS.Buyer.LotDetail, params);
    },
    natigateToInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.InProgress);
    },
    natigateToBidInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.BidInProgress);
    },
    natigateToProposalInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.ProposalInProgress);
    },
    natigateToQuotesInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.QuotesInProgress);
    },
    natigateToAutoBidInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.AutoBidInProgress);
    },
    natigateToLotsInProgressPage: function () {
      this.setCurrentStatusLote("progress");
      this.navigateTo(PATHS.Buyer.LotsInProgress);
    },
    natigateToPedingPage: function () {
      this.navigateTo(PATHS.Buyer.Pending);
    },
    natigateToServicePage: function () {
      this.navigateTo(PATHS.Buyer.Service);
    },
    natigateToPaymentPendingPage: function () {
      this.navigateTo(PATHS.Buyer.PaymentPending);
    },
    natigateToReleasePendingPage: function () {
      this.navigateTo(PATHS.Buyer.ReleasePending);
    },
    natigateToProposalPendingPage: function () {
      this.navigateTo(PATHS.Buyer.ProposalPending);
    },
    natigateToBidPendingPage: function () {
      this.navigateTo(PATHS.Buyer.BidPending);
    },
    natigateToClosedPage: function () {
      this.navigateTo(PATHS.Buyer.Closed);
    },
    natigateToAnnouncementCompletedPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.AnnouncementCompletedPurchasesClosed);
    },
    natigateToProposalCompletedPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.ProposalCompletedPurchasesClosed);
    },
    natigateToQuotesCompletedPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.QuotesCompletedPurchasesClosed);
    },
    natigateToAutoBidCompletedPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.AutoBidCompletedPurchasesClosed);
    },
    natigateToBuyNowCompletedPurchasesClosedPage: function (defaultPeriodFilter = null) {
      this.$router.push({
        path: PATHS.Buyer.BuyNowCompletedPurchasesClosed,
        query: { period: defaultPeriodFilter },
      });
    },
    natigateToOnDemandAssessmentCompletedPurchasesPage: function () {
      this.navigateTo(PATHS.Buyer.OnDemandAssessmentPurchasesClosed);
    },
    natigateToAutoLotsCompletedPurchasesClosedPage: function () {
      this.setCurrentStatusLote("completed");
      this.navigateTo(PATHS.Buyer.LotsCompletedPurchasesClosed);
    },
    natigateToAnnouncementUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.AnnouncementUnfulfilledPurchasesClosed);
    },
    natigateToQuotesUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.QuotesUnfulfilledPurchasesClosed);
    },
    natigateToAutoBidUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.AutoBidUnfulfilledPurchasesClosed);
    },
    natigateToLotsUnfulfilledPurchasesClosedPage: function () {
      this.setCurrentStatusLote("unfulfilled");
      this.navigateTo(PATHS.Buyer.LotsUnfulfilledPurchasesClosed);
    },
    natigateToCanceledUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.CanceledUnfulfilledPurchasesClosed);
    },
    natigateToOnDemandAssessmentInProgressPage: function () {
      this.navigateTo(PATHS.Buyer.OnDemandAssessmentInProgress);
    },
    natigateToOnDemandAssessmentPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.OnDemandAssessmentPurchasesClosed);
    },
    natigateToOnDemandAssessmentPending: function () {
      this.navigateTo(PATHS.Buyer.ProposOnDemandAssessmentalPending);
    },
    natigateToAssessmentUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.AssessmentUnfulfilledPurchasesClosed);
    },
    natigateToRejectedUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.RejectedUnfulfilledPurchasesClosed);
    },
    natigateToOnDemandAssessmentUnfulfilledPurchasesClosedPage: function () {
      this.navigateTo(PATHS.Buyer.OnDemandAssessmentUnfulfilledPurchasesClosed);
    },
    navigateToCreditLetterPage: function () {
      this.navigateTo(PATHS.Buyer.CreditLetter);
    },
    navigatePortalToDashboard() {
      this.navigatePortalTo('dashboard?desativar_novos_filtros=1')
    },
    reloadHomePageNewPortal() {
      window.location.href = '/';
    },
  },
};
