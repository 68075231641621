<template>
  <div class="menu-modal-mobile">
    <v-container style="padding: 0">
      <v-row>
        <v-col>
          <v-img
            :width="200"
            cover
            :src="require('@/assets/logo.png')"
            @click="navigatePortalToDashboard"
            class="logo ml-1"
            id="aav-logo"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ul class="sections">
            <li v-for="(section, key) in sections" :key="key">
              <a
                :href="section.link"
                @click="navigate(section.path)"
                :id="`header-menu-item-${section.id}`"
              >
                <img width="15" height="12" v-if="section.icon.endsWith('.svg')" class="mr-3" :src="section.icon" :alt="section.id" />
                <i v-else :class="`fa ${section.icon} mr-3`"></i>
                {{ section.label }}</a
              >
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import SimpleHeaderMixin from "../mixins/SimpleHeaderMixin";

export default {
  name: "MenuModalMobile",
  props: {
    toggleModal: Function,
  },
  mixins: [SimpleHeaderMixin, NavigateMixings],
  methods: {
    navigate(path) {
      const pathIsNotDefined = !path;
      if (pathIsNotDefined) {
        return;
      }

      const currentPath = this.$route.href;
      if (currentPath === path) {
        this.toggleModal();
        return;
      }

      this.$router.push(path)
    },
  }
};
</script>

<style lang="scss" scoped>
.menu-modal-mobile {
  font-family: "Roboto", Roboto, Helvetica, sans-serif;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  z-index: 99998;
  background-image: url("../../../assets/images/bg-menu.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: auto;
  transition: opacity 0.3s ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
  }

  .sections {
    display: block;

    li {
      float: none;
      position: relative;
      display: block;
      list-style: none;
      border-bottom: 1px solid #333;
      margin-left: 40px;

      a {
        height: auto;
        padding: 5px 0 0;
        text-transform: uppercase;
        margin: 10px 0;
        font-size: 12px;
        color: #eee;
        position: relative;
        display: block;
        line-height: 17px;
        text-decoration: none;
      }

      i, img {
        position: relative;
        display: inline-block;
        padding: 8px;
        border: 1px solid #ffb400;
        color: #ffb400;
        height: 36px;
        width: 36px;

        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        line-height: 19px;
        vertical-align: middle;
        opacity: 0.6;
      }
    }
  }
}
</style>
