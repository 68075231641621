<template>
  <div id="wallet-modal-container" v-if="modalIsOpen">
    <div id="wallet-modal-left" @click="closeModal"></div>
    <v-container class="wallet-modal">
      <button class="close-container" @click="closeModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M12.9703 1.65685L1.65662 12.9706M1.65662 1.65685L12.9703 12.9706"
            stroke="#B7B7B7"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <v-row class="header mt-8">
        <v-col justify="center" align="center" class="left" cols="6">
          <span>NOVIDADE</span>
          <h1 class="mt-3 mb-5">Saldo disponível para taxas</h1>
          <h2>
            Agora você poderá pagar taxas dos seus veículos, utilizando saldo
            disponível em conta!
          </h2>
        </v-col>
        <v-col justify="center" align="center" class="right" cols="6">
          <WalletCard type="modal" :linkText="walletCardTypeText" @click="toggleDetailsStep" />
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-divider color="#E4E4E4"></v-divider>
      </v-row>

      <v-row class="content">
        <v-container>
          <ListOfEligibleVehiclesStep  v-if="isHomeStep" />
          <DetailsStep v-if="isDetailsStep" />
          <WalletBuyPartialStep v-if="isWalletBuyPartialStep" />
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import WalletCard from "@/views/buyer/components/WalletCard.vue";
import ListOfEligibleVehiclesStep from "./components/ListOfEligibleVehiclesStep.vue"
import DetailsStep from "./components/DetailsStep.vue"
import WalletBuyPartialStep from "./components/WalletBuyPartialStep.vue"
import { DETAILS_STEP, HOME_STEP } from "@/store/modules/walletModalModule";
import WalletModalMixin from "../../mixins/WalletModalMixin";

export default {
  name: "WalletModal",
  mixins: [WalletModalMixin],
  components: {
    WalletCard,
    ListOfEligibleVehiclesStep,
    DetailsStep,
    WalletBuyPartialStep,
  },
  computed: {
    ...mapState({
      modalIsOpen: (state) => state.walletModal.modalIsOpen,
      step: (state) => state.walletModal.step,
    }),
    walletCardTypeText() {
      const text = 'Entenda esse valor';
      if(this.isHomeStep) {
        return `${text} →`;
      }
      return `← ${text}`;
    },
    walletModalContainerOverflowY() {
      const isNotFullScreen = window.screen.width < 1920;
      if (this.isHomeStep || isNotFullScreen) {
        return 'scroll';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations("walletModal", [
      "setStep",
    ]),
    toggleDetailsStep() {
      if (this.step === HOME_STEP) {
        this.setStep(DETAILS_STEP);
        return;
      }
      this.setStep(HOME_STEP);
    },
  },
};
</script>

<style lang="scss" scoped>
#wallet-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  display: flex;
  overflow-y: v-bind(walletModalContainerOverflowY);


  #wallet-modal-left {
    width: 100%;
    height: 100vmax;
    background-color: rgb(0 0 0 / 58%);
  }

  .wallet-modal {
    position: relative;
    top: 0;
    right: 0;
    background: #fff;
    box-shadow: -26px 0px 20px 0px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    height: 100vmax;
    width: 40%;

    @media only screen and (max-width: 1536px) {
      width: 48%;
    }

    @media only screen and (max-width: 1280px) {
      width: 52%;
    }

    .close-container {
      position: absolute;
      right: 20px;
      top: 10px;
    }

    .header {
      span {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.2px;
        border-radius: 7px;
        background: #f22300;
        padding: 4px;
      }

      h1 {
        color: #373737;
        text-align: center;
        font-family: "Lexend Deca";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 97.5%; /* 31.2px */
      }

      h2 {
        color: #373737;
        text-align: center;
        font-family: "Lexend Deca";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 124%; /* 19.84px */
      }
    }

    .content {
      position: relative;
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 97.5%; /* 19.5px */
      padding: 20px 10px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
