<template>
  <div class="details-package">
    <figure class="qtd">
      <img
        :src="require('@/assets/icons/package-icon.svg')"
        :height="isMobile ? 28 : 35"
        :width="isMobile ? 33 : 38"
      />
      <figcaption>
        Lote contém
        <strong>{{ count }} Veículos</strong>
      </figcaption>
    </figure>
    <div class="controls">
      <a
        href="javascript:;"
        :class="{ disable: disablePrevious }"
        @click="prevSlide"
        >← Anterior</a
      >
      <a
        href="javascript:;"
        :class="{
          disable: disableNext,
        }"
        @click="nextSlide"
        >Próximo →</a
      >
    </div>
  </div>
</template>
<script>
import { capitalize } from "@/helpers/strings";

export default {
  name: "VehiclePackageInfo",
  props: {
    count: Number,
    disablePrevious: Boolean,
    disableNext: Boolean,
    nextSlide: Function,
    prevSlide: Function,
    isMobile: Boolean,
  },
  methods: {
    capitalize,
  },
  computed: {
    footerPaddingTop: function () {
      if (this.isMobile) {
        return "0.75em";
      }
      return "1.5em";
    },
    footerBorderTop: function () {
      if (this.isMobile) {
        return "";
      }
      return "1px solid #eee";
    },
    footerMarginTop: function () {
      if (this.isMobile) {
        return "0";
      }
      return "2em";
    },
    footerFontSize: function () {
      if (this.isMobile) {
        return "0.75em";
      }
      return "0.85em";
    },
    topFontSize: function () {
      if (this.isMobile) {
        return "0.78em";
      }
      return "0.95em";
    },
  },
};
</script>

<style lang="scss" scoped>
.details-package {
  text-decoration: none;
  color: #373737;

  img {
    float: left;
  }
  strong {
    display: inline-block;
  }
  .qtd {
  }
  figcaption {
    line-height: 1.2em;
    font-size: v-bind(topFontSize);
    text-indent: 0.4em;
  }
  .controls {
    display: flex;
    font-size: v-bind(footerFontSize);
    align-content: space-between;
    margin-top: v-bind(footerMarginTop);
    border-top: v-bind(footerBorderTop);
    padding-top: v-bind(footerPaddingTop);
    a {
      color: #373737;
      text-decoration: none;
      flex: auto;
      transition: 0.2s all;
      &.disable {
        color: lightgray;
        pointer-events: none;
      }
    }
  }
}
</style>
