<template>
  <div class="buyer-in-progress-page">
    <div class="buyer-in-progress-page-top">
      <BuyerSectionItem
        :icon="this.icon"
        :title="this.title"
        :description="this.description"
        :shouldReturnToTheBuyerPage="true"
      />
    </div>

    <div class="buyer-in-progress-page-content">
      <FilterSidebarInProgressItems :isMobile="true" />
    </div>
  </div>
</template>

<script>
import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import FilterSidebarInProgressItems from "@/views/buyer/components/FilterSidebar/components/FilterSidebarInProgressItems.vue";
import { BUYER_SECTION_IN_PROGRESS } from "@/views/buyer/constants/BuyerSections";
import { mapMutations } from "vuex";
import { BUYER } from "@/constants/Buyer";

export default {
  name: "BuyerInProgressPage",
  components: { BuyerSectionItem, FilterSidebarInProgressItems },
  data: function () {
    return {
      ...BUYER_SECTION_IN_PROGRESS,
    };
  },
  methods: {
    ...mapMutations("buyer", [
        "setTitleSectionPage",
        "setTitlePage",
        "setSubTitlePage"
    ]),
  },
  mounted() {
    this.setTitleSectionPage(BUYER.TITLE_DASHBOARD);
    this.setTitlePage("Em andamento");
    this.setSubTitlePage("");
  },
};
</script>

<style scoped>
.buyer-in-progress-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.buyer-in-progress-page-top {
  width: 100%;
}

.buyer-in-progress-page-content {
  margin-top: 4px;
  background-color: white;
  flex: 1;
}
</style>
