<template>
  <div id="seeAllContainer" style="height: 70vh !important">
    <div
      style="color: #797979; border: none; padding: 20px"
      id="header"
      :style="{ borderColor }"
    >
      <div id="header-enabled" v-if="seeAllDrawerEnabled" class="d-flex">
        <button @click="hideSeeAllDrawerHandle" class="seeAll flex-0-1" id="ver-todos-voltar">
          <img :src="require('@/assets/icons/arrow-left.svg')" />
        </button>
        <span
          class="text-uppercase flex-1-1"
          style="text-align: center; text-indent: -22px"
        >
          {{ title }}
        </span>
      </div>
    </div>
    <div v-if="seeAllDrawerEnabled" style="height: 90vh">
      <div class="px-5">
        <v-text-field
          :placeholder="placeholder"
          v-model="itemSearch"
          @input="searchHandle"
          bg-color="#FFFFFF"
          variant="solo"
          id="ver-todos-input"
        />
      </div>
      <v-list>
        <v-list-subheader
          v-if="sectionsSearch.length > 0 && !loading"
          class="px-5 mx-5"
          style="color: black"
          >{{ subtitle }}</v-list-subheader
        >
        <span class="empty" v-else>Nenhum resultado encontrado</span>
        <v-list-item
          v-for="(section, i) in sectionsSearch"
          :key="i"
          :value="section"
          item-props
          class="pa-0"
          active-color="primary"
          :class="this.allowSelection(section.id) ? 'disabledItem' : ''"
        >
          <v-list-subheader
            class="text-uppercase pa-5 mx-5"
            :class="this.allowSelection(section.id) ? 'font-weight-bold' : ''"
            v-text="section.name"
            @click="() => selectItem(section)"
            :id="`ver-todos-${i}`"
          ></v-list-subheader>
          <v-divider></v-divider>
        </v-list-item>
      </v-list>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
      <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
// eslint-disable-next-line no-unused-vars
import store from "@/store";
import { capitalize } from "@/helpers/strings";

export default {
  name: "SeeAll",
  components: {
    VSkeletonLoader,
  },
  data: function () {
    return {
      loading: true,
      itemSearch: null,
      handle: Function,
      sections: [],
      sectionsSearch: [],
      placeholder: "",
      title: "",
      subtitle: "",
      scrollTo: "",
      scope: null,
    };
  },
  computed: {
    ...mapState({
      seeAllDrawerEnabled: (state) => state.drawer.seeAllDrawerEnabled,
      seeAllDrawerObjDetails: (state) => state.drawer.seeAllDrawerObjDetails,
      seeAllDrawerObjSelected: (state) => state.drawer.seeAllDrawerObjSelected,
      borderColor: (state) =>
        state.drawer.seeAllDrawerEnabled ? "#e8e8e8" : "#ffffff",
      params: (state) => state.filterForm.params,
      filters: (state) => state.filterForm.filters,
      aliasFilter: (state) => state.filterForm.aliasFilter,
      models: (state) => state.filterForm.params.models,
      brands: (state) => state.filterForm.params.brands,
    }),
  },
  methods: {
    ...mapMutations("drawer", [
      "hideSeeAllDrawer",
      "showSeeAllDrawer",
      "setSeeAllDrawerObjSelected",
      "unsetSeeAllDrawerObjSelected",
    ]),
    hideSeeAllDrawerHandle() {
      this.hideSeeAllDrawer();
    },
    capitalize,
    searchHandle() {
      this.sectionsSearch = this.sections.filter((s) =>
        s.name.match(new RegExp(this.itemSearch, "i"))
      );
    },
    allowSelection(value) {
      const key = "key-" + this.scrollTo;
      if (this.seeAllDrawerObjSelected[key] && this.seeAllDrawerObjSelected[key][0] && this.seeAllDrawerObjSelected[key][0].branding !== this.seeAllDrawerObjSelected[key][0].id) {
        return this.seeAllDrawerObjSelected[key].some((v) => v.id === value);
      }
      return false;
    },
    selectItem(value) {
      const key = "key-" + (this.scope ? `${this.scope}-` : "") + (this.scrollTo ?? this.title);

      if (key.includes('Modelos') || key.includes('Versões')){
        const split = key.split('-');
        if (key.includes('Modelos')) {
          value.name = value.id.toLowerCase();
          value.value = value.id.toLowerCase();
          value.marca = split[1].toLowerCase();
        }
        else if (key.includes('Versões')) {
          const modelo = this.models.filter(m => m.marca.toLowerCase() === split[1].toLowerCase())[0]
          value.name = value.id.toLowerCase();
          value.value = value.id.toLowerCase();
          value.modelo = modelo.value;
        }
      }

      if (!this.allowSelection(value.id)) {
        this.setSeeAllDrawerObjSelected({
          key,
          value: {
            ...value
          },
        });
      } else {
        this.seeAllDrawerObjSelected[key] = this.seeAllDrawerObjSelected[
          key
        ].filter((v) => v.id !== value.id);
      }

      this.hideSeeAllDrawerHandle();

      if (this.scrollTo) {
        this.$emit("scrollEventHandler", this.scrollTo);
      }

      if (this.seeAllDrawerObjSelected[key] && this.scrollTo && this.scrollTo.toLowerCase() !== 'vehicle-branding') {
        const newValue = [
          ...this.params[this.scrollTo],
          ...this.seeAllDrawerObjSelected[key],
        ].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        store.commit("filterForm/setParam", { [this.scrollTo]: newValue });
      }
    },
  },
  async mounted() {
    this.title = this.seeAllDrawerObjDetails.title;
    this.subtitle = this.seeAllDrawerObjDetails.subtitle;
    this.placeholder = this.seeAllDrawerObjDetails.placeholder;
    this.scrollTo = this.seeAllDrawerObjDetails.scrollTo;
    this.scope = this.seeAllDrawerObjDetails.scope;
    const initialData = await this.seeAllDrawerObjDetails.handle();

    this.loading = false;

    if (this.aliasFilter[this.seeAllDrawerObjDetails.scrollTo]) {
      const filterData = Object.values(this.filters[this.aliasFilter[this.seeAllDrawerObjDetails.scrollTo]] ?? {});

      if (filterData?.length > 0) {
        this.sections = this.sectionsSearch = initialData
            .filter(item => {
              return filterData.some(id => item.id.toString().trim().toLowerCase() === id.toString().trim().toLowerCase())
            });
      } else {
        this.sections = this.sectionsSearch = [];
      }
    }
    else {
      this.sections = this.sectionsSearch = initialData
    }
  },
};
</script>
<style scoped>
.empty {
  color: #818181;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
}
.disabledItem {
  background-color: #d3d3d366;
}
</style>
