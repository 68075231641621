<template>
  <v-container class="fipe">
    <v-row class="header mb-6">
      <MenuTitle text="% FIPE" />
      <FipeIcon :fipe="fipe" />
      <FipeIconInfo :fipe="fipe" />
    </v-row>
    <v-row class="px-2 mb-1">
      <div class="range-container">
        <input
          type="range"
          min="0"
          max="100"
          :value="fipe"
          @input="(event) => setFipeHandler(event.target.value)"
          id="range_fipe"
          class="slider my-slider"
        />
        <span id="rangeValue" :class="{'range-mobile': !isMobile}" >{{ fipe }}</span>
      </div>
    </v-row>
    <v-row justify="space-between" class="px-2">
      <div class="legend">
        <span>0%</span>
      </div>
      <div>
        <v-btn
          height="35"
          width="35"
          variant="text"
          color="#ffffff"
          icon="mdi-minus"
          style="background-color: #ff7a00"
          @click="decrement"
        />
      </div>
      <div class="legend">
        <span>50%</span>
      </div>
      <div>
        <v-btn
          height="35"
          width="35"
          variant="text"
          color="#ffffff"
          icon="mdi-plus"
          style="background-color: #ff7a00"
          @click="increment"
        />
      </div>
      <div class="legend">
        <span>100%</span>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import MenuTitle from "@/components/typographies/MenuTitle.vue";
import FipeIcon from "@/components/FipeIcon.vue";
import FipeIconInfo from "@/components/FipeIconInfo.vue";
import { mapMutations, mapState } from "vuex";
import getPlatform from "@/helpers/getPlatform";
import PLATFORMS from "@/constants/Platforms";
import {debounce} from "@/helpers/strings";

const DEFAULT_RANGE = 5;
export default {
  name: "FipeComponent",
  components: {
    FipeIconInfo,
    MenuTitle,
    FipeIcon,
  },
  data() {
    return {
      debouncedSetFipe: null
    }
  },
  computed: {
    ...mapState({
      fipe: (state) => state.filterForm.params.fipe ?? state.filterForm.fipe,
    }),
    isMobile: function () {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
  },
  mounted() {
    const i = setInterval(() => this.startSelectItem(i), 200)

    this.debouncedSetFipe = debounce((fipe) => {
      this.setFipe(fipe);
    }, 1000);
  },
  methods: {
    ...mapMutations("filterForm", ["setFipeValue", "setFipe"]),
    startSelectItem(interval) {
      clearInterval(interval)

      const range = document.getElementById("range_fipe");
      range.addEventListener("input", this.changeRange);
      this.changeRange();
    },

    setFipeHandler(fipe) {
      this.setFipeValue(fipe);
      this.debouncedSetFipe(fipe);
    },

    increment: function () {
      if ((parseInt(this.fipe) + DEFAULT_RANGE) >= 100) {
        this.setFipeHandler('100');
        return;
      }
      this.setFipeHandler((parseInt(this.fipe) + DEFAULT_RANGE).toString());
      this.changeRange();
    },
    decrement: function () {
      if (parseInt(this.fipe) - DEFAULT_RANGE <= 0) {
        this.setFipeHandler('0');
        return;
      }
      this.setFipeHandler((parseInt(this.fipe) - DEFAULT_RANGE).toString());
      this.changeRange();
    },
    changeRange() {
      const target = document.getElementById("range_fipe");
      const rangeValue = document.getElementById('rangeValue');
      const min = target.min;
      const max = target.max;
      const val = this.fipe;

      const thumbWidth = 30;
      const thumbPosition = ((val - min) * 100) / (max - min) ;
      rangeValue.style.left = `calc(${thumbPosition}% - ${(val/100)*thumbWidth}px)`;
      
      target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
    },
  },
  watch: {
    fipe: function (e) {
      if (parseInt(e) === 0) {
        this.changeRange();
      }
    },
  },

};
</script>
<style scoped>
.legend {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #7c7c7c;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 16px;
  background: #dddada;
  border-radius: 13px;
  background-image: linear-gradient(#ff7a00, #ff7a00);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  margin: 0px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #171618;
  transition: background 0.3s ease-in-out;
  border: 2px solid #ff7a00;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #171618;
  transition: background 0.3s ease-in-out;
  border: 2px solid #ff7a00;
}

.range-container {
  position: relative;
  width: 100%;
}

#rangeValue {
  position: absolute;
  background-color: transparent;
  color: #7c7c7c;
  width: 30px;
  height: 30px;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
  text-align: center;
  top: 50%;
  transform: translatey(-50%);
}
.range-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
