import { createApp } from "vue";
import axios from "axios";
import API_TOKEN from "@/constants/ApiToken";

import App from "./App.vue";
import { registerPlugins } from "./plugins";
import { navigateToPortalLogin } from "./helpers/portal";
import toast from "./plugins/toast";

axios.interceptors.request.use((config) => {
  if (!config.url.includes("/async/veiculos") && API_TOKEN) {
    config.headers.token = API_TOKEN;
    return config;
  }

  config.withCredentials = true;
  return config;
});

axios.interceptors.response.use(
  function (response) {
    const showToastError =
      response.data.statusCode &&
      response.data.statusCode === 400 &&
      response.data.payload &&
      response.data.payload.message;

    if (showToastError) {
      toast.error(response.data.payload.message.join("<br />"));
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      navigateToPortalLogin();
    }
    return Promise.reject(error);
  }
);

const app = createApp(App);
registerPlugins(app);
app.mount("#app");
