function removeDuplicateObjectApi(payload, propertyCheck) {

    return payload.reduce((acumulador, objetoAtual) => {
        let objetoExistente = acumulador.find(item => item[propertyCheck] === objetoAtual[propertyCheck]);
        if (!objetoExistente) {
          acumulador.push(objetoAtual);
        }
        return acumulador;
    }, []);
}
export default removeDuplicateObjectApi;