export function capitalize(str) {
  if (typeof str !== "string") return "";
  const arr = str.toLowerCase().split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(" ");
}

export function toFormatBRL(value) {
  return value.toLocaleString('pt-br', {minimumFractionDigits: 2});
}

export function toFormatUSD(value) {
  return parseFloat(value.toString().replace('.', '').replace(',', '.'));
}

export function removeNullOrEmptyValues(inputObj) {
  const cleanedObj = {};
  const { hasOwnProperty } = Object.prototype;

  for (const key in inputObj) {
    if (hasOwnProperty.call(inputObj, key)) {
      if (Array.isArray(inputObj[key])) {
        const filteredArray = inputObj[key].filter(item => item !== null);
        if (filteredArray.length > 0) {
          cleanedObj[key] = filteredArray;
        }
      }
      else {
        cleanedObj[key] = inputObj[key];
      }
    }
  }

  return cleanedObj;
}

export function generateUUID() {
  return Math.random().toString(16).slice(2)
}

export function generateBrandModelVersionQueryParam(obj, fieldMap) {
  let queryParams = [];

  let brandMap = new Map();
  if (obj.brands) {
    obj.brands.forEach(brand => {
      brandMap.set(brand.uuid, brand.value);
    });
  }

  let index = 1;

  if (obj.brands) {
    obj.brands.forEach(brand => {
      let brandUuid = brand.uuid;
      let brandValue = brand?.value || brand.name;

      let modelsForBrand = obj.models ? obj.models.filter(model => model.parentUuid === brandUuid) : [];

      if (modelsForBrand.length > 0) {
        modelsForBrand.forEach(model => {
          let modelValue = model.value;

          let versionsForBrand = obj.versions ? obj.versions.filter(version => version.parentUuid === brandUuid) : [];

          if (versionsForBrand.length > 0) {
            versionsForBrand.forEach(version => {
              let versionValue = version.value;

              let paramString = `${fieldMap.brands}${index}=${brandValue}&${fieldMap.models}${index}=${modelValue}&${fieldMap.versions}${index}=${versionValue}`;
              queryParams.push(paramString);
              index++;
            });
          } else {
            let paramString = `${fieldMap.brands}${index}=${brandValue}&${fieldMap.models}${index}=${modelValue}`;
            queryParams.push(paramString);
            index++;
          }
        });
      } else {
        let versionsForBrand = obj.versions ? obj.versions.filter(version => version.parentUuid === brandUuid) : [];

        if (versionsForBrand.length > 0) {
          versionsForBrand.forEach(version => {
            let versionValue = version.value;

            let paramString = `${fieldMap.brands}${index}=${brandValue}&${fieldMap.versions}${index}=${versionValue}`;
            queryParams.push(paramString);
            index++;
          });
        } else {
          let paramString = `${fieldMap.brands}${index}=${brandValue}`;
          queryParams.push(paramString);
          index++;
        }
      }
    });
  }

  return queryParams;
}

export function parseBrandModelVersionQueryParam(queryString, fieldMap) {
  let params = new URLSearchParams(queryString);
  let brands = [];
  let models = [];
  let versions = [];
  let index = 1;

  while (params.has(`${fieldMap.brands}${index}`) || params.has(`${fieldMap.models}${index}`) || params.has(`${fieldMap.versions}${index}`)) {
    let brandValue = params.get(`${fieldMap.brands}${index}`);
    let modelValue = params.get(`${fieldMap.models}${index}`);
    let versionValue = params.get(`${fieldMap.versions}${index}`);

    let brandUUID = brands.find(b => b.value === brandValue)?.uuid || generateUUID();
    if (!brands.find(b => b.value === brandValue)) {
      brands.push({ id: brandValue, item: brandValue, value: brandValue, name: brandValue, uuid: brandUUID });
    }

    if (modelValue) {
      let modelUUID = generateUUID();
      models.push({ id: modelValue, title: modelValue, value: modelValue, marca: brandValue, uuid: modelUUID, parentUuid: brandUUID });
    }

    if (versionValue) {
      let versionUUID = generateUUID();
      versions.push({ id: versionValue, title: versionValue, value: versionValue, modelo: modelValue, uuid: versionUUID, parentUuid: brandUUID });
    }

    index++;
  }

  return {
    brands: brands,
    models: models,
    versions: versions
  };
}

export function parseQueryParams(url) {
  const params = {};

  const queryString = url.split('?')[1];
  if (!queryString) return params;

  const pairs = queryString.split('&');
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');

    if (value) {
      try {
        params[key] = JSON.parse(decodeURIComponent(value));
      } catch {
        params[key] = decodeURIComponent(value);
      }
    } else {
      params[key] = null;
    }
  });

  return params;
}

export function debounce(func, delay = 300) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}