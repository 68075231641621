<template>
  <SimpleHeader />
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

import SimpleHeader from '@/components/SimpleHeader';
import PlatformsMixins from '@/mixins/PlatformsMixins';

export default {
  name: "App",
  mixins: [PlatformsMixins],
  components: {
    SimpleHeader,
  },
  methods: {
    ...mapActions("globalFlagsCompany", [
      "getGlobalFlagsCompany"
    ]),
    ...mapActions("participantFlags", [
      "getParticipantFlags"
    ]),
    configureScreen() {
      document.querySelector("html").style["overflow"] = "hidden";
    },
  },
  mounted() {
    this.getGlobalFlagsCompany();
    this.getParticipantFlags();
    this.configureScreen();
  },
};
</script>
