<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras com pagamentos pendentes"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getPaymentPeding"
    labelCard="Você comprou por"
    buyerScope="PaymentPending"
    priceDescriptionPage="Você comprou por"
    noVehicleLabel="Você ainda não possui pagamentos"
  />
</template>

<script>
import { BUYER_SECTION_PENDING } from "@/views/buyer/constants/BuyerSections";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import NavigateMixings from "@/mixins/NavigateMixings";

import BuyerOneColumnPage from "../BuyerOneColumnPage";
import { getPaymentPeding } from "@/services/api/BuyerServiceApi";

export default {
  name: "PaymentPendingPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_PENDING,
      title: "Pagamento pendente - Boleto",
      section: "Pendentes",
    };
  },
  methods: {
    getPaymentPeding,
  },
};
</script>

<style lang="scss" scoped></style>
