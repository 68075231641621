<template>
  <div v-if="modalIsOpen" class="modal-overlay active" :class="isMobile ? 'mobile' : ''">
    <button @click="closeModal()" class="close-bt">X</button>
    <div class="modal-container">
      <div class="modal-content">
        <div id="preferences-modal">
          <div class="preferences-modal-content ">
            <div class="item">
              <div class="clearfix mt-md">
                <h1 class="aa--white-c fl"><i class="fa fa-feed"></i> Lista de Desejos</h1>
                <a v-if="showMessage" @click="() => showMessage = !showMessage" href="#" class="toggle-follow-car-desc fr hdu label text-uppercase"><i
                    class="fa fa-caret-up mr-sm aa-subhead"></i>
                  Esconder mensagem!
                </a>
                <a v-else @click="() => showMessage = !showMessage" href="#" class="toggle-follow-car-desc aa--white-c fr hdu label text-uppercase"><i
                    class="fa fa-caret-right mr-sm aa-subhead"></i>
                  Saiba mais...
                </a>
              </div>
              <div class="headline justify pb-md pt-md" id="followcar-content" v-if="showMessage">
                <h3><strong>Bem-vindo à sua Lista de Desejos.</strong><br></h3>
                <p>Crie sua vitrine personalizada e monitore as condições do veículo que deseja<br><br>
                  Como criar sua lista de desejos:<br><br>
                  <strong>Uma Condição</strong>: Para monitorar anúncios de um modelo específico, como o "Corolla", selecione "Modelo" no filtro e escolha "Corolla". Assim que um anúncio desse modelo for publicado, ele aparecerá em sua lista de desejos como uma vitrine personalizada.<br><br>
                  <strong>Várias Condições</strong>: Se deseja monitorar um "Corolla" de "2018" por até "R$50.000", simplesmente adicione essas três condições ao seu filtro. Use o botão "+" para incluir novas condições.<br><br>
                  Pronto! Assim que um anúncio com essas características entrar em nosso portal, ele será exibido na sua lista de desejos personalizada.<br><br>
                  Lembre-se de clicar em “Salvar e Adicionar outro filtro” após definir as condições para que sua vitrine funcione corretamente.<br><br>
                  Para remover um anúncio indesejado de sua lista, exclua as condições relacionadas e salve as preferências.</p>
              </div>
              <div class="filter-container">
                <fieldset>
                  <legend>Novo filtro</legend>
                  <v-row class="new-filter">
                    <v-col sm="12" md="5">
                      <CustomSelect
                          :options="filteredFilters"
                          label="Selecione: filtro"
                          @selectSearchSelectOption="selectItemFilterHandler"
                          :resetAfterSelection="resetFlag"
                          :disableItemSelected="getSelectedItems()"
                      />
                    </v-col>
                    <v-col sm="12" md="5" v-if="isBrand() && !hasItemSelected('brand')">
                      <SelectSearch
                          :options="brands"
                          label="Selecione: Marca"
                          @selectSearchSelectOption="selectItemHandle"
                      />
                    </v-col>
                    <v-col sm="12" md="5" v-if="isModel()">
                      <SelectSearch
                          :options="models"
                          label="Selecione: Modelo"
                          :errorMessage="!hasItemSelected('brand') ? 'Selecione uma marca primeiro' : ''"
                          @selectSearchSelectOption="selectItemHandle"
                          v-show="!loadingSelect"
                      />
                      <div v-if="loadingSelect" class="loader-overlay"><div class="loader"></div></div>
                    </v-col>
                    <v-col sm="12" md="5" v-if="isVersion()">
                      <SelectSearch
                          v-if="!loadingSelect"
                          :options="versions"
                          label="Selecione: Versão"
                          :errorMessage="!hasItemSelected('model') ? 'Selecione um modelo primeiro' : ''"
                          @selectSearchSelectOption="selectItemHandle"
                      />
                      <div v-if="loadingSelect" class="loader-overlay"><div class="loader"></div></div>
                    </v-col>
                    <v-col sm="12" md="2" v-if="isYear()">
                      <SelectSearch
                          :options="getYears()"
                          keyName="yearFrom"
                          label="De"
                          @selectSearchSelectOption="selectItemHandle"
                      />
                    </v-col>
                    <v-col sm="12" md="2" v-if="isYear()">
                      <SelectSearch
                          :options="getYears().reverse()"
                          keyName="yearTo"
                          label="Até"
                          @selectSearchSelectOption="selectItemHandle"
                      />
                    </v-col>
                    <v-col sm="12" md="2" v-if="isPrice()">
                      <FollowCarInput
                          label="De"
                          keyName="priceFrom"
                          v-model="priceFromValueInput"
                          @inputValue="selectNotFetchItemFilterHandler"
                          type="money"
                      />
                    </v-col>
                    <v-col sm="12" md="2" v-if="isPrice()">
                      <FollowCarInput
                          label="Até"
                          keyName="priceTo"
                          v-model="priceToValueInput"
                          @inputValue="selectNotFetchItemFilterHandler"
                          type="money"
                      />
                    </v-col>
                    <v-col sm="12" md="2" v-if="isKm()">
                      <FollowCarInput
                          label="De"
                          keyName="kmFrom"
                          v-model="kmFromValueInput"
                          @inputValue="selectNotFetchItemFilterHandler"
                          type="number"
                      />
                    </v-col>
                    <v-col sm="12" md="2" v-if="isKm()">
                      <FollowCarInput
                          label="Até"
                          keyName="kmTo"
                          v-model="kmToValueInput"
                          @inputValue="selectNotFetchItemFilterHandler"
                          type="number"
                      />
                    </v-col>
                    <v-col sm="12" md="5" v-if="isMileage()">
                      <v-row>
                        <v-col cols="12">
                          <FollowCarInput
                              label="Informe o Raio (KM)"
                              keyName="mileage"
                              v-model="mileageValueInput"
                              @inputValue="selectNotFetchItemFilterHandler"
                              type="number"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="12" md="5" v-if="isPorcentagem()">
                      <v-row>
                        <v-col cols="12">
                          <FollowCarInput
                              v-if="flagValidacao"
                              label="Informe a porcentagem AA"
                              keyName="percentageAATable"
                              v-model="porcentagemAAValueInput"
                              @inputValue="selectNotFetchItemFilterHandler"
                              type="text"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="12" md="2" align-self="center" class="text-right ml-auto" v-if="hasSelectedItem()">
                      <v-btn
                          class="responsive-btn"
                          height="35"
                          width="35"
                          variant="text"
                          color="#ffffff"
                          icon="mdi-plus"
                          style="background-color: #feb32b"
                          @click="setItemFormHandle"
                          :disabled="loading || disableAddButton()"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-for="(key) in formWithoutFromToValues"
                        :key="key"
                        @click="deleteItemSelected(key)"
                    >
                      {{ form[key].name }}
                    </v-chip>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-if="form.yearFrom || form.yearTo"
                        :key="'yearInterval'"
                        @click="deleteItemSelected('yearFrom', 'yearTo')"
                    >
                      {{ generateFromToString(form, 'yearFrom', 'yearTo') }}
                    </v-chip>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-if="form.priceFrom || form.priceTo"
                        :key="'priceInterval'"
                        @click="deleteItemSelected('priceFrom', 'priceTo')"
                    >
                      {{ generateFromToString(form, 'priceFrom', 'priceTo', 'R$ ') }}
                    </v-chip>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-if="form.kmFrom || form.kmTo"
                        :key="'kmInterval'"
                        @click="deleteItemSelected('kmFrom', 'kmTo')"
                    >
                      {{ generateFromToString(form, 'kmFrom', 'kmTo') }}
                    </v-chip>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-if="form.mileage"
                        :key="'kmInterval'"
                        @click="deleteItemSelected('mileage')"
                    >
                      {{ 'Distância: ' + form.mileage.value + ' km' }}
                    </v-chip>
                    <v-chip
                        class="ma-2 cursor-pointer uppercase-text"
                        prepend-icon="mdi-close-circle-outline"
                        v-if="flagValidacao && form.percentageAATable"
                        :key="'percentageAATable'"
                        @click="deleteItemSelected('percentageAATable')"
                    >
                      {{ 'Porcentagem AA: ' + form.percentageAATable.value + ' %' }}
                    </v-chip>
                  </v-row>
                </fieldset>
                <p style="text-align: right" v-if="Object.keys(form).length > 0">
                  <a @click="saveData" href="#" class="text-white text-uppercase font-weight-bold save-add-filter" style="font-size: 12px; margin-right: 20px">
                    <small><i class="fa mr-sm fa-plus"></i></small>
                    Salvar e Adicionar outro filtro
                  </a>
                </p>
              </div>              
              <div v-if="loading && !loadingSelect" class="loader-overlay"><div class="loader"></div></div>
              <div class="list-preferences">
                <fieldset v-for="(item, i) in alerts" v-bind:key="i" class="aa-alert">
                  <legend>Filtro #{{ i+1 }}</legend>
                  <div class="flex-column">
                    <strong class="block">{{ item.model }}</strong>
                    <small>{{ item.attributes }}</small>
                  </div>
                  <a v-if="loadingRemove !== item.id" class="remove-button cursor-pointer" @click="() => deleteAlertItemHandler({ id: item.id })">Remover</a>
                  <div v-else class="loader-overlay-remove"><div class="loader"></div></div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import SelectSearch from "@/components/inputs/SelectSearch.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import FollowCarInput from "@/components/inputs/FollowCarInput.vue";
import getPlatform from "@/helpers/getPlatform";
import PLATFORMS from "@/constants/Platforms";

export default {
  data: function () {
    return {
      showMessage: true,
      resetFlag: false,
      selectedItem: '',
      filters: [
        {
          id: 'brand',
          name: 'Marca',
        },
        {
          id: 'model',
          name: 'Modelo',
        },
        {
          id: 'version',
          name: 'Versão',
        },
        {
          id: 'year',
          name: 'Ano',
        },
        {
          id: 'km',
          name: 'KM',
        },
        {
          id: 'price',
          name: 'Preço',
        },
        {
          id: 'mileage',
          name: 'Distância',
        }
      ],
      percentageAAFilter: { id: 'percentageAATable', name: 'Tabela Autoavaliar' },
      priceFromValueInput: '',
      priceToValueInput: '',
      kmFromValueInput: '',
      kmToValueInput: '',
      mileageValueInput: '',
      porcentagemAAValueInput: '',
    };
  },
  components: {
    FollowCarInput,
    CustomSelect,
    SelectSearch
  },
  computed: {
    ...mapState({
      participantFlags: (state) => state.participantFlags.flags,
      modalIsOpen: (state) => state.followCarModalModule.modalIsOpen,
      alerts: (state) => state.followCarModalModule.alerts,
      loading: (state) => state.followCarModalModule.loading,
      loadingRemove: (state) => state.followCarModalModule.loadingRemove,
      dataFilter: (state) => state.followCarModalModule.dataFilter,
      form: (state) => state.followCarModalModule.form,
      brands: (state) => state.followCarModalModule.brands,
      models: (state) => state.followCarModalModule.models,
      versions: (state) => state.followCarModalModule.versions,
      formWithoutFromToValues: (state) => Object.keys(state.followCarModalModule.form)?.filter(
          key => !key.includes('year') &&
              !key.includes('price') &&
              !key.includes('km') &&
              !key.includes('mileage') &&
              !key.includes('percentageAATable')
      ) ?? []
    }),
    loadingSelect: function () {
      return this.loading && this.hasSelectedItem();
    },
    isMobile: function () {
      const platform = getPlatform();
      return platform === PLATFORMS.MOBILE;
    },
    flagValidacao() {
      return this.participantFlags.FOLLOWCAR_EXIBE_CAMPO_PERCENTUAL_AA == 1;
    },

    filteredFilters() {
    return this.flagValidacao
      ? [...this.filters, this.percentageAAFilter]
      : [...this.filters]; 
  }
  },
  mounted() {
    this.$store.dispatch('participantFlags/getParticipantFlags');
  },
  methods: {
    ...mapMutations("followCarModalModule", ["toggleModal"]),
    ...mapMutations("followCarModalModule", ["selectDataItemFilter", "resetDataItemFilter", "setItemForm", "resetOption"]),
    ...mapActions("followCarModalModule", ["getAlertList", "deleteAlertItem", "saveAlertItem", "getAllBrands", "getAllBrandingModels", "getAllBrandingModelVersions"]),
    closeModal() {
      this.selectedItem = ''
      this.priceFromValueInput = ''
      this.priceToValueInput = ''
      this.kmFromValueInput = ''
      this.kmToValueInput = ''
      this.mileageValueInput = ''
      this.porcentagemAAValueInput = ''
      this.getSelectedItems()?.forEach(i => this.deleteItemSelected(i))
      this.toggleModal()
    },
    getYears() {
      const currentYear = new Date().getFullYear();
      const yearList = [];
      for (let year = 1990; year <= currentYear; year++) {
        yearList.push({ id: year.toString(), name: year.toString() });
      }
      return yearList;
    },
    generateFromToString(form, keyFrom, keyTo, prefix = '', suffix = '') {
      const from = form[keyFrom] ? prefix + form[keyFrom].id + suffix : null;
      const to = form[keyTo] ? prefix + form[keyTo].id + suffix : null;
      if (from && to) {
        if (from === to) {
          return `Até ${from}`;
        }
        return `Entre ${from} ~ ${to}`;
      }
      if (from && !to) {
        return `Acima de ${from}`;
      }
      if (!from && to) {
        return `Até ${to}`;
      }
      return 'Anos não fornecidos';
    },
    selectNotFetchItemFilterHandler(item) {
  const { key, option } = item;
  
  let value = option.value?.toString() || "";

  if (key === "percentageAATable") {
    if (value === "-") {
      this.dataFilter[key] = { ...option, value };
      return;
    }

    let isNegative = value.startsWith("-");
    value = value.replace(/[^\d]/g, "");

    if (isNegative && value.length > 0) {
      value = `-${value}`;
    }

    value = parseInt(value, 10);

    if (isNaN(value)) {
      delete this.dataFilter[key];
      return;
    }

    value = Math.max(-999, Math.min(999, value));
  } else {
    value = value.match(/\d+/g)?.join("") ?? null;
  }

  if (!value && value !== 0) {
    delete this.dataFilter[key];
  } else {
    this.dataFilter[key] = { ...option, value };
  }
},
    async selectItemFilterHandler(item) {
      const id = item.option?.id ?? null;
      this.selectedItem = id;
      if (id === 'brand') {
        await this.getAllBrands()
      }
      else if (id === 'model' && this.form?.brand?.id) {
        await this.getAllBrandingModels(this.form.brand.id)
      }
      else if (id === 'version' && this.form?.brand?.id && this.form?.model?.id) {
        await this.getAllBrandingModelVersions({brand: this.form.brand.id, model: this.form.model.id})
      }
    },
    hasSelectedItem() {
      return this.selectedItem !== '' && this.selectedItem !== undefined && this.selectedItem !== null;
    },
    isBrand() {
      return this.hasSelectedItem() && this.selectedItem === 'brand';
    },
    isModel() {
      return this.hasSelectedItem() && this.selectedItem === 'model';
    },
    isVersion() {
      return this.hasSelectedItem() && this.selectedItem === 'version';
    },
    isYear() {
      return this.hasSelectedItem() && this.selectedItem === 'year';
    },
    isPrice() {
      return this.hasSelectedItem() && this.selectedItem === 'price';
    },
    isKm() {
      return this.hasSelectedItem() && this.selectedItem === 'km';
    },
    isMileage() {
      return this.hasSelectedItem() && this.selectedItem === 'mileage';
    },
    isPorcentagem() {
      return this.hasSelectedItem() && this.selectedItem === 'percentageAATable';
    },
    selectItemHandle(event) {
      if (event.option == null) {
        delete this.dataFilter[(event?.key ?? this.selectedItem)];
      }
      else {
        this.selectDataItemFilter({key: event?.key ?? this.selectedItem, value: event.option})
      }
    },
    setItemFormHandle() {
      this.resetFlag = true;
      if (this.selectedItem === 'brand') {
        this.setItemForm({ key: 'brand', value: this.dataFilter.brand })
      }
      else if (this.selectedItem === 'model') {
        this.setItemForm({ key: 'model', value: this.dataFilter.model })
      }
      else if (this.selectedItem === 'version') {
        this.setItemForm({ key: 'version', value: this.dataFilter.version })
      }
      else if (this.selectedItem === 'mileage') {
        this.setItemForm({ key: 'mileage', value: this.dataFilter.mileage })
      }
      else if (this.selectedItem === 'percentageAATable') {
        this.setItemForm({ key: 'percentageAATable', value: this.dataFilter.percentageAATable })
      }
      else if (this.selectedItem === 'year') {
        if (this.dataFilter?.yearFrom) {
          this.setItemForm({key: 'yearFrom', value: this.dataFilter.yearFrom})
        }
        if (this.dataFilter?.yearTo) {
          this.setItemForm({key: 'yearTo', value: this.dataFilter.yearTo})
        }
      }
      else if (this.selectedItem === 'price') {
        if (this.dataFilter?.priceFrom) {
          this.setItemForm({key: 'priceFrom', value: this.dataFilter.priceFrom})
        }
        if (this.dataFilter?.priceTo) {
          this.setItemForm({key: 'priceTo', value: this.dataFilter.priceTo})
        }
      }
      else if (this.selectedItem === 'km') {
        if (this.dataFilter?.kmFrom) {
          this.setItemForm({key: 'kmFrom', value: this.dataFilter.kmFrom})
        }
        if (this.dataFilter?.kmTo) {
          this.setItemForm({key: 'kmTo', value: this.dataFilter.kmTo})
        }
      }
      this.selectedItem = '';
      this.resetDataItemFilter();
      this.$nextTick(() => {
        this.resetFlag = false;
      });
    },
    hasItemSelected(itemSelected) {
      const arr = Object.keys(this.form);
      return arr.length > 0 && arr.find(item => item.toLowerCase() === itemSelected.toLowerCase()) !== undefined;
    },
    deleteItemSelected(...keys) {
      const arr = Object.keys(this.form);
      if (arr.length > 0) {
        this.resetFlag = true;
        keys.forEach(key => {
          delete this.form[key];
          if (key === 'model') {
            this.resetOption('versions');
            this.deleteItemSelected('version')
          }
          else if (key === 'brand') {
            this.resetOption('models');
            this.resetOption('versions');
            this.deleteItemSelected('model')
            this.deleteItemSelected('version')
          }
          this.$nextTick(() => {
            this.resetFlag = false;
          });
        });
      }
    },
    getSelectedItems() {
      const arr = Object.keys(this.form);
      if (arr.includes('yearFrom') && arr.includes('yearTo')) {
        arr.push('year')
      }
      if (arr.includes('kmFrom') && arr.includes('kmTo')) {
        arr.push('km')
      }
      if (arr.includes('priceFrom') && arr.includes('priceTo')) {
        arr.push('price')
      }
      return arr.length > 0 ? arr : [];
    },
    async saveData() {
      const arr = Object.keys(this.form);
      const data = arr.length > 0 ? arr : [];
      const form = {};
      data.forEach(item => {
        if (item === 'priceFrom' || item === 'priceTo') {
          // remove pontuacao do valor
          form[item] = this.form[item].id.replace(/[.]/g, '').replace(',00', '');
        }else{
          form[item] = this.form[item].id
        }
      })
      await this.saveAlertItem(form);
      await this.getAlertList()
      this.getSelectedItems()?.forEach(i => this.deleteItemSelected(i))
    },
    async deleteAlertItemHandler(item){
      await this.deleteAlertItem(item)
    },
    disableAddButton() {
      const selectedItems = Object.keys(this.dataFilter);
      if (selectedItems.length === 0) {
        return true;
      }
      if (this.selectedItem == 'brand' && (!selectedItems.includes('brand') || selectedItems.brand === null)) {
        return true;
      }
      else if (this.selectedItem == 'model' && (!selectedItems.includes('model') || selectedItems.model === null)) {
        return true;
      }
      else if (this.selectedItem == 'version' && (!selectedItems.includes('version') || selectedItems.version === null)) {
        return true;
      }
      else if (this.selectedItem == 'price' && (!selectedItems.includes('priceFrom') && !selectedItems.includes('priceTo'))) {
        return true;
      }
      else if (this.selectedItem == 'year' && (!selectedItems.includes('yearFrom') && !selectedItems.includes('yearTo'))) {
        return true;
      }
      else if (this.selectedItem == 'km' && (!selectedItems.includes('kmFrom') && !selectedItems.includes('kmTo'))) {
        return true;
      }
      return false;
    }
  },
};
</script>
<style scoped lang="scss">
.cursor-pointer{
  cursor: pointer;
}
.loader-overlay * {
  font-family: "Roboto", Roboto, Helvetica, sans-serif;
}
.loader-overlay, .loader-overlay-remove {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-overlay {
  width: 100%;
}
.loader {
  position: relative;
  display: block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  opacity: .7;
  animation: spin 2s linear infinite;
  margin: 10px;
}
.loader-overlay-remove .loader {
  width: 30px;
  height: 30px;
}
.remove-button {
  padding: 1px 5px;
  background-color: white;
  color: #666;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 2px;
  font-size: 12px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.filter-container fieldset {
  margin: 20px 0px 5px 0px;
  border: solid 3px white;
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
  font-weight: bold;
  margin-left: 10px;
}
.list-preferences {
  padding: 0px 20px;
  overflow-y: scroll;
  max-height: 250px;
}
.aa-alert {
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #AAA;
}
.aa-alert legend, .filter-container legend {
  color: #FFF;
  padding: 0 10px;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -7px;
}
.filter-container legend {
  font-size: 16px;
}
.btn-close2 button {
  cursor: pointer;
}
.headline p {
  font-size: 15px;
  overflow-y: scroll;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 15px 20px 0 20px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background: radial-gradient(rgba(0, 0, 0, .75), rgb(0 0 0)) !important;
  transition: opacity 0.3s ease;
  overflow-y: scroll;
  max-height: 100vh;
}
.clearfix {
  width: 100%;
  float: left;
}
.fl {
  float: left;
}
.save-add-filter {
  text-decoration: none;
}
.save-add-filter:hover {
  text-decoration: underline;
}
.modal-container {
  width: 100%;
  height: auto !important;
  position: absolute;
  top: auto;
  color: white;
}
.select-container {
  flex-direction: row;
}
@media (max-height: 580px) {
  .modal-overlay {
    display: block;
    padding: 20px;
  }
}
@media (max-width: 960px) {
  .close-bt{
    right: 0px;
  }
  .new-filter {
    flex-direction: column;
  }
  .select-container {
    max-width: 100%;
  }
  .responsive-btn {
    width: 100% !important;
    border-radius: 35px;
  }
}
#preferences-modal {
  position: relative;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  width: 100%;
  max-width: 700px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preferences-modal-content {
  width: 100%;
}
.modal-overlay.active,
.modal2.active {
  opacity: 1;
}
.close-bt {
  background-color: #f0f0f0;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.toggle-follow-car-desc {
  text-decoration: none;
  color: white;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}
@media only screen and (max-width: 920px) {
  #preferences-modal {
    max-width: 90%;
    max-height: 90%;
  }
}
.select-wrapper {
  position: relative;
}
.select-wrapper::after {
  content: "▾";
  color: #000000;
  top: 5px;
  right: 10px;
  position: absolute;
}
.filtro-btn {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  text-transform: uppercase;
  background-color: #fff;
  padding: 5px !important;
  outline: none;
}
.filtro-btn option {
  padding: 8px;
  cursor: pointer;
  color: #000000;
  text-transform: uppercase;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mobile{
  h3 strong{
    font-size: 16px;
  }
  a.toggle-follow-car-desc{
    margin: 1em 0;
  }
  .headline p{
    font-size: 13px;
  }
  .list-preferences{
    margin-top: 1em;
  }
}
.uppercase-text {
  text-transform: uppercase;
}
</style>