import SECTIONS_KEYS from "./SectionsKeys";

const SECTIONS_DEFAULT = [
  { name: "Localização", id: 0, key: SECTIONS_KEYS.Location },
  { name: "Tipo de anunciantes", id: 1, key: SECTIONS_KEYS.AdvertiserType },
  { name: "Modalidade de compra", id: 2, key: SECTIONS_KEYS.PurchaseMode },
  { name: "FIPE", id: 3, key: SECTIONS_KEYS.Fipe },
  { name: "Categoria", id: 4, key: SECTIONS_KEYS.Category },
  { name: "Marca do veículo", id: 5, key: SECTIONS_KEYS.VehicleBranding },
  { name: "Preço do Veículo", id: 6, key: SECTIONS_KEYS.VehiclePrice },
  { name: "Ano do Veículo", id: 7, key: SECTIONS_KEYS.VehicleYear },
  { name: "Quilometragem do Veículo", id: 8, key: SECTIONS_KEYS.VehicleKM },
  { name: "Condição do veículo", id: 9, key: SECTIONS_KEYS.VehicleCondition },
  { name: "Tipo de veículo", id: 10, key: SECTIONS_KEYS.VehicleType },
  { name: "Laudo", id: 11, key: SECTIONS_KEYS.Report },
  { name: "Opcionais", id: 12, key: SECTIONS_KEYS.Opcional },
  { name: "Combustível", id: 13, key: SECTIONS_KEYS.Fuel },
  { name: "Transmissão", id: 14, key: SECTIONS_KEYS.Transmission },
];

export default SECTIONS_DEFAULT;
