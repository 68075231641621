<template>
  <div class="footer-info">
    <img
        :src="require('@/assets/icons/time-icon.svg')"
        height="30"
        width="30"
        v-if="formatedTime"
    />
    <div class="timer" v-if="formatedTime">
      <span v-if="hideDescription === undefined || hideDescription === false">
        Tempo restante:
      </span>
      <h4 v-if="formatedTime">
        {{ formatedTime }}
      </h4>
    </div>
  </div>
</template>
<script>

export default {
  name: "VehicleItemTimer",
  props: {
    vehicle: Object,
    hideDescription: Boolean,
    isMobile: Boolean,
  },
  data: function () {
    return {
      time: null,
      formatedTime: null,
      interval: null,
    };
  },
  computed: {
    timerFontSize: function() {
      if (this.isMobile) {
        return '10px';
      }
      return '12px';
    },
    timerJustifyContent: function() {
      if (this.isMobile) {
        return 'center';
      }
      return '';
    }
  },
  methods: {
    formatTime: function (time) {
      return time < 10 ? "0" + time : time;
    },
    subtractSeconds: function () {
      const newTime = new Date(this.time);
      newTime.setSeconds(newTime.getSeconds() - 1);
      const result = this.countdown(newTime);
      this.formatedTime =  result;
      if (result === 0) {
        clearInterval(this.interval);
      }
    },
    countdown: function (targetDate) {
      const currentDate = new Date();

      const timeRemaining = targetDate.getTime() - currentDate.getTime();

      if (timeRemaining <= 0) {
        return 0;
      }
      let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      days = days ? `${this.formatTime(days)} ${days > 1 ? 'dias' : 'dia'},` : "";

      return `${days} ${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}s`;
    }
  },
  mounted: function () {
    this.time = new Date((parseInt(this.vehicle.dt_ts_final) * 1000));
    this.interval = setInterval(() => this.subtractSeconds(), 1000);
  },
  unmounted: function () {
    clearInterval(this.interval);
    this.time = null;
    this.interval = null;
  },
};
</script>

<style lang="scss" scoped>
.footer-info {
  display: flex;
  flex: 1;
  justify-content: v-bind(timerJustifyContent);
  align-items: center;
}

.footer-info img {
  height: 30px;
  width: 30px;
}

.timer {
  margin-left: 4px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  justify-content: center;
}

.timer span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 0.68rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #373737;
}

.timer h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  white-space: nowrap;
  align-items: center;
  color: #373737;
}

@media only screen and (max-width: 1220px) {
  .timer h4 {
    font-size: 10px;
  }
  .footer-info img {
    height: 21px;
    width: 21px;
  }
}
</style>
