import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export const makeEvaluation = async (id_avaliacao, valor) => {
  return axios
    .post(`${API_BASE}/purchasers/makeEvaluation`, {
        id_avaliacao,
        valor
    }, {
      headers: {
        token: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
};


