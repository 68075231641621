<template>
  <div id="filter-result-showcase-app" :class="isMobile() ? 'mobile' : ''" @scroll="onScroll">

    <DashboardHeader
      :user="userData.user"
      :ads="userData.ads"
      :adsFormatted="userData.adsFormatted"
      :purchasesLastMonth="userData.purchasesLastMonth"
    />
    <v-container v-if="loading" fluid>
      <v-row>
        <v-col
          v-for="(_, index) in Array.from(Array(8).keys())"
          v-bind:key="index"
          lg="4"
          xl="3"
          xxl="2"
        >
          <v-skeleton-loader
            class="mx-auto border card-skeleton"
            type="image, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="campaigns-dashboard">
      <div class="campaign" v-for="(obj, index) in carouselVehicles" v-bind:key="index">
        <VehiclesSlider 
          v-if="obj.vehicles.length > 0"
          :vehicles="obj.vehicles"
          :key_showcase="index"
          :isMobile="isMobile()"
          :title="obj.carousel.title"
          :subtitle="obj.carousel.desc"
          :icon="obj.carousel.icon"
        />
        <v-skeleton-loader v-if="loading"
        class="mx-auto border card-skeleton"
        type="image, article, actions"
      ></v-skeleton-loader>
      </div>
    </v-container>

    <HistoryChip
      :logs="userData.logs"
      style="padding-bottom: 100px"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import VehiclesSlider from "./components/VehiclesSlider.vue";
import DashboardHeader from "./components/DashboardHeader.vue";
import HistoryChip from "@/views/showcase/components/HistoryChip.vue";

export default {
  name: "DashboardShowcase",
  components: {
    VSkeletonLoader,
    VehiclesSlider,
    DashboardHeader,
    HistoryChip
  },
  mixins: [PlatformsMixins],
  data() {
    return {
      showcaseKey: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.filterShowcase.dashboardLoading,
      selectedPage: (state) => state.filterShowcase.page,
      totalPages: (state) => state.filterShowcase.total,
      params: (state) => state.filterShowcase.params,
      userData: (state) => state.filterShowcase.userData,
      carouselVehicles: (state) => state.filterShowcase.carousel,
      ads: (state) => state.ads,
    })
  },
  methods: {
    ...mapActions("filterShowcase", ["search", "pagination", "getDashboardData"]),
    ...mapActions("ads", ["getCampaign"]),
  },
  mounted: async function () {
    this.page = this.selectedPage;
    await this.getDashboardData();
  },
};
</script>

<style scoped lang="scss">
#filter-result-showcase-app {  
  &.mobile{
    .slider-vehicle{
      h2{
        font-size:1.2em;
      }
      .v-slide-group__prev,
      .v-slide-group__next,
      .v-slide-group__next--disabled{
        display:none !important;
        background-color: red;
        min-width: auto !important;
      }
    }
  }
}

.container-alert-text-vehicle {
  display: flex;
  justify-content: flex-start;
}

.card-skeleton {
  border: 7px solid #ffffff;
  border-radius: 12px;
}

.alert-text-vehicle {
  color: #868686;
  font-size: 18px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  width: 500px;
  line-height: 40px;
}

.sorry-text {
  color: #868686;
  font-size: 22px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
}

.btn-alert-vehicle {
  color: white;
  font-size: 18px !important;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  width: 260px !important;
  background-color: #ff7a00 !important;
}

#filter-result-showcase-app {
  flex: 78;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: v-bind(contentHeightWithButtonFooter);
}

.scroll-loading {
  margin-top: 40px;
  margin-bottom: 70px;
}


@media screen and (max-width: 768px) {
  #filter-result {
    position: relative;
  }
  .container-not-result {
    padding: 15px;
  }
  .not-result {
    min-height: 65vh !important;
  }
}
.not-result {
  position: relative;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.scroll-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  text-align: center;
}

.scroll-loading span {
  font-family: "Lexend Deca";
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-loading p {
  font-family: "Lexend Deca";
  font-size: 18px;
  font-weight: bold;
  padding: 0 32%;
  text-align: center;
}

@media only screen and (max-width: 920px) {
  .scroll-loading p {
    padding: 0;
  }
  .scroll-loading span {
    font-size: 16px;
  }

  .scroll-loading p {
    font-size: 14px;
  }
}

.scroll-loading p a {
  color: #ff7a00;
}
.campaigns-dashboard{
  .v-row{
    &:last-child{    
      margin-bottom: 5em;
    }
    .slider-vehicle{
      .vehicle{
        
      }
      .vehicle-footer{
        display:none !important;
      }
    }
  }
  .campaign{
    &:last-child{
      padding-bottom: 8em;
    }
  }
}
h2.showcase-title{
  margin:1em 0;
}
</style>
