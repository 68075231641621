<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import PLATFORMS from "@/constants/Platforms";
import getPlatform from "@/helpers/getPlatform";
import { mapMutations } from "vuex";
import VEHICLES from "@/constants/Vehicles";

export default {
  name: "TopApp",
  inheritAttrs: false,
  customOptions: {},
  components: {
    SearchAutocomplete,
    BreadcrumbsNavVehicle,
    Ordination,
  },
  data() {
    return {
      result: "",
      totalPages: "",
      mobile: false,
      filters: {
        expanded: false,
      },
      filtersLang: {
        location: "Local",
        cities: "Cidade",
        entity: "entity",
        byEntity: "bgEntity",
        brands: "Marca",
        brandings: "Marca",
        models: "Modelo",
        versions: "Versão",
        adType: "Tipo de Anunciante",
        AdvertiserType: "Tipo de Anunciante",
        group: "Grupo",
        priceMin: "Preço Min.",
        priceMax: "Preço Máx.",
        kmMin: "Km Min.",
        kmMax: "Km Máx.",
        yearMin: "Ano Min.",
        yearMax: "Ano Máx.",
        order: "Ordem",
        category: "Categoria",
        relevance: "Relevância",
        query: "Consulta",
        campaign: "Campanha",
        optionals: "Opcionais",
        report: "Laudo",
        fipe: "Fipe",
        VehicleCondition: "Condição do Veículo",
        typeVehicle: "Tipo",
        fuel: "Combustível",
        reportStatus: "Laudo",
        quotation: "Modalidade de Compra",
        transmission: "Transmissão",
      },
    };
  },
  methods: {
    ...mapMutations("buyer", [
        "setTitlePage",
        "setTitleSectionPage"
    ]),
    ...mapMutations("global", ["setView"]),
    getValueFilter(v = false, k = false) {
      let sufix = '';
      if(k == 'fipe') sufix = '%';
      if (typeof v === "null") return false;
      if (typeof v === "undefined") return false;
      if (typeof v === "array" || typeof v === "object") {
        v = v.map((elem) => {
          if(typeof elem === 'object'){
            if ("title" in elem) return elem.title;
            if ("name" in elem) return elem.name;
            if ("value" in elem) return elem.value;
          }
          return elem;
        });
      }
      if (typeof v === "object") v = v.join(", ");
      return v + sufix;
    },
    getTotals() {
      this.result = store.state.filterForm.result;
      this.totalPages = store.state.filterForm.total;
      return this.result.length * this.totalPages;
    },
    isMobile() {
      const platform = getPlatform();
      var isMobile = platform === PLATFORMS.MOBILE;
      this.mobile = isMobile;
      return isMobile;
    },
  },
  computed: {
    ...mapState({
      params: (state) => state.filterForm.params,
      view: (state) => state.global.view
    }),
    total() {
      return this.getTotals().toLocaleString();
    },
    filtersActive() {
      let params = this.params;
      return Object.entries(params).filter(
        (v) => (v[1] && v[1].length > 0) || (v[1] > 0)
      );
    },
  },
  mounted() {
    this.setTitlePage(VEHICLES.TITLE_DASHBOARD);
    this.setTitleSectionPage(VEHICLES.TITLE_SECTION_DEFAULT);
    addEventListener("resize", () => {
      this.isMobile();
    });
  },
};
</script>

<script setup>
import store from "@/store";
import SearchAutocomplete from "@/components/top/SearchAutocomplete.vue";
import BreadcrumbsNavVehicle from "@/components/top/BreadcrumbsNavVehicle.vue";
import Ordination from "@/components/top/Ordination.vue";
import ViewSelect from "@/components/top/ViewSelect.vue";
</script>

<template>
  <div id="top-app">
    <div class="content-top" :class="{ mobile: isMobile() }" v-if="!isMobile()">
      <div class="content-search">
        <SearchAutocomplete />
      </div>
      <div class="content-main">
        <div class="breadcrumbs-nav">
          <BreadcrumbsNavVehicle :mobile="mobile" />
        </div>
        <div class="viewSelect" v-if="!mobile">
          <view-select @change="setView" />
        </div>
        <div class="order">
          <Ordination :mobile="mobile" />
        </div>
      </div>
      <div class="content-filter" v-if="mobile && (filtersActive && Object.keys(filtersActive).length > 0)">
        <p class="filtros-mobile" :class="{ expanded: filters.expanded }">
          <i class="mdi mdi-filter-outline"></i> O que você filtrou:<br />
          <span
            class="filter"
            v-for="(f, k) in filtersActive"
            :key="f"
          >
            <span class="title">{{ filtersLang[f[0]] }}: </span>
            <span class="caption">{{ getValueFilter(f[1], f[0]) }}</span>
          </span>
        </p>
        <p
          class="more"
          v-if="!filters.expanded && filtersActive.length > 2"
          @click="filters.expanded = true"
        >
          Ver mais <i class="mdi mdi-chevron-down"></i>
        </p>
        <p
          class="less"
          v-if="filters.expanded"
          @click="filters.expanded = false"
        >
          Ocultar <i class="mdi mdi-chevron-up"></i>
        </p>
      </div>
    </div>
    <div class="content-top" :class="{ mobile: isMobile() }" v-else>
      <div class="content-search">
        <SearchAutocomplete />
      </div>
      <div class="content-main">
        <div class="breadcrumbs-nav">
          <BreadcrumbsNavVehicle :mobile="mobile" />
        </div>
        <div class="order">
          <Ordination :mobile="mobile" />
        </div>
      </div>
      <div class="d-flex justify-space-between w-100">
        <div class="content-filter" v-if="mobile && (filtersActive && Object.keys(filtersActive).length > 0)">
          <p class="filtros-mobile" :class="{ expanded: filters.expanded }">
            <i class="mdi mdi-filter-outline"></i> O que você filtrou:<br />
            <span
                class="filter"
                v-for="(f, k) in filtersActive"
                :key="f"
            >
            <span class="title">{{ filtersLang[f[0]] }}: </span>
            <span class="caption">{{ getValueFilter(f[1], f[0]) }}</span>
          </span>
          </p>
          <p
              class="more"
              v-if="!filters.expanded && filtersActive.length > 2"
              @click="filters.expanded = true"
          >
            Ver mais <i class="mdi mdi-chevron-down"></i>
          </p>
          <p
              class="less"
              v-if="filters.expanded"
              @click="filters.expanded = false"
          >
            Ocultar <i class="mdi mdi-chevron-up"></i>
          </p>
        </div>
        <div class="viewSelect w-100 mt-2" v-if="!mobile">
          <ViewSelect class="float-right" @change="setView" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.result-actions {
  border: 1px solid #ddd;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 3em;

  a.btn,
  span.total {
    text-decoration: none;
    color: #777;
    padding: 0.5em 1em;
    font-size: 0.85rem;
  }

  span.line {
    color: #ddd;
    padding: 0.3em 0;
  }
}

.content-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px;
  border-bottom: solid 1px #e4e4e4;
  margin-bottom: 0px;
  width: 100%;

  .content-search {
    flex: 22;
    min-width: fit-content;
    margin-right: 0.5em;
  }
  .content-main {
    .breadcrumbs-nav {
      display: flex;
      display: flex;
      flex-direction: column;
      margin-right: 0;
      @media (min-width: 921px) {
        max-width: calc(100% - 22.3em);
      }
      display: grid !important;
    }
    .order {
      @media (max-width: 1400px) {
        //flex: 1;
      }
    }
  }
  .content-main {
    flex: 78;
    overflow-x: hidden;
  }

  &.mobile {
    .filtros-mobile {
      max-height: 5em;
      overflow: hidden;
      font-size: 0.8rem;
      &.expanded {
        height: auto;
        overflow: visible;
        max-height: 100%;
      }
      .filter {
        margin-right: 0.5em;
      }
      .title {
        font-weight: 600;
        font-size: 0.8rem;
        color: #ff9900;
      }
      .caption {
        text-transform: capitalize;
        color: #555;
        &.location{
          text-transform: uppercase;
        }
      }
    }
    .content-main {
      margin-top: 0.5em;
      height: auto;
      select::after {
        font-size: 0.8rem;
      }
    }
    .breadcrumbs-nav {
      margin-right: 0;
      height: auto;
      align-self: baseline;
      align-self: center;
    }

    .d-none {
      display: none;
    }

    .order {
      display: flex;

      #ordination {
        margin: 0;
        padding: 5px;
        font-size: 0.8rem;
        #ordination_wrapper {
          white-space: nowrap;

          .select {
            display: inline-block;
            min-width: auto;
            max-width: 7em;
            font-size: 0.8rem !important;
            font-weight: 400;
          }
          span,
          select {
            font-size: 0.8rem;
          }
        }
        .total {
          font-weight: bold;
        }
      }
    }
    .content-filter {
      display: block;
      width: 100%;
      margin-top: 0.5em;
      p {
        text-align: left;
        font-size: 0.8rem;
        line-height: 1.6em;
        color: #555;
      }
      p.more,
      p.less {
        font-size: 0.7rem;
        color: #777;
        padding: 5px 0;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .content-top {
    flex-direction: column;
    padding: 18px;

    &.mobile {
      justify-content: space-between;
      align-items: center;
    }
  }

  .content-search {
    width: 100%;
    margin-right: 0 !important;
  }

  .content-main {
    width: 100%;
  }
}

.content-top > div:nth-child(1),
.content-top > div:nth-child(2) {
  display: flex;
}

.content-top > div:nth-child(3) {
  display: flex;
  align-items: end;
  align-self: center;
}

.content-top .search-vehicle {
  border: solid 1px red;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  min-width: 380px;
}

.content-search {
  flex: 22 0 auto;
  margin-right: 0;
  min-width: 320px;
}

.breadcrumbs-nav {
  flex: 3;
  margin-right: 20px;

  .scroll-filters {
    overflow-x: scroll;
    overflow-y: visible;
  }
}
</style>
