<template>
  <div class="footer_comp">
    <div class="space2"></div>
    <div class="footer2">
      <footer class="default-footer">
        <div class="wrapper">
          <p>
            © Copyright 2008~2023
            <span class="aa-opacity-6">
              - Plataforma
              <a
                href="https://b2b.autoavaliar.com.br"
                title="Auto Avaliar"
                class="hdu aa-opacity-6"
                target="_parent"
              >
                <img
                  src="https://b2b-dot-autoavaliar-b2b.rj.r.appspot.com/assets/img/logo-min-p-new.png"
                  alt="Auto Avaliar"
                  class="vam"
                />
                Auto Avaliar
              </a>
            </span>
            <span class="fr">
              <strong
                >AutoAvaliar,
                <span class="sm-hide"
                  >A forma mais simples de comprar veículos para seu
                  estoque.</span
                ></strong
              >
              - Todos os direitos reservados.</span
            >
          </p>
          <p class="aa-opacity-5">
            m-59dd3731<br />
            <a href="#!" class="async-terms" id="show-modal" @click="openModal"
              ><u>Política de Privacidade</u></a
            >
            -
            <a href="#!" class="async-terms" @click="openModal2"
              ><u>Termos de uso</u></a
            >
            -
            <a
              href="https://autoavaliar.com.br/queremos-te-ouvir"
              target="blank"
              ><u>Queremos te ouvir</u></a
            >
          </p>
          <span class="to-top transition-all" @click="scrollToTop">
            <i class="fa fa-chevron-up"></i>
          </span>
        </div>
      </footer>
    </div>
  </div>

  <div>
    <ModalFooter :is-open="isModalOpen" @close="closeModal">
      <template v-slot:content>
        <h1>Política Global de Privacidade Auto Avaliar</h1>
        <h2>
          "Transparência, segurança, rentabilidade e eficiência, sempre
          respeitando a todos os envolvidos"
        </h2>
        <br />
        <p><strong>NOSSA CULTURA</strong></p>
        <p>
          Nossa cultura é baseada em respeito. O respeito está presente em nossa
          relação com o mercado, nossos colaboradores, clientes, fornecedores e
          acionistas. Ele é a base de tudo o que fazemos e principalmente dirige
          o "como" fazemos. Dessa forma, o respeito é o elemento central desta
          política. Respeito à sua privacidade, respeito aos seus direitos e
          sobretudo, respeito a você que, de alguma maneira, se relaciona
          conosco através de nossos produtos, serviços ou algum de nossos canais
          existentes.
        </p>
        <br />

        <p><strong>O QUE ENTENDEMOS COMO PRIVACIDADE</strong></p>
        <p>
          Definimos privacidade como o resultado da aplicação da seguinte tríade
          de direitos: "Direito de não ser monitorado", "Direito de não ser
          registrado" e o "Direito de não ser reconhecido". Baseada nessa
          tríade, criamos nossos produtos e serviços de forma a garantir que
          você se relacione conosco de maneira legítima, ética e adequada,
          sempre respeitando todas as partes envolvidas.
        </p>
        <br />

        <p><strong>DEFINIÇÃO DE “NÓS” E “VOCÊ”</strong></p>
        <p>
          No escopo desta política chamamos "NÓS" a tudo o que diga respeito ao
          universo Auto Avaliar. "NÓS" são nossos colaboradores no exercício de
          sua profissão, nossos produtos em operação, o perímetro de nossos
          prédios e tudo o que seja parte da empresa Auto Avaliar, suas
          coligadas ou parceiras de negócio quando em exercício desta parceria.
          Também neste documento, chamamos "VOCÊ" a todas as pessoas naturais,
          titulares de dados tratados no escopo "NÓS" que de alguma forma se
          relacionem conosco. Sejam eles acionistas, colaboradores, clientes,
          terceiros, visitantes, fornecedores ou qualquer pessoa que tenha
          alguma relação conosco que esteja englobada pelos termos desta
          política.
        </p>
        <br />

        <p><strong>ESCOPO DESTA POLÍTICA</strong></p>
        <p>
          Esta Política de Proteção de Dados se aplica à organização nacional da
          empresa Auto Avaliar, e engloba todas as nossas atividades, dimensões
          e aplicações em todo território em que operamos.
        </p>
        <p>
          Esta política se aplica ao processamento de dados pessoais que são
          fornecidos para que possamos operar em nossos negócios, produtos e
          serviços e viabilizar uma melhor experiência para você dentro da
          plataforma de nossos produtos.
        </p>
        <br />

        <p><strong>DEFINIÇÃO DE "DADOS PESSOAIS"</strong></p>
        <p>
          Entendemos "dados pessoais" como sendo todos os dados que processamos,
          de forma direta ou indireta, que torne a pessoa natural identificada
          ou identificável.
        </p>
        <br />

        <p><strong>QUAL IMPORTÂNCIA DAMOS A LEI</strong></p>
        <p>
          Nós embasamos esta Política de Privacidade na Lei Nº 13.709/18 , ou
          Lei Geral de Proteção de Dados (LGPD), a qual estabelece regras sobre
          a coleta, armazenamento, tratamento e compartilhamento de dados
          pessoais, sempre visando a maior proteção dos titulares desses dados,
          ou seja, você.
        </p>
        <br />
        <p>
          Sendo assim, nos comprometemos a seguir as diretrizes da legislação
          que se aplica em todo território nacional a fim de garantir que todos
          os dados pessoais coletados e processados por nós serão tratados de
          acordo com os termos da LGPD, salvo casos específicos desde que sejam
          justificados pelas bases legais cabíveis.
        </p>
        <br />

        <p><strong>POR QUE PRECISAMOS DE SEUS DADOS?</strong></p>
        <p>
          A primeira coisa que queremos que você saiba é que não processamos
          seus dados sem que haja uma real necessidade e uma base legal para
          isso. Assim, os dados podem ser processados quando:
        </p>
        <p>
          a. seja necessário a execução de um contrato do qual você seja parte
          essencial;<br />
          b. seja necessário o cumprimento de uma obrigação legal;<br />
          c. ou então em ações internas, desde que tenhamos o seu consentimento
          prévio.
        </p>
        <br />
        <p>
          Podemos processar seus dados para fins de nossos interesses legítimos,
          mas nunca quando esses interesses ferirem seus próprios interesses,
          direitos ou liberdades fundamentais. Contudo, sempre que necessário e
          aplicável por lei, pediremos seu consentimento antes de fazer a coleta
          de seus dados.
        </p>
        <br />
        <p><strong>QUAIS DADOS COLETAMOS</strong></p>
        <p>
          Nos comprometemos com os princípios da FINALIDADE, ADEQUAÇÃO e
          NECESSIDADE, segundo rege a Lei Geral de Proteção de Dados. Assim
          nunca coletamos dados além daqueles que são estritamente necessários
          para a realização dos negócios a que esses dados se destinam.
          Garantimos que estes dados serão utilizados para o fim declarado no
          momento da coleta e que o tratamento aplicado durante todo o ciclo de
          vida destes dados é adequado à sua finalidade.
        </p>
        <br />
        <p>
          Coletamos dados pessoais de nossos colaboradores, acionistas e
          parceiros comerciais. Entre estes dados estão dados de contato como:
          nome, telefone, celular e e-mail, dados de localização como rua, CEP,
          bairro, cidade e UF. Dados de currículo como formação acadêmica,
          empresas pelas quais passaram e cursos realizados. Dados de saúde como
          condições preexistentes, restrições e recomendações médicas para o
          adequado exercício da profissão. Entre estes dados ainda podem constar
          dados de dependentes, ainda que menores, para fins de concessão de
          plano de saúde e demais exigências legais relativas ao contrato de
          trabalho.
        </p>
        <br />
        <p>
          Tratamos dados referentes aos veículos que são avaliados em nossa
          plataforma, como: placa, chassi, estado de conservação de vários
          componentes, cor e demais informações pertinentes para uma avaliação
          veicular satisfatória. Ainda podemos tratar dados do proprietário do
          veículo, como: nome, CPF, e-mail, telefone, celular, logradouro, cep,
          detalhes de endereço, bairro, cidade. Estes dados são coletados por
          nossos clientes no exercícios da utilização de nossos produtos de
          softwares e podem variar segundo as configurações exercidas por estes
          mesmos clientes.
        </p>
        <br />
        <p>
          Ainda podemos coletar dados de contato, para fins de campanhas de
          marketing e demais promoções destinadas à clientes assinantes de
          nossos produtos; coletar dados referentes ao histórico de atendimento
          em nosso sistema de Help Desk e CRM e coletar dados para emissão de
          notas fiscais segundo determina o conjunto de normas, súmulas e leis
          fiscais vigentes.
        </p>
        <br />
        <p>
          No entanto é imprescindível declarar que nenhum desses dados é ou será
          utilizado de forma diversa daquela a que é destinado sempre segundo o
          consentimento e direito do titular ou segundo o fundamento legal que
          embasa seu tratamento.
        </p>
        <br />
        <p><strong>POR QUANTO TEMPO FICAMOS COM SEUS DADOS</strong></p>
        <p>
          Cada conjunto de dados fica em nosso poder apenas o tempo necessário
          para atingir o objetivo a que se destina. Sendo assim, o tempo que
          mantemos seus dados varia de acordo com o tratamento a que esses dados
          estão destinados. No entanto, sempre que possível, e quando a
          legislação vigente permitir, você pode exercer seu direito de se opor
          ao processamento ou seu direito de ser esquecido.
        </p>
        <br />
        <p>
          As informações precisas sobre o tempo de vida, o tratamento e destino
          dos dados que recolhemos são e sempre serão informados no momento de
          sua coleta, para cada dado ou conjunto de dados especificamente
          coletados.
        </p>
        <br />
        <p><strong>QUANDO E COMO UTILIZAMOS COOKIES</strong></p>
        <p>
          Cookies são pequenos arquivos de texto que armazenam em seu
          dispositivo dados da página que você está visitando. Nós fazemos uso
          de cookies para registrar sua sessão, seu tráfego em nossos produtos e
          tornar a sua experiência melhor e mais personalizada em nossos sites.
          Os cookies aprimoram e permitem que sua experiência online seja
          diferenciada, salvando suas preferências enquanto você visita e navega
          em nossos sites.
        </p>
        <br />
        <p>
          Cada um de nossos sites ou produtos dispõe de uma política de cookies
          própria, sempre acessível na região do rodapé. Nesta política constam
          detalhadamente quais cookies, por quanto tempo e por quê são
          armazenados em seu dispositivo. Nesta mesma região você pode se opor
          ou consentir (a qualquer tempo), no armazenamento de cada um dos
          cookies e saber exatamente o motivo e por quanto tempo este cookie
          estará disponível.
        </p>
        <br />
        <p>
          <a href="https://autoavaliar.com.br/terms/cookies" target="blank"
            >Política de Cookies</a
          >
        </p>
        <br />

        <p><strong>QUANDO, COMO E PARA QUEM DIVULGAMOS SEUS DADOS</strong></p>
        <p>
          Os dados que coletamos ou processamos, tendo em vista a natureza dos
          negócios que realizamos, podem ser compartilhados com nossos
          colaboradores ou parceiros comerciais. Sempre respeitando a
          integridade, os princípios da LGPD, o bom senso e, principalmente, o
          respeito à você, titular do dado.
        </p>
        <br />

        <p>
          Em nenhuma hipótese nós vendemos seus dados e não compartilhamos com
          ninguém que não esteja definido no escopo "nós". No entanto, esses
          dados podem ser utilizados para realização de análises estatísticas
          anônimas e o resultados dessas análises (sempre anônimas) podem ser
          objeto de pesquisa e de comercialização. Porém, os objetos desse
          tratamento não se identificam, nem de forma direta ou indireta uma
          pessoa natural, ou seja, não são considerados dados pessoais.
        </p>
        <br />

        <p><strong>O QUE FAZEMOS PARA PROTEGER SEUS DADOS PESSOAIS</strong></p>
        <p>
          Para nós, proteção de dados é coisa séria. Este é um de nossos
          preceitos fundamentais, por isso empregamos uma série de tecnologias,
          metodologias e procedimentos para garantir sua privacidade e a
          integridade dos dados que você nos confiou. Nossos dados são
          armazenados em Data Centers certificados e em conformidade com LGPD e
          a GDPR da União Europeia e demais certificações relevantes.
        </p>
        <br />

        <p>
          Além disso, contamos com trilhas de treinamentos obrigatórios
          referentes ao assunto para todos nossos colaboradores e parceiros,
          justamente para garantir que todas as pessoas que de alguma forma
          possam manipular ou tratar seus dados estejam aptos e conscientizados
          da importância da seriedade e dedicação em relação aos procedimentos
          de nossa Política de Segurança da Informação.
        </p>
        <br />

        <p><strong>COMO TRATAMOS DADOS DE MENORES</strong></p>
        <p>
          A plataforma do portal Auto Avaliar não coleta nem faz tratamento de
          dados de menores de idade. Os acessos e contas podem ser operados
          somente por pessoas maiores de 18 anos e o cadastro no site
          <a href="https://www.autoavaliar.com.br/"
            >https://www.autoavaliar.com.br/</a
          >
          pode ser criado somente por pessoas jurídicas que se encontram em
          total conformidade com a Receita Federal do Brasil.
        </p>
        <br />

        <p>
          No entanto, para fins de concessão de planos de saúde e conformidade
          com a legislação trabalhista, podemos tratar dados de menores quando
          dependentes de nossos colaboradores ou ainda dados de eventuais
          estagiários quando menores ou membros do programa "menor aprendiz".
          Estes dados ficam restritos aos departamentos de competência (RH,
          Financeiro e Administrativo) e são tratados com especial rigor, e se
          estiverem sob a base legal do consentimento, o mesmo será obtido pelos
          responsáveis legais dos menores de idade, a fim de garantir proteção
          adicional aos titulares menores de idade.
        </p>
        <br />

        <p><strong>SEUS DIREITOS E DEVERES</strong></p>
        <p>
          Nos comprometemos em garantir os seus direitos, sempre com o apoio da
          legislação vigente a fim de proporcionar a máxima proteção dos seus
          dados pessoais.
        </p>
        <p>
          SÃO SEUS DIREITOS:<br />
          DIREITO DE ACESSO: você pode solicitar acesso aos seus dados pessoais,
          bem como a correção de dados imprecisos ou incompletos. Você também
          pode solicitar qualquer informação sobre a fonte dos dados e uma cópia
          destes dados quando aplicável.<br />
          DIREITO DE SER ESQUECIDO: você pode solicitar a exclusão dos seus
          dados em nossa base, quando:<br />
          a. os dados não forem mais necessários;<br />
          b. você não possuir mais interesse em nossos produtos;<br />
          c. você optar por retirar o consentimento previamente dado a nós;<br />
          d. você se opor ao processamento dos seus dados por meios
          automatizados que utilizam especificações técnicas;<br />
          e. existir uma obrigação legal para o apagamento dos seus dados
          pessoais;<br />
          f. o apagamento dos dados pessoais for necessário para garantir a
          conformidade às leis aplicáveis.<br />
          Aqui, nós também nos asseguramos que, se necessário, podemos manter os
          dados pessoais, desde que haja o respaldo de bases legais vigentes.
        </p>
        <p>
          DIREITO A RESTRIÇÃO DE PROCESSAMENTO: você pode restringir o uso dos
          seus dados pessoais, desde que haja uma fundamentação legal e/ou
          coerente para isso.<br />
          DIREITO À OPOSIÇÃO PARA MARKETING DIRETO: você pode, a qualquer
          momento, fazer uso do seu direito de “optar por não” fazer parte dos
          processamentos que envolvem campanhas de marketing, criação de perfis
          ou comunicações diretas. Mesmo que você tenha dado o consentimento
          prévio.<br />
          DIREITO A PORTABILIDADE: quando aplicável, você pode solicitar a nós a
          portabilidade dos seus dados pessoais que foram processados, de forma
          estruturada e de fácil acesso, a fim de transferir a outro Controlador
          de dados as informações coletadas por nós.<br />
          MAS SÃO SEUS DEVERES:<br />
          Manter seus dados sempre atualizados em nossa plataforma, a fim de
          facilitar o contato entre as transações efetuadas exclusivamente por
          ela e para fins de contato pelo nosso Suporte Técnico ou Atendimento
          ao Lojista;<br />
          O cadastro dentro do portal Auto Avaliar é individual e
          intransferível. Sendo assim é de total responsabilidade do usuário
          criar uma senha forte para acesso, bem como não compartilhar a senha
          com terceiros, a fim de evitar constrangimentos e sistemas de fraudes.
        </p>
        <br />

        <p><strong>ATUALIZAÇÕES</strong></p>
        <p>
          Nos comprometemos a manter esta política sempre coerente à legislação
          e atualizada. Fazemos uso do direito de atualizá-la à medida que
          nossos negócios ou requisitos legais sejam alterados. Caso essas
          alterações sejam de grande impacto ou interfiram, de qualquer maneira,
          nos dados previamente coletados, todos os envolvidos serão avisados da
          forma mais pertinente e cabível no momento.
        </p>
        <br />

        <p>
          <strong>DÚVIDAS</strong><br />
          Caso tenha persistido alguma dúvida referente à coleta e processamento
          de seus dados pessoais, dispomos de um canal exclusivo para que você
          possa entrar em contato com nosso Encarregado de Dados. Basta enviar
          um e-mail para dpo@autoavaliar.com.br que teremos o maior prazer de
          ajudá-lo!
        </p>
        <br />

        <p>
          AUTOAVALIAR TECNOLOGIA, PUBLICIDADE E INTERMEDIAÇÃO DE NEGÓCIOS E
          SERVIÇOS S.A. inscrita no CNPJ/MF sob o nº 22.279.800/0001-26, com
          sede na Rua Pardais, 60, Vinhedo / SP
        </p>
        <br />
      </template>
    </ModalFooter>
  </div>

  <div>
    <ModalFooter :is-open="isModalOpen2" @close="closeModal2">
      <template v-slot:content>
        <p>
          <strong>TERMOS DE USO E CONDIÇÕES DE NAVEGAÇÃO</strong>
        </p>
        <br />
        <p>
          <strong>PORTAL AUTOAVALIAR</strong>
        </p>
        <p>
          Bem-vindo ao&nbsp;<strong>PORTAL AUTOAVALIAR</strong>. A seguir
          apresentamos a você (<strong>USUÁRIO</strong>) os Termos de Uso e
          Condições de Navegação, documento que relaciona as principais regras
          que devem ser observadas e seguidas por todos que acessam
          o&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;ou utilizam suas
          funcionalidades.
        </p>
        <p>
          Como condição para acesso e uso das funcionalidades exclusivas
          do&nbsp;<strong>PORTAL AUTOAVALIAR</strong>, sobretudo a contratação
          de serviços oferecidos pela plataforma, é necessário criar uma conta
          de acesso, declarando o&nbsp;<strong>USUÁRIO</strong>&nbsp;que fez a
          leitura completa e atenta das regras deste documento e de sua Política
          de Privacidade, estando plenamente ciente e de acordo com elas.
        </p>
        <br />
        <p>1.&nbsp;&nbsp;<strong>Informações Gerais sobre o Portal</strong></p>
        <p style="margin-left: 35.4pt">
          1.1. Este Portal é dedicado à otimização dos negócios entre
          concessionário, revendedores e interessados, estes últimos a depender
          de critérios pré-estabelecidos com a AUTOAVALIAR, facilitando a
          realização de negócios entre tais empresas.
        </p>
        <p style="margin-left: 70.8pt">
          1.1.1. As&nbsp;<strong>Contas de Acesso</strong>&nbsp;no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;somente podem ser criadas por pessoas jurídicas e somente
          poderão ser operadas por pessoas físicas maiores de 18 anos.
        </p>
        <p style="margin-left: 70.8pt">
          1.1.2. Para a criação de&nbsp;<strong>Contas de Acesso&nbsp;</strong>a
          usuários que desejarem anunciar/vender os veículos, será necessário a
          formalização de contrato específico com a AUTOAVALIAR com esta
          finalidade, manifestando seu interesse em tais atividades por contato
          via ‘Fale Conosco’ ou telefônico no número indicado no Portal.
        </p>
        <p style="margin-left: 70.8pt">
          1.1.3 A <strong>AUTOAVALIAR</strong>, a qualquer momento, poderá
          determinar, de acordo com os seus critérios de utilização da
          plataforma, cobrança mensal para compra e consulta, com pagamento de
          taxa de acesso, no valor de R$599,00 (quinhentos e noventa e nove
          reais), que deverá ser paga através de boleto emitido pela
          <strong>AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 70.8pt">
          1.1.4. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;poderá alterar os
          critérios de elegibilidade de sua plataforma ou da realização de
          atividades nela a qualquer momento. Também, possui a faculdade de se
          recusar a conceder conta de acesso a qualquer pessoa ou entidade que
          utilizar de forma fraudulenta ou de qualquer forma violar os Termos de
          Uso ou documento legal do&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          1.2. Para acessar o&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;de
          forma segura é de inteira responsabilidade
          dos&nbsp;<strong>USUÁRIOS</strong>&nbsp;dispor de serviço de conexão à
          internet, com antivírus e&nbsp;<em>firewall</em>&nbsp;habilitados.
        </p>
        <p style="margin-left: 35.4pt">
          1.3. O&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;e suas
          funcionalidades são apresentadas
          aos&nbsp;<strong>USUÁRIOS</strong>&nbsp;na maneira como estão
          disponíveis, podendo passar por constantes aprimoramentos e
          atualizações, obrigando-se a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;a:
        </p>
        <p style="margin-left: 70.8pt">
          1.3.1. Preservar a funcionalidade do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, com&nbsp;<em>links</em>&nbsp;não quebrados,
          utilizando&nbsp;<em>layout</em>&nbsp;que respeita a usabilidade e
          navegabilidade, sempre que possível.
        </p>
        <p style="margin-left: 70.8pt">
          1.3.2. Exibir as funcionalidades de maneira clara, completa, precisa e
          suficiente de modo que exista a exata percepção das operações
          realizadas.
        </p>
        <p style="margin-left: 70.8pt">
          1.3.3. Garantir, por meio do estado da técnica disponível, o sigilo
          dos dados inseridos nas funcionalidades oferecidas no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, somente sendo acessíveis pela&nbsp;<strong>AUTOAVALIAR</strong>
          &nbsp;e por quem o&nbsp;<strong>USUÁRIO</strong>&nbsp;consentir, além
          de si próprio.
        </p>
        <p style="margin-left: 70.8pt">
          1.3.4. Obter as licenças e autorizações necessárias para a
          disponibilização de conteúdos por meio do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >.
        </p>
        <p style="margin-left: 35.4pt">
          1.4. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;envida seus esforços
          para a disponibilidade contínua e permanente do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade
          temporária decorrente de manutenção necessária ou mesmo gerada por
          motivo de força maior, como desastres naturais, falhas ou colapsos nos
          sistemas centrais de comunicação e acesso à internet ou fatos de
          terceiro que fogem de sua esfera de vigilância e responsabilidade.
        </p>
        <p style="margin-left: 70.8pt">
          1.4.1. Se isso ocorrer, a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;fará
          o que estiver ao seu alcance para restabelecer o acesso
          ao&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;o mais breve
          possível, dentro das limitações técnicas de seus serviços e serviços
          de terceiros, dos quais
          a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;depende para ficar online.
        </p>
        <p style="margin-left: 35.4pt">
          1.5. Eventuais procedimentos de manutenção serão informados por meio
          dos canais oficiais de comunicação do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;(a exemplo, mas não se limitando, perfis oficiais nas mídias
          sociais, telefone de atendimento ou e-mail), caso seja necessário que
          este fique indisponível por longos períodos.
        </p>
        <p>&nbsp;</p>
        <p>
          2.&nbsp;&nbsp;<strong>Funcionalidades do Portal AUTOAVALIAR</strong>
        </p>
        <p style="margin-left: 35.4pt">
          2.1. O&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;é aplicação de
          internet com objetivos de facilitar a compra e venda de automóveis
          novos e seminovos por meio de avaliações realizadas nos automóveis
          anunciados por concessionárias ou revendedoras.
        </p>
        <p style="margin-left: 35.4pt">
          2.2. Avaliações são lances que atendem ao valor máximo e mínimo para
          aquisição dos automóveis anunciados.
        </p>
        <p style="margin-left: 70.8pt">
          2.2.1. Caso ocorra alguma avaliação nos três minutos que antecedem o
          término do prazo estipulado pelo&nbsp;<strong>USUÁRIO</strong>, haverá
          uma prorrogação de mais três minutos no prazo do anúncio. Na hipótese
          de o&nbsp;
          <strong>USUÁRIO</strong>&nbsp;avaliar o carro no valor máximo
          estipulado, este poderá finalizar a compra independente do prazo
          restante para seu término.
        </p>
        <p style="margin-left: 35.4pt">
          2.3. Os&nbsp;<strong>USUÁRIOS&nbsp;</strong>poderão adicionar anúncios
          aos “Meus Favoritos”, para acompanhá-los com maior proximidade, ainda
          que não tenham sido avaliados por ele.
        </p>
        <p style="margin-left: 35.4pt">
          2.4. Cada&nbsp;<strong>USUÁRIO&nbsp;</strong>adota as próprias regras
          de negócio e a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não interfere em
          nenhum deles.
        </p>
        <p style="margin-left: 35.4pt">
          2.5. Entretanto, pelo acesso a anúncios mais vantajosos, poderá haver
          pagamento de tarifa
          pelo&nbsp;<strong>USUÁRIO</strong>&nbsp;à&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;sempre
          que algum negócio for concretizado, cujo valor estará descrito no
          próprio anúncio de avaliação.
        </p>
        <p style="margin-left: 70.8pt">
          2.5.1. Quando o&nbsp;<strong>USUÁRIO&nbsp;</strong>não realizar os
          pagamentos que deveria de modo pontual, a&nbsp;<strong
            >Conta de Acesso&nbsp;</strong
          >poderá ser suspensa até a devida regularização dos pagamentos, have
          incidência de multa moratória no montante de 2% (dois por cento) sobre
          o valor não pago, acrescidos de juros de 1% (um por cento) ao mês até
          a data do efetivo pagamento
        </p>
        <p style="margin-left: 70.8pt">
          2.5.2. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;se reserva o direito
          de cobrar os valores devidos
          dos&nbsp;<strong>USUÁRIOS</strong>&nbsp;inadimplentes nos termos da
          lei civil, podendo para tanto valer-se de todos os meios previstos na
          legislação, incluindo a inserção dos dados do usuário em cadastros de
          restrição ao crédito e promovendo ações judiciais.
        </p>
        <p style="margin-left: 70.8pt">
          <a>2.5.3 BLOQUEIO</a>
        </p>
        <p style="margin-left: 35.4pt">
          2.6. Todos os veículos seminovos são anunciados “<strong
            >no estado em que se encontram</strong
          >”, conforme a descrição de cada anúncio e sem qualquer necessidade de
          garantia do fabricante, de funcionamento, reposição de peças, entre
          outros. Os veículos novos serão vendidos de acordo com as diretrizes
          de cada um de seus fabricantes e sem qualquer responsabilidade
          da&nbsp;<strong>AUTOAVALAIR&nbsp;</strong>neste sentido.
        </p>
        <p style="margin-left: 70.8pt">
          2.6.1 O anunciante deverá inserir os anúncios de veículos
          no&nbsp;<strong>PORTAL</strong>&nbsp;contendo imagens nítidas,
          informação completa, correta, suficiente, adequada e precisa em
          relação à marca, modelo, ano, estado, pendências financeiras e demais
          características do automóvel anunciado.
        </p>
        <p style="margin-left: 70.8pt">
          2.6.2 A AUTO AVALIAR poderá, respeitando o prazo de 30 dias, contados
          a partir da retirada do veículo, intermediar possíveis divergências
          entre USUÁRIOS através de seus canais oficiais pelos departamentos
          responsáveis.
        </p>
        <p style="margin-left: 70.8pt">
          <a>2.6.3 Responsabilidade do COMPRADOR</a>
        </p>
        <p style="margin-left: 35.4pt">
          2.7. As informações indicativas de cada anúncio são fornecidas
          exclusivamente pelos anunciantes e sob a sua total responsabilidade.
          A&nbsp;<strong>AUTOAVALIAR NÃO</strong>&nbsp;se responsabiliza por
          informações equivocadas publicadas por
          seus&nbsp;<strong>USUÁRIOS</strong>&nbsp;ou por eventuais frustrações
          de expectativas pelos automóveis adquiridos. Por isso,
          <a
            >o&nbsp;<strong>USUÁRIO</strong>&nbsp;interessado na compra deve
            adotar as devidas cautelas para constatar a procedência e estado do
            veículo, ficando a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;indene de
            qualquer imprecisão, imperfeição ou ocultação neste sentido.</a
          >
        </p>
        <p style="margin-left: 35.4pt">
          2.8 O Usuário deverá informar a AUTOAVALIAR, a localização do veículo,
          Laudo Técnico, Blindagem, Motorização, Câmbio, Estado Geral, entre
          outros.
        </p>
        <p style="margin-left: 35.4pt">
          2.8. Todos os&nbsp;<strong>USUÁRIOS</strong>&nbsp;têm ciência de que
          os veículos seminovos podem não estar aptos para utilização como meio
          de transporte e a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não se
          responsabiliza por quaisquer defeitos mecânicos, elétricos, falta de
          componentes obrigatórios, débitos que recaírem sobre os veículos
          ofertados, entre outros.
        </p>
        <p style="margin-left: 35.4pt">&nbsp;</p>
        <p>
          3.&nbsp;&nbsp;<strong
            >Das responsabilidades e obrigações dos USUÁRIOS</strong
          >
        </p>
        <p style="margin-left: 35.4pt">
          3.1. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;se obrigam a realizar
          navegação ética no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>, adequada
          com os propósitos aqui estabelecidos, sempre respeitando as condições
          que regem a utilização do&nbsp;<strong>PORTAL AUTOAVALIAR</strong
          >&nbsp;e sua finalidade, sob pena de suportarem os ônus dos atos
          praticados com a possibilidade de Multas e até a exclusão da conta de
          acesso do&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          3.2. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;se comprometem a fornecer
          dados cadastrais corretos, completos e atualizados para que
          a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;possa prestar seus serviços
          em conformidade com as leis e normas regulamentares em vigor, além de
          fornecer canal de contato apto a ser acionado
          pela&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;para o melhor cumprimento
          dos serviços, sob pena da aplicação das penas da lei, de indenizar a
          quem causar dano e de ter a conta de acesso do
          presente&nbsp;<strong>Portal</strong>&nbsp;excluída.
        </p>
        <p style="margin-left: 35.4pt">
          3.3. Os&nbsp;<strong>USUÁRIOS&nbsp;</strong>que anunciarem veículos
          novos e seminovos no&nbsp;<strong>Portal AUTOAVALIAR</strong>&nbsp;se
          responsabilizam para todos os fins previstos na legislação, incluindo,
          mas não se limitando, a eventuais vícios, aparentes ou ocultos nos
          veículos anunciados.
        </p>
        <p style="margin-left: 35.4pt">
          3.4. É de responsabilidade
          dos&nbsp;<strong>USUÁRIOS&nbsp;</strong>deixar seus sistemas
          de&nbsp;anti-spam, filtros similares ou configurações de
          redirecionamento de mensagens ajustados de modo que não interfiram no
          recebimento dos comunicados e materiais
          da&nbsp;<strong>AUTOAVALIAR</strong>, não sendo aceitável nenhuma
          escusa caso não tenha tido acesso a algum e-mail ou mensagem
          eletrônica em virtude dos recursos mencionados.
        </p>
        <p style="margin-left: 35.4pt">
          3.5. Ao acessar o&nbsp;<strong>PORTAL AUTOAVALIAR</strong>,
          os&nbsp;<strong>USUÁRIOS</strong>&nbsp;declaram que irão respeitar
          todos os direitos de propriedade intelectual de titularidade
          da&nbsp;<strong>AUTOAVALIAR</strong>, tal qual todos os direitos
          referentes a terceiros que porventura estejam, ou estiveram, de alguma
          forma, disponíveis no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          3.6. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;devem manter o sigilo dos
          dados de sua conta de acesso em relação a terceiros, não divulgando ou
          compartilhando sua senha a quem quer que seja, sob pena de suportarem
          os ônus dos atos praticados sob sua credencial, terem a exclusão da
          conta e de ressarcirem os danos que sobrevierem dessa divulgação
          indevida.
        </p>
        <p style="margin-left: 35.4pt">
          3.7. Caso o&nbsp;<strong>USUÁRIO</strong>&nbsp;suspeite que a
          confidencialidade de sua senha foi quebrada, este deverá proceder sua
          troca o mais rápido possível.
        </p>
        <p style="margin-left: 35.4pt">
          3.8. Não é permitido o acesso as áreas de programação do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, seu banco de dados ou qualquer outro conjunto de informações que
          faça parte da atividade de&nbsp;<em>Webmastering</em>.
        </p>
        <p style="margin-left: 35.4pt">
          3.9. Também não é autorizado
          aos&nbsp;<strong>USUÁRIOS</strong>&nbsp;realizar ou permitir
          engenharia reversa, nem traduzir, decompilar, copiar, modificar,
          reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
          emprestar, distribuir ou, de outra maneira, dispor das ferramentas de
          consulta deste Portal e de suas funcionalidades.
        </p>
        <p style="margin-left: 35.4pt">
          3.10. No&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;é proibida a
          utilização, de aplicativos&nbsp;<em>spider</em>, ou de mineração de
          dados, de qualquer tipo ou espécie, além de outro aqui não tipificado,
          mas que atue de modo automatizado, tanto para realizar operações
          massificadas ou para quaisquer outras finalidades.
        </p>
        <p style="margin-left: 35.4pt">
          3.11. O descumprimento de quaisquer das obrigações aqui estipuladas
          poderá acarretar na suspensão das funcionalidades, sem prejuízo da
          responsabilização por eventuais prejuízos causados, conforme previsto
          neste documento.
        </p>
        <p style="margin-left: 35.4pt">
          3.12. Visando à boa convivência dentro do ambiente, sempre que
          um&nbsp;<strong>USUÁRIO</strong>&nbsp;puder publicar anúncios, enviar
          comentários ou manifestações de pensamento a qualquer funcionalidade
          do&nbsp;<strong>Portal AUTOAVALIAR</strong>, tais conteúdos poderão
          não ser publicados ou excluídos, a qualquer tempo, sem prévio aviso e
          nenhum direito a indenização a quem quer que seja, que contiverem:
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ofensa à honra, imagem, reputação e
          dignidade de terceiros;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pornografia, pornografia infantil ou
          outras modalidades de satisfação sexual;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Racismo ou discriminação de qualquer
          natureza;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Qualquer espécie de constrangimento
          ilegal, assédio ou aliciamento;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Manifesta violação a direito autoral
          ou direito de imagem;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Utilização de marcas, símbolos,
          logotipos ou emblemas de terceiros;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Instigação ou apologia à prática de
          crimes, como tráfico ou uso de entorpecentes, estupro, homicídio,
          estelionato, dentre outros;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Manifesta prática de ato contrário à
          lei, à ordem pública ou aos bons costumes;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Uso da violência, disseminação de ódio
          ou qualquer outra forma de agressão ao bem-estar físico de alguém;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Publicidade de qualquer tipo,
          especialmente às que concorrerem com a atividade exercida
          pelo&nbsp;<strong>Portal AUTOAVALIAR</strong>;
        </p>
        <p style="margin-left: 53.4pt">
          ●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Erros ou suspeita de equívocos.
        </p>
        <p style="margin-left: 35.4pt">
          3.13. A eventual remoção, bloqueio ou suspensão de qualquer conteúdo
          ou funcionalidade do&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;em
          decorrência de alguma reclamação, deverá ser sempre compreendida como
          demonstração de boa-fé e intenção de solução amigável de conflitos,
          jamais, como reconhecimento de culpa ou de qualquer infração
          pela&nbsp;<strong>AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          <a
            >3.14. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não envia e-mails ou
            outros tipos de comunicação com links externos. Desta forma, caso
            o&nbsp;<strong>USUÁRIO</strong>&nbsp;receba alguma mensagem desse
            tipo, deve estar ciente dos riscos ao clicar no link, pois pode ser
            tentativa de fraude conhecida como&nbsp;<em>phishing</em>.</a
          >
        </p>
        <p style="margin-left: 35.4pt">
          3.15. Na incidência de danos ao&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;ou a terceiros, o responsável se compromete a arcar com todas
          as obrigações de indenizar o sujeito lesado.
        </p>
        <p style="margin-left: 35.4pt">
          3.16 Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;concordam em indenizar,
          defender e manter a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;isenta de
          todos e quaisquer danos, perdas, responsabilidades, custos ou
          despesas, sendo justificado que a responsabilidade pela imagem,
          produto e negociação entre vendedor e comprador, tanto quanto suas
          possíveis consequências, prejuízos e condenações serão apenas
          aplicados aos envolvidos, e a&nbsp;<strong>AUTOAVALIAR</strong>
          &nbsp;não havendo qualquer responsabilidade
          da&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;neste sentido, devendo ser
          isenta de qualquer ação judicial concernente, e, em casos de que seja
          acionada indevidamente, as partes deverão arcar com as custas
          processuais atinentes
        </p>
        <p>&nbsp;</p>
        <p>
          4.&nbsp;&nbsp;&nbsp;&nbsp;<strong
            >Da Isenção e Limitações de Responsabilidade do AUTOAVALIAR</strong
          >
        </p>
        <p style="margin-left: 35.4pt">
          4.1. FICA EXPRESSAMENTE ESTABELECIDO QUE
          A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;NÃO TEM A OBRIGAÇÃO DE
          CONTROLAR TODAS AS AÇÕES EXECUTADAS
          PELOS&nbsp;<strong>USUÁRIOS</strong>&nbsp;NO USO DA PLATAFORMA E, POR
          CONSEGUINTE, A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;NÃO PODERÁ SER
          RESPONSABILIZADA POR QUAISQUER ATOS DE
          SEUS&nbsp;<strong>USUÁRIOS</strong>, INCLUSIVE DE CARÁTER ILEGAL,
          IMORAL OU ANTIÉTICO, PORVENTURA PERPETRADOS, CABENDO À ESTES
          RESPONDEREM PESSOAL E SOLIDARIAMENTE POR EVENTUAIS RECLAMAÇÕES DE
          TERCEIROS OU DEMANDAS JUDICIAIS, DEVENDO MANTER
          A&nbsp;<strong>AUTOAVALIAR&nbsp;</strong>LIVRE DE QUALQUER
          RESPONSABILIDADE OU ÔNUS NESTE SENTIDO.
        </p>
        <p style="margin-left: 70.8pt">
          4.1.1. Igualmente, a&nbsp;<strong>AUTOAVALIAR&nbsp;</strong>não assume
          nenhuma responsabilidade ou ônus que estejam vinculados às atividades
          dos&nbsp;<strong>USUÁRIOS&nbsp;</strong>no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, sobretudo as que envolvam a compra e venda de veículos novos e
          seminovos, entre elas, devendo estes cumprirem uns em relação aos
          outros as obrigações de ordem cível, fiscal, tributária, dentre
          outras.
        </p>
        <p style="margin-left: 35.4pt">
          4.2. As informações disponibilizadas no&nbsp;<strong
            >PORTAL AUTOAVALIAR&nbsp;</strong
          >não podem ser interpretadas como consultoria, não sendo
          a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;responsável de qualquer forma
          pelas decisões financeiras ou de aquisição de determinados produtos
          tomadas pelos&nbsp;<strong>USUÁRIOS</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          4.3. O&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não se responsabiliza
          por qualquer dano direto ou indireto ocasionado por eventos de
          terceiros, a exemplo, mas não se limitando a ataque de hackers, falhas
          no sistema, no servidor ou na conexão à internet, inclusive por ações
          de softwares maliciosos como vírus, cavalos de Tróia, e outros que
          possam, de algum modo, danificar o equipamento ou a conexão
          dos&nbsp;<strong>USUÁRIOS</strong>&nbsp;em decorrência do acesso,
          utilização ou navegação no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>,
          bem como a transferência de dados, arquivos, imagens, textos, áudios
          ou vídeos contidos neste.
        </p>
        <p style="margin-left: 35.4pt">
          4.4. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;não possuem qualquer
          direito para exigir a disponibilidade do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;conforme melhor lhes convêm, tampouco poderão pleitear
          indenização ou reparação de danos em caso do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;permanecer fora do ar, independente da motivação.
        </p>
        <p style="margin-left: 35.4pt">
          4.5. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não detém qualquer
          responsabilidade pela navegação
          dos&nbsp;<strong>USUÁRIOS</strong>&nbsp;nos links externos contidos
          no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>, sendo seus deveres a
          leitura dos Termos de Uso e Política de Privacidade do Portal acessado
          e agir conforme o determinado.
        </p>
        <p style="margin-left: 70.8pt">
          4.5.1. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;não verifica,
          controla, aprova ou garante a adequação ou exatidão das informações ou
          dados disponibilizados em tais links, não sendo, portanto,
          responsáveis por prejuízos, perdas ou danos ocorridos pela visita de
          tais sites, cabendo ao interessado verificar a confiabilidade das
          informações e dados ali exibidos antes de tomar alguma decisão ou
          praticar algum ato.
        </p>
        <p>&nbsp;</p>
        <p>
          5.&nbsp;&nbsp;<strong
            >Direitos Autorais e Propriedade Intelectual do Portal</strong
          >
        </p>
        <p style="margin-left: 35.4pt">
          5.1. O uso comercial da expressão “<strong>AUTOAVALIAR</strong>”,
          “<strong>MEGADEALER</strong>” ou de palavras que se assemelham a
          estas, porém com grafia diferenciada, como marcas, nomes empresariais
          ou nomes de domínio, além dos conteúdos das telas do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, assim como os programas de computador, bancos de dados, redes e
          seus arquivos, são de propriedade
          da&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;e estão resguardados pelas
          leis e tratados internacionais de proteção à propriedade intelectual,
          incluindo, mas não se limitando, às Leis Federais 9.609/1998,
          9.610/1998, 9.279/1996 e 10.406/2002.
        </p>
        <p style="margin-left: 35.4pt">
          5.2. Ao acessar o&nbsp;<strong>PORTAL AUTOAVALIAR,</strong
          >&nbsp;os&nbsp;<strong>USUÁRIOS&nbsp;</strong>declaram que irão
          respeitar todos os direitos de propriedade intelectual
          da&nbsp;<strong>AUTOAVALIAR</strong>, incluindo, mas não se limitando,
          aos programas de computador, direitos autorais e direitos de
          propriedade industrial sobre as marcas, patentes, nomes de domínio,
          denominação social e desenhos industriais, depositados ou registrados
          em nome das empresas&nbsp;<strong>AUTOAVALIAR</strong>, bem como de
          todos os direitos referentes a terceiros que porventura estejam, ou
          estiveram, de alguma forma, disponíveis no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >. O acesso ou uso das funcionalidades do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;não confere aos&nbsp;<strong>USUÁRIOS</strong>&nbsp;quaisquer
          direitos ao uso dos nomes, títulos, palavras, frases, marcas,
          patentes, nomes de domínio, denominação social, obras literárias,
          artísticas, lítero-musicais, imagens, ilustrações, dados e
          informações, dentre outras, que nele estejam ou estiveram disponíveis.
        </p>
        <p style="margin-left: 35.4pt">
          5.3. A reprodução dos conteúdos descritos anteriormente está proibida,
          salvo com prévia autorização por escrito da empresa titular de seus
          direitos patrimoniais ou caso se destinem ao uso exclusivamente
          pessoal e sem que em nenhuma circunstância
          os&nbsp;<strong>USUÁRIOS</strong>&nbsp;adquiram qualquer direito sobre
          os mesmos.
        </p>
        <p style="margin-left: 35.4pt">
          5.4. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;assumem toda e qualquer
          responsabilidade, de caráter civil e/ou criminal, pela utilização
          indevida das informações, textos, gráficos, marcas, patentes, nomes de
          domínio, obras, imagens, logotipos, enfim, de todo e qualquer direito
          de propriedade intelectual ou industrial existentes no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >.
        </p>
        <p style="margin-left: 35.4pt">
          5.5. Qualquer outro tipo de utilização de material autorizado,
          inclusive para fins editoriais, comerciais ou publicitários, só poderá
          ser feito na forma e mediante prévio e expresso consentimento da
          empresa titular de seus direitos patrimoniais. O uso comercial não
          autorizado poderá incorrer em infrações cíveis e criminais.
        </p>
        <p style="margin-left: 35.4pt">
          5.6. Qualquer reutilização de material autorizado deverá ser objeto de
          nova autorização da empresa titular de seus direitos patrimoniais.
        </p>
        <p style="margin-left: 35.4pt">
          5.7. A autorização conferida para utilização do material solicitado
          não poderá ser transferida a terceiros, mesmo que vinculados ao
          sujeito autorizado.
        </p>
        <p style="margin-left: 35.4pt">
          5.8. A utilização das funcionalidades oferecidas se dará na forma de
          prestação de serviços, não conferindo
          aos&nbsp;<strong>USUÁRIOS</strong>&nbsp;nenhum direito sobre o
          programa de computador e/ou do banco de dados utilizados
          pela&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;ou de suas estruturas
          informáticas que sustentam as aplicações do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >.
        </p>
        <p style="margin-left: 35.4pt">
          5.9. Quando os&nbsp;<strong>USUÁRIOS</strong>&nbsp;enviam
          ao&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;quaisquer conteúdos,
          tais como, mas não se limitando a textos, comentários, vídeos,
          imagens, áudio e mensagens, declaram que detém a titularidade dos
          direitos autorais sobre estes ou autorização para sua utilização e
          concedem à&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;licença irrevogável,
          perpétua, mundial, irrestrita, isenta de royalties e não exclusiva de
          reprodução, adaptação, modificação, tradução, publicação, distribuição
          ou exibição no próprio site ou em ambientes digitais que lhe pertençam
          e estejam vinculados às atividades do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >, possibilitando a melhor utilização da ferramenta ou ilustração de
          algum produto, serviço ou atividade por ele oferecidos.
        </p>
        <p>&nbsp;</p>
        <p>6.&nbsp;&nbsp;<strong>Privacidade dos usuários no Portal</strong></p>
        <p style="margin-left: 35.4pt">
          6.1. A&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;possui documento
          próprio, denominado Política de Privacidade, que regula o tratamento
          dado às informações coletadas no&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >.
        </p>
        <p style="margin-left: 35.4pt">
          6.2. A Política de Privacidade é parte integrante e inseparável dos
          Termos de Uso e Condições de Navegação do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;e pode ser acessada no link encontrado em seu rodapé.
        </p>
        <p style="margin-left: 35.4pt">
          6.3. Caso alguma disposição da Política de Privacidade conflite com
          qualquer outra do presente documento, deverá prevalecer o descrito na
          norma mais específica.
        </p>
        <p>&nbsp;</p>
        <p>7.&nbsp;&nbsp;<strong>Disposições Gerais</strong></p>
        <p style="margin-left: 35.4pt">
          7.1. O atendimento
          dos&nbsp;<strong>USUÁRIOS</strong>&nbsp;do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;poderá ser realizado em seu Portal pelo link de “Fale Conosco”
          ou por meio dos outros canais de comunicação disponibilizados e
          indicados no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          7.2. Os&nbsp;<strong>USUÁRIOS</strong>&nbsp;poderão se valer dos
          canais de “Fale Conosco” toda vez que presenciarem ou verificarem
          conteúdo impróprio no&nbsp;<strong>PORTAL AUTOAVALIAR</strong>, seja
          ele notadamente ilícito ou contrário às regras de uso aqui
          estipuladas.
        </p>
        <p style="margin-left: 70.8pt">
          7.2.1. A denúncia será sigilosa e preservará a identidade
          dos&nbsp;<strong>USUÁRIOS</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          7.3. Os presentes Termos de Uso e Condições de Navegação estão
          sujeitos a constante melhoria e aprimoramento. Assim,
          a&nbsp;<strong>AUTOAVALIAR</strong>&nbsp;se reserva o direito de
          modificá-los a qualquer momento, conforme a finalidade do&nbsp;<strong
            >PORTAL AUTOAVALIAR</strong
          >&nbsp;ou conveniência de sua controladora, tal qual para adequação e
          conformidade legal de disposição de lei ou norma que tenha força
          jurídica equivalente, cabendo
          aos&nbsp;<strong>USUÁRIOS</strong>&nbsp;verificarem-nos sempre que
          efetuar o acesso ao&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 35.4pt">
          7.4. Ao navegar pelo&nbsp;<strong>PORTAL AUTOAVALIAR</strong>&nbsp;e
          utilizar suas funcionalidades,
          os&nbsp;<strong>USUÁRIOS</strong>&nbsp;aceitam e obrigam-se pelos
          Termos de Uso e Condições de Navegação e pela Política de Privacidade
          que se encontram vigentes na data de acesso. Por isso, é necessário e
          imprescindível que os&nbsp;<strong>USUÁRIOS</strong>&nbsp;se mantenham
          atualizados.
        </p>
        <p style="margin-left: 35.4pt">
          7.5. A tolerância do eventual descumprimento de quaisquer das
          cláusulas e condições do presente instrumento não constituirá novação
          das obrigações aqui estipuladas e tampouco impedirá ou inibirá a
          exigibilidade das mesmas a qualquer tempo.
        </p>
        <p style="margin-left: 35.4pt">
          7.6. Caso alguma disposição destes Termos de Uso ou da Política de
          Privacidade publicadas no&nbsp;<strong>PORTAL AUTOAVALIAR</strong
          >&nbsp;for julgada inaplicável ou sem efeito, o restante de ambos os
          documentos continuam a viger, sem a necessidade de medida judicial que
          declare tal assertiva.
        </p>
        <p style="margin-left: 35.4pt">
          7.7. Este Portal tem como base a data e horários oficiais de Brasília.
        </p>
        <p>&nbsp;</p>
        <p>8.&nbsp;&nbsp;<strong>Lei aplicável e Jurisdição</strong></p>
        <p style="margin-left: 35.4pt">
          8.1. Os Termos e Condições de Uso aqui descritos são interpretados
          segundo a legislação brasileira, no idioma português, sendo eleito o
          Foro da Comarca de São Paulo para dirimir qualquer litígio ou
          controvérsia envolvendo o presente documento, salvo ressalva
          específica de competência pessoal, territorial ou funcional pela
          legislação aplicável.
        </p>
        <p>&nbsp;</p>
        <p>9.&nbsp;&nbsp;<strong>Glossário</strong></p>
        <p style="margin-left: 35.4pt">
          9.1. Para os fins deste documento, devem se considerar as seguintes
          definições e descrições para seu melhor entendimento:
        </p>
        <p style="margin-left: 70.8pt">
          1.&nbsp;&nbsp;&nbsp;&nbsp;<strong><em>Anti-Spam</em></strong
          >: Sistema que evita correspondências não desejadas, como publicidade
          em massa, pelo bloqueio de mensagens ou as movendo para pasta
          específica.
        </p>
        <p style="margin-left: 70.8pt">
          2.&nbsp;&nbsp;&nbsp;&nbsp;<strong
            ><em>Aplicações de Internet</em></strong
          >: Conjunto de funcionalidades que podem ser acessadas por meio de um
          terminal conectado à internet.
        </p>
        <p style="margin-left: 70.8pt">
          3.&nbsp;&nbsp;&nbsp;&nbsp;<strong>AUTOAVALIAR</strong>: Denominação
          utilizada nesta Política de Privacidade para identificar a detentora e
          proprietária deste&nbsp;<strong>PORTAL AUTOAVALIAR</strong
          >,&nbsp;<strong
            >AUTOAVALIAR TECNOLOGIA, PUBLICIDADE E INTERMEDIAÇÃO DE NEGÓCIOS E
            SERVIÇOS LTDA.</strong
          >, inscrita no CNPJ/MF sob o nº 22.279.800/0001-26, com sede na Rua
          Pardais, nº 60, Jardim Itália, Vinhedo/SP, CEP: 13289-326.
        </p>
        <p style="margin-left: 70.8pt">
          4.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Conta de Acesso</strong>: Credencial
          de um visitante cadastrado que permite o acesso à área restrita e às
          funcionalidades exclusivas do&nbsp;<strong>PORTAL AUTOAVALIAR</strong
          >, que é definida pelo nome de usuário e senha.
        </p>
        <p style="margin-left: 70.8pt">
          5.&nbsp;&nbsp;&nbsp;&nbsp;<strong><em>Crawler/Spider</em></strong
          >: Programas desenvolvidos para atuar ou obter informações de modo
          automatizado em&nbsp;<em>Sites</em>.
        </p>
        <p style="margin-left: 70.8pt">
          6.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Dados Cadastrais</strong>: Conjunto
          de informações pessoais de um&nbsp;<strong>USUÁRIO</strong>&nbsp;de
          modo que seja possível identificá-lo, como, por exemplo, para pessoas
          físicas o número do documento de identidade (RG ou RNE), cadastro de
          pessoa física (CPF), endereço residencial ou comercial e nome completo
          e, para pessoas jurídicas, o número de inscrição de Pessoa Jurídica na
          Receita Federal do Brasil (CNPJ), Razão Social, endereço de sede,
          filiais, dentre outros.
        </p>
        <p style="margin-left: 70.8pt">
          7.&nbsp;&nbsp;&nbsp;&nbsp;<strong><em>Layout</em></strong
          >: Conjunto compreendido entre aparência, design e fluxos
          do&nbsp;<strong>PORTAL AUTOAVALIAR</strong>.
        </p>
        <p style="margin-left: 70.8pt">
          8.&nbsp;&nbsp;&nbsp;&nbsp;<strong><em>Link</em></strong
          >: Terminologia para endereço de internet.
        </p>
        <p style="margin-left: 70.8pt">
          9.&nbsp;&nbsp;&nbsp;&nbsp;<strong>PORTAL AUTOAVALIAR</strong>:
          Conjunto de páginas e funcionalidades contidas pelos domínios&nbsp;<a
            href="https://b2b.autoavaliar.com.br/"
            >https://b2b.autoavaliar.com.br/</a
          >&nbsp;e subdomínio&nbsp;
          <a href="https://b2b.autoavaliar.com.br/adm"
            >https://b2b.autoavaliar.com.br/adm</a
          >&nbsp;.
        </p>
        <p style="margin-left: 70.8pt">
          10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong
            ><em>Site</em></strong
          >: Denominação para página de internet.
        </p>
        <p style="margin-left: 70.8pt">
          11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Usuário</strong>:
          Visitante que possua conta de acesso no Portal.
        </p>
        <p style="margin-left: 70.8pt">
          12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong
            ><em>Spider</em>**</strong
          >:**Programa desenvolvido para obter informações de modo automatizado
          na internet, navegando pela&nbsp;<em>web</em>&nbsp;(teia) como se
          fosse uma&nbsp;<em>spider</em>&nbsp;(aranha).
        </p>
        <p style="margin-left: 70.8pt">
          13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong
            ><em>Webmastering</em></strong
          >: Compreende a criação, programação, desenvolvimento, controle e
          disponibilidade de páginas de internet.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Política de Cancelamento</strong>
        </p>
        <p>
          <strong>Solicitações/Motivos possíveis, do lado COMPRADOR:</strong>
        </p>
        <p style="margin-left: 36pt">
          (1)&nbsp; Divergência entre ANÚNCIO x ESTADO FÍSICO do veículo - o
          COMPRADOR, poderá, dentro do prazo máximo de 30 (trinta) dias
          corridos, solicitar o cancelamento da compra realizada pelo motivo
          citado, desde que não tenha realizada a retirada do veículo. Ou seja,
          antes de efetuar a retirada do veículo, ele precisa garantir que todos
          os itens foram verificados, e havendo divergência, o veículo não
          deverá ser retirado e a solicitação de cancelamento deverá ser
          realizada através do link de Cancelamento de Compras que se encontra
          na seção Minhas Avaliações na área logada da Plataforma Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (2)&nbsp; Problema na DOCUMENTAÇÃO do veículo - dentro do prazo máximo
          de 30 (trinta) dias corridos, o COMPRADOR poderá solicitar o
          Cancelamento de um compra realizada que esteja com o motivo citado,
          desde que não haja observações no anúncio explicando o problema e
          prazo de solução. A solicitação de cancelamento deverá ser realizada
          através do link de Cancelamento de Compras que se encontra na seção
          Minhas Avaliações na área logada da Plataforma Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (3)&nbsp; ATRASO NA ENTREGA do veículo - dentro do prazo máximo de 30
          (trinta) dias corridos, o COMPRADOR poderá solicitar o Cancelamento de
          um compra realizada que esteja com o motivo citado, desde que não haja
          observações no anúncio explicando o problema e prazo de solução. A
          solicitação de cancelamento deverá ser realizada através do link de
          Cancelamento de Compras que se encontra na seção Minhas Avaliações na
          área logada da Plataforma Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (4)&nbsp; FALTA DE RETORNO do ANUNCIANTE após a confirmação da compra
          - quando não houver retorno do ANUNCIANTE, o COMPRADOR poderá, em até
          3 (três) dias corridos da Data da Compra, abrir a solicitação de
          cancelamento através do link de Cancelamento de Compras que se
          encontra na seção Minhas Avaliações na área logada da Plataforma Auto
          Avaliar, para que a equipe de atendimento, antes da efetivação do
          cancelamento, possa atuar de maneira a buscar contato com o ANUNCIANTE
          para viabilização do negócio.
        </p>
        <p style="margin-left: 36pt">
          (5)&nbsp; OUTROS - Todos os pedidos de cancelamentos com esse motivo,
          deverão ser detalhadamente explicados e serão 100% auditados, para
          validação e enquadramento correto, podendo ou não serem aceitos como
          válidos. Esclarecemos que não serão aceitos cancelamentos com os
          seguintes motivos:
        </p>
        <p style="margin-left: 72pt">(a)&nbsp; Cliquei e Comprei SEM QUERER;</p>
        <p style="margin-left: 72pt">
          (b)&nbsp; Não vi o Laudo Técnico ou qualquer outro detalhamento do
          Anúncio;
        </p>
        <p style="margin-left: 72pt">
          (c)&nbsp; Dei Lance em mais carros do que poderia Comprar;
        </p>
        <p style="margin-left: 72pt">
          (d)&nbsp; Um terceiro (colaborador, filho, sócio, entre outros) fez a
          compra;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Solicitações/Motivos possíveis, do lado ANUNCIANTE:</strong>
        </p>
        <p style="margin-left: 36pt">
          (1)&nbsp; ATRASO NA ENTREGA do veículo - dentro do prazo máximo de 30
          (trinta) dias corridos da data da compra, o ANUNCIANTE poderá
          solicitar o Cancelamento de um compra realizada que esteja com o
          motivo citado, desde que não haja observações no anúncio explicando o
          problema e prazo de solução. A solicitação de cancelamento deverá ser
          realizada através do formulário de Cancelamento de Compras no ADM da
          Plataforma Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (2)&nbsp; NÃO PAGAMENTO por parte do COMPRADOR - caso o comprador não
          efetue o pagamento da compra realizada (Taxas e Valor do Veículo),
          dentro do prazo estipulado, considerando no máximo 10 (dez) dias
          corridos da data da compra, o ANUNCIANTE poderá solicitar o
          cancelamento através do formulário de Cancelamento de Compras no ADM
          da Plataforma Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (3)&nbsp; Problema na DOCUMENTAÇÃO do veículo - dentro do prazo máximo
          de 30 (trinta) dias corridos da data da compra, o ANUNCIANTE poderá
          solicitar o Cancelamento de um compra realizada que esteja com o
          motivo citado. A solicitação de cancelamento deverá ser realizada
          através do formulário de Cancelamento de Compras no ADM da Plataforma
          Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (4)&nbsp; ANUNCIANTE informou que o veículo foi VENDIDO POR OUTRO
          CANAL - o ANUNCIANTE, dentro do prazo máximo de 3 (três) dias corridos
          da data da compra, solicitar o cancelamento pelo motivo citado,
          através do formulário de Cancelamento de Compras no ADM da Plataforma
          Auto Avaliar.
        </p>
        <p style="margin-left: 36pt">
          (5)&nbsp; Problema para CONTATAR o COMPRADOR - se o ANUNCIANTE não
          conseguir contato com o COMPRADOR, terá até 3 (três) dias corridos da
          data da compra, abrir a solicitação de cancelamento através do
          formulário de Cancelamento de Compras no ADM da Plataforma Auto
          Avaliar, para que a equipe de atendimento, antes da efetivação do
          cancelamento, possa atuar de maneira a buscar contato com o COMPRADOR
          para viabilização do negócio.
        </p>
        <p style="margin-left: 36pt">
          (6)&nbsp; OUTROS - Todos os pedidos de cancelamentos com esse motivo,
          deverão ser detalhadamente explicados e serão 100% auditados, para
          validação e enquadramento correto, podendo ou não serem aceitos como
          válidos. Esclarecemos que não serão aceitos cancelamentos com os
          seguintes motivos:
        </p>
        <p style="margin-left: 72pt">(a)&nbsp; Anúncio realizado SEM QUERER;</p>
        <p style="margin-left: 72pt">
          (b)&nbsp; Veículo não será mais vendido;
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Multas</strong>
        </p>
        <p style="margin-left: 36pt">
          -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Todos os cancelamentos que
          estiverem fora das regras citadas acima, se forem acatados pela equipe
          de atendimento, gerarão Multa de valor igual à Taxa
          <strong>AUTOAVALIAR</strong> no valor R$ 599,00 (quinhentos e noventa
          e nove reais).
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Atualização:&nbsp;</strong>01 de janeiro de 2023.</p>
        <p>
          <strong
            >AUTOAVALIAR TECNOLOGIA, PUBLICIDADE E INTERMEDIAÇÃO DE NEGÓCIOS E
            SERVIÇOS S.A.</strong
          >
        </p>
      </template>
    </ModalFooter>
  </div>
</template>

<script>
import ModalFooter from "@/components/ModalFooter.vue";

export default {
  components: {
    ModalFooter,
  },
  data() {
    return {
      isModalOpen: false,
      isModalOpen2: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openModal2() {
      this.isModalOpen2 = true;
    },
    closeModal2() {
      this.isModalOpen2 = false;
    },
    scrollToTop() {
      // Scroll suave para o topo da página
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      let div = document.querySelector("#filter-result-app");
      div.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.footer_comp {
  bottom: 0;
  width: 100%;
}

#footer-defaut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
}

.space2 {
  flex: 22;
  padding: 1em;
  justify-content: center;
  padding-right: 25px;
  overflow: hidden;
}

.footer2 {
  flex: 78;
  padding: 1em;
  margin-bottom: 20px;
  justify-content: center;
  padding-right: 25px;
  overflow: hidden;
}

footer {
  position: relative;
  display: block;
  bottom: 0;
  left: 0;
  font-size: 13px;
  color: #666 !important;
  padding: 10px 0;
  margin-top: 20px;
}

footer img {
}

footer a {
  color: #000 !important;
}

footer.default-footer > .wrapper {
  position: relative;
  border-top: 2px solid #e3e3e3;
  padding-top: 10px;
}

@media only screen and (max-width: 920px) {
  footer.default-footer > .wrapper {
    padding: 20px 0;
    margin-bottom: 100px;
  }
}

footer.default-footer > .wrapper:before {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  left: -3px;
  top: -4px;
  background: #fff;
  border: 2px solid #e3e3e3;
}

footer.default-footer > .wrapper:before,
footer.default-footer > .wrapper:after {
  position: absolute;
  content: "";
}

footer.default-footer > .wrapper:after {
  width: 70px;
  height: 40px;
  right: 0;
  top: -24px;
  background: #fafafa url(../assets/images/baixados.png) no-repeat center;
  background-size: 70px auto;
}

footer.default-footer > .wrapper:before,
footer.default-footer > .wrapper:after {
  position: absolute;
  content: "";
}

footer .to-top {
  position: absolute;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50px;
  height: 50px;
  background: #ddd;
  border-radius: 12px;
  color: #333;
}

footer .to-top svg {
  position: relative;
  top: 7px;
}

.fr {
  float: right;
}

footer p {
  margin: 3px 0;
}

.aa-opacity-6 {
  opacity: 0.6;
}

.aa-opacity-5 {
  opacity: 0.5;
}

@media only screen and (max-width: 920px) {
  #footer-defaut {
    position: relative;
    bottom: 120px;
  }

  .wrapper {
    font-size: 16px !important;
  }

  .space2 {
    display: none;
  }
  footer {
    text-align: center;
    font-size: 10px !important;
  }
  .sm-hide {
    display: none;
  }

  .footer2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  footer .to-top {
    font-size: 18px;
    width: 36px !important;
    height: 36px !important;
    bottom: -20px !important;
  }

  footer .fr,
  footer .fl {
    float: none;
  }
}
</style>
