<template>
  <div class="buyer-two-column-page-mobile" v-if="isMobile()">
    <div class="buyer-two-column-page-mobile-top">
      <BuyerSectionItem
        :icon="icon"
        :title="section"
        :description="description"
        :shouldReturnToTheBuyerPage="true"
      />
    </div>

    <div
      class="buyer-two-column-page-container"
      v-if="!loadingLeft && !loadingRight"
    >
      <v-list v-model:opened="openOne" style="padding: 0">
        <v-list-group value="Open">
          <template v-slot:activator="{ props }">
            <div class="top green" v-bind="props">
              <div class="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="white" />
                </svg>
                <span class="ml-2"
                  >Lotes com avaliações ativas
                  {{
                    lots?.ativos.length > 0 ? `(${lots.ativos.length})` : ``
                  }}</span
                >
              </div>
              <img
                :src="require('@/assets/icons/arrow-up-white-icon.svg')"
                :class="{
                  'css-that-rotates-this-icon': !isOpenOne,
                  'css-that-rotates-this-icon-init': isOpenOne,
                }"
              />
            </div>
          </template>

          <div v-if="lots.ativos.length > 0">
            <div
              class="buyer-two-column-page-item mt-2"
              v-for="(lot, key) in lots.ativos"
              :key="key"
            >
              <LotCard
                :lot="lot"
                :label="sectionLeft.labelCard"
                :priceBackgroundColor="priceBackgroundColor"
                :isInProgress="true"
                :onClick="() => onClickInLot(lot)"
                labelPrice="Sua avaliação"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
        </v-list-group>
      </v-list>

      <v-list v-model:opened="openTwo" style="padding: 0" class="mt-2">
        <v-list-group value="Open">
          <template v-slot:activator="{ props }">
            <div class="top red" v-bind="props">
              <div class="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="white" />
                </svg>
                <span class="ml-2"
                  >Lotes com avaliações superados
                  {{
                    lots?.superados.length > 0
                      ? `(${lots.superados.length})`
                      : ``
                  }}</span
                >
              </div>
              <img
                :src="require('@/assets/icons/arrow-up-white-icon.svg')"
                :class="{
                  'css-that-rotates-this-icon': !isOpenTwo,
                  'css-that-rotates-this-icon-init': isOpenTwo,
                }"
              />
            </div>
          </template>

          <div v-if="lots.superados.length > 0">
            <div
              class="buyer-two-column-page-item mt-2"
              v-for="(lot, key) in lots.superados"
              :key="key"
            >
              <LotCard
                :lot="lot"
                :label="sectionRight.labelCard"
                :isInProgress="true"
                :onClick="() => onClickInLot(lot, false)"
                priceBackgroundColor="#F22300"
                labelPrice="Superada"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{
            lots.ativos.length > 0 ? noVehicleRightLabel : noVehicleLabel
          }}</span>
        </v-list-group>
      </v-list>
    </div>

    <div v-else>
      <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
    </div>
  </div>

  <div class="buyer-two-column-page" v-else>
    <div class="buyer-two-column-page-top">
      <h1>{{ subTitle }}</h1>
      <h2 class="mt-2">{{ pageDescription }}</h2>
    </div>
    <div class="buyer-two-column-page-container mt-4 pb-12">
      <div
        class="buyer-two-column-page-content buyer-two-column-page-content-left mr-4"
      >
        <div class="buyer-two-column-page-content-top mb-6">
          <div class="left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 26 26"
              fill="none"
            >
              <circle cx="13" cy="13" r="13" fill="#0DC561" />
            </svg>
            <span class="ml-2"
              >Lotes com avaliações ativas
              {{ lots?.length > 0 ? `(${lots.length})` : `` }}</span
            >
          </div>
          <div class="right">
            <div class="select">
              <select
                :value="orderLeftBy"
                @change="(event) => setOrderLeftBy(event.target.value)"
              >
                <option value="">Ordenar por</option>
                <option value="Mais recentes">Mais recentes</option>
                <option value="Mais antigos">Mais antigos</option>
                <option value="Maior Valor">Maior valor</option>
                <option value="Menor Valor">Menor valor</option>
              </select>
              <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
            </div>
          </div>
        </div>

        <div v-if="!loadingLeft">
          <div v-if="lots.ativos.length > 0">
            <div
              class="buyer-two-column-page-item"
              v-for="(lot, key) in lots.ativos"
              :key="key"
            >
              <LotCard
                :lot="lot"
                :label="sectionLeft.labelCard"
                :priceBackgroundColor="priceBackgroundColor"
                :isInProgress="true"
                :onClick="() => onClickInLot(lot)"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{ noVehicleLabel }}</span>
        </div>
        <div v-else>
          <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
        </div>
      </div>
      <div
        class="buyer-two-column-page-content buyer-two-column-page-content-right ml-4"
      >
        <div class="buyer-two-column-page-content-top mb-6">
          <div class="left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 26 26"
              fill="none"
            >
              <circle cx="13" cy="13" r="13" fill="#F22300" />
            </svg>
            <span class="ml-2"
              >Lotes com avaliações superados
              {{
                lots?.superados.length > 0 ? `(${lots.superados.length})` : ``
              }}</span
            >
          </div>
          <div class="right">
            <div class="select">
              <select
                :value="orderRightBy"
                @change="(event) => setOrderRightBy(event.target.value)"
              >
                <option value="">Ordenar por</option>
                <option value="Mais recentes">Mais recentes</option>
                <option value="Mais antigos">Mais antigos</option>
                <option value="Maior Valor">Maior valor</option>
                <option value="Menor Valor">Menor valor</option>
              </select>
              <img :src="require('@/assets/icons/arrow-down-icon.svg')" />
            </div>
          </div>
        </div>

        <div v-if="!loadingRight">
          <div v-if="lots.superados.length > 0">
            <div
              class="buyer-two-column-page-item"
              v-for="(lot, key) in lots.superados"
              :key="key"
            >
              <LotCard
                :lot="lot"
                :label="sectionRight.labelCard"
                :isInProgress="true"
                priceBackgroundColor="#F22300"
                :onClick="() => onClickInLot(lot, false)"
              />
            </div>
          </div>
          <span class="no-vehicles" v-else>{{
            lots.ativos.length > 0 ? noVehicleRightLabel : noVehicleLabel
          }}</span>
        </div>
        <div v-else>
          <v-skeleton-loader type="card, card" width="100%"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";

import BuyerSectionItem from "@/views/buyer/components/BuyerSectionItem";
import LotCard from "@/views/buyer/components/LotCard";
import NavigateMixings from "@/mixins/NavigateMixings";

export default {
  name: "BuyerOneColumnPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins, NavigateMixings],
  components: {
    BuyerSectionItem,
    LotCard,
    VSkeletonLoader,
  },
  props: {
    title: String,
    subTitle: String,
    section: String,
    icon: String,
    description: String,
    sectionLeft: {
      title: String,
      labelCard: String,
    },
    sectionRight: {
      title: String,
      labelCard: String,
    },
    priceBackgroundColor: String || undefined,
    getContent: Function,
    noVehicleLabel: String,
    noVehicleRightLabel: String,
  },
  data: function () {
    return {
      loadingLeft: true,
      loadingRight: true,
      loading: true,
      lots: [],
      orderBy: null,
      openOne: ["Open"],
      openTwo: ["Open"],
    };
  },
  computed: {
    isOpenOne: function () {
      return this.openOne.length > 0;
    },
    isOpenTwo: function () {
      return this.openTwo.length > 0;
    },
  },
  methods: {
    ...mapMutations("buyerLotDetail", [
      "setTitlePage",
      "setTitleContainer",
      "setTotal",
      "setLotDetail",
      "setIsActive",
    ]),
    onClickInLot: function (lot, isActive = true) {
      this.setTitlePage("Acompanhe seus Lotes!");
      this.setTitleContainer(
        `Lote ${isActive ? "ativo" : "superado"} (${
          lot.qtdVeic
        } veículos) - Lote ${lot.ava_id}`
      );
      this.setLotDetail(lot);
      this.setTotal(lot.valor);
      this.setIsActive(isActive);
      this.natigateToInPackageDetailPage();
    },
    setOrderBy: function (newOrderBy) {
      this.orderBy = newOrderBy;
      this.refresh();
    },
    setOrderLeftBy: function (newOrderBy) {
      this.orderLeftBy = newOrderBy;
      this.loadingLeft = true;
      this.refresh();
    },
    setOrderRightBy: function (newOrderBy) {
      this.orderRightBy = newOrderBy;
      this.loadingRight = true;
      this.refresh();
    },
    refresh: function () {
      this.getContent(this.orderBy)
        .then((lots) => {
          this.lots = lots;
        })
        .finally(() => {
          this.loadingLeft = false;
          this.loadingRight = false;
        });
    },
  },
  mounted: function () {
    this.getContent()
      .then((lots) => {
        this.lots = lots;
      })
      .finally(() => {
        this.loadingLeft = false;
        this.loadingRight = false;
      });
  },
};
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1700px) and (min-width: 1024px) {

  .buyer-two-column-page .buyer-two-column-page-content{
    width: 100% !important;
    display: block !important;
  }
  .buyer-two-column-page-container{
    display: block !important;
  }
  .buyer-two-column-page-content-right{
    margin-top: 30px !important;
    margin-left: 0 !important;
  }
}

.buyer-two-column-page {
  flex: 78;
  padding: 1.5em;
  justify-content: center;
  padding-right: 25px;

  .buyer-one-column-page-container {
    border-radius: 12px;
    background: #fff;
    height: 100%;
    flex-shrink: 0;

    .buyer-one-column-page-top {
      display: flex;
      justify-content: space-between;
      padding: 19px 38px;
      padding-right: 19px;

      h1 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .select {
        position: relative;

        select {
          appearance: none;
          background-color: transparent;
          border-radius: 6px;
          border: 1px solid #808080;
          width: 140px;
          height: 33px;
          flex-shrink: 0;
          color: #808080;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 12px;
        }

        img {
          position: absolute;
          bottom: 8px;
          right: 16px;
        }
      }
    }

    .buyer-one-column-page-content {
      padding: 0 11px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      .buyer-one-column-page-item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 31px;
      }
    }

    .buyer-one-column-page-bottom {
      display: grid;
      justify-content: center;
      align-items: center;
    }

    .buyer-one-column-page-content-loading {
      display: flex;
      flex-direction: column;
      padding: 19px 38px;
      padding-right: 19px;
      padding-top: 0;

      .loading-item {
        display: flex;
        flex: 1;
        flex-direction: row;
      }

      .loading-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.buyer-one-column-page-mobile {
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;

  .buyer-one-column-page-mobile-loading {
    font-family: "Lexend Deca";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buyer-one-column-page-mobile-content {
    .buyer-one-column-page-item {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.buyer-one-column-page-page-top {
  width: 100%;
}

.buyer-one-column-page-page-content {
  margin-top: 4px;
  border-radius: 12px;
  background: #fff;
  flex: 1;
}

.buyer-two-column-page {
  flex: 78;
  padding: 1.5em;
  justify-content: center;
  padding-right: 25px;

  .buyer-two-column-page-top {
    h1 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    h2 {
      color: #424242;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .buyer-two-column-page-container {
    display: flex;
    justify-content: space-between;
  }

  .buyer-two-column-page-content {
    border-radius: 12px;
    background: #fff;
    padding: 17px;
    width: 50%;

    .buyer-two-column-page-content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #373737;
          font-family: "Lexend Deca";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;

        .select {
          position: relative;

          select {
            appearance: none;
            background-color: transparent;
            border-radius: 6px;
            border: 1px solid #808080;
            width: 140px;
            height: 33px;
            flex-shrink: 0;
            color: #808080;
            font-family: "Lexend Deca";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 12px;
          }

          img {
            position: absolute;
            bottom: 8px;
            right: 16px;
          }
        }
      }
    }

    .buyer-two-column-page-item {
      margin-bottom: 16px;
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.buyer-two-column-page-mobile {
  .buyer-two-column-page-container {
    .green {
      background: #0dc561;
    }

    .red {
      background: #f22300;
    }

    .no-vehicles {
      color: #9f9f9f;
      font-family: "Lexend Deca";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 12px;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #fff;
          font-family: "Lexend Deca";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .css-that-rotates-this-icon {
        transform: rotate(180deg);
        transition: 0.2s;
      }

      .css-that-rotates-this-icon-init {
        transform: rotate(0deg);
        transition: 0.2s;
      }
    }
  }
}
</style>
