<template>
  <div id="filter-result-app" @scroll="onScroll" :class="(view == 'list') ? 'view-list' : 'view-grid'">
    <v-container v-if="vehicleLoading && selectedPage <= 1" fluid>
      <v-row v-if="!isMobile()">
        <v-col
          v-for="(_, index) in Array.from(Array(8).keys())"
          v-bind:key="index"
          lg="4"
          xl="3"
          xxl="2"
        >
          <v-skeleton-loader
            class="mx-auto border card-skeleton"
            type="image, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
            v-for="(_, index) in Array.from(Array(8).keys())"
            v-bind:key="index"
           cols="12"
        >
          <v-skeleton-loader
              class="mx-auto border card-skeleton"
              type="article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container
        class="container-not-result not-result pl-6"
        v-if="vehicles.length === 0 && !isMobile()"
      >
        <v-row>
          <v-col>
            <span class="sorry-text">
              {{ textSorryNotResult }}
            </span>
          </v-col>
        </v-row>
      </v-container>

      <div v-else-if="vehicles.length === 0 && isMobile()" class="pa-6">
        <span class="sorry-text"> {{ textSorryNotResult }} </span><br />
      </div>

      <v-container
        v-if="vehicles.length > 0 && !isMobile()"
        v-bind:key="vehicles.length"
        fluid
      >
        <v-row v-show="view === 'grid'">
          <v-col
            v-for="(vehicle, index) in vehicles"
            v-bind:key="vehicle.id_avaliacao"
            v-bind:index="index"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            xxl="2"
          >
            <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
          </v-col>
        </v-row>
        <v-row v-show="view === 'list'">
          <v-col
            v-for="(vehicle, index) in vehicles"
            v-bind:key="vehicle.id_avaliacao"
            v-bind:index="index"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            xxl="6"
          >
            <VehicleItem view="list" :vehicle="vehicle" :index="index" :linha="index" />
          </v-col>
        </v-row>
      </v-container>
      <div v-else-if="vehicles.length > 0 && isMobile()">
        <div
          v-for="(vehicle, index) in vehicles"
          v-bind:key="vehicle.id_avaliacao"
        >
          <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
        </div>
      </div>
    </div>
    <LoadingItems :loading="vehicleLoading" :selectedPage="selectedPage" :totalPages="totalPages" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import VehicleItem from "@/components/VehicleItem/index.vue";
import AdsCard from "@/components/Ads/AdsCard.vue";
import FooterDefault from "@/components/FooterDefault.vue";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import LoadingItems from "@/components/LoadingItems.vue";

export default {
  name: "FilterResultApp",
  components: {
    VehicleItem,
    AdsCard,
    VSkeletonLoader,
    FooterDefault,
    LoadingItems,
  },
  mixins: [PlatformsMixins],
  data() {
    return {
      page: 1,
      textSorryNotResult:
        "Desculpe! No momento, não há veículos com essas características...",
      textAlertNotResult:
        "→ Seja avisado quando este veículo estiver disponível!",
      textAlertButtonNotResult: "Criar alerta de veículo",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.filterForm.loading,
      vehicleLoading: (state) => state.filterForm.vehicleLoading,
      vehicles: (state) => state.filterForm.result,
      selectedPage: (state) => state.filterForm.page,
      totalPages: (state) => state.filterForm.total,
      params: (state) => state.filterForm.params,
      ads: (state) => state.ads,
      view: (state) => state.global.view,
    }),
  },
  methods: {
    ...mapActions("filterForm", ["search", "pagination"]),
    ...mapActions("ads", ["getCampaign"]),
    handlePaginate(page) {
      this.pagination(page);
    },
    injectAds(vehicles) {
      let ads = this.ads.list;
      let order = ads.priority ?? 0;
      vehicles.splice(1, order, ads);
      return vehicles;
    },
    getAds() {
      let ads = this.getCampaign("ads-card");
      let vehicles = this.vehicles;
      if (vehicles.length === 0) return;
      this.vehicles = this.injectAds(vehicles);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      const reachedTheEndOfThePage = Math.round(scrollTop + clientHeight) + 1 >= scrollHeight;
      if (reachedTheEndOfThePage) {
        if (!this.vehicleLoading) {
          const isTheLastPage = this.selectedPage + 1 > this.totalPages;
          if (isTheLastPage) {
            return;
          }
          this.handlePaginate(this.selectedPage + 1);
        }
      }
    },
    onResize() {
      const reachedTheEndOfThePage = parseInt(this.contentHeightWithButtonFooter) > document.querySelector("#filter-result-app .v-container").clientHeight;
      if (reachedTheEndOfThePage) {
        if (!this.loading) {
          const isTheLastPage = this.selectedPage + 1 > this.totalPages;
          if (isTheLastPage) {
            return;
          }
          this.handlePaginate(this.selectedPage + 1);
        }
      }
    }
  },
  mounted: function () {
    this.page = this.selectedPage;
    this.search();
    if (!this.isMobile()) {
      window.addEventListener('resize', this.onResize);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style scoped>
.container-alert-text-vehicle {
  display: flex;
  justify-content: flex-start;
}

.card-skeleton {
  border: 7px solid #ffffff;
  border-radius: 12px;
}

.alert-text-vehicle {
  color: #868686;
  font-size: 18px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  width: 500px;
  line-height: 40px;
}

.sorry-text {
  color: #868686;
  font-size: 22px;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
}

.btn-alert-vehicle {
  color: white;
  font-size: 18px !important;
  font-family: "Lexend Deca";
  font-weight: 400;
  word-wrap: break-word;
  width: 260px !important;
  background-color: #ff7a00 !important;
}

#filter-result-app {
  flex: 78;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: v-bind(contentHeightWithButtonFooter);
}

@media screen and (max-width: 768px) {
  #filter-result {
    position: relative;
  }
  .container-not-result {
    padding: 15px;
  }
  .not-result {
    min-height: 65vh !important;
  }
}
.not-result {
  position: relative;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}
</style>
