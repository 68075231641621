<template>
    <v-expansion-panels v-model="selectedPanel">
        <v-expansion-panel>
            <v-expansion-panel-title>
            <div class="custom-header">
                <i class="fa fa-heart-o"></i>
                <span class="favoriteTitle">Meus veículos favoritos
                    <span class="qtdItens">{{ vehicles.length }} favoritos</span>
                </span>
            </div>
            </v-expansion-panel-title>
    
            <v-expansion-panel-text class="painel-content">
                <v-container
                    class="container-list"
                    v-if="vehicles.length > 0"
                    v-bind:key="vehicles.length"
                    fluid
                >
                    <v-row>
                    <v-col
                        v-for="(vehicle, index) in vehicles?.slice(0, maxVehicleItens)"
                        v-bind:key="vehicle.ava_id"
                        v-bind:index="index"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="3"
                        xxl="2"
                    >
                        <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
                    </v-col>
                    </v-row>
                    <div v-if="vehicles.length > maxVehicleItens" class="more" @click="seeMoreVehicleItens()">Veja mais</div>
                </v-container>
                <div v-else class="message">Você ainda não tem favoritos</div>
            </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-title>
            <div class="custom-header">
                <i class="fa fa-clock-o"></i>
                <span class="favoriteTitle">AutoBID
                    <span class="qtdItens">{{ vehiclesAutobid.vehicles?.length }} favoritos</span>
                </span>
            </div>
            </v-expansion-panel-title>
    
            <v-expansion-panel-text class="painel-content">
                <v-container
                    class="container-list"
                    v-if="vehiclesAutobid.vehicles?.length > 0"
                    fluid
                >
                    <v-row>
                        <v-col
                        v-for="(vehicle, index) in vehiclesAutobid.vehicles?.slice(0, maxAutoBidItens)"
                        v-bind:key="vehicle.id_avaliacao"
                        v-bind:index="index"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="3"
                        xxl="2"
                        >
                        <VehicleItem :vehicle="vehicle" :index="index" :linha="index" :show-autobid-info="true" />
                        </v-col>
                    </v-row>
                    <div v-if="vehiclesAutobid.vehicles.length > maxAutoBidItens" class="more" @click="seeMoreAutoBidItens()">Veja mais</div>
                </v-container>
                <div v-else class="message">Sem anuncios</div>
            </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-title>
            <div class="custom-header">
                <i class="fa fa-heart-o"></i>
                <span class="favoriteTitle">Meus anunciantes favoritos
                    <span class="qtdItens">{{ dealers.length }} favoritos</span>
                </span>
            </div>
            </v-expansion-panel-title>
    
            <v-expansion-panel-text class="painel-content">
                <v-container
                    class="container-list"
                    v-if="dealers.length > 0"
                    fluid
                    >
                    <v-row class="dealer-list">
                        <v-col
                            v-for="(dealer, index) in dealers.slice(0, maxDealersItens)"
                            v-bind:key="dealer.id_empresa"
                            v-bind:index="index"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="3"
                            xxl="2"
                        >
                            <DealerItem :dealer="dealer" :index="index" :linha="index" />
                        </v-col>
                    </v-row>
                    <div v-if="dealers.length > maxDealersItens" class="more" @click="seeMoreDelerItens()">Veja mais</div>
                </v-container>
                <div v-else class="message">Você ainda não tem favoritos</div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
  
<script>
import FilterSidebarSectionMixin from "@/views/buyer/components/FilterSidebar/mixins/FilterSidebarSectionMixin";
import NavigateMixings from "@/mixins/NavigateMixings";
import {mapActions, mapMutations, mapState} from "vuex";
import PlatformsMixins from "@/mixins/PlatformsMixins";
import getPlatform from "@/helpers/getPlatform";
import PLATFORMS from "@/constants/Platforms";
import VehicleItem from "@/components/VehicleItem/index.vue";
import DealerItem from "@/components/DealerItem/index.vue";

  export default {
    name: "FavoritesMobile",
    mixins: [FilterSidebarSectionMixin, NavigateMixings, PlatformsMixins],
    components: { VehicleItem, DealerItem },
    data() {
        return {
            expanded: null,
            selectedPanel: null
        }
    },
    computed: {
        ...mapState({
        vehicles: (state) => state.favorite.vehicles ?? [],
        vehiclesAutobid: (state) => state.favorite.autobid ?? [],
        dealers: (state) => state.favorite.dealers ?? [],
        maxVehicleItens: (state) => state.favorite.maxVehicleItens,
        maxAutoBidItens: (state) => state.favorite.maxAutoBidItens,
        maxDealersItens: (state) => state.favorite.maxDealersItens,
        }),
    },
    methods: {
        ...mapActions("favorite", ["getFavorites", "getAutoBidsFavorite", "getDealersFavorite"]),
        ...mapMutations("filterShowcase", ["setTitleBreadcrumb"]),
        ...mapMutations("global", ["setCurrentMainMenu"]),
        isMobile() {
        const platform = getPlatform();
        return platform === PLATFORMS.MOBILE;
        },
        togglePanel(id) {
            this.expanded = id;
        },
        seeMoreVehicleItens() {
            this.$store.commit("favorite/setMaxVehicleItens", this.maxVehicleItens + 4);
        },
        seeMoreAutoBidItens() {
            this.$store.commit("favorite/setMaxAutoBidItens", this.maxAutoBidItens + 4);
        },
        seeMoreDelerItens() {
            this.$store.commit("favorite/setMaxDealersItens", this.maxDealersItens + 4);
        }
    },
    mounted: function () {
        this.setCurrentMainMenu('vehicles')
        this.setTitleBreadcrumb('Favoritos')
        this.$store.commit("favorite/setMaxVehicleItens", 4);
        this.$store.commit("favorite/setMaxAutoBidItens", 4);
        this.$store.commit("favorite/setMaxDealersItens", 4);
        this.getFavorites();
        this.getAutoBidsFavorite();
        this.getDealersFavorite();
    },
  };
  </script>
  
<style scoped>
.custom-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #333;
  gap: 5px;
}
.favoriteTitle {
    font-size: 17px;
    font-weight: bold;
}
.qtdItens {
    font-size: 11px;
    font-weight: normal;
}
.container-list{
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
}
.painel-content >>> .v-expansion-panel-text__wrapper {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.more {
  margin: 17px;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  font-family: "Lexend Deca", Helvetica, Arial;
  color: #FF7A00;
  font-size: 10pt;
  margin-top: 50px;
}
.dealer-list {
    flex-direction: column;
}
.message {
    padding: 12px;
    color: #333;
    margin: 4px;
    text-align: center;
    font-family: "Lexend Deca", Helvetica, Arial;
    color: #373737;
}
</style>
  