<template>
  <div id="pagination-products-container">
    <v-col sm="12" md="6" lg="6" xl="6" xxl="12">
      <v-sheet elevation="1" class="pagination-container" rounded>
        <v-pagination
          v-model="page"
          :length="length"
          :total-visible="totalVisible"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          @update:model-value="pagination"
          v-if="length > 1"
        >
          <template v-slot:prev>
            <v-btn
              class="v-btn v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-text"
              variant="text"
              size="default"
              :disabled="page <= 1"
              @click="prevPage"
            >
              <img
                style="width: 18px"
                src="@/assets/icons/pagination-arrow-left.svg"
                width="1000"
              />
            </v-btn>
          </template>
          <template v-slot:next>
            <div class="d-flex align-center">
              <form @submit.prevent="pagination(gotoPageInput)">
                <div class="d-flex">
                  <input
                    type="text"
                    class="input-filter-goto-page text-center"
                    v-model="gotoPageInput"
                  />
                  <button
                    type="submit"
                    class="button-goto-page ms-2"
                    :disabled="
                      gotoPageInput > length || isNaN(Number(gotoPageInput))
                    "
                  >
                    ir
                  </button>
                </div>
              </form>
              <v-btn
                class="v-btn v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-text next-button"
                variant="text"
                style="width: 75px !important"
                :disabled="page === length"
                @click="nextPage"
              >
                <img
                  style="width: 18px"
                  class="ma-0 ms-3"
                  src="@/assets/icons/pagination-arrow-right.svg"
                />
              </v-btn>
            </div>
          </template>
        </v-pagination>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "PaginationProducts",
  props: {
    selectedPage: {
      required: true,
      default: 1,
    },
    length: {
      required: true,
      default: 15,
    },
    totalVisible: {
      required: false,
      default: 4,
    },
    handlePagination: {
      required: true,
      type: Function,
    },
  },
  data: function () {
    return {
      page: 1,
      oldPage: 1,
      pageChanged: false,
      maxPage: 1,
      gotoPageInput: null,
    };
  },
  methods: {
    prevPage: function () {
      this.page--;
      this.pagination(this.page);
    },
    nextPage: function () {
      this.page++;
      this.pagination(this.page);
    },
    pagination(page) {
      this.handlePagination(page);
    },
  },
  mounted() {
    this.page = this.selectedPage;
  },
};
</script>

<style>
#pagination-products-container {
  display: grid;
  justify-content: center;
}

.next-button {
  width: 100% !important;
  font-family: "Lexend Deca" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 18px important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  color: #ff7a00 !important;
  text-transform: capitalize !important;
  color: #ff7a00;
  letter-spacing: 0px !important;
}

.pagination-container {
  min-width: 800px;
}

@media screen and (max-width: 920px) {
  #pagination-products-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin: -12px;
  }

  .pagination-container {
    min-width: 100%;
  }

  .pagination-container button {
    font-family: "Lexend Deca" !important;
    font-size: 12px !important;
  }

  .pagination-container img {
    width: 12px !important;
  }

  .input-filter-goto-page {
    width: 40px !important;
    height: 29px !important;
  }
}

.v-pagination__item {
  width: 30px;
  height: 30px;
  margin-top: 15px !important;
}

.v-pagination__item > button {
  width: 30px !important;
  height: 30px !important;
  font-weight: bold;
}

.v-pagination__item > button:hover {
  color: white;
  background-color: #ff7a00;
}

.v-pagination__item--is-active {
  color: white;
  background-color: #ff7a00;
  border-radius: 5px;
}

.input-filter-goto-page {
  outline: none;
  width: 86px;
  height: 29px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #c9c9c9 !important;
}

.input-filter-goto-page:hover,
.input-filter-goto-page:focus {
  border: 1px solid #ff7a00 !important;
}

.button-goto-page {
  width: 30px !important;
  height: 30px !important;
  color: white !important;
  background-color: #ff7a00 !important;
  border-radius: 5px !important;
  font-weight: bold;
}
.button-goto-page:disabled {
  opacity: 0.5;
}
</style>
