<template>
  <simple-header-desktop v-if="!isMobile() && isShowHeader"  id="header" />
  <simple-header-mobile v-if="isMobile() && isShowHeader"  id="header" />
</template>

<script>
import { mapState } from "vuex";

import NavigateMixings from "@/mixins/NavigateMixings";
import PlatformsMixins from "@/mixins/PlatformsMixins";

import SimpleHeaderDesktop from "./SimpleHeaderDesktop.vue";
import SimpleHeaderMobile from "./SimpleHeaderMobile.vue";

export default {
  name: "SimpleHeader",
  mixins: [NavigateMixings, PlatformsMixins],
  data: () => ({
    subMenuOpened: false,
  }),
  computed: {
    ...mapState({
      isNotFoundPage: (state) => state.global.isNotFoundPage,
      isHideHeader: (state) => state.global.isHideHeader,
    }),
    isShowHeader() {
      if (this.isNotFoundPage) {
        return false;
      }
      if (this.isHideHeader) {
        return false;
      }
      return true;
    },
  },
  components: { SimpleHeaderDesktop, SimpleHeaderMobile },
};
</script>

<style lang="scss" scoped></style>
