import { createRouter, createWebHistory } from "vue-router";

import PATHS from "@/constants/Paths";
import VechiclesView from "@/views/vehicles";
import FavoriteView from "@/views/favorites";
import DealerView from "@/views/dealers";
import CampaignsView from "@/views/showcase/pages/Campaigns";
import NotFoundView from "@/views/notFound";

import Showcase from "@/views/showcase";
import DashboardShowcase from "@/views/showcase/pages/Dashboard";

import BuyerView from "@/views/buyer";
import Dashboard from "@/views/buyer/pages/Dashboard";
import VehicleDetail from "@/views/buyer/pages/VehicleDetailPage";
import LotDetail from "@/views/buyer/pages/PackageDetailPage";

import InProgressPage from "@/views/buyer/pages/InProgress";
import BidInProgress from "@/views/buyer/pages/InProgress/BidInProgressPage";
import ProposalInProgressPage from "@/views/buyer/pages/InProgress/ProposalInProgressPage";
import QuotesInProgressPage from "@/views/buyer/pages/InProgress/QuotesInProgressPage";
import AutoBidInProgressPage from "@/views/buyer/pages/InProgress/AutoBidInProgressPage";
import LotsInProgressPage from "@/views/buyer/pages/InProgress/LotsInProgressPage";
import OnDemandAssessmentInProgressPage from "@/views/buyer/pages/InProgress/OnDemandAssessmentInProgressPage";

import PendingPage from "@/views/buyer/pages/Pending";
import ServicePage from "@/views/buyer/pages/Service";
import PaymentPendingPage from "@/views/buyer/pages/Pending/PaymentPendingPage";
import ReleasePendingPage from "@/views/buyer/pages/Pending/ReleasePendingPage";
import ProposalPendingPage from "@/views/buyer/pages/Pending/ProposalPendingPage";
import ProposOnDemandAssessmentalPending from "@/views/buyer/pages/Pending/ProposOnDemandAssessmentalPending";
import BidPendingPage from "@/views/buyer/pages/Pending/BidPendingPage";

import ClosedPage from "@/views/buyer/pages/Closed";
import AnnouncementCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/AnnouncementCompletedPurchasesPage";
import ProposalCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/ProposalCompletedPurchasesPage";
import QuotesCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/QuotesCompletedPurchasesPage";
import AutoBidCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/AutoBidCompletedPurchasesPage";
import BuyNowCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/BuyNowCompletedPurchasesPage";
import LotsCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/LotsCompletedPurchasesPage";
import AnnouncementUnfulfilledPurchasesPage from "@/views/buyer/pages/Closed/UnfulfilledPurchases/AnnouncementUnfulfilledPurchasesPage";
import QuotesUnfulfilledPurchasesPage from "@/views/buyer/pages/Closed/UnfulfilledPurchases/QuotesUnfulfilledPurchasesPage";
import AutoBidUnfulfilledPurchasesPage from "@/views/buyer/pages/Closed/UnfulfilledPurchases/AutoBidUnfulfilledPurchasesPage";
import LotsUnfulfilledPurchasesPage from "@/views/buyer/pages/Closed/UnfulfilledPurchases/LotsUnfulfilledPurchasesPage";
import CanceledUnfulfilledPurchasesPage from "@/views/buyer/pages/Closed/UnfulfilledPurchases/CanceledUnfulfilledPurchasesPage";
import OnDemandAssessmentPurchasesClosed from "@/views/buyer/pages/Closed/UnfulfilledPurchases/OnDemandAssessmentPurchasesClosed";
import AssessmentUnfulfilledPurchasesClosed from "@/views/buyer/pages/Closed/UnfulfilledPurchases/AssessmentUnfulfilledPurchasesPage";
import RejectedUnfulfilledPurchasesClosed from "@/views/buyer/pages/Closed/UnfulfilledPurchases/RejectedUnfulfilledPurchasesPage";
import OnDemandAssessmentUnfulfilledPurchasesClosed from "@/views/buyer/pages/Closed/UnfulfilledPurchases/OnDemandAssessmentUnfulfilledPurchasesPage";
import OnDemandAssessmentCompletedPurchasesPage from "@/views/buyer/pages/Closed/CompletedPurchases/OnDemandAssessmentCompletedPurchasesPage";
import CreditLetterHomePage from "@/views/buyer/pages/CreditLetter/CreditLetterHomePage";
//import { navigateToPortalLogin } from "@/helpers/portal";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: '/',
      redirect: '/veiculos'
    } ,
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFoundView
    },
    {
      path: PATHS.Vehicles,
      name: "Vehicles",
      component: VechiclesView,
    },
    {
      path: PATHS.Favorites,
      name: "Favorites",
      component: FavoriteView,
    },
    {
      path: PATHS.Dealers,
      name: "Dealers",
      component: DealerView,
    },
    {
      path: PATHS.Showcase.Root,
      name: "Showcase",
      component: Showcase,
      children: [
        {
          path: PATHS.Showcase.Root,
          component: DashboardShowcase,
          name: 'dashboardshowcase'
        },
        {
          path: PATHS.Showcase.vehicles,
          component: CampaignsView,
          props: true,
          name: 'showCaseVehicles'
        }
      ]
    },
    {
      path: PATHS.Buyer.Root,
      name: "Buyer",
      component: BuyerView,
      children: [
        {
          path: PATHS.Buyer.Root,
          component: Dashboard,
          name: 'dashboard'
        },
        {
          path: PATHS.Buyer.VehicleDetail,
          component: VehicleDetail,
          props: true,
        },
        {
          path: PATHS.Buyer.LotDetail,
          component: LotDetail,
          props: true,
        },
        {
          path: PATHS.Buyer.BidInProgress,
          component: BidInProgress,
        },
        {
          path: PATHS.Buyer.InProgress,
          component: InProgressPage,
        },
        {
          path: PATHS.Buyer.ProposalInProgress,
          component: ProposalInProgressPage,
        },
        {
          path: PATHS.Buyer.QuotesInProgress,
          component: QuotesInProgressPage,
        },
        {
          path: PATHS.Buyer.AutoBidInProgress,
          component: AutoBidInProgressPage,
        },
        {
          path: PATHS.Buyer.LotsInProgress,
          component: LotsInProgressPage,
        },
        {
          path: PATHS.Buyer.Pending,
          component: PendingPage,
        },
        {
          path: PATHS.Buyer.Service,
          component: ServicePage,
        },
        {
          path: PATHS.Buyer.PaymentPending,
          component: PaymentPendingPage,
        },
        {
          path: PATHS.Buyer.ReleasePending,
          component: ReleasePendingPage,
        },
        {
          path: PATHS.Buyer.BidPending,
          component: BidPendingPage,
        },
        {
          path: PATHS.Buyer.ProposalPending,
          component: ProposalPendingPage,
        },
        {
          path: PATHS.Buyer.Closed,
          component: ClosedPage,
        },
        {
          path: PATHS.Buyer.AnnouncementCompletedPurchasesClosed,
          component: AnnouncementCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.ProposalCompletedPurchasesClosed,
          component: ProposalCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.AnnouncementCompletedPurchasesClosed,
          component: AnnouncementCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.QuotesCompletedPurchasesClosed,
          component: QuotesCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.AutoBidCompletedPurchasesClosed,
          component: AutoBidCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.BuyNowCompletedPurchasesClosed,
          component: BuyNowCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.OnDemandAssessmentPurchasesClosed,
          component: OnDemandAssessmentCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.LotsCompletedPurchasesClosed,
          component: LotsCompletedPurchasesPage,
        },
        {
          path: PATHS.Buyer.AnnouncementUnfulfilledPurchasesClosed,
          component: AnnouncementUnfulfilledPurchasesPage,
        },
        {
          path: PATHS.Buyer.QuotesUnfulfilledPurchasesClosed,
          component: QuotesUnfulfilledPurchasesPage,
        },
        {
          path: PATHS.Buyer.AutoBidUnfulfilledPurchasesClosed,
          component: AutoBidUnfulfilledPurchasesPage,
        },
        {
          path: PATHS.Buyer.LotsUnfulfilledPurchasesClosed,
          component: LotsUnfulfilledPurchasesPage,
        },
        {
          path: PATHS.Buyer.CanceledUnfulfilledPurchasesClosed,
          component: CanceledUnfulfilledPurchasesPage,
        },
        {
          path: PATHS.Buyer.OnDemandAssessmentInProgress,
          component: OnDemandAssessmentInProgressPage,
        },
        {
          path: PATHS.Buyer.ProposOnDemandAssessmentalPending,
          component: ProposOnDemandAssessmentalPending,
        },
        {
          path: PATHS.Buyer.OnDemandAssessmentPurchasesClosed,
          component: OnDemandAssessmentPurchasesClosed,
        },
        {
          path: PATHS.Buyer.AssessmentUnfulfilledPurchasesClosed,
          component: AssessmentUnfulfilledPurchasesClosed,
        },
        {
          path: PATHS.Buyer.RejectedUnfulfilledPurchasesClosed,
          component: RejectedUnfulfilledPurchasesClosed,
        },
        {
          path: PATHS.Buyer.OnDemandAssessmentUnfulfilledPurchasesClosed,
          component: OnDemandAssessmentUnfulfilledPurchasesClosed,
        },
        {
          path: PATHS.Buyer.CreditLetter,
          component: CreditLetterHomePage,
        },
      ],
    },
  ],
});

export default router;
