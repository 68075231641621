<template>
  <v-row>
    <wallet-buy-step :type="type" @finished="$emit('finished')" />
  </v-row>
</template>

<script>
import WalletBuyStep, { WALLET_BUY_PARTIALS_STEP } from "./WalletBuyStep.vue";

export default {
  name: "WalletBuyPartialStep",
  components: { WalletBuyStep },
  computed: {
    type() {
      return WALLET_BUY_PARTIALS_STEP;
    }
  },
};
</script>

<style lang="scss" scoped></style>
