<template>
   <div class="slider-vehicle">
     <h2 v-if="!isMobile" class="carousel-title cursor-pointer d-flex align-center" @click="navigateToVehicleList(key_showcase)">
        <span>
          <v-img
            v-if="icon"
            :width="35"
            aspect-ratio="1/1"
            :src="require(`@/assets/images/showcase/${icon}`)"
          ></v-img>
        </span>
        <span class="ml-2">
          {{ title }}
        </span>
     </h2>
      <h1 v-else class="d-flex align-center" style="font-size: 18px;" @click="navigateToVehicleList(key_showcase)">
         <img
           :src="require(`@/assets/images/showcase/${icon}`)"
           class="mr-2"
           style="width: 25px;"
         />
         {{ title }}
       </h1>
        <p class="subtitle">{{ subtitle }}</p>
        <v-sheet
            v-if="vehicles.length > 0 && !isMobile"
            v-bind:key="vehicles.length"
            fluid
        >
            <v-slide-group multiple show-arrows="always">
                <v-row justify="center" align="left" class="my-1">
                    <v-slide-group-item 
                        v-for="(vehicle, index) in vehicles"
                        v-bind:key="vehicle.id_avaliacao"
                        v-bind:index="index"
                        sm="3"
                        md="4"
                        lg="4"
                        xl="3"
                        xxl="2"
                    >
                        <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
                    </v-slide-group-item>
                </v-row>
            </v-slide-group>
        </v-sheet>
        <v-sheet class="mobile"
            v-if="vehicles.length > 0 && isMobile"
            v-bind:key="vehicles.length"
            fluid>
            <v-carousel
                    height="auto"
                    show-arrows="true"
                    cycle
                    hide-delimiters
                >
                <template v-slot:prev="{ props }">
                    <v-btn class="ma-2 showcase prev-icon" color="grey" icon="mdi-chevron-left" variant="text" @click="props.onClick"></v-btn>
                  </template>
                  <template v-slot:next="{ props }">
                    <v-btn class="ma-2 showcase next-icon" color="grey" icon="mdi-chevron-right" variant="text" @click="props.onClick"></v-btn>
                  </template>
                    <v-carousel-item
                    v-for="(vehicle, index) in vehicles"
                    v-bind:key="vehicle.id_avaliacao"
                    v-bind:index="index"
                    >
                    <VehicleItem :vehicle="vehicle" :index="index" :linha="index" />
                    </v-carousel-item>
                </v-carousel>
        </v-sheet>
   </div>
</template>
  
<script> 
    import VehicleItem from "@/components/VehicleItem/index.vue";
    export default {
        name: "VehiclesSlider",
        props: {
            vehicles: Array,
            isMobile: Boolean,
            title: String,
            subtitle: String,
            icon: String,
            key_showcase: String
        },
        components: {
            VehicleItem
        },
        methods: {
            navigateToVehicleList(key) {
                this.$router.push({ name: "showCaseVehicles", params: { slug: key } })
                .then(() => {
                    window.location.reload();
                })
            }
        },
        computed: {
        
        },
        mounted() {
        
        },
    };
</script>

<style lang="scss" scoped>
.v-slide-group__content {
    justify-content: center;
  }
  #filter-result-showcase-app {
    .vehicle{
        margin-right: 2em;
        width:calc(100vw/4.9);
        &:first-child{
            margin-left: 2em;
        }
    }
    &.mobile{
        .campaign{
            margin-top: 2em;
            p.subtitle{
                font-size:0.8em;
                margin-bottom:1em;
            }
        }
        .slider-vehicle{
            .v-sheet{
                margin-top: -1.5em;
            }
            .v-window__container{
                padding-top:1em;
            }
            .v-btn.showcase.next-icon {
                position: absolute;
                right: 0;
                top: -.5em;
              }
            .v-btn.showcase.prev-icon {
                position: absolute;
                right: 3em;
                top: -.5em;
            }
            .v-slide-group__container{
                
            }
            .v-slide-group{
                position: relative;
                .v-slide-group__prev,
                .v-slide-group__next{
                    position: absolute;
                    top:45%;
                    z-index: 1;
                    top:0;
                }
                .v-slide-group__prev{
                    right: 1.2em;
                }
                .v-slide-group__next{
                    right: -1.2em;
                }
            }
                #vehicle-item-mobile{
                    width: 89vw;
                }
                h2{
                    font-size:1.2em;
                }
            }
        }
    }
        h2{
            font-size:2em;
            margin-top: 1em;
            color: #555;
            i{
                font-size: 1.5em;
                margin-top: -5px;
            }
        }
        p.subtitle{
            font-size: 1em;
            color:gray;
            margin:.5em 0;
        }
.cursor-pointer{
    cursor: pointer;
}
</style>
  