<template>
  <LotCardDesktop
    :lot="lot"
    v-if="!isMobile()"
    :label="label"
    :priceBackgroundColor="priceBackgroundColor"
    :isInProgress="isInProgress"
    :onClick="onClick"
    :isActive="isActive"
    :labelPrice="labelPrice"
    :labelAbouvePrice="labelAbouvePrice"
    :price="price"
  />
  <LotCardMobile
    :lot="lot"
    :onClick="onClick"
    :label="label"
    :priceBackgroundColor="priceBackgroundColor"
    :isInProgress="isInProgress"
    :isActive="isActive"
    :labelPrice="labelPrice"
    :labelAbouvePrice="labelAbouvePrice"
    :price="price"
    v-else
  />
</template>

<script>
import PlatformsMixins from "@/mixins/PlatformsMixins";
import LotCardDesktop from "./LotCardDesktop.vue";
import LotCardMobile from "./LotCardMobile.vue";

export default {
  name: "LotCard",
  mixins: [PlatformsMixins],
  components: { LotCardDesktop, LotCardMobile },
  props: {
    lot: Object,
    onClick: Function,
    label: String,
    priceBackgroundColor: String || undefined,
    isInProgress: Boolean,
    isActive: Boolean,
    labelPrice: String,
    labelAbouvePrice: String,
    waOvercome: Boolean,
  },
  computed: {
    price() {
      if (
        this.waOvercome &&
        parseFloat(this.lot.tem_oferta) > parseFloat(this.lot.valor)
      ) {
        return this.lot.tem_oferta;
      }

      return this.lot.valor;
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-card {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 13px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .vehicle-card-left {
    margin-right: 8px;
    flex: 2.6;

    .vehicle-card-image {
      position: relative;

      img {
        width: 100%;
        height: 130.465px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 6px;
      }

      .vehicle-card-image-overlay {
        width: 100%;
        height: 130.465px;
        position: absolute;
        border-radius: 6px;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 49.66%);
        top: 0;
      }
    }

    .vehicle-card-price {
      padding: 8px;
      flex-shrink: 0;
      border-radius: 6px;
      background: #0dc561;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .label {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 59%; /* 10.62px */
      }

      .price {
        color: #fff;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .vehicle-card-center {
    flex: 5.4;
    flex-wrap: wrap;
    .header {
      display: flex;

      h3 {
        color: #373737;
        font-family: "Lexend Deca";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 44%;
      }

      .fipe-content {
        position: relative;

        .fipe-container {
          position: relative;
        }
        .percent {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 7px;
          letter-spacing: -0.11em;
          margin-top: 12px;
          margin-left: 11px;

          color: #ffffff;
        }
        .fipe {
          position: absolute;
          z-index: 100;
          font-family: "Lexend Deca";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: -0.11em;
          margin-left: 7.8px;

          color: #ffffff;
        }
      }
    }

    h5 {
      color: #373737;
      font-family: "Lexend Deca";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .status-item {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 21px;
          height: 21px;
          opacity: 0.7;
          mix-blend-mode: luminosity;
        }

        span {
          color: #696969;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .report {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8;
          color: #525252;
          font-family: "Lexend Deca";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 108.5%; /* 15.19px */
          letter-spacing: -0.28px;
          padding: 12px;
        }

        .date {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
          padding: 8px 16px;

          img {
            width: 21px;
            height: 21px;
          }

          span {
            color: #646464;
            font-family: "Lexend Deca";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 108.5%; /* 17.36px */
          }
        }
      }

      .right {
        display: flex;

        .seller {
          padding: 4px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          display: flex;
          border-radius: 7px;
          border: 1px solid #e8e8e8;
          background: #fff;
        }

        .seller h3 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          margin-top: -2px;
        }

        .seller h4 {
          color: #373737;
          text-align: center;
          font-family: "Lexend Deca";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 175px;
    background: #ededed;
  }

  .vehicle-card-right {
    flex: 2;
  }
}
</style>
