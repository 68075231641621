import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export function getParticipantFlags( flag = '') {
  if(flag.length > 0){
    flag = '/' + flag;
  }
  return axios
  .get(`${API_BASE}/purchasers/flagsParticipant/` + flag, {
    headers: {
      token: API_TOKEN,
    },
  })
  .then((response) => response.data.payload.data);
}