import {
    getAllBrandingModels,
    getAllBrandingModelVersions,
    getAllBrandings
} from "@/services/api/ItemMenuSeeAll/SeeAllService";
import {deleteAlertItem, getAlertList, saveAlertItem} from "@/services/FollowCarModalServiceApi";

const DEFAULT_STATE = {
    modalIsOpen: false,
    loading: false,
    loadingRemove: 0,
    alerts: [],
    dataFilter: {},
    form: {},
    brands: [],
    models: [],
    versions: []
};
const followCarModalModule = {
    namespaced: true,
    state() {
        return DEFAULT_STATE;
    },
    mutations: {
        toggleModal(state) {
            state.modalIsOpen = !state.modalIsOpen;
            if (!state.modalIsOpen) {
                state.alerts = [];
                state.dataFilter = {};
                state.form = {};
                state.brands = [];
                state.models = [];
                state.versions = [];
            }
        },
        selectDataItemFilter(state, data) {
            state.dataFilter[data.key] = data.value
        },
        resetDataItemFilter(state) {
            state.dataFilter = {};
        },
        setItemForm(state, data) {
            state.form[data.key] = data.value
        },
        resetOption(state, key) {
            state[key] = []
        }
    },
    actions: {
        async getAlertList({ state }) {
            state.loading = true;
            try {
                state.alerts = await getAlertList();
                state.loading = false;
            } catch (error) {
                state.loading = false;
            }
        },
        async deleteAlertItem({ state }, data) {
            state.loadingRemove = data.id;
            try {
                await deleteAlertItem(data.id);
                state.alerts = state.alerts.filter((el) => el.id !== data.id)
                state.loadingRemove = 0;
            } catch (error) {
                state.loadingRemove = 0;
            }
        },
        async saveAlertItem({ state }, data) {
            state.loading = true;
            try {
                await saveAlertItem(data);
                state.loading = false;
            } catch (error) {
                state.loading = false;
            }
        },
        async getAllBrands({ state }) {
            try {
                state.brands = await getAllBrandings()
            } catch (error) {
                //
            }
        },
        async getAllBrandingModels({ state }, brand) {
            try {
                state.loading = true;
                state.models = await getAllBrandingModels(brand)
                state.loading = false;
            } catch (error) {
                state.loading = false;
            }
        },
        async getAllBrandingModelVersions({ state }, data) {
            const { brand, model } = data;
            state.loading = true;
            try {
                state.versions = await getAllBrandingModelVersions(brand, model)
                state.loading = false;
            } catch (error) {
                state.loading = false;
                //
            }
        },
    },
};
export default followCarModalModule;
