<template>
  <div class="dealer" ref="dealerBox">
    <div
      class="dealer-item w-100"
      :class="{ pushCard: animate }"
    >
      <div class="dealer-header pb-1">

        <div class="offers of-title">
          {{ title }}
        </div>
        <span class="favorite" :id="'fav-' + dealer?.id_empresa">
          <Heart @click="handleUnFavorite(dealer?.id_empresa)" color="#ee2424" :size="22" fill="#ee2424" v-if="isFavorite"/>
          <Heart @click="handleFavorite(dealer?.id_empresa)" color="white" :size="22" v-else />
        </span>

        <a href="#" @click="handleClick(dealer)">
          <div class="ph-12">
            <v-img
              :src="
                image === 'Array' || image === null
                  ? require('@/assets/images/VehicleItemImage.png')
                  : image
              "
              width="100%"
              height="234px"
              cover
              class="image"
            >
              <div class="overlay"></div>
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
              <template v-slot:error>
                <v-img
                  :src="require('@/assets/images/VehicleItemImage.png')"
                ></v-img>
              </template>
            </v-img>
          </div>
        </a>
          <span class="location">
            <span class="rating">
              <v-rating
                v-bind:model-value="dealer.points"
                size="x-small"
                density="compact"
                color="#FF7A00"
                half-increments
                disabled
                readonly
              ></v-rating>
            </span>
            <span class="font-[16px]">{{ dealer.points }}</span>
        </span>
        <div class="px-3 text-description text-uppercase">
          <span>
            {{ title }}
          </span>
          <span class="float-right">
            {{ dealer.n_avaliacoes }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="dealer-item clone"
      v-if="is_package"
      :class="{ pushCardClone: animate }"
    ></div>
    <div
      class="dealer-item clone"
      v-if="is_package"
      :class="{ pushCardClone: animate }"
    ></div>
    <div class="offers of-bottom">
      <i class="fa fa-car mr-2"></i>
      <span class="count">{{ dealer.n_avaliacoes }}</span> veiculos
    </div>

  </div>
</template>
<script>
import { capitalize } from "@/helpers/strings";
import { Heart } from "lucide-vue-next";

import {mapState} from "vuex";

import { gotoDealerPage } from "@/helpers/vdp";

import {
  favoriteDealer,
  unFavoriteDealer
} from "@/services/api/DealerServiceApi";

export default {
  name: "DealerItemDesktop",
  props: {
    dealer: Object,
    index: Number,
    linha: Number,
    link: String,
    title: String,
    image: String,
    version: String,
    isPackage: Boolean,
    animate: Boolean,
  },
  components: {
    Heart,
  },
  data: () => {
    return {
      time: null,
      interval: null,
      is_package: false,
      currentSlide: 0,
      rating: 5,
      imageHeight: "",
      isFavorite: false,
    };
  },
  methods: {
    capitalize,
    handleClick(dealer) {
      gotoDealerPage(dealer);
    },
    async handleFavorite(id) {
      this.isFavorite = true;
      await favoriteDealer(id)
    },
    async handleUnFavorite(id) {
      this.isFavorite = false;
      await unFavoriteDealer(id)
    }
  },
  mounted: function () {
    this.is_package = parseInt(this.dealer?.qtdeVeic) > 1;
    this.isFavorite = (this.dealer.favorita == 1);
  },
  computed: {
    ...mapState({
      params: (state) => state.filterForm.params,
    }),
  },
};
</script>

<style lang="scss" scoped>

.offers {
  display: block;
  position: absolute;
  background: #000;
  color: #FFF;
  padding: 6px 0;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.of-bottom {
  bottom: 2px;
  border-radius: 0 0 12px 12px;
}

.of-title {
  border-radius: 12px 12px 0 0;
  top: 5px;
}

.offers .counter {
  display: block;
  padding: 5px;
  margin-right: 5px;
}

.text-description {
  font-size: 14px;
  color: #555;

  word-wrap: break-word;
}

.text-description span {
  display: inline-block;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.rating {
  font-size: 12px;
  font-weight: 700;
  color: #ff7a00;
}

#stars {
  margin-left: 8px;
  margin-bottom: 2px;
  text-align: center;
}

@keyframes pushCard {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
    top: 0;
    right: -2px;
  }
  70% {
    opacity: 0.8;
    right: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes pushCardClone {
  0% {
  }
  50% {
    top: -1px;
    right: -2px;
  }
  100% {
    top: 0;
    right: initial;
  }
}
.pushCard {
  animation-name: pushCard;
  animation-duration: 0.5s;
  animation-direction: normal;
  animation-timing-function: ease;
  transition: all;
}
.pushCardClone {
  animation: pushCardClone 0.8s;
  animation-direction: normal;
  transition: all;
}

.dealer {
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 1;
  display: flex;
  position: relative;
  margin-right: v-bind(dealerMarginRight);
  &:hover {
    .offers {
      visibility: visible;
      opacity: 1;
    }
  }
  .dealer-item {
    background-color: #fff;
    position: relative;
    z-index: 2;
    border-radius: 12px;
    &.package {
      position: relative;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    }
    &.clone {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
      right: -6px;
      animation-duration: 0.3s;
      &:nth-child(2) {
        z-index: 0;
        right: -12px;
        animation-duration: 0.4s;
      }
    }
  }
}

.dealer-header {
  position: relative;
}

.dealer-header .location {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ff7a00;
  z-index: 999;
}
.dealer-header .favorite {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  z-index: 999
}
.dealer-header img {
  width: 100%;
  border: 7px solid #ffffff;
  border-radius: 12px;
  object-fit: cover;
}

.dealer-header .image {
  border: 7px solid #ffffff;
  border-radius: 12px;
  z-index: -1;
}

.dealer-header .price {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}
.dealer-header .price,
.dealer-header .adsType {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}
.dealer-header .adsType {
  right: inherit;
  left: 12px;
  font-size: 11px;
  font-weight: 700;
  &.repasse {
    background: #1872fa;
  }
  &.cotacao {
    background: #ff7a00;
  }
  &.bid-sob-aprovacao {
    background: #0dc561;
  }
}

.dealer-header h3 {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: #373737;
  margin-left: 12px;
  text-transform: capitalize;
}

.dealer-header h4 {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  word-wrap: break-word;
  max-width: 300px;
}

.dealer-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #000000 0.01%,
    rgba(0, 0, 0, 0) 33.11%
  );
  z-index: 0;
}

.divider {
  width: 100%;
  padding: 2px 10px;
  margin: 6px 0;
}

.divider .content {
  width: 100%;
  border: 0.8px solid #ededed;
}

.description {
  display: flex;
  margin: 4px 12px;
}

.detail {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0;
}

.detail span {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  text-align: left;
  flex: 1;
}

.more-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.more-info .fipe {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.11em;
  color: #ffffff;
}
.more-info .percent {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  letter-spacing: -0.11em;
  margin-top: 12px;
  margin-left: 11px;

  color: #ffffff;
}

.seller {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  height: 107px;
  width: v-bind(sellerWidth);
  margin-left: v-bind(sellerMarginLeft);
  padding: 8px 2px;
}

.seller h3 {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  text-align: center;
}

.seller h4 {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.seller img {
  object-fit: cover;
}

.seller .rating {
  color: #ff7a00;
  font-family: "Lexend Deca", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dealer-footer {
  display: flex;
  flex-direction: row;
  margin: 8px;
  justify-content: center;
  align-items: center;
}

.footer-info {
  display: flex;
  flex: 1;
}

.timer {
  margin-left: 4px;
}

.timer span {
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  display: flex;
  align-items: center;

  color: #373737;
}

.timer h4 {
  margin-top: -4px;
  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  align-items: center;
  color: #373737;
}

.dealer-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ff7a00;
  border-radius: 7px;
  padding: 0 8px;
  height: 28px;

  font-family: "Lexend Deca", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  align-items: center;

  color: #ff7a00;
  padding: 0 22px;
  text-decoration: none;
}

.dealer-footer a:hover {
  opacity: 0.8;
}
.nav-bullets {
  position: absolute;
  z-index: 1;
  bottom: 4em;
  left: 1em;
  i {
    cursor: pointer;
    margin: 3px;
    color: #000f;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid #fff;
    border-radius: 1em;
    &.active {
      color: #fff;
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
    }
  }
}
.fipe-container {
  position: relative;
}
.details-package {
  img {
    float: left;
  }
  strong {
    display: inline-block;
  }
  .qtd {
  }
  figcaption {
    line-height: 1.2em;
    font-size: 0.92em;
    text-indent: 0.4em;
  }
  .controls {
    font-size: 0.85em;
    align-content: space-between;
    display: flex;
    margin-top: 2em;
    border-top: 1px solid #eee;
    padding-top: 1.5em;
    a {
      color: #373737;
      text-decoration: none;
      flex: auto;
      transition: 0.2s all;
      &.disable {
        color: lightgray;
        pointer-events: none;
      }
    }
  }
}
</style>
