<template>
    <v-container>
        <v-row>
            <MenuTitle text="Ano" />
        </v-row>
        <v-row>
      <v-col class="ps-0">
        <v-text-field
          label="De"
          variant="outlined"
          hide-details="auto"
          v-maska:[year]
          id="year_from"
          :model-value="yearMin"
          @input="(event) => setYearMinHandle(event.target.value)"
          color="#939292"
          base-color="#939292"
          :persistent-placeholder="true"
          :placeholder="yearMinPlaceholder"
        >
        <template v-slot:label>
          <span class="custom-text-field--label">
            De
          </span>
        </template>
      </v-text-field>
        <small class="input-field-message" id="year_from_message"></small>
      </v-col>
      <v-col>
        <v-text-field
          label="Até"
          variant="outlined"
          hide-details="auto"
          v-maska:[year]
          id="year_at"
          @keyup="yearChange"
          :model-value="yearMax"
          @input="(event) => setYearMaxHandle(event.target.value)"
          color="#939292"
          base-color="#939292"
          :persistent-placeholder="true"
          :placeholder="yearMaxPlaceholder"
        >
        <template v-slot:label>
          <span class="custom-text-field--label">
            Até
          </span>
        </template>
      </v-text-field>
      </v-col>
    </v-row>
    </v-container>
</template>
<script>
    import { vMaska } from "maska";
    import MenuTitle from "@/components/typographies/MenuTitle.vue";
    import { getDefaultValueFilterInputs } from "@/services/api/FiltersServiceApi";
    import { mapMutations, mapState } from "vuex";
    import {debounce} from "@/helpers/strings";

export default {
  name: "VehicleYearRange",
  components: { MenuTitle },
  directives: { maska: vMaska },
  data: () => ({
    year: {
      mask: "####",
    },
    yearMaxPlaceholder: '',
    yearMinPlaceholder: '',
  }),
  computed: {
    ...mapState({
        yearMin: (state) => state.filterForm.params.yearMin ?? state.filterForm.yearMin,
        yearMax: (state) => state.filterForm.params.yearMax ?? state.filterForm.yearMax,
    }),
  },
  methods: {
    ...mapMutations("filterForm", [
      "setYearMin",
      "setYearMax",
    ]),
    yearChange(e) {
      const from = document.getElementById("year_from");
      const from_parent_closest = from.closest(".v-input");
      const e_parent_closest = e.target.closest(".v-input");
      const message_place = document.getElementById("year_from_message");

      if (e.target.value.length == 4) {
        if (from.value) {
          if (from.value > e.target.value) {
            from_parent_closest.classList.add("input-field-error");
            message_place.innerText = "Ano inicial maior que final";
            message_place.classList.add("error");

            e_parent_closest.classList.add("input-field-error");
          } else {
            from_parent_closest.classList.remove("input-field-error");
            message_place.innerText = "";
            message_place.classList.remove("error");

            e_parent_closest.classList.remove("input-field-error");
          }
        }
      }
    },
    setDefaultDataValue(response){
      const { ano_max, ano_min } = response.data;
      this.yearMaxPlaceholder = ano_max?.toString() ?? '';
      this.yearMinPlaceholder = ano_min?.toString() ?? '';
    },
    setYearMinHandle: debounce(function (year) {
      this.setYearMin(year);
    }, 1000),
    setYearMaxHandle: debounce(function (year) {
      this.setYearMax(year);
    }, 1000),
  },
  mounted() {
    getDefaultValueFilterInputs().then((response) => {
      this.setDefaultDataValue(response.data.payload);
    });
  },
}
</script>
<style scoped>
.field-example {
  color: #cdcdcd;
  display: inline-block;
  margin-top: 5px;
}
.input-field-error {
  color: red;
}
.input-field-message {
  display: none;
  font-size: 12px;
}
.input-field-message.error {
  display: block;
  color: red;
}
</style>