import axios from "axios";

import API_BASE from "@/constants/ApiBase";
import API_TOKEN from "@/constants/ApiToken";

export async function getAllSuggestions() {
  return axios
    .get(`${API_BASE}/search/suggestions`, { headers: {
        token: API_TOKEN
      } })
    .catch((error) => `getAllSuggestions ${error}`);
}

export async function autoComplete(term) {
  return await axios
    .get(`${API_BASE}/search/vehicles/${term.replace(/ /g, "_")}`, { headers: {
        token: API_TOKEN
      } })
    .catch((error) => `searchAutoComplete ${error}`);
}



