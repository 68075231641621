<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="modalIsOpen"
      width="auto"
    >
      <v-card
        class="card-modal"
        title="Selecione um Estado: "
        append-icon="close"
      >
        <template v-slot:append>
          <v-icon
            class="ms-auto"
            @click="closeModal"
          >mdi-close</v-icon>
        </template>
        <template v-slot:text>
          <DealerItem
            :dealer="dealer"
            :index="1"
            :linha="1"
          />
          <div class="scroll-loading" v-if="loading">
            <v-progress-circular
              indeterminate
              :size="24"
              :width="6"
              color="#ff7a00"
            ></v-progress-circular>
          </div>
          <div v-else class="mt-4">
            <v-checkbox
              v-for="ufObject in ufs"
              :key="ufObject.uf"
              class="m-0 p-0"
              density="compact"
              hide-details
              :value="ufObject.uf_abbr"
              v-model="selectedUf"
              @change="gotoDealerPage(dealer, ufObject.uf_abbr)"
            >
              <template v-slot:label>
                <span class="m-0 p-0">
                  <strong>
                    {{ ufObject.uf }}
                  </strong>
                  -
                  <span>
                    {{ ufObject.qtde }}
                  </span>
                </span>
              </template>
            </v-checkbox>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import DealerItem from "@/components/DealerItem/index.vue";
import {gotoDealerPage} from "@/helpers/vdp";

export default {
  components: {DealerItem},
  data: function () {
    return {
      selectedUf: null,  
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.dealer.dealerUfsLoading,
      modalIsOpen: (state) => state.dealer.modalIsOpen,
      ufs: (state) => state.dealer.ufs,
      dealer: (state) => state.dealer.selectedDealer,
    })
  },
  methods: {
    gotoDealerPage,
    ...mapMutations("dealer", ["toggleModal"]),
    closeModal() {
      this.selectedUf = null;
      this.toggleModal();
    }
  },
}
</script>

<style scoped lang="scss">


.options {
  margin: 1rem auto;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  padding: 5px;
}

.option:hover {
  background-color: rgba(159, 159, 159, 0.27);
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #797979;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.scroll-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  text-align: center;
}

.scroll-loading span {
  font-family: "Lexend Deca";
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-loading p {
  font-family: "Lexend Deca";
  font-size: 18px;
  font-weight: bold;
  padding: 0 32%;
  text-align: center;
}

.card-modal {
  width: auto;
}

@media only screen and (min-width: 500px) {
  .card-modal {
    width: 400px !important;
  }
}

@media only screen and (max-width: 920px) {
  .scroll-loading p {
    padding: 0;
  }
  .scroll-loading span {
    font-size: 16px;
  }

  .scroll-loading p {
    font-size: 14px;
  }
}

.scroll-loading p a {
  color: #ff7a00;
}
</style>