import SECTIONS_DEFAULT from "@/constants/SectionsDefault";
import {
  getItem,
  restoreDefault,
  setItem,
} from "@/services/localStorage/SectionsLocalStorage";

const sectionsModule = {
  namespaced: true,
  state() {
    return {
      list: null,
    };
  },
  mutations: {
    initSectionsModule(state) {
      const notStarted = state.list === null;
      if (notStarted) {
        state.list = getItem();
      }
    },
    setSections(state, item) {
      state.list = item;
      setItem(item);
    },
    restoreSectionsDefault(state) {
      restoreDefault();
      state.list = [...SECTIONS_DEFAULT];
    },
  },
};

export default sectionsModule;
