<template>
  <v-container v-if="!loading && (type === 'compact')" class="wallet-card compact">
    <v-row align-content="space-around">
      <v-col>
        <p class="" align="center">Saldo disponível:</p>
      </v-col>
      <v-col>
        <p class="balance">
          {{balance.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}}</p>
      </v-col>
      <v-col>
        <div class="waller-card-header-icon px-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
          >
            <path
              d="M25.3173 7.4095H4.54564C4.01239 7.4095 3.58136 6.96667 3.58136 6.42103C3.58136 5.8754 4.01239 5.43256 4.54564 5.43256H21.4901V1.7258C21.4901 1.19894 21.2596 0.707671 20.8585 0.378511C20.4554 0.0473731 19.9337 -0.072232 19.4361 0.0424306L1.31336 4.21378C0.540964 4.3917 0 5.08462 0 5.89714V25.2741C0 26.225 0.755036 27 1.68268 27H25.3173C26.245 27 27 26.226 27 25.2741V9.13438C27 8.18347 26.245 7.4095 25.3173 7.4095ZM22.2808 18.9667C21.3792 18.9667 20.6463 18.2145 20.6463 17.2903C20.6463 16.366 21.3801 15.6138 22.2808 15.6138C23.1834 15.6138 23.9162 16.366 23.9162 17.2903C23.9162 18.2145 23.1834 18.9667 22.2808 18.9667Z"
              fill="#D2D2D2"
            />
          </svg>
        </div>
      </v-col>
    </v-row>
    <v-row><p class="date">Atualizado dia {{ date }}</p></v-row>
  </v-container>

  <v-container v-if="!loading && (type === 'dashboard' || type === 'modal')" class="wallet-card">
    <div class="waller-card-label" v-if="type === 'dashboard' && hideLabel == false">NOVO</div>

    <v-row @click="onClick" id="btn-dashboard">
      <v-col class="wallet-card-header cursor-pointer" align="center">
        <h4 class="mt-2" v-if="type === 'dashboard'">
          Seu saldo disponível para pagamento de taxas:
        </h4>
        <h3 class="mt-2" v-if="type === 'modal'">Saldo disponível</h3>
        <span>Atualizado dia {{ date }}</span>

        <div class="waller-card-header-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
          >
            <path
              d="M25.3173 7.4095H4.54564C4.01239 7.4095 3.58136 6.96667 3.58136 6.42103C3.58136 5.8754 4.01239 5.43256 4.54564 5.43256H21.4901V1.7258C21.4901 1.19894 21.2596 0.707671 20.8585 0.378511C20.4554 0.0473731 19.9337 -0.072232 19.4361 0.0424306L1.31336 4.21378C0.540964 4.3917 0 5.08462 0 5.89714V25.2741C0 26.225 0.755036 27 1.68268 27H25.3173C26.245 27 27 26.226 27 25.2741V9.13438C27 8.18347 26.245 7.4095 25.3173 7.4095ZM22.2808 18.9667C21.3792 18.9667 20.6463 18.2145 20.6463 17.2903C20.6463 16.366 21.3801 15.6138 22.2808 15.6138C23.1834 15.6138 23.9162 16.366 23.9162 17.2903C23.9162 18.2145 23.1834 18.9667 22.2808 18.9667Z"
              fill="#D2D2D2"
            />
          </svg>
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-3" color="#D1D1D1"></v-divider>

    <v-row @click="onClick">
      <v-col align="center" class="waller-card-bottom mb-1 cursor-pointer">
        <p class="balance">
          <span class="wallet-card-eye" @click.stop="toggleEyeBalance" id="btn-wallet-card-eye">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :width="isMobile() ? 28 : 42"
              :height="isMobile() ? 16 : 26"
              viewBox="0 0 42 26"
              fill="none"
              class="mr-2"
              v-if="showBalance"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4524 12.9C11.4524 7.81686 15.587 3.68054 20.6757 3.68054C25.7589 3.68054 29.8934 7.81677 29.8934 12.9C29.8934 17.9844 25.7589 22.1189 20.6757 22.1189C15.5869 22.1189 11.4524 17.9844 11.4524 12.9ZM0.238535 12.0205C-0.0795117 12.5957 -0.0795117 13.2043 0.238535 13.7801C4.36766 21.1949 12.1981 25.8011 20.6757 25.8011C29.1476 25.8011 36.9782 21.195 41.1073 13.7801C41.4308 13.2043 41.4308 12.5957 41.1073 12.0205C36.9782 4.60566 29.1477 0 20.6757 0C12.1981 0 4.36766 4.60566 0.238535 12.0205ZM20.6757 6.70198C17.254 6.70198 14.4737 9.48211 14.4737 12.9C14.4737 16.3179 17.2539 19.0981 20.6757 19.0981C24.0919 19.0981 26.872 16.318 26.872 12.9C26.872 9.48211 24.0919 6.70198 20.6757 6.70198Z"
                fill="#FF7A00"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :width="isMobile() ? 28 : 42"
              :height="isMobile() ? 16 : 26"
              viewBox="0 0 41 30"
              fill="none"
              v-else
            >
              <path
                d="M7.38778 0.447978L7.38217 0.441572C7.24021 0.300763 7.0719 0.189295 6.88685 0.113532C6.70181 0.0377689 6.50365 -0.000805213 6.3037 1.27407e-05C6.10374 0.000830694 5.90591 0.0410247 5.72149 0.118299C5.53707 0.195573 5.36968 0.308415 5.22887 0.450381C5.08806 0.592347 4.97659 0.760657 4.90083 0.945702C4.82507 1.13075 4.78649 1.3289 4.78731 1.52886C4.78896 1.93268 4.95097 2.3193 5.23768 2.60368L7.53352 4.89952C4.74929 6.80638 2.35276 9.22493 0.471429 12.0265C0.164209 12.4801 0 13.0153 0 13.5632C0 14.111 0.164209 14.6463 0.471429 15.0999C5.27372 22.2076 12.8867 26.3717 20.4998 26.3196C22.9658 26.3286 25.4153 25.918 27.7436 25.1056L31.4601 28.8221C31.7464 29.0992 32.13 29.2529 32.5285 29.2501C32.9269 29.2474 33.3084 29.0885 33.591 28.8076C33.8735 28.5266 34.0345 28.146 34.0395 27.7476C34.0444 27.3491 33.8929 26.9646 33.6174 26.6768L7.38778 0.447978ZM20.4998 22.1868C19.0574 22.1863 17.6382 21.8242 16.3718 21.1338C15.1054 20.4434 14.0322 19.4467 13.2503 18.2346C12.4684 17.0225 12.0027 15.6339 11.8959 14.1955C11.789 12.757 12.0443 11.3148 12.6385 10.0005L15.0368 12.3988C14.8437 13.3092 14.881 14.2533 15.1454 15.1456C15.4099 16.0378 15.8931 16.8498 16.5511 17.5078C17.2091 18.1659 18.0212 18.6491 18.9134 18.9135C19.8056 19.178 20.7498 19.2153 21.6601 19.0221L24.0584 21.4204C22.9406 21.9273 21.7271 22.1886 20.4998 22.1868Z"
                fill="#FF7A00"
              />
              <path
                d="M40.5281 12.0225C35.7258 4.91073 28.1128 0.74667 20.4998 0.79872C18.0338 0.789788 15.5842 1.2003 13.2559 2.0127L16.9395 5.6963C18.5379 4.97294 20.3188 4.7539 22.0449 5.06838C23.771 5.38285 25.3602 6.2159 26.6008 7.45651C27.8414 8.69712 28.6745 10.2864 28.989 12.0124C29.3034 13.7385 29.0844 15.5194 28.361 17.1178L33.4652 22.222C36.2499 20.3155 38.6468 17.8969 40.5281 15.0951C40.8352 14.6416 40.9993 14.1064 40.9993 13.5588C40.9993 13.0111 40.8352 12.476 40.5281 12.0225Z"
                fill="#FF7A00"
              />
              <path
                d="M20.4998 7.97452C20.1098 7.97457 19.7209 8.01536 19.3394 8.09624L25.9627 14.7187C26.1354 13.905 26.1242 13.0631 25.9298 12.2543C25.7355 11.4455 25.3629 10.6904 24.8393 10.044C24.3158 9.39766 23.6544 8.87642 22.9037 8.51838C22.1529 8.16033 21.3316 7.97452 20.4998 7.97452Z"
                fill="#FF7A00"
              />
            </svg>
          </span>
          {{
            showBalance
              ? balance.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ ***.***,**"
          }}
        </p>

        <a href="#" id="btn-modal-entenda-esse-valor" @click.stop="onClick" v-if="linkText"
          >{{ linkText }}</a
        >
        <a href="#" id="btn-saiba-como-utilizar" @click.stop="onClick" v-else-if="type === 'dashboard'"
          >Saiba como utilizar →</a
        >
      </v-col>
    </v-row>
  </v-container>
  <v-container v-if="loading" class="wallet-card">
    <v-skeleton-loader type="article"></v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { VSkeletonLoader } from "vuetify/lib/labs/VSkeletonLoader";

import PlatformsMixins from "@/mixins/PlatformsMixins";

export default {
  name: "WalletCard",
  mixins: [PlatformsMixins],
  components: {
    VSkeletonLoader,
  },
  props: {
    onClick: Function,
    type: String,
    linkText: String,
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.wallet.loadingBalance,
      balance: (state) => state.wallet.balance,
      showBalance: (state) => state.wallet.showBalance,
      date: (state) => state.wallet.balanceDate,
    }),
  },
  methods: {
    ...mapActions("wallet", ["getBalance"]),
    ...mapMutations("wallet", ["setShowBalance"]),
    toggleEyeBalance() {
      this.setShowBalance(!this.showBalance);
    },
  },
  mounted() {
    this.getBalance();
    this.$emitter.on('refreshEvent', (from) => {
      if (from === 'RefreshBalance') {
        this.getBalance();
      }
    });
  },
};
</script>

<style lang="scss" scoped>

.cursor-pointer{
  cursor: pointer;
}

.wallet-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  position: relative;
  height: 190px;
  &.compact{
    height: auto;
    font-size: 12px;
    text-align: center;
    p.balance{
      color: #454545;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .date{
      padding: .3em;
      margin:0 auto;
      color:#777;
      font-size: 11px;
    }
  }

  .waller-card-label {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 63px; /* Need a specific value to work */
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 7px;
    background: #f22300;
    color: #fff;
    font-family: "Lexend Deca";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.75px;
  }

  .wallet-card-header {
    position: relative;

    h4 {
      color: #373737;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 113.5%; /* 20.43px */
      padding: 0 80px;
    }

    h3 {
      color: #373737;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 113.5%; /* 24.97px */
    }

    span {
      color: #979797;
      font-family: "Lexend Deca";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .waller-card-header-icon {
      position: absolute;
      top: 8px;
      right: 12px;
    }
  }

  .waller-card-bottom {
    .balance {
      color: #454545;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .wallet-card-eye {
        cursor: pointer;
      }
    }

    a {
      color: #979797;
      text-align: center;
      font-family: "Lexend Deca";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1048px) {
  .wallet-card {
    .wallet-card-header {
      h4 {
        font-size: 14px;
      }

      span {
        font-size: 10px;
      }
    }

    .waller-card-bottom {
      .balance {
        font-size: 24px;
      }

      a {
        font-size: 10px;
      }
    }
  }
}
</style>
