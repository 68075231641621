export default {
  data: () => ({
    open: ["Open"],
  }),
  computed: {
    isOpen: function () {
      return this.open.length > 0;
    },
  }
};
