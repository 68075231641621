import getCampaign from "@/services/api/CampaignServiceApi";

const campaignModule = {
  namespaced: true,
  state() {
    return {
      list: null,
    };
  },
  mutations: {
    setResult(state, result) {
      state.list = result;
    }
},
actions: {
  async getCampaign({ commit }, key) {
    let response = await getCampaign(key);
    commit("setResult", response);
  }
}
}

export default campaignModule;
