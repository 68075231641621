import SECTIONS_DEFAULT from "@/constants/SectionsDefault";
import testStorage from "./index";

export const LOCAL_STORAGE_KEY = "SECTIONS";

function checkIfItemIsValid(item) {
  const itemIsNull = item === null;
  if (itemIsNull) {
    return false;
  }

  const invalidSections = item
    .map((section) => {
      const sectionDefault = SECTIONS_DEFAULT.find(
        (sectionDefault) => sectionDefault.key === section.key
      );
      if (sectionDefault !== undefined) {
        const isNotValid = sectionDefault.name !== section.name;
        if (isNotValid) {
          return section;
        }

        return null;
      }
    })
    .filter((section) => section !== null);

  const itemIsNotNeedToBeUpdated =
    item.length === SECTIONS_DEFAULT.length && invalidSections.length === 0;

  return itemIsNotNeedToBeUpdated;
}

export function getItem() {
  if(!testStorage()) return;
  const item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const shouldLoadFromLocalStorage = checkIfItemIsValid(item);
  if (shouldLoadFromLocalStorage) {
    return item;
  }

  restoreDefault();
[...SECTIONS_DEFAULT];
}
export function setItem(item) {
  if(!testStorage()) return;
  const parsed = JSON.stringify(item);
  localStorage.setItem(LOCAL_STORAGE_KEY, parsed);
}

export function restoreDefault() {
  localStorage.clear(LOCAL_STORAGE_KEY);
  setItem(SECTIONS_DEFAULT);
}
