<template>
  <v-list v-model:opened="open" style="padding: 0">
    <v-list-group value="Open">
      <template v-slot:activator="{ props }">
        <FilterItemHeader
          :icon="require('@/assets/icons/pending-icon.svg')"
          label="Pendentes"
          v-bind="props"
          :isOpen="isOpen"
        />
      </template>
      <FilterSidebarInPendingItems />
    </v-list-group>
  </v-list>
</template>

<script>
import NavigateMixings from "@/mixins/NavigateMixings";
import FilterItemHeader from "@/views/buyer/components/FilterItemHeader";
import FilterSidebarInPendingItems from "@/views/buyer/components/FilterSidebar/components/FilterSidebarInPendingItems";
import FilterSidebarSectionMixin from "@/views/buyer/components/FilterSidebar/mixins/FilterSidebarSectionMixin";

export default {
  name: "FilterSidebarInPendingSection",
  mixins: [FilterSidebarSectionMixin, NavigateMixings],
  components: { FilterItemHeader, FilterSidebarInPendingItems },
};
</script>

<style scoped>
.filter-sidebar-in-pending-items {
  margin: 12px 0;
}

#buyer-sidebar {
  flex: 22;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Lexend Deca", Helvetica, Arial;
  height: 90vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
#buyer-sidebar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#buyer-sidebar::-webkit-scrollbar-thumb {
  background-color: lightgray;
}
.buyer-header {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: #fff;
  border-bottom: solid 1px #e4e4e4;
}
.item {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  padding: 10px 0;
}
.item .prepend-icon {
  color: #f90;
  flex: 8;
  text-align: right;
}
.item .prepend-icon > * {
  vertical-align: bottom;
}
.item .item-label {
  flex: 73;
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}
.item .append-icon {
  flex: 8;
}
.sub-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.sub-item .prepend-icon {
  color: #f90;
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 7;
}
.sub-item .prepend-icon > * {
  vertical-align: sub;
}
.sub-item .sub-item-label {
  color: #f90;
  font-weight: 600;
  font-size: 18px;
  flex: 84;
  padding-left: 10px;
}
.sub-item .sub-item-label > * {
  color: #e1e1e1;
}
.sub-item .append-icon {
  flex: 8;
}
.sub-sub-item {
  display: flex;
  flex-direction: row;
  padding: 2px 0;
}
.sub-sub-item .prepend-icon {
  text-align: right;
  font-size: 4px;
  padding: 8px 0;
  flex: 15;
}
.sub-sub-item-label {
  flex: 85;
  padding-left: 8px;
  color: #303030;
}
.sub-item.pending {
  padding: 3px 0;
}
.sub-item.pending .prepend-icon {
  color: #6e6e6e;
}
.sub-item.pending .sub-item-label .label {
  color: #6e6e6e;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sub-header {
  display: flex;
  flex-direction: row;
}
.sub-header .prepend-icon {
  flex: 8;
  text-align: right;
  font-size: 8px;
  padding: 5px 0;
}
.sub-header .prepend-icon > * {
  vertical-align: bottom;
}
.sub-header .prepend-icon.red {
  color: #f22300;
}
.sub-header .prepend-icon.green {
  color: #0dc561;
}
.sub-header .item-label {
  flex: 92;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}
#submit-button {
  padding: 20px;
  margin: 50px auto 100px auto;
  text-align: center;
  width: 80%;
  border: 3px solid #f90;
  color: #f90;
  font-weight: 600;
  border-radius: 3px;
}
</style>
