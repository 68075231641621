<template>
  <div id="filter-app-showcase">
    <v-app>
      <v-main>
        <v-dialog
          v-model="isSidebarOpen"
          v-on:update:model-value="toggleSidebar"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
          v-if="isMobile()"
        >
          <template v-slot:activator="{ props }">
            <div id="filter-drawer-container">
              <v-btn
                variant="plain"
                v-bind="props"
                id="filter-drawer-button"
                width="248px"
                height="49px"
              >
                <img
                  :src="require('@/assets/icons/filter-icon.svg')"
                />&nbsp;Filtros
              </v-btn>
            </div>
          </template>
          <v-card>
            <v-toolbar dark color="#FF7A00" align-items="center">
              <v-toolbar-title class="title" align-items="center"
                >Filtros</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click="closeSidebar">
                  <v-icon color="#FFFFFF">mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <FilterSidebar :closeSidebar="closeSidebar" />
          </v-card>
        </v-dialog>

        <div class="filter-app-showcase-content" v-else>
          <FilterSidebar :closeSidebar="closeSidebar" />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import FilterSidebar from "./FilterSidebar.vue";

export default {
  name: "FilterApp",
  mixins: [PlatformsMixins],
  components: {
    FilterSidebar,
  },
  data: function () {
    return {
      isSidebarOpen: false,
    };
  },
  methods: {
    ...mapMutations("global", ['setIsHideHeader']),
    closeSidebar: function () {
      this.toggleSidebar(false);
    },
    toggleSidebar: function (isSidebarOpen) {
      this.isSidebarOpen = isSidebarOpen;
      if (isSidebarOpen) {
        this.setIsHideHeader(true);
      } else {
        this.setIsHideHeader(false);
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Lexend Deca";
  src: local("Lexend Deca"),
    url(@/assets/fonts/LexendDeca-Regular.ttf) format("truetype");
}

#filter-app-showcase {
  background-color: #fafafa;
  margin: 0;
  overflow-x: hidden;
  flex: 22;
  font-family: "Lexend Deca", Helvetica, Arial;
  height: v-bind(contentHeight);
  overflow-y: scroll;
}

@media only screen and (max-width: 920px) {
  #filter-app-showcase {
    flex: 0;
    min-width: 0;
  }
}

#filter-app-showcase::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#filter-app-showcase::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

#filter-drawer-container {
  background: #efefef;
  box-shadow: 0px -2px 22px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 77px;
  padding: 16px;
  z-index: 1000;
}

#filter-drawer-button {
  border-radius: 4px;
  background: #ff7a00;
  color: #fff;
  text-transform: none;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 248px;
  height: 49px;
  flex-shrink: 0;
  opacity: 1;
  z-index: 1000;
}
@media only screen and (max-width: 575px) {
  #filter-drawer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: normal;
  }
}

.isSidebarOpen-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.title {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;
}
</style>
