<template>
  <div class="vehicle-card-timer ml-2" v-if="(isMobile && formatedTime !== null && formatedTime > 0) || !isMobile || isPackage">
    <img
      :src="require('@/assets/icons/timer-icon.svg')"
      :height="isMobile ? 19 : 28"
      :width="isMobile ? 19 : 28"
      v-if="formatedTime !== null"
    />
    <span v-if="formatedTime === null" class="loading">Carregando...</span>
    <span v-else>{{ formatedTime === 0 ? 'Finalizado' : formatedTime }}</span>
  </div>
</template>

<script>
export default {
  name: "VehicleCardTimer",
  props: {
    dt_ts_final: Number,
    isMobile: Boolean,
    isPackage: Boolean,
  },
  data: function () {
    return {
      time: null,
      formatedTime: null,
      interval: null,
    };
  },
  methods: {
    formatTime: function (time) {
      return time < 10 ? "0" + time : time;
    },
    subtractSeconds: function () {
      const newTime = new Date(this.time);
      newTime.setSeconds(newTime.getSeconds() - 1);
      const result = this.countdown(newTime);
      this.formatedTime = result;
      if (result === 0) {
        clearInterval(this.interval);
      }
    },
    countdown: function (targetDate) {
      const currentDate = new Date();

      const timeRemaining = targetDate.getTime() - currentDate.getTime();

      if (timeRemaining <= 0) {
        return 0;
      }
      let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      days = days
        ? `${this.formatTime(days)} ${days > 1 ? "dias" : "dia"},`
        : "";

      return `${days} ${this.formatTime(hours)}:${this.formatTime(
        minutes
      )}:${this.formatTime(seconds)}s`;
    },
  },
  computed: {
    fontSize: function() {
      if (this.isMobile) {
        return '12px';
      }
      return '14px'
    },
    loadingFontSize: function() {
      if (this.isMobile) {
        return '8px';
      }
      return '12.3px'
    },
  },
  mounted: function () {
    this.time = new Date(parseInt(this.dt_ts_final) * 1000);
    this.interval = setInterval(() => this.subtractSeconds(), 1000);
  },
  unmounted: function () {
    clearInterval(this.interval);
    this.time = null;
    this.interval = null;
  },
};
</script>

<style lang="scss" scoped>
.vehicle-card-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 18px;
  width: 140px;
  
  @media only screen and (max-width: 920px) {
    width: 100px;
  }

  span {
    color: #373737;
    font-family: "Lexend Deca";
    font-size: v-bind(fontSize);
    font-style: normal;
    font-weight: 700;
    line-height: 108.5%; /* 17.36px */
    text-align: left;
  }

  .loading {
    font-size: v-bind(loadingFontSize);

  }
}


</style>
