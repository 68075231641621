<template>
  <button class="back-version-button" @click="disableNewPortal" id="alterarPortal">
    <i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar para a versão
    original
  </button>
</template>

<script>
import PORTAL_BASE from "@/constants/PortalBase";

export default {
  name: "BackToLegacyButton",
  methods: {
    disableNewPortal() {
      window.location.href = `${PORTAL_BASE}/avaliacoes?desativar_novos_filtros=1`;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-version-button {
  background: linear-gradient(270deg, #ff7a00 0%, #ff7a00 100%),
    linear-gradient(90deg, #ff7a00 0%, #ff7a00 100%);
  border-radius: 7px;
  color: white;
  padding: 4px 24px;
  font-size: 13px;
  font-family: "Roboto", Roboto, Helvetica, sans-serif;
}

@media only screen and (max-width: 1024px) {
  .back-version-button  {
    font-size: 11px !important;
  }
}
</style>
