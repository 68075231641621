<template>
  <BuyerOneColumnPage
    :title="title"
    subTitle="Suas compras concretizadas via propostas"
    :icon="icon"
    :section="section"
    :description="description"
    :getContent="getProposalCompleted"
    labelCard="Comprado por"
    buyerScope="ProposalCompleted"
    noVehicleLabel="Você ainda não enviou proposta em nenhum veículo…"
  />
</template>

<script>
import { BUYER_SECTION_CLOSED } from "@/views/buyer/constants/BuyerSections";

import { getProposalCompleted } from "@/services/api/BuyerServiceApi";

import PlatformsMixins from "@/mixins/PlatformsMixins";
import BuyerComponentPagesMixins from "@/mixins/BuyerComponentPagesMixins";
import BuyerOneColumnPage from "../../BuyerOneColumnPage";

export default {
  name: "AnnouncementCompletedPurchasesPage",
  mixins: [PlatformsMixins, BuyerComponentPagesMixins],
  components: { BuyerOneColumnPage },
  data: function () {
    return {
      ...BUYER_SECTION_CLOSED,
      title: "Compras concretizadas - Propostas aceitas",
      section: "Encerrados",
    };
  },
  methods: {
    getProposalCompleted,
  },
};
</script>

<style scoped></style>
