<template>
  <VehicleItemMobile
    v-if="isMobile"
    :vehicle="vehicle"
    :index="index"
    :linha="linha"
    :link="link"
    :isPackage="is_package"
    :title="
      capitalize(`${getValue(vehicle.v_marca)} ${getValue(vehicle.v_modelo)}`)
    "
    :image="getValue(vehicle.foto)"
    :version="getValue(vehicle.v_versao)"
    :packageData="{
      count: parseInt(vehicle.qtdeVeic),
      disablePrevious: currentSlide == 0,
      disableNext: currentSlide == parseInt(vehicle.qtdeVeic - 1),
      nextSlide,
      prevSlide,
    }"
    :showAutobidInfo="showAutobidInfo"
  />
  <VehicleItemDesktop
    v-else-if="!isMobile && view === 'grid'"
    :vehicle="vehicle"
    :index="index"
    :linha="linha"
    :link="link"
    :isPackage="is_package"
    :title="
      capitalize(`${getValue(vehicle.v_marca)} ${getValue(vehicle.v_modelo)}`)
    "
    :image="getValue(vehicle.foto)"
    :version="getValue(vehicle.v_versao)"
    :packageData="{
      count: parseInt(vehicle.qtdeVeic),
      disablePrevious: currentSlide == 0,
      disableNext: currentSlide == parseInt(vehicle.qtdeVeic - 1),
      currentSlide,
      setSlide,
      nextSlide,
      prevSlide,
    }"
    :animate="animate"
    :showAutobidInfo="showAutobidInfo"
  />
  <VehicleItemDesktopHorizontal
    v-else-if="!isMobile && view === 'list'"
    :vehicle="vehicle"
    :index="index"
    :linha="linha"
    :link="link"
    :isPackage="is_package"
    :title="
      capitalize(`${getValue(vehicle.v_marca)} ${getValue(vehicle.v_modelo)}`)
    "
    :image="getValue(vehicle.foto)"
    :version="getValue(vehicle.v_versao)"
    :packageData="{
      count: parseInt(vehicle.qtdeVeic),
      disablePrevious: currentSlide == 0,
      disableNext: currentSlide == parseInt(vehicle.qtdeVeic - 1),
      currentSlide,
      setSlide,
      nextSlide,
      prevSlide,
    }"
    :animate="animate"
  />
</template>
<script>
import PLATFORMS from "@/constants/Platforms";

import getPlatform from "@/helpers/getPlatform";
import { capitalize } from "@/helpers/strings";
import { getLinkForVdp } from "@/helpers/vdp";

import VehicleItemDesktop from "./VehicleItemDesktop.vue";
import VehicleItemDesktopHorizontal from "./VehicleItemDesktopHorizontal.vue";
import VehicleItemMobile from "./VehicleItemMobile.vue";

export default {
  name: "VehicleItem",
  props: {
    vehicle: Object,
    index: Number,
    linha: Number,
    view: {
      type: String,
      default: "grid",
    },
    showAutobidInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      time: null,
      interval: null,
      is_package: false,
      currentSlide: 0,
      animate: false,
    };
  },
  methods: {
    setSlide(newCurrentSlide) {
      this.currentSlide = newCurrentSlide;
    },
    nextSlide() {
      this.currentSlide++;
      return this.currentSlide;
    },
    prevSlide() {
      this.currentSlide--;
      return this.currentSlide;
    },
    getValue: function (str) {
      if (!this.is_package) return str;
      let parts = str.split(",");
      if (this.currentSlide > parts.length) return str;
      return parts[this.currentSlide] ?? str;
    },
    capitalize,
    formatTime: function (time) {
      return time < 10 ? "0" + time : time;
    },
    subtractSeconds: function () {
      const newTime = new Date(this.time);
      newTime.setSeconds(newTime.getSeconds() - 1);
      this.time = newTime;
    },
  },
  watch: {
    currentSlide: function () {
      this.animate = false;
      window.setTimeout(() => {
        this.animate = true;
      }, 100);
    },
  },
  computed: {
    link: function () {
      return getLinkForVdp(this.vehicle);
    },
    isMobile: function () {
      return getPlatform() === PLATFORMS.MOBILE;
    },
  },
  mounted: function () {
    this.time = new Date(parseInt(this.vehicle.dt_ts_final));
    this.interval = setInterval(() => this.subtractSeconds(), 1000);
    this.is_package = parseInt(this.vehicle.qtdeVeic) > 1;
  },
  unmounted: function () {
    clearInterval(this.interval);
    this.time = null;
    this.interval = null;
  },
  components: { VehicleItemDesktop, VehicleItemDesktopHorizontal, VehicleItemMobile },
};
</script>

<style lang="scss" scoped>
@keyframes pushCard {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
    top: 1px;
    right: -2px;
  }
  70% {
    opacity: 0.8;
    right: 0;
  }
  100% {
    top: initial;
    opacity: 1;
  }
}
@keyframes pushCardClone {
  0% {
  }
  50% {
    top: -1px;
    right: -2px;
  }
  100% {
    top: initial;
    right: initial;
  }
}
.pushCard {
  animation-name: pushCard;
  animation-duration: 0.5s;
  animation-direction: normal;
  animation-timing-function: ease;
  transition: all;
}
.pushCardClone {
  animation: pushCardClone 0.8s;
  animation-direction: normal;
  transition: all;
}

.vehicle {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 1;
  display: flex;
  position: relative;
  .vehicle-item {
    background-color: #fff;
    position: relative;
    z-index: 2;
    border-radius: 12px;
    &.package {
      position: relative;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    }
    &.clone {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      right: -7px;
      top: -3px;
      animation-duration: 0.3s;
      &:nth-child(2) {
        z-index: 0;
        right: -12px;
        top: -8px;
        animation-duration: 0.4s;
      }
    }
  }
}

.vehicle-header {
  position: relative;
}

.vehicle-header .location {
  position: absolute;
  margin-top: 12px;
  margin-left: 12px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  z-index: 1;
}

.vehicle-header img {
  height: 211px;
  width: 332px;
  border: 7px solid #ffffff;
  border-radius: 12px;
  object-fit: cover;
}

.vehicle-header .price {
  position: absolute;
  background: #ff7a00;
  border-radius: 5px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  right: 12px;
  bottom: 40px;
}

.vehicle-header h3 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: #373737;
  margin-left: 12px;
  text-transform: capitalize;
}

.vehicle-header h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.vehicle-header .overlay {
  position: absolute;
  top: 7px;
  display: block;
  height: 211px;
  width: 319px;
  border-radius: 5px;
  left: 7px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #000000 0.01%,
    rgba(0, 0, 0, 0) 33.11%
  );
  z-index: 0;
}

.divider {
  width: 100%;
  padding: 2px 10px;
  margin: 6px 0;
}

.divider .content {
  width: 100%;
  border: 0.8px solid #ededed;
}

.description {
  display: flex;
  margin: 4px 12px;
}

.detail {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0;
}

.detail span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  text-align: left;
  flex: 1;
}

.more-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.more-info .fipe {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.11em;

  color: #ffffff;
}
.more-info .percent {
  position: absolute;
  z-index: 100;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  letter-spacing: -0.11em;
  margin-top: 12px;
  margin-left: 11px;

  color: #ffffff;
}

.seller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  height: 107px;
  width: 107px;
}

.seller h3 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  text-align: center;
}

.seller h4 {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  align-items: center;
}

.vehicle-footer {
  display: flex;
  flex-direction: row;
  margin: 8px;
}

.footer-info {
  display: flex;
  flex: 1;
}

.timer {
  margin-left: 4px;
}

.timer span {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  display: flex;
  align-items: center;

  color: #373737;
}

.timer h4 {
  margin-top: -4px;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  align-items: center;
  color: #373737;
}

.vehicle-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ff7a00;
  border-radius: 7px;
  padding: 0 8px;

  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  align-items: center;

  color: #ff7a00;
  padding: 0 22px;
  text-decoration: none;
}

.vehicle-footer a:hover {
  opacity: 0.8;
}
.nav-bullets {
  position: absolute;
  z-index: 1;
  bottom: 3.5em;
  left: 1em;
  i {
    cursor: pointer;
    margin: 3px;
    color: #000f;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
    &.active {
      color: #fff;
    }
  }
}
.fipe-container {
  position: relative;
}
.details-package {
  img {
    float: left;
  }
  strong {
    display: inline-block;
  }
  .qtd {
  }
  figcaption {
    line-height: 1.2em;
    font-size: 0.92em;
    text-indent: 0.4em;
  }
  .controls {
    font-size: 0.85em;
    align-content: space-between;
    display: flex;
    margin-top: 2em;
    border-top: 1px solid #eee;
    padding-top: 1.5em;
    a {
      color: #373737;
      text-decoration: none;
      flex: auto;
      transition: 0.2s all;
      &.disable {
        color: lightgray;
        pointer-events: none;
      }
    }
  }
}
</style>
